import React, { useEffect, useState } from "react";
import { clearLocalStorages } from "../../settings/CommonJS";
 import { useDispatch, useSelector } from 'react-redux';

import LocalizedLanguage from "../../settings/LocalizedLanguage";
import { externalLogin } from "./externalLoginSlice";
import { encode_UDid } from "../common/localSettings";
import { useNavigate } from "react-router-dom";
import Oliver_Type from '../../assets/images/svg/Oliver-Type.svg';
import { NavigateComponent } from "../../settings";
import { useIndexedDB } from "react-indexed-db";
import { productCount } from "../loadProduct/productCountSlice";

function LoadingMessage(props) {
  
      // var isDemoUser = localStorage.getItem("demoUser") ? localStorage.getItem("demoUser") : false;
        return (
            <div className="loading-page-wrapper">
            <img src={Oliver_Type} alt="" />
            <svg xmlns="http://www.w3.org/2000/svg" x={0} y={0} width={85} height={85} viewBox="0 0 400 400" enableBackground="new 0 0 400 400">
              <g>
                <rect x="249.28" y="156.01" className="st0 ologo-1" width="103.9" height="103.9" />
                <path id="teal" className="st1 ologo-2" d="M249.28,363.81V259.91h103.9C353.17,317.29,306.66,363.81,249.28,363.81z" />
                <rect id="cyan" x="145.38" y="259.91" className="st2 ologo-3" width="103.9" height="103.89" />
                <path id="blue" className="st3 ologo-4" d="M41.49,259.91L41.49,259.91h103.9v103.89C88,363.81,41.49,317.29,41.49,259.91z" />
                <rect id="purple" x="41.49" y="156.01" className="st4 ologo-5" width="103.9" height="103.9" />
                <path id="red" className="st5 ologo-6" d="M41.49,156.01L41.49,156.01c0-57.38,46.52-103.9,103.9-103.9v103.9H41.49z" />
                <rect id="orange" x="145.38" y="52.12" className="st6 ologo-7" width="103.9" height="103.9" />
                <path id="yellow" className="st7 ologo-8" d="M281.3,123.99V20.09c57.38,0,103.9,46.52,103.9,103.9H281.3z" />
              </g>
            </svg>
           {/*  <p className="style1">{LocalizedLanguage.syncingwithyourstore}</p>
          {props.statusCompleted && props.statusCompleted !== 'NaN' &&
            <p className="style2">{props.statusCompleted > 100 ? 100 : props.statusCompleted}% {LocalizedLanguage.completed}</p>} */}
          
          {  props.loginError =="" && <p className="style2">Loading...</p>}
           {  props.loginError =="" && <p className="style3">{LocalizedLanguage.dontCloseWindow}</p>}
            {props.loginError && props.loginError !=="" &&  <div className="custom-error-message user-info">
                        { props.loginError}
                    </div>}
          </div>
        )
      
   
  }
  

const ExternalLogin = () => {
    const dispatch = useDispatch();
    const [loginError,setLoginError]=useState("");
    // const {getByID } = useIndexedDB("products");
    // const [isShowPopups, setisShowPopups] = useState(false);
    // const [selProduct, setSelProduct] = useState(null);
    
    // const [isShowProductTypeNotSupported, setIsShowProductTypeNotSupported] = useState(false);
     const navigate = useNavigate()
    // var Cash_Management_ID = localStorage.getItem('Cash_Management_ID')
    const { status, data, error, is_success } = useSelector((state) => state.externalLogin)
    console.log( status, data, error, is_success)
    

  //Clear index db-----------------------------   
  var { clear: customers_clear } = useIndexedDB('customers' );
  var { clear: modifiers_clear } = useIndexedDB('modifiers');
  var { clear: products_clear } = useIndexedDB('products');
  var _error="";
  if (!sessionStorage.getItem("lastUrl")) {
      customers_clear().then(() => {
          //console.log('All Clear customers!');
      });
      modifiers_clear().then(() => {
          // console.log('All Clear modifiers!');
      });
      products_clear().then(() => {
          //console.log('All Clear products!');
      });      
  }
  //-------------------------------------------

    useEffect(() => {
      if (is_success===false  && error && error !== "") {
        if(error.length>0)
        console.log("ohh!",error)
          setLoginError(error)
        }
    }, [error, is_success]);

    var isCallable = true;
    useEffect(() => {
      localStorage.removeItem('remember_data');
              if (isCallable === true) {
            clearLocalStorages()
           // window.indexedDB.deleteDatabase('ProductDB');

            var urlParam = window.location.search;
            var splParam = urlParam.replace("?", "").split("&");
            var finalParam = ""
            splParam.forEach(element => {
              finalParam += finalParam == "" ? "" : "&";
              finalParam += element.substring(0, element.indexOf('=')) + "=" + encodeURIComponent(element.substring(element.indexOf('=') + 1));
            });
        
            //console.log("finalParam",finalParam)
            if (finalParam && finalParam !== "") {
              
              setTimeout(function () {
                dispatch(externalLogin(finalParam))
              }, 500)
            }
            isCallable = false;
        }
             
    }, []);

    if (is_success===true  && data && data.content) {
        var loginRes =  data.content;
        //this.setState({ loading: false })
        if (loginRes.subscription_detail.udid) {
          
          var locations = []
          locations=loginRes.locations;
          var userSubscription=loginRes.subscription_detail;
          localStorage.setItem("AUTH_KEY",userSubscription.client_guid + ":" +  userSubscription.server_token);
        
          var lang =  loginRes && loginRes.subscription_permission.language ? loginRes.subscription_permission.language :'en';
          localStorage.setItem("LANG", lang);  
         // console.log("loginRes",loginRes);
          var sitelis ={};
       
          Object.keys(loginRes).map(key => {
            var taxvalue = loginRes[key];
            sitelis[key]=taxvalue;            
          })
          sitelis['subscriptions']= [{"subscription_detail":userSubscription}];
          console.log("sitelis",sitelis)
          localStorage.setItem('sitelist', JSON.stringify(sitelis));
         
                              
              localStorage.setItem('userId', loginRes.user_id)
              localStorage.setItem("clientDetail",JSON.stringify(loginRes));
              localStorage.setItem("hasPin", loginRes.HasPin && loginRes.HasPin);
              localStorage.setItem("showExtention", loginRes && loginRes.subscription_permission?loginRes.subscription_permission.show_extension:false);
              encode_UDid(loginRes.udid);


          setTimeout(function () {
            localStorage.removeItem("selectedRegister");   
            localStorage.setItem("UserLocations", JSON.stringify(locations));
            encode_UDid(loginRes.subscription_detail.udid);      
              //GTM_ClientDetail();      
              if (loginRes.locations.length==1 && loginRes.registers.length==1) {
            // if (loginRes.locations.length==1 && loginRes.registers.length==1) {
              localStorage.setItem("Location", loginRes.locations[0].id);
              localStorage.setItem("LocationName", loginRes.locations[0].name);
              localStorage.setItem('WarehouseId', loginRes.locations[0].warehouse_id);
              localStorage.setItem("register", loginRes.registers[0].id);
              localStorage.setItem('pdf_format', JSON.stringify(loginRes.registers));
              localStorage.setItem('registerName', loginRes.registers[0].name);
              localStorage.setItem("selectedRegister",JSON.stringify(loginRes.registers[0]));   
              
            //   if (localStorage.getItem('demoUser') != 'true') {
            //     //window.location = '/loginpin';
            //     isShowWrapperSetting("ExternalLogin.js",'loginpin');
            //   }
           
            dispatch(productCount(loginRes.subscription_detail.udid));
            setTimeout(function () {
                navigate('/productloader');
              }, 500)
            //NavigateComponent(navigate,'pin');
            } else if (loginRes.locations) {
              localStorage.setItem('UserLocations', JSON.stringify(loginRes.locations));
              
             // isShowWrapperSetting("ExternalLogin.js",'login_location');
              //history.push('/login_location');
              navigate('/location')
            } else {
                navigate('/login');
            }
          }, 500)
        } else {
            navigate('/login');
        }
      }

    return (
        <React.Fragment>
        <LoadingMessage loginError={loginError}/>
        </React.Fragment>)


}
export default ExternalLogin 