import { addSimpleProducttoCart, addtoCartProduct } from "../product/productLogic";
import { product } from "../product/productSlice";
import Config from '../../../Config'
import FormateDateAndTime from "../../../settings/FormateDateAndTime";
import { store } from "../../../app/store";
import { v4 as uniqueKey } from 'uuid';

export const addProductWithDefaultModifier = (_product, rows) => {
    var modifiers_key = uniqueKey();
    if (typeof _product != "undefined") {
        var result = '';
        if (rows.length > 0) {
            var defaultModifers = getProductModifiers(rows, _product, false, modifiers_key, true)
            if (defaultModifers && defaultModifers.length > 0) {
                var ids = defaultModifers;//getDefautModifiersId(defaultModifers);
                ids = ids.filter(a => a.is_active == true);
                var _data = [];
                var oldCartlist = localStorage.getItem("CARD_PRODUCT_LIST") ? JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST")) : [];
                if (oldCartlist && oldCartlist.length > 0) {
                    var updatedItem = oldCartlist.find(a => a.product_id == _product.WPID
                        && (a.hasOwnProperty("modifiers_Id") ? (JSON.stringify(a.modifiers_Id) == JSON.stringify(ids) || a.modifiers_Id == JSON.stringify(ids)) : true))
                    //&& ((a.hasOwnProperty("modifiers_Id") && JSON.stringify(a.modifiers_Id) == JSON.stringify(ids)) || (!a.hasOwnProperty("modifiers_Id"))));
                    if (typeof updatedItem != "undefined" && updatedItem != null) {
                        if (updatedItem.hasOwnProperty("modifiers_Id")) {
                            _product["modifiers_Id"] = updatedItem.modifiers_Id;

                        }
                        var cartItemIndex = oldCartlist.indexOf(updatedItem);
                        if (cartItemIndex != -1) {
                            _product["selectedIndex"] = cartItemIndex;
                            _product["quantity"] = updatedItem.quantity + 1;
                            _product["old_price"] = updatedItem.old_price;
                            _product["incl_tax"] = updatedItem.incl_tax;
                            _product["excl_tax"] = updatedItem.excl_tax;
                            _product['after_discount'] = updatedItem.after_discount;
                            _product['discount_amount'] = updatedItem.discount_amount;
                            _product['product_after_discount'] = updatedItem.product_after_discount;
                            _product['product_discount_amount'] = updatedItem.product_discount_amount;
                            _product['discount_type'] = updatedItem.discount_type;
                            _product['new_product_discount_amount'] = updatedItem.new_product_discount_amount;
                            _product['cart_after_discount'] = updatedItem.cart_after_discount;
                            _product['cart_discount_amount'] = updatedItem.cart_discount_amount;
                        }
                        _product["modifiers_key"] = updatedItem.modifiers_key;
                        modifiers_key = updatedItem.modifiers_key;
                    }
                    else if (typeof ids !== "undefined" && ids != null && ids.length > 0) {
                        _product["modifiers_Id"] = JSON.stringify(ids);
                        _product["modifiers_key"] = modifiers_key;
                    }
                    else {
                        _product["modifiers_Id"] = [];
                        _product["modifiers_key"] = modifiers_key;
                    }
                    _data = oldCartlist
                        .filter(prd => (
                            (prd.WPID == _product.WPID || prd.variation_id == _product.WPID || prd.product_id == _product.WPID)
                            && (prd.hasOwnProperty("modifiers_key") && _product.hasOwnProperty("modifiers_key") && prd.modifiers_key == _product.modifiers_key)
                            && (prd.hasOwnProperty("modifiers_Id") && _product.hasOwnProperty("modifiers_Id") && JSON.stringify(prd.modifiers_Id) == JSON.stringify(_product.modifiers_Id))
                        )
                        );
                    if (_data && _data.length > 0) {
                        modifiers_key = _data[0].modifiers_key;
                    }
                }
                if (ids) {
                    _product["modifiers_key"] = modifiers_key;
                    _product["modifiers_Id"] = JSON.stringify(ids);
                }
                result = addSimpleProducttoCart(_product,null,true);
                if (result === 'outofstock') {
                    return result;
                }
                else {
                    addModifierAsCustomFee(defaultModifers, _product);
                }
            }
            else {
                result = addSimpleProducttoCart(_product);
                if (result === 'outofstock') {
                    return result;
                }
                else {
                    store.dispatch(product());
                }
            }
        }
        else {
            result = addSimpleProducttoCart(_product);
            if (result === 'outofstock') {
                return result;
            }
            else {
                store.dispatch(product());
            }
        }
    }
}
export const getProductModifiers = (rows, product = null, isClear = false, modifiers_key = '', sendBackIds = false) => {
    var _modifierList = rows;
    var d = _modifierList && _modifierList.filter(match => match.Visible == true && match.modifierAssingnees.find(m =>
        (m.AssigneeId == product.WPID && m.AssigneeType == Config.key_AssigneeType.Product) ||
        (product.CategorieList && product.CategorieList.find(x => x.toLowerCase() == m.AssigneeName.toLowerCase()) && m.AssigneeType == Config.key_AssigneeType.Category) ||
        (product.ProductAttributes && product.ProductAttributes.find(x => x.Name.toLowerCase() == m.AssigneeName.toLowerCase()) && m.AssigneeType == Config.key_AssigneeType.Attribute) ||
        (product.CategorieList && product.CategorieList.find(x => x.toLowerCase() == m.AssigneeName.toLowerCase()) && m.AssigneeType == Config.key_AssigneeType.SubCategory) ||
        (product.ProductAttributes && product.ProductAttributes.find(x => x.Name.toLowerCase() == m.AssigneeName.toLowerCase()) && m.AssigneeType == Config.key_AssigneeType.SubAttribute)
    )).map((match) => { return match })

    var all_modifiers = [];
    var modifiers = [];
    if (d && d.length > 0) {
        d.map(m => {
            let result = (m.modifierSchedulings && m.modifierSchedulings.length > 0) ? isActive(m.modifierSchedulings[0]) : true;
            if (result && result == true) {
                all_modifiers.push(m);
                var data = [];
                m.modifierFields && m.modifierFields.map(mf => {
                    return (mf.ExtendFormData && mf.ExtendFormData.map(efm => {
                        if (efm.Default === "yes") {
                            data.push({ id: efm.ModifierId, sub_title: efm.Name, qty: 1, amount: efm.Amount, add_sub: efm.AddnSubtract, amount_type: efm.Type, field_type: m.Type });
                        }
                    }))
                })
                modifiers.push({ id: m.Id, modifier_id: m.Title.replace(/ /g, "_"), title: m.Title.replace(/ /g, "_"), type: m.Type, is_active: data.length > 0 ? true : false, TaxOption: m.TaxOption, data: data/*, modifiers_key: modifiers_key*/ })
            }
        });
        //var ids = getDefautModifiersId(modifiers);
        var ids = modifiers;//getDefautModifiersId(modifiers);
        if (sendBackIds == false) {
            var cartlist = localStorage.getItem("CARD_PRODUCT_LIST") ? JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST")) : []
            if (cartlist && cartlist.length > 0) {
                //var updatedItem = cartlist.find(a => a.product_id == product.WPID);
                var updatedItem = cartlist.find(a => a.product_id == product.WPID
                    && ((a.hasOwnProperty("modifiers_Id") && JSON.stringify(a.modifiers_Id) == JSON.stringify(ids)) || (!a.hasOwnProperty("modifiers_Id"))));
                if (typeof updatedItem != "undefined" && updatedItem != null) {
                    if (updatedItem.hasOwnProperty("modifiers_Id")) {
                        product["modifiers_Id"] = updatedItem.modifiers_Id;

                    }
                    product["modifiers_key"] = updatedItem.modifiers_key;
                }

                //modifiers = modifiers.map(obj => { return { ...obj, modifiers_key: updatedItem.modifiers_key }; })

                //modifiers=  modifiers && modifiers.map(b => b["modifiers_key"] = updatedItem.modifiers_key);
            }
        }
        else {
            return modifiers;
        }
    }
    if (sendBackIds == false) {
        addModifierAsCustomFee(modifiers, product);
    }
}

export const getDefautModifiersId = (_saveSelectedModifiers) => {
    var modiferData = (_saveSelectedModifiers && _saveSelectedModifiers.length > 0) ? _saveSelectedModifiers.filter(a => a.is_active == true) : [];
    var modiferIds = modiferData && modiferData.map((values, index) => {
        return values.data.map(({
            ...d
        }) => {
            return [d.id, d]
        });
    });
    return modiferIds;
}

export const addModifierAsCustomFee = (_selectedModifiers, _product) => {
    var cartlist = localStorage.getItem("CARD_PRODUCT_LIST") ? JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST")) : []
    if (cartlist && cartlist.length > 0) {
        var updatedItem = cartlist.find(a => a.product_id == _product.WPID && ((a.hasOwnProperty("modifiers_key") && a.modifiers_key == _product.modifiers_key) || !a.hasOwnProperty("modifiers_key")));
        //var updatedItem = cartlist.find(a => a.product_id == _product.WPID && ((a.hasOwnProperty("modifiers_key") && _product.hasOwnProperty("modifiers_key") && a.modifiers_key == _product.modifiers_key) || (!a.hasOwnProperty("modifiers_key") || !_product.hasOwnProperty("modifiers_key"))));
        if (typeof updatedItem != "undefined" && updatedItem != null) {
            _product = updatedItem;
        }
    }
    var _saveSelectedModifiers = _selectedModifiers;
    var _quantity = _product.quantity + (_product.hasOwnProperty('quantity_refunded') == true ? _product.quantity_refunded : 0);
    //var product_price = props.selProduct.Price * (props.selProduct.quantity + (props.selProduct.hasOwnProperty('quantity_refunded')==true ? props.selProduct.quantity_refunded : 0));//getSettingCase() == 2 || getSettingCase() == 4 || getSettingCase() == 7 ? tax_is && cartPriceWithTax(tax_is.old_price, getSettingCase(), tax_is.TaxClass) : getSettingCase() == 6 ? tax_is && tax_is.old_price : tax_is && tax_is.old_price;
    var product_price = (_product.after_discount != 0 ? _product.after_discount : (_product.old_price * _quantity));
    // console.log("---product_price---" + product_price);
    var _data = [];
    _saveSelectedModifiers && _saveSelectedModifiers.map(m => {
        if (m.is_active == true) {
            var _summary = "";
            var _sum = 0;
            var _amount = 0;
            m.data.map(n => {
                _summary += (_summary == "" ? "" : ", ") + (n.sub_title != null ? n.sub_title : "");
                if (n.add_sub && n.add_sub == "subtract") {

                    if (n.amount_type == "%") {
                        var a = (product_price * n.amount) / 100;
                        _sum += parseFloat(-a);
                        _amount+=parseFloat(-a/_quantity);
                    }
                    else {
                        _sum += parseFloat(-n.amount * _quantity);
                        _amount+=parseFloat(-n.amount);
                    }
                }
                else {

                    if (n.amount_type == "%") {
                        var a = (product_price * n.amount) / 100;
                        _sum += parseFloat(a);
                        _amount+=parseFloat(a);
                    }
                    else { 
                        _sum += parseFloat(n.amount * _quantity); 
                        _amount+=parseFloat(n.amount);
                    }
                }
            });
            if (m.data.length > 0)
                _data.push({ id: m.Id, Title: m.title.replace("_", " ") + (_summary != null & _summary != "" ? " (" + _summary + ")" : ""), Price: _sum, old_price: _amount, isTaxable: m.TaxOption, TaxStatus: (m.TaxOption == true ? "taxable" : "none"), TaxClass: _product ? _product.TaxClass : '', discount_type: '', quantity: _quantity, check_modifer: true, pid: _product.hasOwnProperty("product_id") ? _product.product_id : _product.WPID, modifiers_key: _product.modifiers_key });
        }
    })
    var modiferData = (_saveSelectedModifiers && _saveSelectedModifiers.length > 0) ? _saveSelectedModifiers.filter(a => a.is_active == true) : [];
    var modiferIds = modiferData;
    // var modiferIds = modiferData.map((values, index) => {
    //     return values.data.map(({
    //         ...d
    //     }) => {
    //         return [d.id, d]
    //     });
    // })

    if (_data && _data.length > 0) {
        var cartlist = localStorage.getItem("CARD_PRODUCT_LIST") ? JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST")) : []
        var _pid = _product.product_id ? _product.product_id : _product.WPID;

        var productToModifier = cartlist.find(a => a.product_id == _pid && a.modifiers_key == _product.modifiers_key);
        if (typeof productToModifier != "undefined" && productToModifier != null) {
            productToModifier["modifiers_Id"] = JSON.stringify(modiferIds);
            var _index = cartlist.indexOf(productToModifier);
            cartlist[_index] = productToModifier;
        }

        // var replaceModi = cartlist.filter(a => (a.pid != _pid) || (!a.hasOwnProperty('check_modifer') && a.Price == null));
        var replaceModiOnly = cartlist.filter(a => !a.hasOwnProperty("product_id") && a.modifiers_key == _product.modifiers_key && (a.hasOwnProperty('check_modifer')) && a.check_modifer == true);

        // var replaceModi = cartlist.filter(a =>(
        //     a.hasOwnProperty("pid") == false || (a.hasOwnProperty("pid") == true && a.pid !== _product.product_id)
        //     ||(a.hasOwnProperty("pid") == true && a.pid == _product.product_id && a.hasOwnProperty("modifiers_key") && _product.hasOwnProperty("modifiers_key")
        //     ? a.modifiers_key !== _product.modifiers_key : true)) 
        // );
        var replaceModi = cartlist.filter((el) => !replaceModiOnly.includes(el));
        if (typeof replaceModi != "undefined" && replaceModi != null && replaceModi.length > 0) {
            cartlist = replaceModi == null ? [] : replaceModi;
        }
        else {
            cartlist = cartlist == null ? [] : cartlist;
        }
        //cartlist = cartlist == null ? [] : cartlist;

        //ADDING fee_id ATTRIBUTE IN THE MODIFIER(lineitem/custom fee)
        if (typeof replaceModiOnly != "undefined" && replaceModiOnly != null && replaceModiOnly.length > 0) {
            replaceModiOnly && replaceModiOnly.map((m, index) => {
                var feefound = replaceModiOnly.find((a, _index) => a.Title == m.Title && index == _index);
                if (typeof feefound != "undefined" && feefound != null && feefound.hasOwnProperty("fee_id")) {
                    var foundIndex = replaceModiOnly.indexOf(feefound);
                    if (_data[foundIndex]) {
                        _data[foundIndex]["fee_id"] = feefound.fee_id;
                    }
                }
            })
        }
        //END
        cartlist = cartlist.concat(_data);
        addtoCartProduct(cartlist);
        store.dispatch(product());
    }
    else {
        store.dispatch(product());
    }
}


const isActive = (modifierSchedulings) => {
    var schedul = modifierSchedulings;
    var isActive = false;
    if (schedul != null) {
        if (!schedul.AllowScheduling)
            isActive = true;
        else {
            //var today = SystemTime != null ? SystemTime : DateTime.Today.ToLocalTime();
            var today = new Date();
            if (schedul.SelectDateRange) {
                //if (today.Date >= schedul.FromDate.Date && today.Date <= schedul.ToDate.Date)
                if (new Date(today.toDateString()) >= new Date(schedul.FromDate) && new Date(today.toDateString()) <= new Date(schedul.ToDate))
                    isActive = checkActiveTime(today, schedul);
                else
                    isActive = false;
            }
            else
                isActive = checkActiveTime(today, schedul);
        }
    }
    return isActive;

}


const checkActiveTime = (today, schedul) => {
    //var todayTime = today.TimeOfDay;
    var todayTime = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var _to = 0;
    var _from = 0;
    switch (today.getDay()) {
        case 1:
            if (schedul.MondayActive && schedul.MondayActive == true) {
                _to = FormateDateAndTime.timeCompare(todayTime, schedul.MondayFrom);
                _from = FormateDateAndTime.timeCompare(schedul.MondayTo, todayTime);
                if ((_to == 1 || _to == 0) && (_from == 1 || _from == 0)) { return true; }
                else { return false; }
            }
            // if (schedul.MondayActive && todayTime >= schedul.MondayFrom && todayTime <= schedul.MondayTo)
            //     return true;
            break;
        case 2:
            if (schedul.TuesdayActive && schedul.TuesdayActive == true) {
                _to = FormateDateAndTime.timeCompare(todayTime, schedul.TuesdayFrom);
                _from = FormateDateAndTime.timeCompare(schedul.TuesdayTo, todayTime);
                if ((_to == 1 || _to == 0) && (_from == 1 || _from == 0)) { return true; }
                else { return false; }
            }
            // if (schedul.TuesdayActive && todayTime >= schedul.TuesdayFrom && todayTime <= schedul.TuesdayTo)
            //     return true;
            break;
        case 3:
            if (schedul.WednesdayActive && schedul.WednesdayActive == true) {
                _to = FormateDateAndTime.timeCompare(todayTime, schedul.WednesdayFrom);
                _from = FormateDateAndTime.timeCompare(schedul.WednesdayTo, todayTime);
                if ((_to == 1 || _to == 0) && (_from == 1 || _from == 0)) { return true; }
                else { return false; }
            }
            // if (schedul.WednesdayActive && todayTime >= schedul.WednesdayFrom && todayTime <= schedul.WednesdayTo)
            //     return true;
            break;
        case 4:
            if (schedul.ThursdayActive && schedul.ThursdayActive == true) {
                _to = FormateDateAndTime.timeCompare(todayTime, schedul.ThursdayFrom);
                _from = FormateDateAndTime.timeCompare(schedul.ThursdayTo, todayTime);
                if ((_to == 1 || _to == 0) && (_from == 1 || _from == 0)) { return true; }
                else { return false; }
            }
            // if (schedul.ThursdayActive && todayTime >= schedul.ThursdayFrom && todayTime <= schedul.ThursdayTo)
            //     return true;
            break;
        case 5:
            if (schedul.FridayActive && schedul.FridayActive == true) {
                _to = FormateDateAndTime.timeCompare(todayTime, schedul.FridayFrom);
                _from = FormateDateAndTime.timeCompare(schedul.FridayTo, todayTime);
                if ((_to == 1 || _to == 0) && (_from == 1 || _from == 0)) { return true; }
                else { return false; }
            }
            // if (schedul.FridayActive && todayTime >= schedul.FridayFrom && todayTime <= schedul.FridayTo)
            //     return true;
            break;
        case 6:
            if (schedul.SaturdayActive && schedul.SaturdayActive == true) {
                _to = FormateDateAndTime.timeCompare(todayTime, schedul.SaturdayFrom);
                _from = FormateDateAndTime.timeCompare(schedul.FridayTo, schedul.SaturdayTo);
                if ((_to == 1 || _to == 0) && (_from == 1 || _from == 0)) { return true; }
                else { return false; }
            }
            // if (schedul.SaturdayActive && todayTime >= schedul.SaturdayFrom && todayTime <= schedul.SaturdayTo)
            //     return true;
            break;
        case 0:
            if (schedul.SundayActive && schedul.SundayActive == true) {
                _to = FormateDateAndTime.timeCompare(todayTime, schedul.SundayFrom);
                _from = FormateDateAndTime.timeCompare(schedul.FridayTo, schedul.SundayTo);
                if ((_to == 1 || _to == 0) && (_from == 1 || _from == 0)) { return true; }
                else { return false; }
            }
            // if (schedul.SundayActive && todayTime >= schedul.SundayFrom && todayTime <= schedul.SundayTo)
            //     return true;
            break;
        default:
            return false;
            break;
    }
    return false;
}


