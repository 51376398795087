import { get_UDid } from '../../common/localSettings';
//import { customerActions } from "../CustomerPage";
//import { history, store } from "../_helpers";
import { store } from '../../../app/store'
import Config from '../../../Config'
//import { PrintAppData } from "../_components/PrintAppData";
//import { TriggerCallBack } from '../appManager/FramManager'
import { isMobileOnly, isIOS } from "react-device-detect";
//import { activityActions } from "../ActivityPage";
//import FetchIndexDB from "../settings/FetchIndexDB";
//import { cartProductActions } from "../_actions";
//import { checkoutActions } from "../CheckoutPage";
import { changeTaxRate, getInclusiveTaxType, getTaxAllProduct, PrintPage } from "../../../settings";
import ActiveUser from '../../../settings/ActiveUser';


import {
  DataToReceipt, PrintReceiptWithAppData,
  handleCartValue, handleCart,
  sendCustomerDetail, HandleCustomer, CustomerToSale, retrieveCustomerInSale,
  addCartDiscount, cartTaxes, Notes, lockEnvironment, Environment,
  getOrderStatus, sendClientsDetails, doCustomFee, getReceiptData, addDiscountCoupon,
  transactionApp, transactionStatus, DoParkSale, AddProductToCart, payfromApp,
  WrapperReceipt,
  AddCustomerToSale,
  sendRegisterDetails, appreadywithtoken, getAmeliaMenu, WrapperReceiptUpdated, addShippingAddress,
  redeemPointsExtentionCart,cancelRedeemedPointsExtentionCart, TestPrintAndroidReceipt
} from './apps';
import { productPriceUpdate, RawProductData, sendProductQuantity } from './apps/productApp';
import { UpdateRecentUsedApp } from '../commonFunctions/AppDisplayFunction';
import { getStoreDetails, openCashDrawer, getEndOfDayDetails } from './commonWrapperAppHandler';
// var JsBarcode = require('jsbarcode');
// var print_bar_code;
// export const textToBase64Barcode = (text) => {
//   var canvas = document.createElement("canvas");
//   JsBarcode(canvas, text, {
//     format: "CODE39", displayValue: false, width: 1,
//     height: 30,
//   });
//   print_bar_code = canvas.toDataURL("image/png");
//   return print_bar_code;
// }

export const handleAppEvent = (value, whereToview, isbackgroudApp = false, navigate = null) => {
  // console.log("value", value)

  var jsonMsg = value ? value : '';
  var clientEvent = jsonMsg && jsonMsg !== '' && jsonMsg.command ? jsonMsg.command.toLowerCase() : '';
  
  //console.log("App Handler", jsonMsg)
  // console.log("clientEvent", clientEvent,whereToview)
  var appResponse = '';

  if (clientEvent && clientEvent !== '') {
    // console.log("clientEvent", jsonMsg)
    //this.setState({ showNewAppExtension:true})
    switch (clientEvent) {
      // common apps----------------------------------
      case ("appReady").toLowerCase():  //working
        appReady(whereToview, isbackgroudApp, isbackgroudApp) //done
        break;
      case ("ClientInfo").toLowerCase(): //done
        appResponse = sendClientsDetails(jsonMsg)
        break;
      case ("CloseExtension").toLowerCase():
        CloseExtension();
        break;
      case ("RegisterInfo").toLowerCase():
        sendRegisterDetails(jsonMsg);
        break;
      //-------------------------------------------------


      // for Home and checkot ------------------    
      case ("cartDiscount").toLowerCase():
        appResponse = addCartDiscount(jsonMsg, isbackgroudApp, whereToview)
        break
      case ("cartTaxes").toLowerCase():
      case ("CartTax").toLowerCase():
        appResponse = cartTaxes(jsonMsg, isbackgroudApp)
        break
      case ("addProductToCart").toLowerCase():
        appResponse = AddProductToCart(jsonMsg, isbackgroudApp, whereToview)
        break
      case ("Notes").toLowerCase():
        appResponse = Notes(jsonMsg, isbackgroudApp, whereToview)
        break
      case ("CustomFee").toLowerCase():
        appResponse = doCustomFee(jsonMsg)
        break
      //----------------------------------

      // For Checkout----------------------------------
      case ("CartValue").toLowerCase():
        handleCartValue(jsonMsg, whereToview, isbackgroudApp)
        break;
      case ("Cart").toLowerCase():
        handleCart(jsonMsg, whereToview, isbackgroudApp)
        break;

      case ("Payment").toLowerCase():
        appResponse = payfromApp(jsonMsg, isbackgroudApp) //done
        break
      case ("rawProductData").toLowerCase():
        RawProductData(jsonMsg, isbackgroudApp)
        break
      // //App 2.0--------------------
      case ("Transaction").toLowerCase(): //same as payment
        appResponse = transactionApp(jsonMsg, isbackgroudApp)
        break;
      case ("TransactionStatus").toLowerCase(): //same as payment
        appResponse = transactionStatus(jsonMsg, whereToview, isbackgroudApp)
        break;
      case ("discountCoupon").toLowerCase():
        appResponse = addDiscountCoupon(jsonMsg, isbackgroudApp, whereToview)
        break
      case ("ParkSale").toLowerCase():
        appResponse = DoParkSale(jsonMsg, navigate)
        break
      // ------------------------------------------
      //customer apps------------------------------
      case ("Customers").toLowerCase(): //Handle Customer events
        HandleCustomer(jsonMsg, isbackgroudApp);
        break;
      case ("CustomerDetails").toLowerCase():
        sendCustomerDetail(jsonMsg, isbackgroudApp)
        break;
      case ("CustomerInSale").toLowerCase():
        if (jsonMsg && jsonMsg.method == "post") {
          AddCustomerToSale(jsonMsg, isbackgroudApp)
        }
        else {
          retrieveCustomerInSale(jsonMsg, isbackgroudApp)

        }
        break;
      case ("CustomerToSale").toLowerCase():
        CustomerToSale(jsonMsg, isbackgroudApp)
        break;

      //----end customer apps--------------------------


      ///---Producut------------------------
      case ("Environment").toLowerCase():
        Environment(jsonMsg, isbackgroudApp, whereToview)
        break
      case ("lockEnvironment").toLowerCase():
        appResponse = lockEnvironment(jsonMsg, isbackgroudApp, whereToview)
        break
      case ("productPriceUpdate").toLowerCase():
        appResponse = productPriceUpdate(jsonMsg, isbackgroudApp, whereToview)
        break
      case ("sendProductQuantity").toLowerCase():
        appResponse = sendProductQuantity(jsonMsg, isbackgroudApp, whereToview)
        break
      // case ("productDetail").toLowerCase():
      //   productDetail(jsonMsg, isbackgroudApp)
      //   break

      //------------------------------------------------

      // Transaction view----------------------------------------
      case ("DataToReceipt").toLowerCase():
        appResponse = DataToReceipt(jsonMsg, whereToview, isbackgroudApp);
        break;
      case ("Receipt").toLowerCase():
        PrintReceiptWithAppData(jsonMsg, isbackgroudApp)
        break;

      case ("OrderStatus").toLowerCase():
        appResponse = getOrderStatus(jsonMsg, whereToview)
        break;
      //-------------------------------------------------------

      // sellComplete---------------------------------------
      case ("ReceiptData").toLowerCase():
        const Wrapper = window.Wrapper;
        if (typeof Wrapper !== "undefined" && Wrapper !== null) {
          appResponse = WrapperReceiptUpdated(jsonMsg, whereToview);
        }
        else {
          appResponse = getReceiptData(jsonMsg, whereToview)
        }

        break;
      //---------------------------------------------------

      //-------3.0----------------------------
      case ("ReceiptDetail").toLowerCase():
        appResponse = WrapperReceipt(jsonMsg, whereToview)
        break;

      case ("getStoreDetails").toLowerCase():
        appResponse = getStoreDetails(jsonMsg, whereToview)
        break;
      case ("openCashDrawer").toLowerCase():
        appResponse = openCashDrawer(jsonMsg, whereToview)
        break;
      case ("getEndOfDayDetails").toLowerCase():
        appResponse = getEndOfDayDetails(jsonMsg, whereToview)
        break;
      //-------Amelia app--------------------------------
      case ("appreadywithtoken").toLowerCase():
        appResponse = appreadywithtoken(jsonMsg, whereToview)
        break;
      case ("amelia_menu").toLowerCase():
        appResponse = getAmeliaMenu(jsonMsg, whereToview)
        break;
      //----------------------------------------
      case ("addShippingAddress").toLowerCase():
        appResponse = addShippingAddress(jsonMsg, isbackgroudApp, whereToview)
        break;

      case ("redeemPoints").toLowerCase():   // function for redeem points on total price
        appResponse = redeemPointsExtentionCart(jsonMsg,null,whereToview);
          break;      
      case ("cancelRedeemedPoints").toLowerCase(): // call for reverse redeem points on total price
          appResponse = cancelRedeemedPointsExtentionCart(jsonMsg,null,whereToview);
          break;

      // test Print receipt------------
      case ("testPrintReceipt").toLowerCase():
      appResponse = TestPrintAndroidReceipt(jsonMsg,null,whereToview);
      break;
      //--end print receipt-------------

      default: // extensionFinished
        var clientJSON = {
          command: jsonMsg.command,
          version: jsonMsg.version,
          method: jsonMsg.method,
          status: 406,
          error: "Invalid Command" //GR[2]
        }
        postmessage(clientJSON)
        console.error('App Error : Extension event does not match ', jsonMsg)
        break;
    }
    return appResponse;
  }
}
export const postmessage = (clientJSON) => {
  //var iframex = document.getElementsByTagName("iframe")[0].contentWindow;
  var iframex = undefined;

  if (document.getElementById("amelia-Iframe")) {
    iframex = document.getElementById("amelia-Iframe").contentWindow;
  }
  else if (document.getElementById("commoniframe")) {
    iframex = document.getElementById("commoniframe").contentWindow;
    if (!iframex)
      iframex = document.getElementById("iframeid").contentWindow;
  }
  // else if (document.getElementById("iframeid")) {
  //   iframex = document.getElementById("iframeid").contentWindow;
  // }
  // else if (document.getElementById("iframeViewSecond")) {
  //   iframex = document.getElementById("iframeViewSecond").contentWindow;
  // }

//  console.log("clientJSON-->", JSON.stringify(clientJSON))
  if (iframex) {
    iframex.postMessage(JSON.stringify(clientJSON), '*');

    // ----------Set recent app call-------------------------
    // var app = null
    // var currentAppName = document.getElementById('app_Name');
    // var currentAppID = document.getElementById('app_Id');

    // if (currentAppID) {
    //   app = {
    //     "Id": currentAppID.innerText,
    //     "Name": currentAppName.innerText
    //   }
    //   //console.log("text", app)
    //   if (app !== null) { UpdateRecentUsedApp(app, false, true) }
    // }
    //-------------------------------------------------------

  }
}


export const appReady = (whereToview, isbackgroudApp) => {
  var clientDetails = localStorage.getItem('clientDetail') ?
    JSON.parse(localStorage.getItem('clientDetail')) : 0
  var client_guid = clientDetails && clientDetails.subscription_detail ? clientDetails.subscription_detail.client_guid : ''

  if (whereToview == 'ActivityView') {
    // var pagesize = Config.key.ACTIVITY_PAGE_SIZE
    // var UID = get_UDid('UDID');
    // var pagno = 0;
    //store.dispatch(activityActions.getOne(UID,pagesize,pagno));
    setTimeout(() => {
      const state = store.getState();
      //console.log("state", state)
      if (state.single_Order_list && state.single_Order_list.items && state.single_Order_list.items.content) {
        var _OrderId = state.single_Order_list.items.content.order_id;
        var OliverReciptId = state.single_Order_list.items.content.OliverReciptId;
        var _customerId = state.single_Order_list.items.content.customer_id;
        var clientJSON = {
          command: "appReady",
          version: "1.0",
          method: "get",
          status: 200,
          data:
          {
            OrderId: _OrderId,
            WooCommerceId: _customerId,
            clientGUID: client_guid,
            view: whereToview,
            privilege: clientDetails && clientDetails.user_role,
            viewport: isMobileOnly == true ? "Mobile" : "desktop"
          },
          error: null
        }
        postmessage(clientJSON)
      }
    }, 1000);

  } else if (whereToview == 'CheckoutView' || whereToview == 'RefundView' || whereToview == 'efundCompleteView') {
    var clientJSON = {
      command: "appReady",
      version: "1.0",
      method: "get",
      status: 200,
      data:
      {
        clientGUID: client_guid,
        view: whereToview,
        privilege: clientDetails && clientDetails.user_role,
        viewport: isMobileOnly == true ? "Mobile" : "desktop"
      },
      error: null
    }
    postmessage(clientJSON)
  } else if (whereToview == 'CustomerView') {
    //var UID = get_UDid('UDID');
    //store.dispatch(customerActions.getAllEvents(UID));
    setTimeout(() => {
      // const state = store.getState();
      // console.log("customerDetailData", state.customerAllDetails)
      //if (state.single_cutomer_list && state.single_cutomer_list.items && state.single_cutomer_list.items.content) {
        var _CustomerId = "";
        var _email="";
        var currentCust= sessionStorage.getItem("currentCustomer")? JSON.parse(sessionStorage.getItem("currentCustomer")):null;
        if(currentCust){
           _CustomerId=currentCust.WPId;
          _email=currentCust.Email;
        }
       
        var clientJSON = {
          command: "appReady",
          version: "1.0",
          method: "get",
          status: 200,
          data:
          {
            CustomerId: _CustomerId,
            CustomerEmail:_email,
            clientGUID: client_guid,
            view: whereToview,
            privilege: clientDetails && clientDetails.user_role,
            viewport: isMobileOnly == true ? "Mobile" : "desktop"
          },
          error: null
        }
        postmessage(clientJSON)
     // }
    }, 1000);
  } else if (whereToview == 'ProductView') {   
    var clientJSON = {
      command: "appReady",
      version: "1.0",
      method: "get",
      status: 200,
      data:
      {
        ProductId: sessionStorage.getItem("productId"),
        view: whereToview,
        privilege: clientDetails && clientDetails.user_role,
        viewport: isMobileOnly == true ? "Mobile" : "desktop"
      },
      error: null
    }
    postmessage(clientJSON)
    //console.log("clientJSON from shopview", clientJSON)
  } else {  //home
    var clientJSON = {
      command: "appReady",
      version: "1.0",
      method: "get",
      status: 200,
      data:
      {
        view: whereToview,
        privilege: clientDetails && clientDetails.user_role,
        viewport: isMobileOnly == true ? "Mobile" : "desktop"
      },
      error: null
    }
    postmessage(clientJSON)
    //console.log("clientJSON from shopview", clientJSON)
  }

}
// Product Detail end****************
export const CloseExtension = () => {
  //hideModal('common_ext_popup');

}



export const postClientExtensionResponse = (method, isSuccess, message, command = "Customers", data = "") => {
  var _method = command == "CustomerDetails" ? 'get' :
    method == 'save' ? 'post' :
      method == 'update' ? 'put' :
        method == 'delete' ? 'delete' : 'get'

  var clientJSON = {
    command: command,
    version: "1.0",
    method: _method,
    status: isSuccess == true ? 200 : 406,
    error: isSuccess == true ? null : message
  }
  if (isSuccess == true && data !== "") {
    clientJSON['data'] = data
  }
  postmessage(clientJSON);

}

