import React, { useEffect, useLayoutEffect, useMemo } from "react";
import X_Icon_DarkBlue from '../../assets/images/svg/X-Icon-DarkBlue.svg';
import AppIconPlaceholder from '../../assets/images/svg/AppIconPlaceholder.svg';
import ActiveUser from '../../settings/ActiveUser';
import validator from 'validator'
const IframeWindow = (props) => {    
    const { Email, RegisterName, LocationName } = ActiveUser.key;
    
        var extentionUrl = ""
        if(props.exApp && props.exApp.PageUrl){
            if ( props.exApp.PageUrl && validator.isURL(props.exApp.PageUrl)) {   //check PageUrl is full URL
                extentionUrl = props.exApp.ShowAtCheckout == true ? props.exApp.PageUrl : '';
            } else {
                extentionUrl = props.exApp.ShowAtCheckout == true ? props.exApp.HostUrl + '/' + props.exApp.PageUrl : '';
            }
    
            var extentionUrlContansQryMark = extentionUrl.includes("?") ? '&' : '?';
            extentionUrl += extentionUrlContansQryMark;
            var extentionUserEmail = Email;    
        }
           
       // HostUrl = extentionUrl + encodeURIComponent(`userEmail=${extentionUserEmail}&location=${LocationName}&register=${RegisterName}&total=${extentionTotal}`);
    
    if (!extentionUrl.includes("http")) {
        extentionUrl = "";
    }
    var verNo = new Date().getTime();
    if (extentionUrl && typeof extentionUrl !== "undefined" && extentionUrl !== "") {
        if (extentionUrl.includes("?")) {
            extentionUrl = extentionUrl + "&v=" + verNo;
        }
        else
        {
            extentionUrl = extentionUrl + "?v=" + verNo;
        }
    }

    //console.log("URL",extentionUrl)
   // console.log("exApp",props.exApp, "isShow",props.isShow, "ToggleiFrameWindow",props.ToggleiFrameWindow)
const DisplayPluginPopup = useMemo(()=>{   
    
    return <iframe key={extentionUrl}
    id="commoniframe"
    src={extentionUrl}
   //src={props.product && props.product.ParamLink ? props.product.PageUrl : props.exApp ? props.exApp.PageUrl : ''}
    // src='http://localhost:63336/externalApp/printReceipt.html'
    frameBorder="0"
    sandbox="allow-scripts allow-same-origin allow-forms"
// ref={(f) => this.ifr = f}
></iframe>
},[extentionUrl]);

    return (<div className={props.isShow === true ? "subwindow-wrapper" : "subwindow-wrapper hidden"} >

        <div className={props.isShow === true ? "subwindow app-launcher-subwindow current" : "subwindow app-launcher-subwindow"}>
            <div className="subwindow-header">
                <div className="app-wrapper">
                {props?.exApp?.logo &&
                    <img id="appIconSRC" src={props?.exApp?.logo != ' ' ? props?.exApp?.logo : AppIconPlaceholder} alt="" />
                }
                    <div className="text-col">
                        <p id="appName" className="style1" style={{textTransform:"capitalize" }}>{props && props.exApp && props.exApp.Name}</p>
                        {/* <p id="appAuthorName" className="style2">Author Name</p> */}

                    </div>
                </div>
                <label id="app_Name" style={{ display: "none"}}>{props && props.exApp && props.exApp.Name}</label>
                <label id="app_Id" style={{ display: "none" }}>{props && props.exApp && props.exApp.Id}</label>
                <button className="close-subwindow" onClick={() => props.ToggleiFrameWindow(props.exApp,props.view)}>
                    <img src={X_Icon_DarkBlue} alt="" />
                </button>

                {/* <div className="img-container">
                <img src="" alt="" />
            </div>
            <div className="col">
                <p className="style1">{props && props.exApp && props.exApp.Name}</p>
                <p className="style2">Placeholder</p>
            </div>
            <button className="close-subwindow">
                <img src={X_Icon_DarkBlue} alt="" />
            </button> */}
            </div>
            <div className="subwindow-body">
               {DisplayPluginPopup}
            </div>
        </div></div>)
}

export default IframeWindow 