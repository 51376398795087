// A mock function to mimic making an async request for data
import { serverRequest } from '../../CommonServiceRequest/serverRequest'
export function locationAPI(parameter) {
  return serverRequest.clientServiceRequest('GET', `/Locations/FindAll`, parameter)
    .then(locationsRes => {
      localStorage.setItem('UserLocations', JSON.stringify(locationsRes.content));
      return locationsRes;
    }).catch(error => {
      return error
    });
}
export function getAdvertiseImagesAPI(parameter) {
  //GetAdvertiseImages
  return serverRequest.clientServiceRequest('GET', `/Locations/GetAdvImages?id=${parameter.id}`)
    .then(locationsRes => {
      if (locationsRes && locationsRes.is_success && locationsRes.content != "") {
        localStorage.setItem('customer_advertise_images', JSON.stringify(locationsRes.content));
      }
      return locationsRes;
    }).catch(error => {
      return error
    });
}