import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import X_Icon_DarkBlue from '../../assets/images/svg/X-Icon-DarkBlue.svg';
import ErrorIconRed from '../../assets/images/svg/ErrorIconRed.svg';
import StorefrontIconGreen from '../../assets/images/svg/StorefrontIconGreen.svg';
import SettingsCog from '../../assets/images/svg/SettingsCog.svg';
import Approval_Icon from '../../assets/images/svg/Approval-Icon.svg';
import VolumeIcon from '../../assets/images/svg/VolumeIcon.svg';
import Changelog_Icon from '../../assets/images/svg/Changelog-Icon.svg';
import Info_Icon from '../../assets/images/svg/Info-Icon.svg';
import Error_Icon from '../../assets/images/svg/Error-Icon.svg';
import ActiveUser from '../../settings/ActiveUser';
import LocalizedLanguage from "../../settings/LocalizedLanguage";
import Config from '../../Config';
import { v4 as uniqueKey } from 'uuid';
import { checkTempOrderSync } from "../checkout/checkoutSlice";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import FormateDateAndTime from "../../settings/FormateDateAndTime";
const Notifications = (props) => {
    const [isSoundNotification, setisSoundNotification] = useState(false);
    const [notificationList, setNotificationList] = useState([]);
    const [notiDate, setNotiDate] = useState([])
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const toggleiSoundNotification = () => {
        setisSoundNotification(!isSoundNotification)
    }
    const outerClick = (e) => {
        if (e && e.target && e.target.className && e.target.className === "notifications-wrapper") {
            props.toggleNotifications();
        }
    }
    var current_date = moment().format(Config.key.NOTIFICATION_FORMAT);
    if (current_date.includes(',')) {
        current_date = current_date.split(',')[0];
    }
    const OpenTransactions = (orderid) => {
        if (orderid !== '' && orderid !== 0 && orderid != null) {
            sessionStorage.removeItem('transactionredirect');
            sessionStorage.setItem("notificationRedirect", orderid);
            navigate('/transactions')
        }
    }
   
    const [respCheckTempOrderSync] = useSelector((state) => [state.checkTempOrderSync])
    useEffect(() =>{
        if(respCheckTempOrderSync && respCheckTempOrderSync.status == 'idle'){
            prepareNotificationList();
        }
    },[respCheckTempOrderSync])
    const reSyncOrder = (orderId) => {
        var TempOrders = localStorage.getItem(`TempOrders_${ActiveUser.key.Email}`) ? JSON.parse(localStorage.getItem(`TempOrders_${ActiveUser.key.Email}`)) : [];
        TempOrders.map(ele => {
            if (ele.TempOrderID == orderId) {
                if (ele.Sync_Count >= Config.key.SYNC_COUNT_LIMIT) {
                    ele.Sync_Count = ele.Sync_Count - 2;
                }
            }
        });
        dispatch(checkTempOrderSync(orderId));
        localStorage.setItem(`TempOrders_${ActiveUser.key.Email}`, JSON.stringify(TempOrders));
        
        // setTimeout(() => {
        //     prepareNotificationList();
        // }, 2000);
    }
    const prepareNotificationList = async () => {
        var temp_Order = 'TempOrders_' + (ActiveUser.key.Email);
        var TempOrders = localStorage.getItem(temp_Order) ? JSON.parse(localStorage.getItem(temp_Order)) : [];  //JSON.stringify
        var notificationlist = [];
        TempOrders = TempOrders.reverse();
        TempOrders && TempOrders.map(list => {
            if (list.new_customer_email !== "") {
                var tempVar = TempOrders.find(l => l.TempOrderID == list.TempOrderID && l.new_customer_email == "")
                if (tempVar) {
                    list.order_status = tempVar.order_status
                    list.OrderID = tempVar.OrderID
                }
            }
        })
        TempOrders && TempOrders.map(list => {
            var description = "";

            var time = list.date.includes(',') ? list.date.split(',')[1] : "";
            if (typeof time != "undefined" && time != null) {
                time = time.toUpperCase();
            }
          
            if (list.order_status == "completed" && list.new_customer_email !== "" && list.isCustomerEmail_send == true) {
                description = ((
                    <div className="notification-card" key={uniqueKey()} onClick={() => list.OrderID !== 0 ? OpenTransactions(list.OrderID) : null}>
                        <div className="icon-wrapper green">
                            <img src={StorefrontIconGreen} alt="" />
                        </div>
                        <div className="col">
                            <p className="style1">{LocalizedLanguage.emailsentsuccessfully} </p>
                            <p className="style2">{LocalizedLanguage.Order}#{list.TempOrderID}</p>
                        </div>
                        <p>{time}</p>
                    </div>

                ))
                //"Email sent succeessfully to customer for order#" + list.TempOrderID + ""
            }
            else if (list.order_status == "completed" && list.new_customer_email !== "" && list.isCustomerEmail_send == false) {
                description = ((<div className="notification-card" key={uniqueKey()} onClick={() => list.OrderID !== 0 ? OpenTransactions(list.OrderID) : null}>
                    <div className="side-color"></div>
                    <div className="main-row">
                        <img src={Approval_Icon} alt="" />
                        <p>{LocalizedLanguage.sendingemailtocustomer} <br />{LocalizedLanguage.Order}# {list.TempOrderID}</p>
                    </div>
                </div>))
                //"Sending email to customer for order#" + list.TempOrderID + ""
            }
            else if (list.Status == "true" && list.order_status == "completed") {
                description = ((<div className="notification-card" key={uniqueKey()} onClick={() => list.OrderID !== 0 ? OpenTransactions(list.OrderID) : null}>
                    <div className="icon-wrapper green">
                        <img src={StorefrontIconGreen} alt="" />
                    </div>
                    <div className="col">
                        <p className="style1">{LocalizedLanguage.ordercreated}</p>
                        <p className="style2">{LocalizedLanguage.Order} #{list.TempOrderID}</p>
                    </div>
                    <p>{time}</p>
                </div>))
            }
            //order refunded successfully
            else if (list.Status == "true" && list.order_status === "refunded") {
                description = ((<div className="notification-card" key={uniqueKey()} onClick={() => list.OrderID !== 0 ? OpenTransactions(list.OrderID) : null}>
                    <div className="icon-wrapper green">
                        <img src={StorefrontIconGreen} alt="" />
                    </div>
                    <div className="col">
                        <p className="style1">{LocalizedLanguage.orderrefundedsuccessfully}</p>
                        <p className="style2">
                        {LocalizedLanguage.Order}# {list.TempOrderID}
                        </p>
                    </div>
                    <p>{time}</p>
                </div>))
            }//sync issue
            else if (list.Status == "failed" && list.new_customer_email == "") {
                description = ((

                    <div className="notification-card" key={uniqueKey()} onClick={() => list.OrderID !== 0 ? OpenTransactions(list.OrderID) : null}>
                        <div className="icon-wrapper red">
                            <img src={ErrorIconRed} alt="" />
                        </div>
                        <div className="col">
                            <p className="style1">{LocalizedLanguage.ordernotsynced}</p>
                            <p className="style2">{LocalizedLanguage.Order} #{list.TempOrderID}</p>
                            <a href="#" onClick={(e) => {e.stopPropagation(); reSyncOrder(list.TempOrderID)}}>Retry</a>
                        </div>
                        <p>{time}</p>
                    </div>



                ))
            }
            else if (list.Sync_Count > 1 && list.order_status == "completed" && list.new_customer_email !== "" && list.isCustomerEmail_send == false) {
                description = ((<div className="notification-card" key={uniqueKey()} onClick={() => list.OrderID !== 0 ? OpenTransactions(list.OrderID) : null}>
                    <div className="side-color"></div>
                    <div className="main-row">
                        <img src={Approval_Icon} alt="" />
                        {/* <p>Order# {list.TempOrderID} </p> */}
                        <p>{LocalizedLanguage.issuetosendemailcustomer}<br /> {LocalizedLanguage.Order}# {list.TempOrderID}</p>
                    </div>
                </div>))
            }
            else {
                description = ((<div className="notification-card" key={uniqueKey()} onClick={() => list.OrderID !== 0 ? OpenTransactions(list.OrderID) : null}>
                    <div className="side-color"></div>
                    <div className="icon-wrapper main-row">
                        <img src={Info_Icon} alt="" />
                     </div>
                     <div className="col">
                        <p className="style1">{LocalizedLanguage.ordernotsynced}</p>
                        <p className="style2">{LocalizedLanguage.Order}# {list.TempOrderID}</p>
                       </div>  
                       <p>{time}</p>
                       
                </div>))
            }

            var TempOrderID = list.TempOrderID
            var time = list.Status == "true" ? list.date : list.date;
            var title = "";
            var status = list.Status
            var Index = list.Index
            var OrderID = list.OrderID
          

            var _order = {
                "time": time,
                "title": title,
                "description": description,
                "status": status,
                "Index": Index,
                "OrderID": OrderID,
                "TempOrderID": TempOrderID,
                "new_customer_email": list.new_customer_email,
                "isCustomerEmail_send": list.isCustomerEmail_send,
                "Sync_Count": list.Sync_Count
            }
            notificationlist.push(_order);
        })
        // console.log("notifyList", notificationlist);
        localStorage.setItem('notifyList', JSON.stringify(notificationlist))
        //setNotificationList(notificationlist)

        var _newnotificationlist = {};
        var _notifications = notificationlist;
        _notifications && _notifications.map(item => {
            var dateKey = item.time;
            if (dateKey.includes(',')) {
                dateKey = dateKey.split(',')[0];
            }

           if (!_newnotificationlist.hasOwnProperty(dateKey)) {
                _newnotificationlist[dateKey] = new Array(item);
            } else {
                if (typeof _newnotificationlist[dateKey] !== 'undefined' && _newnotificationlist[dateKey].length > 0) {
                    _newnotificationlist[dateKey].push(item)
                }
            }
        })
        setNotificationList(_newnotificationlist)

        var _notiDate = new Array();
        if (typeof _newnotificationlist !== 'undefined') {
            for (const key in _newnotificationlist) {
                if (_newnotificationlist.hasOwnProperty(key)) {
                    _notiDate.push(key)
                }
            }
            if (_notiDate.length > 0) {
                _notiDate.sort(function (a, b) {
                    var keyA = new Date(a),
                        keyB = new Date(b);
                    // Compare the 2 dates
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0;
                });
                _notiDate.reverse();
            }
        }
        setNotiDate(_notiDate);
    }

    useEffect(() => {
        prepareNotificationList();
    }, []);
    return (
        <div id="notificationsWrapper" className={props.isShow === true ? "notifications-wrapper" : "notifications-wrapper hidden"} onClick={(e) => outerClick(e)}>
            <div id="notificationsContent" className={isSoundNotification === true ? "notifications settings" : "notifications"}>
               
                <div className="noti-header">
                    <p>{LocalizedLanguage.notification}</p>
                  
                    <button id="mobileNotiExit" onClick={() => props.toggleNotifications()}>
                        <img src={X_Icon_DarkBlue} alt="" />
                    </button>
                </div>
                <div className="noti-body">
                    {/* <p>Today</p> */}
                    {
                        notificationList && notiDate && notiDate.map((getDate, index) => {
                            //console.log(current_date + "----" + getDate)
                            return (<>
                                <div key={uniqueKey()} className="date"> <p>{current_date === getDate ? <>{LocalizedLanguage.today}</> : getDate}</p></div>
                                {
                                    getDate && notificationList && notificationList[getDate] && notificationList[getDate].map((order, index) => {
                                        return (order.description)
                                    })
                                }</>
                            )
                        })
                   
                    }                   
                </div>
                <div className="noti-settings-background" id="notiSettingsBackground"></div>
                <div className="noti-settings-wrapper">
                    <div className="noti-settings">
                        <div className="header">
                            <p>{LocalizedLanguage.settings}</p>
                        </div>
                        <div className="settings-list">
                            <div className="setting-row">
                                <img src={VolumeIcon} alt="" />
                                <p>{LocalizedLanguage.soundnotifications}</p>
                            </div>
                            <div className="setting-row">
                                <label>
                                    {LocalizedLanguage.posorders}
                                    <input type="checkbox" />
                                    <div className="toggle"></div>
                                </label>
                            </div>
                            <div className="setting-row">
                                <label>
                                    {LocalizedLanguage.weborder}
                                    <input type="checkbox" />
                                    <div className="toggle"></div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}

export default Notifications 