import { serverRequest } from '../../../CommonServiceRequest/serverRequest'
import { useIndexedDB } from 'react-indexed-db';

export function productWarehouseQuantityAPI(productId) {

    return serverRequest.clientServiceRequest('GET', `/Product/GetWarehouseQuantity?wpid=${productId}`, '')
        .then(productdetail => {
            //console.log("---productdetail quantity---" + JSON.stringify(productdetail));
            setTimeout(() => {
                if (productdetail && productdetail.is_success === true && productdetail.message === "Success" && productdetail.content && productdetail.content.length > 0) {
                    const { update, getByID } = useIndexedDB("products");
                   var _currentWarehouseId= localStorage.getItem("WarehouseId")? localStorage.getItem("WarehouseId"):null;
                   var _productdetail=productdetail.content.find(p=>p.warehouseId==_currentWarehouseId);

                    getByID(productId).then((product) => {
                        if (typeof product != "undefined") {
                            product['StockQuantity'] =_productdetail && _productdetail.Quantity;
                            product['StockStatus'] = _productdetail.Quantity > 0 &&  product.StockStatus=="outofstock" ? "instock" : product.StockStatus;
                            update(product);
                            // update(product).then(() => {
                            //     console.log("---quantity by get---" + product.StockQuantity);
                            // });
                        }
                    });
                }
            }, 100);


            //setTimeout(() => {
            return productdetail;
            //}, 1000);


        })
        .catch(error => {
            return error
        });
}

export function updateInventoryAPI(data) {
    return serverRequest.clientServiceRequest('POST', `/Product/UpdateInventory`, { 'wpid': data.wpid, "Quantity": parseInt(data.quantity), "WarehouseId": data.WarehouseId })
        .then(response => {
            //console.log("---productdetail quantity---" + JSON.stringify(response));
            setTimeout(() => {
                if (response && response.is_success === true && response.message === "Success") {
                    const { update, getByID } = useIndexedDB("products");
                    data.wpid && getByID(data.wpid).then((product) => {
                        product['StockQuantity'] = parseInt(data.quantity);
                        product['StockStatus'] = parseInt(data.quantity) > 0 ? "instock" : "outofstock";;
                        update(product);
                        // update(product).then(() => {
                        //     console.log("---quantity by updated---" + data.quantity);
                        // });
                    });
                }
            }, 100);
            return response;
        });
}
function addInventoryQuantity(data) {
    // return serverRequest.clientServiceRequest('GET', `/ShopData/GetUpdateInventory?udid=${data.udid}&wpid=${data.wpid}&quantity=${data.quantity}`, '')


}