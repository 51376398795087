import { sendDataToWrapper } from '../../settings/AndroidIOSConnect';
import html2canvas from 'html2canvas';

const handleScalingReceipt = (scale) => {
  return scale + 1.0;
};

export const printImageHandler = (html, wrapper_listener) => {
  const iframeLoaded = new Promise((resolve) => {
    let iframe = document.createElement('iframe');

    iframe.onload = function () {
      resolve(iframe);
    };

    iframe.style.zIndex = -1000;
    iframe.style.position = 'absolute';
    iframe.style.left = '-9999px';

    document.body.appendChild(iframe);
  });

  iframeLoaded.then((iframe) => {
    let temp = iframe.contentDocument.createElement('div');
    temp.innerHTML = html;
    // alert('IFRAME LOADED');
    temp.style.zIndex = -900;

    iframe.contentDocument.body.appendChild(temp);

    const element = iframe.contentDocument.getElementById('print');

    const imgElement =
      iframe.contentDocument.getElementById('receipt-logo-image');

    const printerBrand = localStorage.getItem('printerBrand');

    if (imgElement) {
      // Get the URL of the image
      const imgUrl = imgElement.src;
      // alert("FETCHING IMAGE")
      // Fetch the image data and convert it to base64
      fetch(imgUrl)
        .then((r) => r.blob())
        .then((blob) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = reject;
            img.src = URL.createObjectURL(blob);
          });
        })
        .then((img) => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0, img.width, img.height);

          // if (printerBrand === 'imin') {
          //   ditherImageToBW(canvas);
          // }
          ditherImageToBW(canvas);

          const base64 = canvas.toDataURL('image/png');

          return base64;
        })
        .then((base64) => {
          // alert('IMG FETCHED')
          imgElement.src = base64;
          const scale = +localStorage.getItem('imgScale') || 1;
          const width = localStorage.getItem('pageSize') || '80mm';

          imgElement.onload = function () {
            html2canvas(element, {
              scale: handleScalingReceipt(scale),
              windowWidth: convertToPx(width),
              useCORS: true,
            })
              .then(function (canvas) {
                // if (printerBrand === 'imin') {
                //   // ditherImageToBW(canvas);
                //   convertToGrayscal(canvas);
                //   applyMonochromeThreshold(canvas);
                // }
                convertToGrayscal(canvas);
                applyMonochromeThreshold(canvas);
                // ditherImageToBW(canvas);
                const base64Image = canvas.toDataURL('image/png');
                const data = base64Image.split(',');

                let wrapperData = {
                  version: '3.0',
                  data: { base64Image: '---not-this-again---' },
                };

                wrapperData['data']['base64Image'] = data[1];

                console.log('Based: ', data[1]);

                document.body.removeChild(iframe);
                console.log('sending to wrapper : ', wrapperData);
                sendDataToWrapper(wrapper_listener, '', wrapperData);
              })
              .catch(function (error) {
                console.error('Error creating canvas:', error);
              });
          };
        })
        .catch((e) => console.log(e));
    } else {
      const scale = +localStorage.getItem('imgScale') || 1;
      const width = localStorage.getItem('pageSize') || '80mm';

      html2canvas(element, {
        scale: handleScalingReceipt(scale),
        windowWidth: convertToPx(width),
        useCORS: true,
      })
        .then(function (canvas) {
          const printerBrand = localStorage.getItem('printerBrand');
          // if (printerBrand === 'imin') {
          //   // ditherImageToBW(canvas);
          //   convertToGrayscal(canvas);
          //   applyMonochromeThreshold(canvas);
          // }
          convertToGrayscal(canvas);
          applyMonochromeThreshold(canvas);
          // ditherImageToBW(canvas);
          const base64Image = canvas.toDataURL('image/png');
          const data = base64Image.split(',');

          let wrapperData = {
            version: '3.0',
            data: { base64Image: '---not-this-again---' },
          };

          wrapperData['data']['base64Image'] = data[1];

          console.log('Based: ', data[1]);
          document.body.removeChild(iframe);
          console.log('sending to wrapper : ', wrapperData);
          sendDataToWrapper(wrapper_listener, '', wrapperData);
        })
        .catch(function (error) {
          console.error('Error creating canvas:', error);
        });
    }

    function convertToPx(number, dpi = 96) {
      if (number.toLowerCase() === 'a4') {
        number = '210mm';
      }
      return parseFloat((number.replace('mm', '') * dpi) / 25.4).toString();
    }

    function ditherImageToBW(canvas, options = { grayscale: true }) {
      const context = canvas.getContext('2d');
      const { width, height } = canvas;
      const imageData = context.getImageData(0, 0, width, height);
      const data = imageData.data;
      const { threshold = 180, ditherMatrix = getFloydSteinbergMatrix() } =
        options;

      for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
          const index = (y * width + x) * 4;
          const oldR = data[index];
          const oldG = data[index + 1];
          const oldB = data[index + 2];
          const gray = options.grayscale ? (oldR + oldG + oldB) / 3 : oldR; // Convert to grayscale if enabled

          const newColor = gray > threshold ? 255 : 0; // Threshold for black and white

          data[index] = data[index + 1] = data[index + 2] = newColor;

          const error = gray - newColor;

          // Error diffusion to neighboring pixels
          for (let i = 0; i < ditherMatrix.length; i++) {
            const offsetY = Math.floor(i / 3);
            const offsetX = i % 3;
            const neighborX = x + offsetX - 1;
            const neighborY = y + offsetY;

            if (neighborX >= 0 && neighborX < width && neighborY < height) {
              const neighborIndex = (neighborY * width + neighborX) * 4;
              data[neighborIndex] += (error * ditherMatrix[i]) / 16;
            }
          }
        }
      }

      return context.putImageData(imageData, 0, 0);
    }

    function getFloydSteinbergMatrix() {
      return [0, 0, 7, 3, 5, 1, 0, 0, 0];
    }

    function convertToGrayscal(canvas) {
      const context = canvas.getContext('2d');

      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      for (let i = 0; i < data.length; i += 4) {
        const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
        data[i] = avg; // red
        data[i + 1] = avg; // green
        data[i + 2] = avg; // blue
      }

      context.putImageData(imageData, 0, 0);
    }

    function applyMonochromeThreshold(canvas, threshold = 170) {
      const ctx = canvas.getContext('2d');
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      for (let i = 0; i < data.length; i += 4) {
        const brightness = (data[i] + data[i + 1] + data[i + 2]) / 3;
        const binaryColor = brightness < threshold ? 0 : 255;
        data[i] = binaryColor; // red
        data[i + 1] = binaryColor; // green
        data[i + 2] = binaryColor; // blue
      }

      ctx.putImageData(imageData, 0, 0);
    }
  });
};


// Backup please do not remove.

// import { sendDataToWrapper } from '../../settings/AndroidIOSConnect';
// import html2canvas from 'html2canvas';

// const handleScalingReceipt = (scale) => {
//   return scale + 1.0;
// };

// export const printImageHandler = (html, wrapper_listener) => {
//   const iframeLoaded = new Promise((resolve) => {
//     let iframe = document.createElement('iframe');

//     iframe.onload = function () {
//       resolve(iframe);
//     };

//     iframe.style.zIndex = -1000;
//     iframe.style.position = 'absolute';
//     iframe.style.left = '-9999px';

//     document.body.appendChild(iframe);
//   });

//   iframeLoaded.then((iframe) => {
//     let temp = iframe.contentDocument.createElement('div');
//     temp.innerHTML = html;
//     // alert('IFRAME LOADED');
//     temp.style.zIndex = -900;

//     iframe.contentDocument.body.appendChild(temp);

//     const element = iframe.contentDocument.getElementById('print');

//     const imgElement =
//       iframe.contentDocument.getElementById('receipt-logo-image');

//     if (imgElement) {
//       // Get the URL of the image
//       const imgUrl = imgElement.src;
//       // alert("FETCHING IMAGE")
//       // Fetch the image data and convert it to base64
//       fetch(imgUrl)
//         .then((r) => r.blob())
//         .then((blob) => {
//           return new Promise((resolve, reject) => {
//             const reader = new FileReader();
//             reader.onloadend = () => {
//               resolve(reader.result);
//             };
//             reader.onerror = reject;
//             reader.readAsDataURL(blob);
//           });
//         })
//         .then((base64) => {
//           // alert('IMG FETCHED')
//           imgElement.src = base64;
//           const scale = +localStorage.getItem('imgScale') || 1;
//           const width = localStorage.getItem('pageSize') || '80mm';

//           html2canvas(element, {
//             scale: handleScalingReceipt(scale),
//             windowWidth: convertToPx(width),
//             useCORS: true,
//           })
//             .then(function (canvas) {
//               const printerBrand = localStorage.getItem("printerBrand");
//               if(printerBrand === 'imin') {
//                 // ditherImageToBW(canvas);
//                 convertToGrayscal(canvas);
//                 applyMonochromeThreshold(canvas);
//               }
//               // convertToGrayscal(canvas);
//               // applyMonochromeThreshold(canvas);
//               // ditherImageToBW(canvas);
//               const base64Image = canvas.toDataURL('image/png');
//               const data = base64Image.split(',');

//               let wrapperData = {
//                 version: '3.0',
//                 data: { base64Image: '---not-this-again---' },
//               };

//               wrapperData['data']['base64Image'] = data[1];

//               console.log('Based: ', data[1]);

//               document.body.removeChild(iframe);
//               console.log('sending to wrapper : ', wrapperData);
//               sendDataToWrapper(wrapper_listener, '', wrapperData);
//             })
//             .catch(function (error) {
//               console.error('Error creating canvas:', error);
//             });
//         })
//         .catch((e) => console.log(e));
//     } else {
//       const scale = +localStorage.getItem('imgScale') || 1;
//       const width = localStorage.getItem('pageSize') || '80mm';

//       html2canvas(element, {
//         scale: handleScalingReceipt(scale),
//         windowWidth: convertToPx(width),
//         useCORS: true,
//       })
//         .then(function (canvas) {
//           const printerBrand = localStorage.getItem("printerBrand");
//           if(printerBrand === 'imin') {
//             // ditherImageToBW(canvas);
//             convertToGrayscal(canvas);
//             applyMonochromeThreshold(canvas);
//           }
//           // convertToGrayscal(canvas);
//           // applyMonochromeThreshold(canvas);
//           // ditherImageToBW(canvas);
//           const base64Image = canvas.toDataURL('image/png');
//           const data = base64Image.split(',');

//           let wrapperData = {
//             version: '3.0',
//             data: { base64Image: '---not-this-again---' },
//           };

//           wrapperData['data']['base64Image'] = data[1];

//           console.log('Based: ', data[1]);
//           document.body.removeChild(iframe);
//           console.log('sending to wrapper : ', wrapperData);
//           sendDataToWrapper(wrapper_listener, '', wrapperData);
//         })
//         .catch(function (error) {
//           console.error('Error creating canvas:', error);
//         });
//     }

//     function convertToPx(number, dpi = 96) {
//       if (number.toLowerCase() === 'a4') {
//         number = '210mm';
//       }
//       return parseFloat((number.replace('mm', '') * dpi) / 25.4).toString();
//     }

//     function ditherImageToBW(canvas, options = { grayscale: true }) {
//       const context = canvas.getContext('2d');
//       const { width, height } = canvas;
//       const imageData = context.getImageData(0, 0, width, height);
//       const data = imageData.data;
//       const { threshold = 180, ditherMatrix = getFloydSteinbergMatrix() } =
//         options;

//       for (let y = 0; y < height; y++) {
//         for (let x = 0; x < width; x++) {
//           const index = (y * width + x) * 4;
//           const oldR = data[index];
//           const oldG = data[index + 1];
//           const oldB = data[index + 2];
//           const gray = options.grayscale ? (oldR + oldG + oldB) / 3 : oldR; // Convert to grayscale if enabled

//           const newColor = gray > threshold ? 255 : 0; // Threshold for black and white

//           data[index] = data[index + 1] = data[index + 2] = newColor;

//           const error = gray - newColor;

//           // Error diffusion to neighboring pixels
//           for (let i = 0; i < ditherMatrix.length; i++) {
//             const offsetY = Math.floor(i / 3);
//             const offsetX = i % 3;
//             const neighborX = x + offsetX - 1;
//             const neighborY = y + offsetY;

//             if (neighborX >= 0 && neighborX < width && neighborY < height) {
//               const neighborIndex = (neighborY * width + neighborX) * 4;
//               data[neighborIndex] += (error * ditherMatrix[i]) / 16;
//             }
//           }
//         }
//       }

//       return context.putImageData(imageData, 0, 0);
//     }

//     function getFloydSteinbergMatrix() {
//       return [0, 0, 7, 3, 5, 1, 0, 0, 0];
//     }

//     function convertToGrayscal(canvas) {
//       const context = canvas.getContext('2d');

//       const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
//       const data = imageData.data;

//       for (let i = 0; i < data.length; i += 4) {
//         const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
//         data[i] = avg; // red
//         data[i + 1] = avg; // green
//         data[i + 2] = avg; // blue
//       }

//       context.putImageData(imageData, 0, 0);
//     }

//     function applyMonochromeThreshold(canvas, threshold = 150) {
//       const ctx = canvas.getContext('2d');
//       const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
//       const data = imageData.data;

//       for (let i = 0; i < data.length; i += 4) {
//         const brightness = (data[i] + data[i + 1] + data[i + 2]) / 3;
//         const binaryColor = brightness < threshold ? 0 : 255;
//         data[i] = binaryColor; // red
//         data[i + 1] = binaryColor; // green
//         data[i + 2] = binaryColor; // blue
//       }

//       ctx.putImageData(imageData, 0, 0);
//     }
//   });
// };
