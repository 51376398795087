import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import AngledBracket_Left_Blue from '../../assets/images/svg/AngledBracket-Left-Blue.svg'
import AngledBracket_Right_Grey from '../../assets/images/svg/AngledBracket-Right-Grey.svg'
import Register_Icon_White from '../../assets/images/svg/Register-Icon-White.svg'
// import Kiosk_Icon_White from '../../assets/images/svg/Kiosk-Icon-White.svg'
import X_Icon_DarkBlue from '../../assets/images/svg/X-Icon-DarkBlue.svg'
import LocalizedLanguage from '../../settings/LocalizedLanguage';
import STATUSES from "../../constants/apiStatus";
import { register } from "./registerSlice";
import { firebaseRegister } from "./firebaseRegisterSlice";
import { useLocation, useNavigate } from 'react-router-dom';
import { get_locName, get_UDid } from "../common/localSettings";
// import { toggleSubwindow } from "../common/EventFunctions";
import { LoadingModal } from "../common/commonComponents/LoadingModal";
import { GetOpenRegister } from '../cashmanagement/CashmanagementSlice'
import { NavigateComponent,  RememberData } from "../../settings/CommonModuleJS";
import { Markup } from "interweave";
import { sendDataToWrapper } from "../../settings/AndroidIOSConnect";
import { useRef } from "react";
// import { getAdvertiseImages } from "../location/locationSlice";
import { convertImageToBase64, notificationStatus } from "../../settings/CommonWork";
import Config from '../../Config';
import { productCount } from "../loadProduct/productCountSlice";
const Register = () => {
    const [selRegister, setSelRegister] = useState(null);
    const [isShowTakeOver, setisShowTakeOver] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    var registers = [];
    var self_registers = [];
    var firebase_registers = [];

    const registerHide = useRef();
    const toggleShowTakeOver = () => {
        setisShowTakeOver(!isShowTakeOver)
    }

    const [respRegister, respFirebaseRegisters] = useSelector((state) => [state.register, state.firebaseRegister])

    if (respRegister.status === STATUSES.error) {
        //console.log(respRegister.error)
    }
    if (respRegister.status === STATUSES.IDLE && respRegister.is_success) {
        const data = respRegister.data;
        registers = data && data.content && data.content.filter(a => a.IsSelfCheckout === false);
        self_registers = data && data.content && data.content.filter(a => a.IsSelfCheckout === true);
    }

    if (respFirebaseRegisters.status === STATUSES.IDLE && respFirebaseRegisters.is_success) {
        firebase_registers = respFirebaseRegisters.data && respFirebaseRegisters.data.content;
    }

    const openRegister = () => {
        var result = false;
        var selectedRegister = localStorage.getItem('selectedRegister') ? JSON.parse(localStorage.getItem("selectedRegister")) : '';
        var isDrawerOpen = localStorage.getItem("IsCashDrawerOpen") ? localStorage.getItem("IsCashDrawerOpen") : "false";
        var client = localStorage.getItem("clientDetail") ? JSON.parse(localStorage.getItem("clientDetail")) : '';
        if (isDrawerOpen === "false" && (client && client.subscription_permission && client.subscription_permission.AllowCashManagement === true && selectedRegister && selectedRegister.EnableCashManagement === true)) {
            result = true;
        }
        return result;
    }

    const { status, dataone, error, is_success } = useSelector((state) => state.GetOpenRegister)
    //console.log("status", status, "dataone", dataone, "error", error, "is_success", is_success)
    useEffect(() => {
        if (status === STATUSES.IDLE && is_success === true /*&& error === ""*/) {
            //if (dataone && dataone.content && dataone.content !== undefined) {
            if (dataone && dataone.hasOwnProperty('message') && dataone.message === "Success" && loading === true) {
                // if (dataone.content && dataone.content !== '' && dataone.content !== 0) {
                //     localStorage.setItem("IsCashDrawerOpen", "true");
                //     localStorage.setItem("Cash_Management_ID", dataone.content.Id);
                // } else {
                //     localStorage.setItem("IsCashDrawerOpen", "false");
                //     localStorage.removeItem("Cash_Management_ID");
                // }
                // console.log("path",document.referrer)
                setLoading(false)
                if (openRegister() === true) {
                    navigate('/openregister')
                }
                else {
                    navigate('/productloader')
                   // NavigateComponent(navigate,'pin');                    
                }
            }
            else {
                setLoading(false);
            }
        }
        else if (status === STATUSES.IDLE && error !== "") {
            if (loading === true) { setLoading(false) }
        }
    }, [dataone]);

    useEffect(() => {
        if(notificationStatus()=='false')
        {
            navigate('/enablenotification?page=register');
            return;
        }
        //Trigger this listener when the view has changed
        sendDataToWrapper("current_view", "register", "");
        if (localStorage.getItem("rememberme") && localStorage.getItem("rememberme") === "true") {
            if (localStorage.getItem("remember_data")) {
                RememberData("get");
                //NavigateComponent(navigate,'pin');
                navigate('/productloader')
                return;
            }
        }
        fetchData();
    }, []);

    const fetchData = async () => { //calling multiple api
        var loc_id = localStorage.getItem('Location');
        // dispatch(getAdvertiseImages({ "id": loc_id }));       
        dispatch(register({ "id": loc_id }));
        dispatch(firebaseRegister());
         if (!localStorage.getItem("productcount")) {
            console.log("callCount")
            var uid=get_UDid();
            dispatch(productCount(uid));
        }

    }

    // const [respGetAdvertiseImages] = useSelector((state) => [state.getAdvertiseImages])
    // useEffect(() => {
    //     if ((respGetAdvertiseImages && respGetAdvertiseImages.status === STATUSES.IDLE && respGetAdvertiseImages.is_success == true && respGetAdvertiseImages.data != "")) {
    //         //console.log("---respGetAdvertiseImages---" + JSON.stringify(respGetAdvertiseImages.data.content))

    //         // var imgPath = "";
    //         // if (respGetAdvertiseImages.data && respGetAdvertiseImages.data.content && respGetAdvertiseImages.data.content.length > 0) {
    //         //     imgPath = Config.key.OP_IMAGE_URL + "" + respGetAdvertiseImages.data.content[0];
    //         // }
    //         //var imgArray = [];
    //         // var imgCount = respGetAdvertiseImages.data.content.length;
    //         // var i = 0;
    //         // var tempimgArray = [
    //         //     { "url": "https://upload.wikimedia.org/wikipedia/commons/4/47/PNG_transparency_demonstration_1.png", "type": "screensaver" },
    //         //     { "url": "https://w7.pngwing.com/pngs/114/579/png-transparent-pink-cross-stroke-ink-brush-pen-red-ink-brush-ink-leave-the-material-text.png", "type": "checkout" },
    //         //     { "url": "https://cdn.pixabay.com/photo/2015/10/01/17/17/car-967387__480.png", "type": "thankyou" },
    //         //     { "url": "https://i.ytimg.com/vi/xxGqTMlE_Yk/mqdefault.jpg", "type": "refund" }
    //         // ];
    //         // var count = 0;
    //         // tempimgArray.map(d => {
    //         //     convertImageToBase64(d.url,
    //         //         function (base64image) {
    //         //             imgArray.push({ "data": base64image, "type": d.type });
    //         //             localStorage.setItem("AdvImages", JSON.stringify(imgArray))
    //         //             //console.log("------advertise_image_base64-------->" + JSON.stringify(imgArray));
    //         //             // i++;
    //         //             // if (imgCount == i) {
    //         //             //     var data = {
    //         //             //         version: "3.0",
    //         //             //         data: {
    //         //             //             images: imgArray
    //         //             //         },
    //         //             //         type: d.type
    //         //             //     }
    //         //             //     sendDataToWrapper("customer_display", "", data)
    //         //             // }
    //         //         }, 'advertise_image_base64_' + d.type);
    //         //     count++;
    //         // })
    //         var count = 1;
    //         respGetAdvertiseImages.data.content && respGetAdvertiseImages.data.content.map(d => {
    //             //console.log("imageurl config-->"+(Config.key.OP_IMAGE_URL + "" + (d.hasOwnProperty("url") ? d.url : d)))
    //             convertImageToBase64(Config.key.OP_IMAGE_URL + "" + (d.hasOwnProperty("url") ? d.url : d),
    //                 function (base64image) {
    //                     var item = ({ "data": base64image, "type": (d.hasOwnProperty("type") ? d.type : ""), "imageUrl": (Config.key.OP_IMAGE_URL + "" + (d.hasOwnProperty("url") ? d.url : d)) });
    //                     console.log((d.hasOwnProperty("url") ? d.url : d) + count + "-->" + base64image.substring(0, 10));
    //                     localStorage.setItem("AdvImages_" + count, JSON.stringify(item));
    //                 }, "");
    //             count++;
    //             // }, 'advertise_image_base64_' + d.hasOwnProperty("type") ? d.type : "");
    //         })

    //     }
    // }, [respGetAdvertiseImages]);

    const handleSubmit = (item, isTakeOver = false) => {
        try {

            if (typeof item !== "undefined" && item !== null && item.hasOwnProperty("id")) {
                sessionStorage.removeItem("backUser")
                var arry = [];
                arry.push(item)
                localStorage.setItem('pdf_format', JSON.stringify(arry))
                localStorage.setItem('register', item.id);
                localStorage.setItem('registerName', item.name);
                var getudid = get_UDid('UDID');
                localStorage.setItem(`last_login_register_id_${getudid}`, item.id);
                localStorage.setItem(`last_login_register_name_${getudid}`, item.name);
                localStorage.setItem('selectedRegister', JSON.stringify(item))

                setSelRegister(item);
                if (isTakeOver === false) {
                    fetchRegisterData(item.id)
                    // if (openRegister() === true) {
                    //     navigate('/openregister')
                    // }
                    // else {
                    //     navigate('/pin');
                    // }
                }
                else {
                    toggleShowTakeOver();
                }
            }
        } catch (error) {
            alert("--register selection---" + error.message)
        }
        //toggleSubwindow("takeover-register");
    }
    const fetchRegisterData = (register_Id) => {

        var client = localStorage.getItem("clientDetail") ? JSON.parse(localStorage.getItem("clientDetail")) : '';
        var selectedRegister = localStorage.getItem('selectedRegister') ? JSON.parse(localStorage.getItem("selectedRegister")) : '';
        if (client && client.subscription_permission && client.subscription_permission.AllowCashManagement === true && selectedRegister && selectedRegister.EnableCashManagement === true) {
            if (typeof register_Id !== "undefined" && register_Id !== null) {
                dispatch(GetOpenRegister(register_Id));
                setLoading(true);
            }

        }
        else {
            localStorage.setItem("IsCashDrawerOpen", "false");
            navigate('/productloader')
           // NavigateComponent(navigate,'pin');
        }
    }

    const takeOver = () => {
        setTimeout(() => {
            selRegister && selRegister.hasOwnProperty("id") && fetchRegisterData(selRegister.id);
        }, 100);

        // if (openRegister() === true) {
        //     navigate('/openregister')
        // }
        // else
        //     navigate('/pin');
    }

    // if (respRegister.status === STATUSES.LOADING) {
    //     return <div> Loading... </div>
    // }
    // setTimeout(() => {
    //     if(registers.length >=1){
    //         handleSubmit(registers[0])
    //     }
    // }, 200);
    const backFun = () => {
        sessionStorage.setItem("backUser", true)
        navigate('/location')
    }
    useEffect(() => {
        if (registers && registers.length == 1 && !sessionStorage.getItem("backUser")) {
            handleSubmit(registers[0])
        }
        setTimeout(() => {
            if ((registers && registers.length > 1) || sessionStorage.getItem("backUser")) {
                registerHide.current &&  registerHide.current.classList &&  registerHide.current.classList.remove('hide_wrapper')
            }
        }, 50);
    }, [respRegister])

    return (
        <React.Fragment>
            {respRegister.status === STATUSES.LOADING || loading === true ? <LoadingModal></LoadingModal> : null}
            {
                <div className="choose-wrapper hide_wrapper" ref={registerHide}>
                    <div className="choose-header">
                        <button id="backButton" onClick={backFun} >
                            <img src={AngledBracket_Left_Blue} alt="" />
                            {LocalizedLanguage.back}
                        </button>
                        <p>{get_locName()}</p>
                        {/* get_userName() + " - " +  */}
                    </div>
                    <div className="choose-body-default">
                        <p>{LocalizedLanguage.registerdevice}</p>
                        <div className="divider"></div>
                        <div className="button-container">
                            <p>{LocalizedLanguage.registers}</p>
                            <div className="divider"></div>
                            <div className="button-group col">
                                {
                                    registers.map((item, index) => {
                                        var inr = true;
                                        {
                                            firebase_registers && firebase_registers.length > 0 && firebase_registers.map((firebaseItem, indx) => {
                                                if (inr === true) {
                                                    if (firebaseItem.RegisterId === item.id && firebaseItem.Status !== "available") {
                                                        inr = false
                                                    }
                                                }
                                            })
                                        }
                                        return <button key={index} className={inr === true ? "option" : "option assigned"} onClick={() => handleSubmit(item, inr === true ? false : true)}>
                                            <div className="img-container background-blue">
                                                <img src={Register_Icon_White} alt="" className="register-icon" />
                                            </div>
                                            <div className="col">
                                                <p className="style1"><Markup content={item.name} noWrap={true}></Markup></p>
                                                <p className="style2">{inr === true ? <>{LocalizedLanguage.available}</> : <> {<>{LocalizedLanguage.assigned}</>} </>}</p>
                                            </div>
                                            {inr === true ?
                                                <React.Fragment><img src={AngledBracket_Right_Grey} alt="" />
                                                    <div className="fake-button background-blue">{LocalizedLanguage.select}</div></React.Fragment>
                                                : <React.Fragment>
                                                    <img src={AngledBracket_Right_Grey} alt="" onClick={() => toggleShowTakeOver()} />
                                                    <div className="fake-button background-blue">{LocalizedLanguage.takeover}</div></React.Fragment>}
                                        </button>

                                    })}

                            </div>
                            {/* {self_registers && self_registers.length > 0 && <>
                                <p>Kiosks</p>
                                <div className="divider"></div>
                                <div className="button-group">
                                    {self_registers.map((item, index) => {
                                        return <button key={index} className="option" onClick={() => handleSubmit(item)}>
                                            <div className="img-container background-violet">
                                                <img src={Kiosk_Icon_White} alt="" className="kiosk-icon" />
                                            </div>
                                            <div className="col">
                                                <p className="style1">{item.name}</p>
                                                <p className="style2">{LocalizedLanguage.available}</p>
                                            </div>
                                            <img src={AngledBracket_Right_Grey} alt="" />
                                            <div className="fake-button background-violet">{LocalizedLanguage.select}</div>
                                        </button>
                                    })}
                                </div>
                            </>
                            } */}
                        </div>
                    </div>
                </div>
            }
            <div className={isShowTakeOver ? "subwindow-wrapper" : "subwindow-wrapper hidden"}>
                <div className={isShowTakeOver ? "subwindow takeover-register current" : "subwindow takeover-register"}>
                    <div className="subwindow-header">
                        <p>{LocalizedLanguage.takeoverregister}</p>
                        <button className="close-subwindow" onClick={() => toggleShowTakeOver()}>
                            {/* onClick={() => toggleSubwindow()} */}
                            <img src={X_Icon_DarkBlue} alt="" />
                        </button>
                    </div>
                    <div className="subwindow-body">
                        <div className="auto-margin-top"></div>
                        <p>
                            {LocalizedLanguage.areyousureyou} <br />
                            {LocalizedLanguage.wanttotakeover} <b>{selRegister && selRegister.name}</b>? <br /><br />
                            {LocalizedLanguage.Thisactionwillkickoutthecurrentuser}
                        </p>
                        <button id="takeoverRegister" onClick={() => takeOver()}>{LocalizedLanguage.takeover}</button>
                        <button id="cancelTakeover" onClick={() => toggleShowTakeOver()}>{LocalizedLanguage.cancel}</button>
                        {/* onClick={() => toggleSubwindow()} */}
                        <div className="auto-margin-bottom"></div>
                    </div>
                </div>
            </div>
        </React.Fragment>)
}
export default Register