import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { productWarehouseQuantityAPI, updateInventoryAPI } from './warehouseAPI';
import STATUSES from '../../../constants/apiStatus';


const initialState = {
    inventoryGet: {
        "status": STATUSES.IDLE,
        "data": "",
        "error": '',
        "is_success": false
    },
    inventoryUpdate: {
        "status": STATUSES.IDLE,
        "data": "",
        "error": '',
        "is_success": false
    }
};



export const getInventory = createAsyncThunk(
    'inventory/getInventory',
    async (parameter, { rejectWithValue }) => {
        try {
            if(parameter==null ||parameter=="clear")
            {return initialState}
            const response = await productWarehouseQuantityAPI(parameter);
            // The value we return becomes the `fulfilled` action payload
            return response;
        } catch (err) {
            // Use `err.response.data` as `action.payload` for a `rejected` action,
            // by explicitly returning it using the `rejectWithValue()` utility
            return rejectWithValue(err.response.data)
        }
    }
);

export const updateInventory = createAsyncThunk(
    'inventory/updateInventory',
    async (parameter, { rejectWithValue }) => {
        try {
            if (parameter == "clear") { return null; }
            const response = await updateInventoryAPI(parameter);
            // The value we return becomes the `fulfilled` action payload
            return response;
        } catch (err) {
            // Use `err.response.data` as `action.payload` for a `rejected` action,
            // by explicitly returning it using the `rejectWithValue()` utility
            return rejectWithValue(err.response.data)
        }
    }
);


// export const productQuantityInWarehouseSlice = createSlice({
//     name: 'productQuantityInWarehouse',
//     initialState,
//     // The `reducers` field lets us define reducers and generate associated actions
//     reducers: {
//         // loginPanding:(state)=>{
//         //   state.status=STATUSES.LOADING;
//         //   state.data=""
//         // },
//         // loginSuccess:(state,action)=>{
//         //   state.status=STATUSES.IDLE;
//         //   state.error='';
//         //   state.data=action.payload
//         // },
//         // loginFail:(state,action)=>{
//         //   state.status=STATUSES.ERROR;    
//         //   state.data="";
//         //   state.error=action.payload;
//         // }
//     },
//     // The `extraReducers` field lets the slice handle actions defined elsewhere,
//     // including actions generated by createAsyncThunk or in other slices.
//     // extraReducers: () => {}
//     extraReducers: (builder) => {
//         builder
//             .addCase(productQuantityInWarehouse.pending, (state) => {
//                 state.status = STATUSES.LOADING;
//                 state.data = "";
//                 state.error = "";
//                 state.is_success = false;
//             })
//             .addCase(productQuantityInWarehouse.fulfilled, (state, action) => {
//                 state.status = action.payload && action.payload ? STATUSES.IDLE : STATUSES.ERROR;
//                 state.data = (action.payload && action.payload ? action.payload : "");
//                 state.error = action.payload && action.payload ? '' : "Fail to fetch";
//                 state.is_success = action.payload && action.payload ? true : false;

//             })
//             .addCase(productQuantityInWarehouse.rejected, (state, action) => {
//                 state.status = STATUSES.IDLE;
//                 state.data = "";
//                 state.error = action.error;
//                 state.is_success = false;
//             })


//     },
// });
const inventorySlice = createSlice({
    name: "inventory",
    initialState,
    extraReducers: {
        [getInventory.fulfilled]: (state, action) => {
            state.inventoryGet.status = action.payload && action.payload ? STATUSES.IDLE : STATUSES.ERROR;
            state.inventoryGet.data = (action.payload && action.payload ? action.payload : "");
            state.inventoryGet.error = action.payload && action.payload ? '' : "Fail to fetch";
            state.inventoryGet.is_success = action.payload && action.payload ? true : false;
        },

        [updateInventory.fulfilled]: (state, action) => {
            // const index = state.findIndex(tutorial => tutorial.id === action.payload.id);
            // state[index] = {
            //   ...state[index],
            //   ...action.payload,
            // };
            state.inventoryUpdate.status = action.payload && action.payload ? STATUSES.IDLE : STATUSES.ERROR;
            state.inventoryUpdate.data = (action.payload && action.payload ? action.payload : "");
            state.inventoryUpdate.error = action.payload && action.payload ? '' : "Fail to fetch";
            state.inventoryUpdate.is_success = action.payload && action.payload ? true : false;
        }
        //   ,
        //   [deleteTutorial.fulfilled]: (state, action) => {
        //     let index = state.findIndex(({ id }) => id === action.payload.id);
        //     state.splice(index, 1);
        //   },
        //   [deleteAllTutorials.fulfilled]: (state, action) => {
        //     return [];
        //   },
        //   [findTutorialsByTitle.fulfilled]: (state, action) => {
        //     return [...action.payload];
        //   },
    },
});

const { reducer } = inventorySlice;
export default reducer;

//export default productQuantityInWarehouseSlice;
