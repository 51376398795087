import React from 'react'

function Loader({width=85, height=85}) {
  return (
    <div style={{
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 200000
    }}>
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 400 400" enableBackground="new 0 0 400 400">
        <g>
        <rect x="249.28" y="156.01" className="st0 ologo-1" width="103.9" height="103.9" />
        <path id="teal" className="st1 ologo-2" d="M249.28,363.81V259.91h103.9C353.17,317.29,306.66,363.81,249.28,363.81z" />
        <rect id="cyan" x="145.38" y="259.91" className="st2 ologo-3" width="103.9" height="103.89" />
        <path id="blue" className="st3 ologo-4" d="M41.49,259.91L41.49,259.91h103.9v103.89C88,363.81,41.49,317.29,41.49,259.91z" />
        <rect id="purple" x="41.49" y="156.01" className="st4 ologo-5" width="103.9" height="103.9" />
        <path id="red" className="st5 ologo-6" d="M41.49,156.01L41.49,156.01c0-57.38,46.52-103.9,103.9-103.9v103.9H41.49z" />
        <rect id="orange" x="145.38" y="52.12" className="st6 ologo-7" width="103.9" height="103.9" />
        <path id="yellow" className="st7 ologo-8" d="M281.3,123.99V20.09c57.38,0,103.9,46.52,103.9,103.9H281.3z" />
      </g>
        </svg>
    </div>
  )
}

export default Loader