import {
  sendDataToWrapper,
  setAndroidKeyboard,
  showAndroidReceipt,
  wrapperReceiptData,
} from '../../settings/AndroidIOSConnect';
import moment from 'moment';
import Config from '../../Config';
import LocalizedLanguage from '../../settings/LocalizedLanguage';

import {
  pdfFormate,
  recieptDateFormate,
  recieptTimeFormate,
  companyLogo,
} from '../../settings/ReceiptSetting';
import { isSafari } from 'react-device-detect';
import FormateDateAndTime from '../../settings/FormateDateAndTime';
import { formatAmount } from './EodPrint';
import { printImageHandler } from './HtmlToImageConvertor';

export const CashLedger = {
  PrintCashLedger,
};

function PrintCashLedger(data, notes = '', printExcel) {
  //eod app variables STARTS HEHE

  var summary = [];

  var PrintAndroidReceiptData = {};

  var rowNumber = 0;
  var _taxDetail = [];
  var _closeRegister = data;

  // const pageSize = pdfFormate();
  const pageSize = localStorage.getItem('pageSize') || '80mm';

  var _openingBalance = _closeRegister && _closeRegister.OpeningBalance;
  var _totalDiff =
    _closeRegister && _closeRegister.Actual - _closeRegister.Expected;
  var _totalActual = _closeRegister && _closeRegister.Actual;
  var _totalExpected = _closeRegister && _closeRegister.Expected;
  var closePersonName =
    _closeRegister &&
    _closeRegister.ClosingByName &&
    _closeRegister.ClosingByName.trim() !== ''
      ? _closeRegister.ClosingByName
      : _closeRegister.ClosingByEmail;
  var SalePersonName =
    _closeRegister &&
    _closeRegister.SalePersonName &&
    _closeRegister.SalePersonName.trim() !== ''
      ? _closeRegister.SalePersonName
      : _closeRegister.SalePersonEmail;

  // _closeRegister && _closeRegister.PaymentSummery && _closeRegister.PaymentSummery.map(item => {
  //    })

  var registerName = localStorage.getItem('registerName');
  var user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : '';
  var shopName = user && user.shop_name ? user.shop_name : '';
  var shopWebsite = user && user.website ? user.website : '';

  const userLocations =
    localStorage.getItem('UserLocations') &&
    JSON.parse(localStorage.getItem('UserLocations'));
  const locationIndex =
    localStorage.getItem('Location') &&
    JSON.parse(localStorage.getItem('Location'));

  let shopaddress;
  const add = userLocations.find((a) => a.id === locationIndex);

  if (userLocations && locationIndex && add) {
    const address1 = add.address_1 && add.address_1 !== '' ? add.address_1 : '';
    const address2 =
      add.address_2 && add.address_2 !== '' ? ',' + ' ' + add.address_2 : '';
    const addressCity = add.city && add.city !== '' ? ',' + ' ' + add.city : '';
    const addressCountry =
      user.shop_country_full_Name && user.shop_country_full_Name !== ''
        ? ',' + ' ' + user.shop_country_full_Name
        : '';

    shopaddress = address1 + address2 + addressCity + addressCountry;
  } else {
    const address1 =
      user.shop_address1 && user.shop_address1 !== '' ? user.shop_address1 : '';
    const address2 =
      user.shop_address2 && user.shop_address2 !== ''
        ? ',' + ' ' + user.shop_address2
        : '';
    const addressCity =
      user.shop_city && user.shop_city !== '' ? ',' + ' ' + user.shop_city : '';
    const addressCountry =
      user.shop_country_full_Name && user.shop_country_full_Name !== ''
        ? ',' + ' ' + user.shop_country_full_Name
        : '';

    shopaddress = address1 + address2 + addressCity + addressCountry;
  }

  // var shopaddress = user && user.shop_address1 ? user.shop_address1 : '';

  var arrayCashLog = [];
  var androidCashLogArray = [];

  var _manualTransac = [];
  var totalManual = 0;

  var cashLedgerLog = '';
  var _taxes = '00.00';
  var toatlCash = 0.0;
  var openingDate =
    _closeRegister && _closeRegister.UtcOpenDateTime
      ? _closeRegister.UtcOpenDateTime
      : '';
  var openingDateTime = moment
    .utc(openingDate)
    .local()
    .format(Config.key.DATETIME_FORMAT);

  var closingDate =
    _closeRegister && _closeRegister.UtcClosedDateTime
      ? _closeRegister.UtcClosedDateTime
      : '';
  var closingDateTime = moment
    .utc(closingDate)
    .local()
    .format(Config.key.DATETIME_FORMAT);
  var now = moment.utc(_closeRegister.UtcClosedDateTime); //todays date
  var end = moment.utc(_closeRegister.UtcOpenDateTime); // another date
  var duration = moment.duration(now.diff(end));
var hours = duration.asHours().toFixed(2);

  _closeRegister &&
    _closeRegister.CashRegisterlog &&
    _closeRegister.CashRegisterlog.map((item) => {
      if (item.IsManual == true) {
        var ManualCashType =
          item.Expected < 0 ? 'Cash withdrawal' : 'Cash deposit';
        var androidManualCashType =
          item.Expected < 0 ? 'withdrawal' : 'deposit';

        var date = moment.utc(item.TransactionDateUtc).local();
        var transactionData = moment
          .utc(item.TransactionDateUtc)
          .local()
          .format('DD/MM/YYYY');
        var transactionTime = moment
          .utc(item.TransactionDateOffset)
          .local()
          .format(Config.key.ONLY_TIME);
        toatlCash += item.Expected;
        arrayCashLog.push({
          type: ManualCashType,
          taxes: '0%',
          notes: item.Notes,
          amount: item.Expected,
          datetime: transactionData + '-' + transactionTime,
          balance: toatlCash,
          orderStatus: 'manual',
          date: date,
        });

        androidCashLogArray.push({
          type: androidManualCashType,
          orderId: item.orderId,
          taxes: '0%',
          amt: item.Expected,
          cashBalance: toatlCash,
          timeStamp: transactionData + ' ' + transactionTime,
          comments: item.Notes,
          date: date,
        });
      }
    });

  var orderTaxes = [];
  var totAdded = 0;
  var totSubstract = 0;
  var timezone = _closeRegister.LocalTimeZoneType;
  _closeRegister &&
    _closeRegister.orders &&
    _closeRegister.orders.map((item) => {
      //var taxInclusiveName = getInclusiveTaxType(item.order_meta_lines);
      // var arrItem = orderTaxes[item.order_taxes.RateId]
      var totOrderAmount = item.total_amount;

      var _taxesApplied = '';
      var taxRateList = localStorage.getItem('TAXT_RATE_LIST')
        ? JSON.parse(localStorage.getItem('TAXT_RATE_LIST'))
        : '';
      var taxRateFound = '';
      item.order_taxes &&
        item.order_taxes.length > 0 &&
        item.order_taxes.map((tax) => {
          if (taxRateList && taxRateList != '') {
            if(taxRateList==Array){
                  taxRateList.map((tr) => {
                          if (tr.TaxId == tax.RateId) {
                            taxRateFound = tr.TaxRate;
                          }
                        });
            }else{
              if (taxRateList.TaxId &&  taxRateList.TaxId == tax.RateId) {
                taxRateFound = taxRateList.TaxRate && taxRateList.TaxRate;
              }
            }
          
          }
          if (taxRateFound == '') {
            taxRateFound =
              ((tax.Total * 100.0) / totOrderAmount).toFixed() + '%';
          }
          _taxesApplied =
            _taxesApplied + (_taxesApplied !== '' ? '/' : '') + taxRateFound;
        });
      if (item.order_payments && item.order_payments.length > 0) {
        item.order_payments.map((payment) => {
          if (payment.type.toLowerCase() == 'cash') {
            arrayCashLog.push({
              type: 'Order ID: ' + item.order_id,
              taxes: _taxesApplied,
              notes: '',
              amount: payment.amount,
              datetime:
                moment.utc(item.order_time).local().format('DD/MM/YYYY') +
                '-' +
                FormateDateAndTime.formatDateWithTime(
                  item.order_time,
                  timezone,
                  Config.key.ONLY_TIME
                ),
              balance: 0,
              orderStatus: 'complete',
              date: moment.utc(item.order_time).local(),
            });

            androidCashLogArray.push({
              type: 'sale',
              orderId: item.order_id,
              taxes: _taxesApplied,
              amt: payment.amount,
              cashBalance: 0,
              timeStamp:
                moment.utc(item.order_time).local().format('DD/MM/YYYY') +
                ' ' +
                FormateDateAndTime.formatDateWithTime(
                  item.order_time,
                  timezone,
                  Config.key.ONLY_TIME
                ),
              comments: '',
              date: moment.utc(item.order_time).local(),
            });
          }
        });
      }
      if (item.order_Refund_payments && item.order_Refund_payments.length > 0) {
        item.order_Refund_payments.map((payment) => {
          if (payment.type.toLowerCase() == 'cash') {
            arrayCashLog.push({
              type: 'Order ID: ' + item.order_id,
              taxes: _taxesApplied,
              notes: '',
              amount: payment.amount,
              datetime:
                moment.utc(item.order_time).local().format('DD/MM/YYYY') +
                '-' +
                FormateDateAndTime.formatDateWithTime(
                  item.complete_date,
                  timezone,
                  Config.key.ONLY_TIME
                ),
              balance: 0,
              orderStatus: 'refund',
              date: moment.utc(item.order_time).local(),
            });

            androidCashLogArray.push({
              type: 'refund',
              orderId: item.order_id,
              taxes: _taxesApplied,
              amt: payment.amount,
              cashBalance: 0,
              timeStamp:
                moment.utc(item.order_time).local().format('DD/MM/YYYY') +
                ' ' +
                FormateDateAndTime.formatDateWithTime(
                  item.complete_date,
                  timezone,
                  Config.key.ONLY_TIME
                ),
              comments: '',
              orderStatus: 'refund',
              date: moment.utc(item.order_time).local(),
            });
          }
        });
      }
    });
  //var ordersDate = new Array();
  arrayCashLog.sort((a, b) => {
    let da = new Date(a.date),
      db = new Date(b.date);
    return da - db;
  });  

  // code of android listener
  var transctionData = [];
  androidCashLogArray.sort((a, b) => {
    let da = new Date(a.date),
      db = new Date(b.date);
    return da - db;
  });

  var _balance = _openingBalance;

  androidCashLogArray.map((item) => {
    if (item.orderStatus == 'refund') {
      _balance -= item.amt;
    } else {
      _balance += item.amt;
    }

    transctionData.push({
      type: item.type,
      orderId: item.orderId,
      taxes: item.taxes,
      // "amount": (item.orderStatus == "refund" ? "-" : "+") + item.amt.toFixed(2),
      amount: item.amt.toFixed(2),
      cashBalance: _balance.toFixed(2),
      timeStamp: item.timeStamp,
      comments: item.comments,
    });
  });
  // end code of android listener

  var balance = _openingBalance;

  arrayCashLog &&
    arrayCashLog.length > 0 &&
    arrayCashLog.map((item) => {
      if (item.orderStatus == 'refund') {
        balance -= item.amount;
        totSubstract += item.amount * -1;
      } else {
        balance += item.amount;

        if (item.amount < 0) totSubstract += item.amount;
        else totAdded += item.amount;
      }
      cashLedgerLog =
        cashLedgerLog +
        `<tr>
                                              <td colspan="3">
                                                   <table style="width:100%">
                                                       <tr>
                                                         <td style="text-align:left"> <b>${
                                                           item.type
                                                         }</b></td>
                                                      
                                                      <td style="text-align:right">${
                                                        item.datetime
                                                      }</td>
                                                     </tr>
                                                     </table>
                                                        
                                                </td>  
                                                
                                      </tr>
                                      <tr>
                                               <td>
                                                  Taxes
                                                </td>
                                                <td style="text-align:center">Amount</td>
                                                <td style="text-align:right">Cash Balance</td>
                                        </tr>
                                        <tr>
                                            <td>
                                              <b>${item.taxes}</b>
                                            </td>
                                            <td style="text-align:center">${
                                              item.orderStatus == 'refund'
                                                ? '-'
                                                : item.amount > 0
                                                ? '+'
                                                : ''
                                            }${item.amount.toFixed(2)}</td>
                                            <td style="text-align:right">${balance.toFixed(
                                              2
                                            )}</td>
                                          </tr>                                                             
                                          <tr>
                                            <td colspan="3">${item.notes}</td>
                                          </tr> `;
    });

  //     cashLedgerLog = cashLedgerLog + `<tr>
  //                                             <td><div>
  //                                                           <table >
  //                                                             <tr>
  //                                                               <td>
  //                                                                 <b>${item.type}</b>
  //                                                               </td>
  //                                                             </tr>
  //                                                             <tr>
  //                                                               <td>
  //                                                                 <b>Taxes</b>
  //                                                               </td>
  //                                                             </tr>
  //                                                             <tr>
  //                                                               <td>
  //                                                                 <b>${item.taxes}</b>
  //                                                               </td>
  //                                                             </tr>

  //                                                               <tr>
  //                                                                 <td>${item.notes}</td>
  //                                                               </tr>

  //                                                           </table>
  //                                             </div></td>
  //                                             <td><div>
  //                                                   <table >
  //                                                     <tr>
  //                                                       <td>Amount</td>
  //                                                     </tr>
  //                                                     <tr>
  //                                                     <td>${(item.orderStatus=="refund" ?"-": item.amount>0?"+" :"")}${item.amount.toFixed(2)}</td>
  //                                                   </tr>
  //                                                   </table>
  //                                             </div></td>
  //                                             <td><div>
  //                                                   <table style="width:100%;text-align:right">
  //                                                     <tr>
  //                                                       <td>${item.datetime}</td>
  //                                                     </tr>
  //                                                     <tr>
  //                                                     <td>Cash Balance</td>
  //                                                   </tr>
  //                                                     <tr>
  //                                                     <td>${balance.toFixed(2)}</td>
  //                                                   </tr>
  //                                                   </table>
  //                                             </div></td>

  //                                     </tr> `

  // })

  var html = `
              <html lang="en">
              <head>
                  <meta charset="UTF-8">
                  <meta http-equiv="X-UA-Compatible" content="IE=edge">
                  <meta name="viewport" content="width=device-width, initial-scale=1.0">
                  <title>Cash Ledger</title>
                  <style>
                  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                  body {
                      font-family: 'Poppins', sans-serif;
                      font-size: 14px;
                      line-height: 19px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: 100%;
                  }
                  body, h1,h2,h3,h4,h5,h6 {
                      margin: 0;
                      padding: 0;
                  }
                  .table-common {
                      margin-bottom: 30px;
                  }
                  .table-common td {
                      padding-top: 5px;
                      padding-bottom: 5px;
                  }
                  .table-common tr:first-child td {
                      padding-top: 6px;
                  }
                  .table-common thead th{
                      border-bottom: 1px solid #050505;
                      padding-bottom: 10px;
                  }
                  .table-common tr:last-child td {
                      padding-bottom: 8px;
                     
                  }
                  .font-bold {
                      font-weight: 600;
                  }
                  
                  .section-heading{
                      font-weight: 500;
                      border-bottom: 1px solid #050505;
                      padding-bottom: 10px;
                      font-size: 14px;
                  }
                  .opening-balance{
                    font-weight: 500;
                    font-size: 14px;
                    padding-bottom: 5px;
                   }
                  /* .table-common2 thead tr:first-child th{
                      font-weight: 500;
                      border-bottom: 1px solid #050505;
                      padding-bottom: 10px;
                      font-size: 14px;
                  } */
                  .category-list tfoot tr:last-child td {
                      border-bottom: 0;
                  }
                  .pagesize{
                      width:${pageSize.width}; overflow:hidden;
                        margin: 0 auto;
                    }
                    .innter-table tr:last-child td {
                      padding-bottom: 8px;
                     
                  }
                 
                  </style>
              </head>
      <body>
      <div id="print" style='padding:${
        pageSize.width == '80mm'
          ? '20px'
          : pageSize.width == '52mm' || pageSize.width == '58mm'
          ? '10px'
          : '40px'
      };'   class='pagesize'>
      <div style="text-align: center; padding-bottom: 30px;">
          <h4 style="margin-bottom: 8px;">Cash Ledger</h4>
          <address>
              ${shopaddress}
             
          </address>
      </div>
      <table class="table-common" style="width: 100%;text-align: left;border-collapse: collapse;">
          <thead>
              <th>Cash Ledger Report #</th>
              <th style="text-align: right;">${
                _closeRegister && _closeRegister.CashManagementId
              }</th>
          </thead>
          <tbody>
          <tr>
          <td colspan="2">Z-Report: ${
            _closeRegister && _closeRegister.CashManagementId
          }</td>
          </tr>
              <tr>
              <td colspan="2"><b>Opening</b></td>
              </tr>
              <tr>
                  <td colspan="2"> Opening Time: ${openingDateTime} </td>
              </tr>
              <tr>
              <td colspan="2"> Opening Balance: ${formatAmount(
                _openingBalance.toFixed(2)
              )} </td>
            </tr>              
              <tr>
                  <td colspan="2">${
                    LocalizedLanguage.openedby
                  }: ${SalePersonName} </td>
              </tr>

               <tr>
              <td colspan="2"><b>Closing</b></td>
              </tr>
              <tr>
                  <td colspan="2">Closing Time:  ${closingDateTime}</td>
              </tr>
             
              <tr>
                  <td colspan="2">${
                    LocalizedLanguage.closedby
                  }: ${closePersonName} </td>
              </tr>
              <tr>
              <td colspan="2">Closing Balance: ${formatAmount(
                _totalActual.toFixed(2)
              )}</td>
              </tr>
          </tbody>
      </table>  
             
      <div >
      <h1 class="section-heading">
          ${LocalizedLanguage.transactions}
      </h1>
      <div class="category-list">
      <table class="table-common" style="width: 100%;text-align: left;border-collapse: collapse;">
              <tbody>
               ${cashLedgerLog}  
                  <tr><td colspan="3"> <h1 class="section-heading"> </h1></td></tr>
                  <tr>
                      <td>
                          Opening Balance
                      </td>
                      <td style="text-align:center">
                          Added
                      </td>
                      <td   style="text-align:right">
                          Subtracted
                      </td>
                  </tr>  

                  <tr>
                  <td>${_openingBalance.toFixed(2)}</td>
                  <td style="text-align:center">+${totAdded.toFixed(2)}</td>
                  <td  style="text-align:right">${totSubstract.toFixed(2)}</td>
                </tr>                
                <tr>
                  <td colspan='3'><b> Closing Balance :${(
                    _openingBalance +
                    totAdded +
                    totSubstract
                  ).toFixed(2)} </b></td>
                  
                </tr> 
      </tbody>                    
          </table>
      </div>
  
    
    
`;

  html += '</body ></html>';

  // code of android listener
  var _user = localStorage.getItem('user');
  var shop_address1 =
    _user &&
    _user !== null &&
    _user !== 'null' &&
    JSON.parse(_user).shop_address1;
  var shop_address2 =
    _user &&
    _user !== null &&
    _user !== 'null' &&
    JSON.parse(_user).shop_address2;
  var shop_postcode =
    _user &&
    _user !== null &&
    _user !== 'null' &&
    JSON.parse(_user).shop_postcode;
  var shop_country =
    _user &&
    _user !== null &&
    _user !== 'null' &&
    JSON.parse(_user).shop_country_full_Name;
  var androidcloseBalance = (_openingBalance + totAdded + totSubstract).toFixed(
    2
  );

  var data = {
    version: '3.0',
    data: {
      store: {
        name: _closeRegister.RegisterName,
        address: {
          lineOne: shop_address1,
          lineTwo: shop_address2,
          zip: shop_postcode,
          country: shop_country,
        },
      },
      report: {
        id: _closeRegister.CashManagementId,
        openTime: openingDateTime,
        openingBalance: _openingBalance.toFixed(2),
        openedBy: SalePersonName,
        closeTime: closingDateTime,
        closingBalance: androidcloseBalance,
        closedBy: closePersonName,
        totalAdded: totAdded.toFixed(2),
        totalSubtracted: totSubstract.toFixed(2),
      },
      transactions: transctionData,
    },
  };

  const isImgPrint = localStorage.getItem('isImgPrint')?.toLowerCase() === 'true';

  if (isImgPrint) {
    printImageHandler(html, 'cash_ledger_receipt');
  } else {
    sendDataToWrapper('cash_ledger_receipt', '', data);
  }

  // end code of android listener

 if (
    typeof window.Android !== 'undefined' &&
    window.Android !== null &&
    window.Android.getDatafromDevice('isWrapper') == true
  ) {
    showAndroidReceipt('', PrintAndroidReceiptData);
    //wrapperReceiptData(eodData);
    return PrintAndroidReceiptData;
  } else {
    if (printExcel == true) {
      var location = 'data:application/vnd.ms-excel;base64,';
      var uri = location + window.btoa(html);
      var link = document.createElement('a');
      link.download = 'cash ledger';
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return true;
    }
    var a = window.open('#', '', 'width=400', 'A2');
    a && a.document && a.document.write(html);
    a && a.print();
    if (!isSafari) {
      a && a.close();
    }
    return true;
  }
}
