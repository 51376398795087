

export const pdfFormate = () => {
    var pdfFormateSize = '';
    var pdfFormnateName= localStorage.getItem('pdf_format') && JSON.parse(localStorage.getItem('pdf_format')) ? JSON.parse(localStorage.getItem('pdf_format'))[0]:'';
    if(pdfFormnateName){
    if(pdfFormnateName.recipt_format_code == 'letterhead'){
      pdfFormateSize = {
        width: '210mm', height:'297mm'
      }
    }
    
    if(pdfFormnateName.recipt_format_code == 'a4'){
      return pdfFormateSize = {
        width: '215mm', height:'280mm'
      }
    }
     if(pdfFormnateName.recipt_format_code == '80mm'){
        return  pdfFormateSize = {
          width:  pdfFormnateName.recipt_format_value, height:'0 auto'
        }
      }
    if(pdfFormnateName.recipt_format_code == '52mm' || pdfFormnateName.recipt_format_code == '58mm'){
       return pdfFormateSize = {
          width: pdfFormnateName.recipt_format_value, height:'0 auto'
        }
      }
      }else{
        return pdfFormateSize = {
          width: '800px', height:'0 auto'
        }
      }
      return pdfFormateSize;
  }



export const recieptDateFormate = () => {
  var orderRecieptFormate = (localStorage.getItem('orderreciept') 
  && typeof(localStorage.getItem('orderreciept')) !=='undefined'
  && localStorage.getItem('orderreciept') !=='undefined'? JSON.parse(localStorage.getItem('orderreciept')) : '');

  //console.log("orderRecieptFormate", orderRecieptFormate)
  var recieptDateFormate = orderRecieptFormate ? orderRecieptFormate.DateFormat : 'DD, MMMM YYYY';
  //04 Feb 2020
  if(recieptDateFormate == "dd MMM yyyy" || recieptDateFormate == "dd MMM YYYY"){
       return 'DD MMM YYYY'
  }
  //2020 Feb 04
  else
  if(recieptDateFormate == "yyyy MMM dd" || recieptDateFormate == "YYYY MMM dd"){
      return 'YYYY MMM DD'
  }
  //2020-02-04
  else
  if(recieptDateFormate == "yyyy-MM-dd" || recieptDateFormate == "YYYY-MM-dd"){
     return 'YYYY-MM-DD'
  }
  //02-04-2020
  else
  if(recieptDateFormate == "MM-dd-yyyy" || recieptDateFormate == "MM-dd-YYYY"){
    return 'MM-DD-YYYY'
  } 
  //2020/02/04
  else
  if(recieptDateFormate == "yyyy/MM/dd" || recieptDateFormate == "YYYY/MM/dd"){
    return 'YYYY/MM/DD'
  }
 
  //02/04/2020
  else
  if(recieptDateFormate == "MM/dd/yyyy" || recieptDateFormate == " "){
    return 'MM/DD/YYYY'
  }
  else
  {
    if(recieptDateFormate && recieptDateFormate !== " "){
      return recieptDateFormate.toUpperCase()
    }
    else
    {
    return 'DD, MMMM YYYY'
    }
  }
}

export const recieptTimeFormate = () => {
  var orderRecieptFormate = (localStorage.getItem('orderreciept') 
  && typeof(localStorage.getItem('orderreciept')) !=='undefined'
  && localStorage.getItem('orderreciept') !=='undefined'? JSON.parse(localStorage.getItem('orderreciept')) : '');

  var recieptDateFormate = orderRecieptFormate ? orderRecieptFormate.TimeFormat : 'h:mm a';
  //01:53:43 AM
  if(recieptDateFormate == "hh:mm:ss tt" || recieptDateFormate == "hh:mm:ss A") {
       return 'hh:mm:ss A'
  }
  //01:53:43
  else
  if(recieptDateFormate == "HH:mm:ss") {
      return 'HH:mm:ss'
  }
  //01:53 AM
  else
  if(recieptDateFormate == "hh:mm tt" || recieptDateFormate == "hh:mm A") {
     return 'hh:mm A'
  }
  //01:53
  else
  if(recieptDateFormate == "HH:mm") {
    return 'HH:mm'
  } 
  else
  {
    return 'h:mm a'
  }
}
export const companyLogo = () => {
  var IMAGE_DOMAIN  ="https://"+((window.location.hostname=='dev1.sell.oliverpos.com' || window.location.hostname=='localhost')? 'dev1.app.olivertest.com' :'app.oliverpos.com' );
 var _user=localStorage.getItem('user');
  var _logUrl = _user && _user !==null &&  _user !=="null" && JSON.parse(_user).shop_logo;
  var _companyLogo=_logUrl ? IMAGE_DOMAIN+ _logUrl : null;
  return _companyLogo
}




