import React, { useEffect, useState } from 'react';
import { Markup } from 'interweave';

const TileText = ({ txtColor, content, tilesNo, tileWidth  }) => {
  const [textWidth, setTextWidth] = useState(0);

  useEffect(() => {
        let maxAllowedWidth = tileWidth * 0.8; 
        if (window.innerWidth <  700) {
          maxAllowedWidth =  tileWidth * 0.9
        }
        setTextWidth(maxAllowedWidth);
  }, [tilesNo, tileWidth, window.innerWidth]);

  return (
    <p className="main" style ={{
      color: txtColor,
      width: `${textWidth}px`, 
      maxWidth: `${textWidth}px`,
    }}>
      <Markup content={content} />
    </p>
  );
};

export default TileText;
