import React, { useLayoutEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
// import logo from './logo.svg';
// import { Counter } from './features/counter/Counter';
import Login from './components/login/Login'
import Site from './components/site/Site'
import Location from './components/location/Location'
import Register from './components/register/Register'
import NoPage from './components/NoPage'
import Pin from './components/pinPage/Pin';
import ProductLoader from './components/loadProduct/ProductLoader';
import Cashmanagement from './components/cashmanagement/Cashmanagement';
import { initDB } from "react-indexed-db";
import { DBConfig } from "./DBConfig";
import Home from './components/dashboard/Home';
import OpenRegister from './components/cashmanagement/OpenRegister';
import Closeregister from './components/cashmanagement/Closeregister';
import CustomerView from './components/customer/Customerview';
import Checkout from './components/checkout/Checkout';
import ActivityView from './components/activity/ActivityView';

import Refund from './components/refund/Refund';
import SaleComplete from './components/salecomplete/SaleComplete';
import RefundComplete from './components/refund/RefundComplete';
import { checkForEnvirnmentAndDemoUser } from './settings/CommonJS';
import { isIOS, isSafari } from 'react-device-detect';
import { getFirebaseNotification, pingFirebaseRegister } from './components/firebase/FirebaseNotifications';
import { checkRelogin } from './settings';
import ExternalLogin from './components/externalLogin/ExternalLogin';
import { useEffect } from 'react';
import { GTM_ClientDetail } from './components/common/CommonfunctionGTM';
import { get_UDid } from './components/common/localSettings';
import Config from '../src/Config'
import EnableBrowserNotification from './components/common/commonComponents/EnableBrowserNotification';
import { checkNotifyPermission } from './settings/CommonWork';
import LocalizedLanguage from './settings/LocalizedLanguage';

initDB(DBConfig);
function App() {  
  const dispatch = useDispatch();
  if (isIOS == false && isSafari == false) {
    var _register = localStorage.getItem("register")
    var _user = localStorage.getItem('user')
    var myInterval = setInterval(() => {
      if (_register && _user) {
        // console.log("ping register")
        pingFirebaseRegister(dispatch)
      } else {
        clearInterval(myInterval);
      }
    }, 240000)
  }
  //console.log("Check Ënviroment", process.env.NODE_ENV);

  localStorage.removeItem(get_UDid() + "_FavList");
  localStorage.removeItem(get_UDid() + "_NumOfTiles");
  const authenticateComponent = (component) => {
    checkRelogin();//check if auth key not exist then reset
    let isAuth = JSON.parse(localStorage.getItem('clientDetail'));
    if (!isAuth || isAuth !== null) {
      return component
    } else {
      return <Login />
    }
  }
  const authenticateInnerComponent = (component) => {
    checkRelogin();//check if auth key not exist then reset          
    // let clientDetail = JSON.parse(localStorage.getItem('clientDetail'));
    // if (clientDetail && clientDetail !== null) {
    //   let isAuth = JSON.parse(localStorage.getItem('user'));
    //   if (!isAuth || isAuth !== null) {
    return component
    //   } else {
    //     return <Pin />
    //   }
    // }
    // else {
    //   return <Login />
    // }

  }

  // call firebase notification functionality
  var isValidENV = checkForEnvirnmentAndDemoUser()
  if (isValidENV == true && isIOS == false && isSafari == false) { // call notification functionality only on dev1 and qa1 (development)
    if (window.location.hostname !== Config.key.DEMO_USER_URL) {
      getFirebaseNotification()
      setInterval(() => {
        getFirebaseNotification()
      }, 40000);
    }
  }
  var isCallable = true;
  useEffect(() => {
    // if(isCallable==true){
    //   const request = indexedDB.open("POSDB")
    //   let db;
    //   request.onsuccess = () =>{
    //       db = request.result;
    //       // console.log(db.objectStoreNames.contains('products'),"storename")
    //       if(!db.objectStoreNames.contains('products') || !db.objectStoreNames.contains('customers') || !db.objectStoreNames.contains('modifiers')){
    //           // alert('available')
    //           window.location.reload();
    //       }
    //   }
    //   isCallable=false;
    // }       
    if (window.location.hostname !== Config.key.DEMO_USER_URL) {
      checkNotifyPermission()
    }

    setTimeout(() => {
      GTM_ClientDetail();
    }, 1000);

    //Intercom initialization  
    if (window.location.hostname === Config.key.DEMO_USER_URL) {
      window.Intercom('boot', {
        app_id: Config.key.INTERCOM_APP_ID,
        api_base: "https://api-iam.intercom.io", //for US region, if want to change the region need to add region base URL
       //Website visitor so may not have any user related info
      });
    }
  }, [])

  
  const isTryOliver = window.location.hostname === Config.key.DEMO_USER_URL;
  const isPathPin = window.location.pathname === '/pin';

console.log("App Env",process.env.NODE_ENV);

  return (
    <>
      <div className={isTryOliver && !isPathPin ? 'demo-header' : 'demo-header hide'}>
        <p className='demo-header-text'>
          {LocalizedLanguage.demoEnvironment}
        </p>
        <a className="demo-started-btn" href={Config.key.DEMO_POS_URL} target='_blank'>
          {LocalizedLanguage.getStarted}
        </a>
      </div>
      <div className={isTryOliver && !isPathPin ? 'content-wrapper set' : 'content-wrapper unset'}>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/site" element={authenticateComponent(<Site />)} />
            <Route path="/location" element={authenticateComponent(<Location />)} />
            <Route path="/register" element={authenticateComponent(<Register />)} />
            <Route path="/pin" element={authenticateComponent(<Pin />)} />
            <Route path="/openregister" element={authenticateComponent(<OpenRegister />)} />
            <Route path="/closeregister" element={authenticateComponent(<Closeregister />)} />
            <Route path="*" element={authenticateComponent(<NoPage />)} />
            <Route path='/externallogin' element={authenticateComponent(<ExternalLogin />)} />

            {/* Internal login check for user authonication */}
            <Route path="/productloader" element={authenticateComponent(<ProductLoader />)} />
            <Route path="/home" element={authenticateInnerComponent(<Home />)} />
            <Route path="/cashdrawer" element={authenticateInnerComponent(<Cashmanagement />)} />
            <Route path="/customers" element={authenticateInnerComponent(<CustomerView />)} />
            <Route path="/checkout" element={authenticateInnerComponent(<Checkout />)} />
            <Route path='/transactions' element={authenticateInnerComponent(<ActivityView />)} />
            <Route path='/refund' element={authenticateInnerComponent(<Refund />)} />
            <Route path='/salecomplete' element={authenticateInnerComponent(<SaleComplete />)} />
            <Route path='/refundcomplete' element={authenticateInnerComponent(<RefundComplete />)} />
            <Route path='/enablenotification' element={authenticateInnerComponent(<EnableBrowserNotification />)} />
          </Routes>
        </Router>
      </div>
    </>
  );
}

export default App;
