import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteDB, openDB } from 'idb';

import { get_UDid } from '../common/localSettings'
import Config from '../../Config';
import { receiptSetting } from '../serverSetting/receiptSettingSlice';
import ActiveUser from '../../settings/ActiveUser';
import { taxSetting } from '../serverSetting/taxSettingSlice';
import STATUSES from '../../constants/apiStatus';
import { productCount } from './productCountSlice';
import { productLoader } from './loadProductSlice';
import { useNavigate } from 'react-router-dom';
import { useIndexedDB } from 'react-indexed-db';
import moment from 'moment';
import LoaderOnboarding from '../common/commonComponents/LoaderOnboarding';
import { NavigateComponent } from '../../settings';

const ProductLoader = () => {

    // const addProducts = useIndexedDB("products").add;
    // const addCustomers = useIndexedDB("customers").add;
    // const addModifiers = useIndexedDB("modifiers").add;

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [loadingProducts, setLoadingProducts] = useState(0)
    const [loadPerc, setLoadPerc] = useState(0)
    // const [productLoading, setProductLoading] = useState(false)
    // const { getAll: getAllProducts } = useIndexedDB("products");

    var isCallable = true;
    useEffect(() => {
        if (isCallable == true) {
            const request = indexedDB.open("POSDB")
            let db;
            request.onsuccess = () => {
                db = request.result;
                // console.log(db.objectStoreNames.contains('products'),"storename")
                if (!db.objectStoreNames.contains('products') || !db.objectStoreNames.contains('customers') || !db.objectStoreNames.contains('modifiers')
                ) {
                    // alert('available')
                    window.location.reload();
                }
            }
            isCallable = false;
        }
    }, [])

    //------------Customer records-----
    var tableCount = 0;
    const UpdateCustomerInIndexDB = (udid, CustomerArray) => {
        let db;
        const request = window.indexedDB.open("POSDB");
        request.onerror = (event) => {
            console.log("----onerror--" + event);
            // Do something with request.errorCode!
        };
        request.onsuccess = (event) => {
            db = request.result;
            const transaction = db.transaction(["customers"], "readwrite");
            const objectStore = transaction.objectStore("customers");
            //console.log("----onsuccess--" + event);
            CustomerArray && CustomerArray.length > 0 && CustomerArray.map((item) => {

                if (item && item.hasOwnProperty("WPId") && item.WPId !== null && item.WPId !== 0) {
                    var isExist=  objectStore.get(item.WPId)
                let objectStoreRequest=null;
                if(isExist){                    
                    objectStoreRequest=  objectStore.put(item);                    
                }else{
                    objectStoreRequest = objectStore.add(item);   
                }

                objectStoreRequest.onsuccess = (event) => {
                    // report the success of the request (this does not mean the item
                    // has been stored successfully in the DB - for that you need transaction.onsuccess)
                   // console.log("<li>Request successful.</li>",item);
                };
                objectStoreRequest.onerror=(error)=>{                  
                    console.log("error",error,item)
                }
                }
                //var temp=transaction.db;

            })
            transaction.commit();
            db.close();
            // Do something with request.result!
        };

    }
    const getCustomerList = (pn, pl, trc, _totalCustomer) => {
        var udid = get_UDid(localStorage.getItem("UDID"));
        var pageNumber = pn;
        var PageSize = Config.key.CUSTOMER_PAGE_SIZE;
        var CustomerArray = pl;
        var TotalRecord = trc;
        var totalCustomer = _totalCustomer;
        const requestOptions = {
            method: 'GET',
            headers: {
                "access-control-allow-origin": "*",
                "access-control-allow-credentials": "true",
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Authorization': 'Basic ' + btoa(sessionStorage.getItem("AUTH_KEY")),
                'Authorization': 'Basic ' + btoa(localStorage.getItem("AUTH_KEY")),
            }
            , mode: 'cors'
        };
        var isDemoUser = localStorage.getItem('demoUser') == 'true' && localStorage.getItem('DemoGuid');
        if (isDemoUser == true) {
            requestOptions.headers['demoauth'] = localStorage.getItem('DemoGuid') && localStorage.getItem('DemoGuid')
        }
        if (TotalRecord == 0) {
            UpdateCustomerInIndexDB(udid, CustomerArray);
        }
        // call firstTime------------------
        //  call common service
        var _totalReccord = pageNumber > 1 ? "&totalRecord=" + totalCustomer : ""
        fetch(`${Config.key.OP_API_URL}/v1/customers/GetPage?pageSize=${PageSize}&pageNumber=${pageNumber}${_totalReccord}`, requestOptions)
            .then(response => {
                if (response.ok) { return response.json(); }
                throw new Error(response.statusText)  // throw an error if there's something wrong with the response
            })
            .then(function handleData(data) {

                TotalRecord = data && data.content && data.content.Records && data.content.Records.length;
                CustomerArray = [...new Set([...CustomerArray, ...data.content.Records])];
                totalCustomer = data && data.content && data.content.Records && data.content.TotalRecords;
                if (data.content.Records)
                    UpdateCustomerInIndexDB(udid, data.content.Records);
                //check dataExist into indexdb-------------------------
                if (isDemoUser == false && (TotalRecord >= PageSize)) {
                    pageNumber++;
                    getCustomerList(pageNumber, CustomerArray, TotalRecord, totalCustomer);
                }
                else {
                    console.log("--------------all customer records are done-----------" + CustomerArray.length);
                    // UpdateCustomerInIndexDB(udid, CustomerArray);
                    var customerList = [];
                    customerList = CustomerArray ? CustomerArray : [];
                    // customerList = customerList.sort(function (a, b) {
                    //     return b.WPId - a.WPId;
                    // })
                    sessionStorage.setItem("CUSTOMER_ID", customerList[0].WPId ? customerList[0].WPId : 0);
                    // // check is loading completed -----------------                
                    // var interval = setInterval(() => {
                    //     getAllProducts().then((rows) => {
                    //         clearInterval(interval);
                    //         NavigateComponent(navigate,"home");
                    //     })
                    // }, 500);
                    // //---------------------------------------
                }
            })
            .catch(function handleError(error) {
                console.error('Console.save: No data ' + error + " " + JSON.stringify(error));
            })
    }


    //-------

    //------------Modifier records----

    const UpdateModifierInIndexDB = (udid, ModifierArray) => {

        let db;
        const request = window.indexedDB.open("POSDB");
        request.onerror = (event) => {
            console.log("----onerror--" + event);
            // Do something with request.errorCode!
        };
        request.onsuccess = (event) => {
            db = request.result;
            const transaction = db.transaction(["modifiers"], "readwrite");
            const objectStore = transaction.objectStore("modifiers");
            //console.log("----onsuccess--" + event);
            ModifierArray && ModifierArray.length > 0 && ModifierArray.map((item) => {
                var isExist=  objectStore.get(item.Id)
                let objectStoreRequest=null;
                if(isExist){                    
                    objectStoreRequest=  objectStore.put(item);                    
                }else{
                    objectStoreRequest = objectStore.add(item);   
                }

                objectStoreRequest.onsuccess = (event) => {
                    // report the success of the request (this does not mean the item
                    // has been stored successfully in the DB - for that you need transaction.onsuccess)
                   // console.log("<li>Request successful.</li>",item);
                };
                objectStoreRequest.onerror=(error)=>{                  
                    console.log("error",error,item)
                }
                //var temp=transaction.db;

            })
            transaction.commit();
            db.close();
            // Do something with request.result!
        };

        // ModifierArray && ModifierArray.length > 0 && ModifierArray.map((item) => {
        //     addModifiers(item).then(
        //         (key) => {
        //             // console.log("ID Generated: ", key);
        //             //   let newState = Object.assign({}, state);
        //             //   newState.id = key;
        //             //   setState(newState);
        //             //   history.goBack();
        //         },
        //         (error) => {
        //             // console.log(error);
        //         }
        //     )
        // })
    }
    const getModiferList = (pn, pl, trc) => {
        var time = moment().toDate().getTime();
        var offset = moment().toDate().getTimezoneOffset();
        var udid = get_UDid(localStorage.getItem("UDID"));
        var pageNumber = pn;
        var PageSize = Config.key.FETCH_PRODUCTS_PAGESIZE;
        var ModifierArray = pl;
        var TotalRecord = trc;
        const requestOptions = {
            method: 'GET',
            headers: {
                "access-control-allow-origin": "*",
                "access-control-allow-credentials": "true",
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Authorization': 'Basic ' + btoa(sessionStorage.getItem("AUTH_KEY")),
                'Authorization': 'Basic ' + btoa(localStorage.getItem("AUTH_KEY")),
            }
            , mode: 'cors'
        };
        var isDemoUser = localStorage.getItem('demoUser') == 'true' && localStorage.getItem('DemoGuid');
        if (isDemoUser == true) {
            requestOptions.headers['demoauth'] = localStorage.getItem('DemoGuid') && localStorage.getItem('DemoGuid')
        }
        if (TotalRecord == 0) {
            UpdateModifierInIndexDB(udid, ModifierArray);
        }
        // call firstTime------------------
        //  call common service
        fetch(`${Config.key.OP_API_URL}/v1/ProductModifier/Records?pageSize=${PageSize}&pageNumber=${pageNumber}&time=${time}&offset=${offset}`, requestOptions)
            .then(response => {
                if (response.ok) { return response.json(); }
                throw new Error(response.statusText)  // throw an error if there's something wrong with the response
            })
            .then(function handleData(data) {
                TotalRecord = data && data.content && data.content.Records && data.content.Records.length;
                ModifierArray = [...new Set([...ModifierArray, ...data.content.Records])];
                //check dataExist into indexdb-------------------------
                if (isDemoUser == false && (TotalRecord >= PageSize)) {
                    pageNumber++;
                    getModiferList(pageNumber, ModifierArray, TotalRecord);
                }
                else {
                    console.log("--------------all modifiers records are done-----------" + ModifierArray.length);
                    UpdateModifierInIndexDB(udid, ModifierArray);
                    //calling function to get all customer
                    console.log("--------------Start loading customer-----------")
                    // getCustomerList(1, [], 0);
                    //
                }
            })
            .catch(function handleError(error) {
                console.error('Console.save: No data ' + error + " " + JSON.stringify(error));
            })
        ///ProductModifier/Records?pageSize=${pageSize}&pageNumber=${pageNumber}&time=${time}&offset=${offset}`
    }

    //------

    //........Test--------------------------
    const saveDataIntoIndexDB = (_ProductArray) => {
        let db;
        const request = window.indexedDB.open("POSDB");
        request.onerror = (event) => {
            console.log("----onerror--" + event);
            // Do something with request.errorCode!
        };
       var ProductArray=_ProductArray.filter(a=>a.Status != "auto-draft" && a.Status != "draft" && a.Status != "trash")
        request.onsuccess = (event) => {
            db = request.result;
            const transaction = db.transaction(["products"], "readwrite");
            const objectStore = transaction.objectStore("products");
            //console.log("----onsuccess--" + event);
            ProductArray && ProductArray.length > 0 && ProductArray.filter(item => item.VisibilityOliverPOS == 0).map((item) => {
                item['CategorieList']=item.Categories ? item.Categories.split(","):[];
                
                var isExist=  objectStore.get(item.WPID)
                let objectStoreRequest=null;
                if(isExist){                    
                    objectStoreRequest=  objectStore.put(item);                    
                }else{
                    objectStoreRequest = objectStore.add(item);   
                }

                objectStoreRequest.onsuccess = (event) => {
                    // report the success of the request (this does not mean the item
                    // has been stored successfully in the DB - for that you need transaction.onsuccess)
                   // console.log("<li>Request successful.</li>",item);
                };
                objectStoreRequest.onerror=(error)=>{                  
                    console.log("error",error,item)
                }

            })
            transaction.commit();
            db.close();
            // Do something with request.result!
        };

        // ProductArray.filter(item => item.VisibilityOliverPOS == 0).map((item) => {
        //     addProducts(item).then(
        //         (key) => {
        //             // console.log("ID Generated: ", key);
        //             //   let newState = Object.assign({}, state);
        //             //   newState.id = key;
        //             //   setState(newState);
        //             //   history.goBack();
        //         },
        //         (error) => {
        //             // console.log(error);
        //         }
        //     )
        // })

        // var _state = { "WPID": 101, "name": "nagendra", "age": 32 }

    }
    //........................
    const displayProductLoadStatus = (total, Synched) => {
        if (total != 0) {
            var _perc = ((Synched * 100) / total).toFixed(0);
            setLoadingProducts("Synched " + Synched + " Products, Out of " + total + "");
            setLoadPerc(_perc);
        }
    }
    const getProductList = (pn, pz, totalSync) => {
        var totalRecord = localStorage.getItem("productcount") ? localStorage.getItem("productcount") : 0;
        displayProductLoadStatus(totalRecord, totalSync)


        //if (!localStorage.getItem('user') || !sessionStorage.getItem("issuccess")) {
        //redirectToURL()
        // navigate('/loginpin');
        //}
        //var RedirectUrl = ActiveUser.key.isSelfcheckout && ActiveUser.key.isSelfcheckout == true ? '/selfcheckout' : '/home';

        //var udid = get_UDid(localStorage.getItem("UDID"));

        var WarehouseId = localStorage.getItem("WarehouseId") ? parseInt(localStorage.getItem("WarehouseId")) : 0;

        var pageNumber = pn;
        //var pageSize = Config.key.FETCH_PRODUCTS_PAGESIZE;
        var PageSize = Config.key.FETCH_PRODUCTS_PAGESIZE;

        //var ProductArray = pl;       
        var totalProductSync = parseInt(totalSync);
        const requestOptions = {
            method: 'GET',
            headers: {
                "access-control-allow-origin": "*",
                "access-control-allow-credentials": "true",
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Authorization': 'Basic ' + btoa(sessionStorage.getItem("AUTH_KEY")),
                'Authorization': 'Basic ' + btoa(localStorage.getItem("AUTH_KEY")),
            }
            , mode: 'cors'
        };
        var isDemoUser = localStorage.getItem('demoUser') == 'true' && localStorage.getItem('DemoGuid');
        if (isDemoUser == true) {
            requestOptions.headers['demoauth'] = localStorage.getItem('DemoGuid') && localStorage.getItem('DemoGuid')
        }
        // if (totalRecord == 0 && isDemoUser == false) {
        //     // navigate( RedirectUrl) ;  
        //     console.log("test4")
        //     // UpdateIndexDB(udid, ProductArray, RedirectUrl);
        // }

        console.log("-------------- Stat time--------" + new Date());
        fetch(`${Config.key.OP_API_URL}/v1/Product/GetRecords?pageNumber=${pageNumber}&pageSize=${PageSize}&WarehouseId=${WarehouseId}`, requestOptions)
            .then(response => {
                if (response.ok) { return response.json(); }
                throw new Error(response.statusText)  // throw an error if there's something wrong with the response
            })
            .then(function handleData(data) {
                var reloadCount = localStorage.getItem("ReloadCount");
                //ProductArray = [...new Set([...ProductArray, ...data.content.Records])];
                if (data && data.content && data.content.Records) { saveDataIntoIndexDB(data.content.Records) }
                //check dataExist into indexdb-------------------------
                var isExist = false;

                //console.log("--------------Total Products count--------" + totalRecord);

                totalProductSync += parseInt(data.content.Records.length);

                displayProductLoadStatus(totalRecord, totalProductSync)

                if (isDemoUser == false && (totalRecord > totalProductSync) && ((totalRecord != totalProductSync) || pageNumber <= (totalRecord / PageSize * 1.0))) {
                    console.log("-------------- Product list response time--------" + new Date());
                    // self.UpdateIndexDB(udid,ProductArray);
                    pageNumber++;
                    //console.log("ProductArray1",ProductArray.length)     
                    // console.log("test5")
                    getProductList(pageNumber, PageSize, parseInt(totalProductSync));
                    //console.log("test6")
                }
                else {
                    console.log("--------------all product records are done-----------");
                    //console.log("ProductArray2",ProductArray.length)                       
                    //call to get modifires......
                    console.log("--------------Start loading Modifires-----------");
                    // getCustomerList(1, [], 0);
                    // getModiferList(1, [], 0);
                    setTimeout(() => {
                        //if(window.location.hostname !== Config.key.DEMO_USER_URL){ 
                            NavigateComponent(navigate, "pin");
                        // }else{
                        //     window.location.href = '/home';
                            
                        // }
                       

                    }, 200);
                    // tableCount = tableCount + 1;
                    // if (tableCount >= 2) {
                    //     NavigateComponent(navigate,"home");
                    // }

                }
            })
            .catch(function handleError(error) {
                console.error('Console.save: No data ' + error + " " + JSON.stringify(error));
                var reloadCount = localStorage.getItem("ReloadCount");
                // handle errors here
                if (reloadCount < 3) {
                    localStorage.setItem("ReloadCount", (parseInt(reloadCount) + 1));
                    setTimeout(function () {
                        navigate('/'); //Reload to get product
                        // navigate( '/home')
                    }, 1000)
                    // NavigateComponent(navigate,"home")
                }
            })
    }


    //Getting the receipt and tax setting--------------------        

    const [respReceiptSetting, respTaxSetting, resProlductCount] = useSelector((state) => [state.receiptsetting, state.taxsetting, state.productcount])

    if (respReceiptSetting && respReceiptSetting.status == STATUSES.IDLE && respReceiptSetting.is_success) {
        localStorage.setItem('orderreciept', JSON.stringify(respReceiptSetting.data && respReceiptSetting.data.content))
    }
    if (respTaxSetting && respTaxSetting.status == STATUSES.IDLE && respTaxSetting.is_success) {
        localStorage.setItem('TAX_SETTING', JSON.stringify(respTaxSetting.data && respTaxSetting.data.content))
    }
    if (resProlductCount && resProlductCount.status == STATUSES.IDLE && resProlductCount.is_success) {
        localStorage.setItem('productcount', JSON.stringify(resProlductCount.data && resProlductCount.data.content.count))

    }

    let useCancelled = false;
    useEffect(() => {
        dispatch(receiptSetting());
        if (useCancelled == false) {
            fetchData();

            // CustomerLoader();
            // ModifierLoader();
        }
        return () => {
            useCancelled = true;
        }
    }, []);

    let useApiCancelled = false;
    var productcount=localStorage.getItem("productcount");
    useEffect(() => {
        
        if (useApiCancelled == false) {
            console.log("......Loading prodcuts......................")
            if(productcount>0){
            getProductList(1, Config.key.FETCH_PRODUCTS_PAGESIZE, 0);
            }

            //
            getCustomerList(1, [], 0);
            getModiferList(1, [], 0);
        }
        return () => {
            useApiCancelled = true;
        }
    }, [productcount])

    const fetchData = async () => { //calling multiple api

       // if (!localStorage.getItem("productcount")) { 
        var uid=get_UDid();          
            dispatch(productCount(uid));
       // }

        dispatch(taxSetting());

    }

    var isDemoUser = false;
    //  this.getProductList(1, Config.key.FETCH_PRODUCTS_PAGESIZE, [], pcount);
    return <>
        {/* <h1>Product Loading...</h1>
        <h2> {loadingProducts} ( {loadPerc})% </h2>; */}
        <LoaderOnboarding isDemoUser={isDemoUser} statusCompleted={loadPerc}></LoaderOnboarding>
    </>
}

export default ProductLoader