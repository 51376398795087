import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { cashRecords, getDetails } from './CashmanagementSlice'
import { activityRecords } from '../activity/ActivitySlice'
import ActiveUser from '../../settings/ActiveUser';
import moment from 'moment';
import Config from '../../Config'
import { get_UDid } from '../common/localSettings';
import { FormateDateAndTime } from '../../settings/FormateDateAndTime';
import LeftNavBar from "../common/commonComponents/LeftNavBar";
import STATUSES from "../../constants/apiStatus";
import CashDrawerPaymentDetailList from './CashDrawerPaymentDetailList'
import { LoadingSmallModal } from "../common/commonComponents/LoadingSmallModal";
import AddRemoveCashPopup from './AddRemoveCashPopup'
import { useNavigate } from "react-router-dom";
import Oliver_Icon_BaseBlue from '../../assets/images/svg/Oliver-Icon-BaseBlue.svg';
import TransactionHistoryIcon from '../../assets/images/svg/TransactionHistoryIcon.svg';
import PrinterIcon from '../../assets/images/svg/PrinterIcon.svg';
import { sendDataToWrapper, setAndroidKeyboard, showAndroidReceipt } from "../../settings/AndroidIOSConnect";
import AngledBracket_Left_Blue from '../../assets/images/svg/AngledBracket-Left-Blue.svg';
import AppLauncher from "../common/commonComponents/AppLauncher";
import { LoadingModal } from "../common/commonComponents/LoadingModal";
import { NumericFormat } from "react-number-format";
import { CommonModuleJS } from "../../settings";
import MsgPopup from "../common/commonComponents/MsgPopup";
import { popupMessage } from "../common/commonAPIs/messageSlice";
import { v4 as uniqueKey } from 'uuid';
import { EodPrint } from "../common/EodPrint";
import { CashLedger } from "../common/CashLedger";
import LocalizedLanguage from "../../settings/LocalizedLanguage";
import { currecyFunction } from "../common/commonFunctions/currencyFunction";
import { GTM_ClientDetail } from "../common/CommonfunctionGTM";
import { ValidateComponent } from "../common/validate";
import ViewRecieptCashLedger from "../common/commonComponents/ViewRecieptCashLedger";
import { customergetDetail, customersave, customerupdate } from "../customer/CustomerSlice";
import { isMobileOnly } from "react-device-detect";
import { IsFreePlan } from "../../settings/CommonJS";
import NoUserAccess from "../common/commonComponents/NoUserAccess";

function Cashmanagement() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  var registerId = localStorage.getItem('register');

  // const [PageSize, setpageSize] = useState(Config.key.CUSTOMER_PAGE_SIZE)
  var current_date = moment().format(Config.key.DATE_FORMAT);
  const myRef = useRef();
  const [defauldnumber, setDefauldNumber] = useState(2);
  const [isShowAppLauncher, setisShowAppLauncher] = useState(false);
  const [isShowLinkLauncher, setisShowLinkLauncher] = useState(false);
  const [isShowiFrameWindow, setisShowiFrameWindow] = useState(false);
  const [isShowMobLeftNav, setisShowMobLeftNav] = useState(false);
  const [isMobileNav, setisMobileNav] = useState(false);
  const [cashPopUpOpen, setcashPopUpOpen] = useState(false)
  const [popupstatus, setpopupstatus] = useState('')
  const [addremoveCash, setaddremoveCash] = useState(true)
  const [isMobileList, setisMobileList] = useState(false)
  const [CashDrawerPaymentDetail, setcashDrawerDetailData] = useState([])
  const [activateSelect, setActivateSelect] = useState()
  const [_cashmangementlist, setCashmanagementList] = useState([])
  const [listrecordscount, setlistrecordscount] = useState([])
  const [isShowMsg, setisShowMsg] = useState(false);
  const [msgTitle, setmsgTitle] = useState('');
  const [msgBody, setmsgBody] = useState('');
  const [warningImg, setWarningImg] = useState('');
  const [isShowViewReceipt, setisShowViewReceipt] = useState(false);
  const [isFreePlan,setIsFreePlan]=useState(false);

  var firstRecordId = "";
  const [callDetailApiOnLoad, setCallDetailApiOnLoad] = useState(true);
  ValidateComponent()
  const HundleCashPopup = (status) => {
    var inputAmt = document.getElementById("addCashAmount");
    var inputNote = document.getElementById("addCashNote");
    if (inputAmt) {
      inputAmt.value = ""
    }
    if (inputNote) {
      inputNote.value = ""

    }
    setcashPopUpOpen(true)
    setpopupstatus(status)
  }
  const HundlePOpupClose = () => {  
      var Cash_Management_ID = localStorage.getItem('Cash_Management_ID');
      dispatch(getDetails(Cash_Management_ID));
    setcashPopUpOpen(false)
  }
  const toggleViewReceipt = () => {
    setisShowViewReceipt(!isShowViewReceipt)
  }

  const toggleMobileNav = () => {
    setisMobileNav(!isMobileNav)
    setisShowMobLeftNav(!isShowMobLeftNav)
  }
  const toggleLinkLauncher = () => {
    setisShowLinkLauncher(!isShowLinkLauncher)
    setisShowAppLauncher(false)
  }

  const toggleAppLauncher = () => {
    setisShowAppLauncher(!isShowAppLauncher)
    setisShowLinkLauncher(false)
  }
  const toggleiFrameWindow = () => {
    setisShowiFrameWindow(!isShowiFrameWindow)
  }
  const toggleListWrapp = () => {
    setisMobileList(!isMobileList)
  }
  const toggleMsgPopup = () => {
    setisShowMsg(!isShowMsg)
  }


  const getCashDrawerPaymentDetail = (OrderId, index) => {
    toggleListWrapp()
    dispatch(getDetails(OrderId));
    setActivateSelect(OrderId)
  }

  useEffect(() => {
    if(IsFreePlan()==true){
      setIsFreePlan(true);
  }
    GTM_ClientDetail();
  }, []);

  let useCancelled = false;
  useEffect(() => {
    if (useCancelled == false) {
      loadMore(1)
      dispatch(activityRecords("clear"));
      dispatch(customersave('clear'));
      dispatch(customergetDetail('clear'));
      dispatch(customerupdate('clear'));
    }
    return () => {
      useCancelled = true;
    }
  }, []);

    const loadMore = (number) => {
      var PageSize = Config.key.CASHLOG_PAGE_SIZE;
      var TotalRecords = 0;
      var PageNumber = 0;

      if (TotalRecords == 0 || (TotalRecords / PageSize) >= (PageNumber)
        || ((PageSize * (PageNumber + number)) > 0)) {
        PageNumber = PageNumber + number;
        dispatch(cashRecords({ "registerId": registerId, "pageSize": PageSize, "pageNumber": number }));
      }

    }



  const [cashdrawer] = useSelector((state) => [state.cashmanagement])
  useEffect(() => {
    if (cashdrawer && cashdrawer.data && cashdrawer.data.content && cashdrawer.data.content.Records.length > 0) {
      // setCashmanagementList(cashdrawer.data && cashdrawer.data.content && cashdrawer.data.content.Records)
      var temState = [..._cashmangementlist, ...cashdrawer.data && cashdrawer.data.content && cashdrawer.data.content.Records]
      setCashmanagementList(temState);
      setlistrecordscount(cashdrawer.data && cashdrawer.data.content && cashdrawer.data.content.TotalRecords)
    }
  }, [cashdrawer]);



  const { status, data, error, is_success } = useSelector((state) => state.cashmanagement)
  // console.log("status", status, "data", data, "error", error, "is_success", is_success)
  if (status === STATUSES.IDLE && is_success) {
    if (data && data.content && data.content !== undefined) {
      var _RecordArray = _cashmangementlist
      var array = [];
      if (_RecordArray.length > 0) {
        array = _RecordArray.slice().sort((a, b) => b.LogDate - a.LogDate)
        // TempOrders = TempOrders.filter(item => item.TempOrderID == tempOrderId);
        // array = [...new Set([...array, ...data.content.Records])];
        array.reverse();
        var openingCashDrawerRecord = array ? array.find(Items => Items.ClosedTimeUtc == null) : null;
        var _id = openingCashDrawerRecord?.Id ? openingCashDrawerRecord.Id : '';

        var _list = array ? array.filter(Items => Items.ClosedTimeUtc == null) : null;
        if (_list && _list.length > 1) {
          var farray = _list.sort((a, b) => b.Id - a.Id);
          openingCashDrawerRecord = farray[0];
          _id = farray[0].Id;
        }

        if (_id != "") {
          localStorage.setItem("Cash_Management_ID", _id)
          localStorage.setItem("IsCashDrawerOpen", "true");
          firstRecordId = _id;
        }
      }
    }
  }







  //======Cash ReCords API Data Store 
  var allCashRecords = []
  var allCashRecords = _cashmangementlist ? _cashmangementlist && _cashmangementlist.filter(Items => Items.ClosedTimeUtc !== null) : null;
  var current_date = moment().format(Config.key.DATE_FORMAT);
  /// Find Dates and And filter list with dates----------------------
  var getDistinctActivity = {};
  var _activity = allCashRecords
  _activity && _activity.map(item => {
    var dateKey = FormateDateAndTime.formatDateAndTime(item.LogDate && item.LogDate !== undefined ? item.LogDate : item.LogDateUtc, item.TimeZoneType);
    if (!getDistinctActivity.hasOwnProperty(dateKey)) {
      getDistinctActivity[dateKey] = new Array(item);
    } else {
      if (typeof getDistinctActivity[dateKey] !== 'undefined' && getDistinctActivity[dateKey].length > 0) {
      
        var arrFound=getDistinctActivity[dateKey].find(i=>i.Id==item.Id)
        if (!arrFound || arrFound.length==0) {
          getDistinctActivity[dateKey].push(item);
        }        
        
      }
    }
  })

  var ordersDate = new Array();
  var orders = getDistinctActivity;

  if (typeof orders !== 'undefined') {
    for (const key in orders) {
      if (orders.hasOwnProperty(key)) {
        ordersDate.push(key)
      }
    }
    if (ordersDate.length > 0) {
      ordersDate.sort(function (a, b) {
        var keyA = new Date(a),
          keyB = new Date(b);
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
      ordersDate.reverse();
    }
  }




  const { statusgetdetail, getdetail, errorgetdetail, is_successgetdetail } = useSelector((state) => state.cashmanagementgetdetail)
  const [cashDrawerAllDetails] = useSelector((state) => [state.cashmanagementgetdetail])
  useEffect(() => {
    if (cashDrawerAllDetails && cashDrawerAllDetails.statusgetdetail == STATUSES.IDLE && cashDrawerAllDetails.is_successgetdetail && cashDrawerAllDetails.getdetail
      && cashDrawerAllDetails.getdetail.content) {
      setcashDrawerDetailData(cashDrawerAllDetails.getdetail && getdetail.content);
      //  setActivateSelect(cashDrawerAllDetails.getdetail && getdetail.content.CashManagementId)

    }
  }, [cashDrawerAllDetails]);

  if (callDetailApiOnLoad === true && firstRecordId !== "") {
    setCallDetailApiOnLoad(false)
    dispatch(getDetails(firstRecordId));
    setActivateSelect(firstRecordId)
  }

  // Getting Common Message Responce

  const [respopupMessage] = useSelector((state) => [state.popupMessage])
  useEffect(() => {
    if (respopupMessage && respopupMessage.status == STATUSES.IDLE && respopupMessage.is_success && respopupMessage.data) {
      toggleMsgPopup(true);
      setmsgBody(respopupMessage.data.msg);
      setmsgTitle(respopupMessage.data.title);
      setWarningImg(respopupMessage.data.img);
      dispatch(popupMessage(null));
    }
  }, [respopupMessage]);


 
   const onScroll = (e) => {
     const bottom = Number((e.target.scrollHeight - e.target.scrollTop).toFixed(0)) - e.target.clientHeight < 50;
    if (_cashmangementlist.length >= listrecordscount) {
      return;
    }
    else if(e.target.scrollTop + window.innerHeight + 200 >= e.target.scrollHeight){
      setDefauldNumber(defauldnumber + 1)
      if (defauldnumber != 1) {
        loadMore(defauldnumber)
      }
    }
};

  // PRINT EOD 
  const selectPrinting = () => {
    setisShowViewReceipt(true);
  }

  function PrintClick() {
    setisShowViewReceipt(false);
    EodPrint.PrintEodEle(CashDrawerPaymentDetail);
  }

  function Printledger(printExcel) {
    setisShowViewReceipt(false);
    sendDataToWrapper("cashdrawer","","")
    CashLedger.PrintCashLedger(CashDrawerPaymentDetail, "",printExcel);
  }
  
  var _balance = 0;
  if (CashDrawerPaymentDetail) {
    if (CashDrawerPaymentDetail.Status == "Close")
      _balance = CashDrawerPaymentDetail.Actual;
    else
      _balance = CashDrawerPaymentDetail.Expected;
  }
  var closeDateTime = CashDrawerPaymentDetail ? CashDrawerPaymentDetail.UtcClosedDateTime : "";
  var _closeDateTime = moment.utc(closeDateTime).local().format(Config.key.MONTH_DAY_FORMAT);
  var openDateTime = CashDrawerPaymentDetail && CashDrawerPaymentDetail ? CashDrawerPaymentDetail.UtcOpenDateTime : "";
  var _openDateTime = moment.utc(openDateTime).local().format(Config.key.MONTH_DAY_FORMAT);
  var Status = CashDrawerPaymentDetail && CashDrawerPaymentDetail.Status
  var OpeningBalance = CashDrawerPaymentDetail && CashDrawerPaymentDetail.OpeningBalance
  var closeDate = CashDrawerPaymentDetail ? CashDrawerPaymentDetail.CloseDateTimeOffset: "";
  var _closeDate = moment.utc(closeDate).local().format(Config.key.DATE_FORMAT_CASH_DETAIL);

  return (
    <>
      <React.Fragment>
        {(isFreePlan==false && cashdrawer.status == STATUSES.LOADING && defauldnumber <=2 )? <LoadingModal></LoadingModal> : null}
        {(isFreePlan==false && cashDrawerAllDetails.status == STATUSES.LOADING )? <LoadingModal></LoadingModal> : null}
        <div className="cash-management-wrapper">
          <LeftNavBar isShowMobLeftNav={isShowMobLeftNav} toggleLinkLauncher={toggleLinkLauncher} toggleAppLauncher={toggleAppLauncher} toggleiFrameWindow={toggleiFrameWindow} view="Cash Drawer"></LeftNavBar>
          <AppLauncher isShow={isShowAppLauncher} toggleAppLauncher={toggleAppLauncher} toggleiFrameWindow={toggleiFrameWindow}></AppLauncher>

          <div className="mobile-cm-header">
            <button id="mobileNavToggle" onClick={() => toggleMobileNav()} className={isMobileNav === true ? "opened" : ""} >
              <img src="" alt="" />
            </button>
            <p>{LocalizedLanguage.cashmanagement}</p>
            <button id="mobileAppsButton" onClick={() => toggleAppLauncher()}>
              <img src={Oliver_Icon_BaseBlue} alt="" />
            </button>
          </div>
        
          <div className="cm-list">
            <div className="cm-list-header-landscape">
              <p>{LocalizedLanguage.cashmanagement}</p>
            </div>
            <div className="cm-list-body" ref={myRef} onScroll={onScroll} >
              {(((!allCashRecords) || allCashRecords.length == 0) && Status !== "Open") ? <>
               </> :
                <>
                {
                  isMobileOnly === true? <button className={activateSelect == openingCashDrawerRecord?.Id ? "current-register no-transform" : "current-register no-transform"} onClick={() => getCashDrawerPaymentDetail(openingCashDrawerRecord.Id)}>
                  <p className="style1"> {openingCashDrawerRecord && openingCashDrawerRecord.ClosedTimeUtc == null ? <> {LocalizedLanguage.currentlyactive}</> : "Currently  Closed "}  </p>
                  <div className="text-row">
                    <p>{openingCashDrawerRecord && openingCashDrawerRecord.RegisterName}</p>
                    <p className="open"> {openingCashDrawerRecord && openingCashDrawerRecord.ClosedTimeUtc == null ? <>{LocalizedLanguage.open}</> : <>{LocalizedLanguage.closed}</>}  </p>

                  </div>
                  <p className="style2">User: {openingCashDrawerRecord && openingCashDrawerRecord.SalePersonName}</p>
                  <div className="mobile-fake-button">{openingCashDrawerRecord && openingCashDrawerRecord.ClosedTimeUtc == null ? <>{LocalizedLanguage.open}</> : <>{LocalizedLanguage.closed}</>}</div>
                </button>:
                <button className={activateSelect == openingCashDrawerRecord?.Id ? "current-register no-transform selected" : "current-register no-transform"} onClick={() => getCashDrawerPaymentDetail(openingCashDrawerRecord && openingCashDrawerRecord.Id)}>
                  <p className="style1"> {openingCashDrawerRecord && openingCashDrawerRecord.ClosedTimeUtc == null ? <> {LocalizedLanguage.currentlyactive}</> : "Currently  Closed "}  </p>
                  <div className="text-row">
                    <p>{openingCashDrawerRecord && openingCashDrawerRecord.RegisterName}</p>
                    <p className="open"> {openingCashDrawerRecord && openingCashDrawerRecord.ClosedTimeUtc == null ? <>{LocalizedLanguage.open}</> : <>{LocalizedLanguage.closed}</>}  </p>

                  </div>               
                  <div className="mobile-fake-button">{openingCashDrawerRecord && openingCashDrawerRecord.ClosedTimeUtc == null ? <>{LocalizedLanguage.open}</> : <>{LocalizedLanguage.closed}</>}</div>
                </button>
                }
                
                  {
                    orders && ordersDate && ordersDate.map((getDate, index) => {
                      var sortedArray=  getDate && orders && orders[getDate] && orders[getDate]
                      if (sortedArray.length > 0) {
                        sortedArray.sort(function (a, b) {
                          var keyA = new Date(a.ClosedDateTime),
                            keyB = new Date(b.ClosedDateTime);
                          // Compare the 2 dates
                          if (keyA < keyB) return -1;
                          if (keyA > keyB) return 1;
                          return 0;
                        });
                        sortedArray.reverse();
                      }
                      return (<React.Fragment> <div key={uniqueKey()} className="date"><p>{current_date == getDate ? <>{LocalizedLanguage.today}</> : getDate} </p></div>
                        {
                       
                        sortedArray && sortedArray.map((order, index) => {                        
                          var _time = FormateDateAndTime.formatDateWithTime(order.ClosedDateTime, order.TimeZoneType, Config.key.ONLY_TIME)
                          return (
                            <>
                            {
                              isMobileOnly === true ?
                              <button key={uniqueKey()} className={activateSelect == order.Id ? "other-register no-transform" : "other-register no-transform"} onClick={() => getCashDrawerPaymentDetail(order.Id, index)} >
                              <div className="row">
                                <p className="style1">{order.RegisterName}</p>
                                <p className="style2">{!order.ClosedTime ? <>{LocalizedLanguage.open}</> : <>{LocalizedLanguage.closed}</>}</p>
                              </div>
                              <div className="row">
                                <p className="style2">{LocalizedLanguage.closedby}:   {order.ClosingByName && order.ClosingByName !==""?order.ClosingByName :order.SalePersonName}</p>
                                {/* <p className="style2">{tConvert(order.OpenTime)}</p> */}
                                <p className="style2">{_time}</p>

                              </div>
                            </button>:
                            <button key={uniqueKey()} className={activateSelect == order.Id ? "other-register no-transform selected" : "other-register no-transform"} onClick={() => getCashDrawerPaymentDetail(order.Id, index)} >
                              <div className="row">
                                <p className="style1">{order.RegisterName}</p>
                                <p className="style2">{!order.ClosedTime ? <>{LocalizedLanguage.open}</> : <>{LocalizedLanguage.closed}</>}</p>
                              </div>
                              <div className="row">
                                <p className="style2">{LocalizedLanguage.closedby}:   {order.ClosingByName && order.ClosingByName !==""?order.ClosingByName :order.SalePersonName}</p>
                                {/* <p className="style2">{tConvert(order.OpenTime)}</p> */}
                                <p className="style2">{_time}</p>

                              </div>
                            </button>
                            }
                            </>
                           
                          )
                        })
                        }
                      </React.Fragment>)
                    })}
                </>
              }
            </div>
          </div>
          <div id="cmDetailed" className={isMobileList === true ? "cm-detailed open " : "cm-detailed"}>
            <div className="detailed-header-landscape" >
              <img src={TransactionHistoryIcon} alt="" />
              <p>{LocalizedLanguage.transactionhistory}</p>
            </div>
            <div className="detailed-header-mobile">
              <button id="mobileDetailedExit" onClick={toggleListWrapp}>
                <img src={AngledBracket_Left_Blue} alt="" />
                {LocalizedLanguage.goBack}
              </button>
            </div>
            <div className="detailed-quick-info">
              <div className="row">
                <div className="row-group">
                  <p>{CashDrawerPaymentDetail && CashDrawerPaymentDetail.RegisterName}</p>
                  <div className= {CashDrawerPaymentDetail && CashDrawerPaymentDetail.Status == 'Close'? "status open statusClose" : "status open"}>{CashDrawerPaymentDetail && CashDrawerPaymentDetail.Status == 'Close' ? <>{LocalizedLanguage.closed}</> : <>{LocalizedLanguage.open}</>}</div>
                </div>
                {CashDrawerPaymentDetail && CashDrawerPaymentDetail.Status == 'Open' && CommonModuleJS.permissionsForPrintEOD() == true ?
                  <p className="active"> {CashDrawerPaymentDetail && CashDrawerPaymentDetail.Status == 'Close' ? '' : <>{LocalizedLanguage.currentlyactive}</>} </p> : ''}
                {CashDrawerPaymentDetail && CashDrawerPaymentDetail.Status && CashDrawerPaymentDetail.Status.toLowerCase() == 'close' && CommonModuleJS.permissionsForPrintEOD() == true ?
                  <p className=""> {CashDrawerPaymentDetail && CashDrawerPaymentDetail.Status == 'Close' ? <>{_closeDate}</> : ''} </p> : ''}
              </div>
              <div className="row">
                <div className="col-group">
                  <p className="style1">{LocalizedLanguage.openingFloat}:</p>
                  {/* <p className="style2">$<NumericFormat value={OpeningBalance} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} /></p> */}
                  <p className="style2">{currecyFunction(OpeningBalance)}</p>
                </div>
                {CashDrawerPaymentDetail && CashDrawerPaymentDetail.Status == 'Close' && CommonModuleJS.permissionsForPrintEOD() == true ? <button onClick={() => selectPrinting(CashDrawerPaymentDetail)} >
                  <img src={PrinterIcon} alt="" />
                  {LocalizedLanguage.print}
                </button> : null}
              </div>
            </div>
            <div className="detailed-body">
              <CashDrawerPaymentDetailList isFreePlan={isFreePlan}/>
            </div>

            {/* Footer only available if register is active */}
            <div style={{ display: CashDrawerPaymentDetail && CashDrawerPaymentDetail.Status !== 'Open' ? "none" : "" }} className="detailed-footer">
              <button style={{ opacity: CashDrawerPaymentDetail && CashDrawerPaymentDetail.Status !== 'Open' ? 0.5 : 1 }} disabled={CashDrawerPaymentDetail && CashDrawerPaymentDetail.Status !== 'Open' ? true : false} onClick={() => HundleCashPopup('remove')} id="removeCashSubwindowButton">{LocalizedLanguage.removeCash}</button>
              <button style={{ opacity: CashDrawerPaymentDetail && CashDrawerPaymentDetail.Status !== 'Open' ? 0.5 : 1 }} disabled={CashDrawerPaymentDetail && CashDrawerPaymentDetail.Status !== 'Open' ? true : false} onClick={() => HundleCashPopup('add')} id="addCashSubwindowButton">{LocalizedLanguage.addCash}</button>
            </div>
          </div>
        
        </div>
        {isFreePlan==true &&
        <NoUserAccess isShow={isFreePlan} tab={LocalizedLanguage.cashmanagement}></NoUserAccess>
        }
        <AddRemoveCashPopup popupstatus={popupstatus} isShow={cashPopUpOpen} drawerBalance={_balance} HundlePOpupClose={HundlePOpupClose} />
        <MsgPopup isShow={isShowMsg} toggleMsgPopup={toggleMsgPopup} msgTitle={msgTitle} img={warningImg} msgBody={msgBody}></MsgPopup>
        {isShowViewReceipt ? <ViewRecieptCashLedger isShow={isShowViewReceipt} toggleViewReceipt={toggleViewReceipt} Printledger={Printledger} PrintClick={PrintClick}></ViewRecieptCashLedger> : null}
      </React.Fragment>
    </>
  )
}

export default Cashmanagement