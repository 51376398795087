import Config from '../../Config';
import moment from 'moment';
import { get_UDid } from './localSettings';
import { FormateDateAndTime } from '../../settings/FormateDateAndTime';
import ActiveUser from '../../settings/ActiveUser';
import LocalizedLanguage from '../../settings/LocalizedLanguage';
import {
  getAddonsField,
  getBookingField,
  productRetrunDiv,
  getInclusiveTaxType,
  productxArray,
  showSubTitle,
  showTitle,
} from '../../settings/CommonModuleJS';
// import { productxArray, showSubTitle, showTitle } from '../_components';
//import { showAndroidToast, showAndroidReceipt } from '/..../settings/AndroidIOSConnect';

import CommonJs from '../../settings/CommonJS';
import { isSafari } from 'react-device-detect';
import {
  sendDataToWrapper,
  showAndroidReceipt,
  wrapperReceiptData,
} from '../../settings/AndroidIOSConnect';
import {
  addModiferTaxToLineItemTax,
  getCustomFee,
  getDiscountPerItem,
  getModifierFromCustomFee,
  getOrderNotes,
  getProdcutAddons,
  getProductNotes,
  getWCDiscount,
} from '../../settings/CommonWork';
import {
  pdfFormate,
  recieptDateFormate,
  recieptTimeFormate,
  companyLogo,
} from '../../settings/ReceiptSetting';
import { postmessage } from './AppHandeler/commonAppHandler';
import { printImageHandler } from './HtmlToImageConvertor';

function stripHtml(html) {
  // Create a new div element
  var temporalDivElement = document && document.createElement('div');
  // Set the HTML content with the providen
  temporalDivElement.innerHTML = html;
  // Retrieve the text property of the element (cross-browser support)
  return (
    temporalDivElement.textContent ||
    temporalDivElement.innerText ||
    ''
  ).replace(/^"(.*)"$/, '$1');
}

/**
 * CreatedBy :Shakuntala Jatav
 * Created Date : 20-02-2020
 * @param {*} product_id get id
 * @param {*} productlist get all product list
 * Description: get product-x sub tilte list
 */
function showProductxSubTitle(product_id, productlist) {
  var productxValue = '';
  var productxSubList = productxArray(product_id, productlist);
  productxValue =
    productxSubList && productxSubList.props && productxSubList.props.children
      ? productxSubList.props.children
      : '';

  var productXData = localStorage.getItem('PRODUCTX_DATA')
    ? JSON.parse(localStorage.getItem('PRODUCTX_DATA'))
    : '';
  var bookingData = null;
  if (productXData && productXData !== '') {
    productXData.map((itm) => {
      if (itm.product_id == product_id && itm.booking) {
        bookingData = getBookingField({ booking: itm.booking });
        // var metaValues = bookingData && bookingData.join(";<br/>")
        // productxValue = metaValues && metaValues !== '' ? metaValues : productxValue
        productxValue = productRetrunDiv(bookingData, true);
      }
    });
  }

  return productxValue;
}

// function showAddonsProductxSubTitle(product_id, addons, pricemeasurmentdata) {
//   var addonsSubTitle = ''

//   var _addons = {};
//   _addons['addons'] = addons && addons.length > 0 && JSON.parse(addons);
//   //var prodXMeta = addons ? JSON.parse(addons) : ''
//   _addons['pricing_item_meta_data'] = pricemeasurmentdata;
//   // get meta values from meta field for addons and measurment types
//   // console.log("getAddonsField(_addons)", getAddonsField(_addons))
//   var metaValues = getAddonsField(_addons);

//   // var metaValues =metaValues.join(";<br/>")
//   // addonsSubTitle = metaValues !== '' ? metaValues : addonsSubTitle
//   addonsSubTitle = productRetrunDiv(metaValues, true);

//   return addonsSubTitle

// }
function getProductShortDesc(data, type, productID) {
  var shortdesc;
  var getorderlist = '';
  var _data;
  if (type !== 'activity') {
    _data = data.meta_datas && data.meta_datas[0];
    getorderlist = _data && _data._order_oliverpos_product_discount_amount;
  } else {
    _data =
      data.meta_datas &&
      data.meta_datas.find(
        (data) => data.ItemName == '_order_oliverpos_product_discount_amount'
      );
    getorderlist = _data && _data.ItemValue && JSON.parse(_data.ItemValue);
  }
  if (getorderlist) {
    getorderlist
      .filter((itm) => itm.product_id && itm.product_id == productID)
      .map((prd) => {
        // console.log("prd",prd)
        shortdesc = prd.shortDescription;
      });
  }
  return shortdesc;
}
// function getDiscountPerItem_old(data, type, productID) {
//   var discountDetail;
//   var getorderlist = "";
//   var _data;
//   if (type !== 'activity') {
//     _data = data.meta_datas && data.meta_datas[0]
//     getorderlist = _data && _data._order_oliverpos_product_discount_amount
//   } else {
//     _data = data.meta_datas && data.meta_datas.find(data => data.ItemName == '_order_oliverpos_product_discount_amount');
//     getorderlist = _data && _data.ItemValue && JSON.parse(_data.ItemValue);
//   }
//   if (getorderlist) {
//     getorderlist.filter(itm => itm.product_id && (itm.variation_id == productID || itm.product_id == productID)).map(prd => {
//       // console.log("prd",prd)
//       var typeRate = 0;
//       if (prd.discount_type == "Number") {
//         var disPerc = (prd.new_product_discount_amount * 100) / prd.Price
//         typeRate = disPerc.toFixed(2)
//       } else {
//         typeRate = prd.new_product_discount_amount
//       }
//       discountDetail = {
//         "discountApply": typeRate,
//         "discountAmount": (prd.product_discount_amount * (prd.discount_type == "Number" ? 1 : prd.quantity)),
//         "discounttype": prd.discount_type,
//         "discountCart": prd.discountCart ? prd.discountCart : 0,
//         "cartItemDiscount": prd.cart_discount_amount
//       }
//     })
//   }
//   return discountDetail

// }
function calculateTaxRate(totalAmount, itemvalue) {
  return Math.round((itemvalue * 100) / totalAmount, 0);
}

var getRefundTotalDetail = (
  order_reciept,
  refundAmount,
  refundSubTotal,
  RefundTotalTax,
  total_cashround,
  labelsubTotal,
  discountAfterRefund,
  refund_receipt,
  PrintAndroidData,
  rowNumber,
  labelIncl = '',
  new_print = false
) => {
  var refTotal = '';
  var newReceiptRefTotal = '';

  refTotal += `<table class="item-total" >
  <tbody><tr>
    <td colspan="2">${
      order_reciept.SubTotal ? order_reciept.SubTotal : `${labelsubTotal}`
    }</td>
    <td align="right"> ${refundSubTotal.toFixed(2)}</td>
  </tr>`;

  RefundTotalTax.length > 0 &&
    RefundTotalTax.map((_tax) => {
      refTotal += `<tr>
  <td colspan="2">${_tax.tax} ${labelIncl}</td>
  <td align="right"> ${parseFloat(_tax.value).toFixed(2)}</td>
</tr>`;
    });
  refTotal +=
    discountAfterRefund &&
    discountAfterRefund !== 0 &&
    discountAfterRefund !== '0.00'
      ? `<tr>
  <td colspan="2">${LocalizedLanguage.discount}</td>
  <td align="right">-${parseFloat(discountAfterRefund).toFixed(2)}
  </td>
</tr>`
      : '';
  refTotal +=
    total_cashround && total_cashround !== 0 && total_cashround !== '0.00'
      ? `<tr>
      <td colspan="2">${LocalizedLanguage.cashRounding}</td>
      <td align="right">${parseFloat(RoundAmount(total_cashround)).toFixed(2)}
      </td>
  </tr>`
      : '';

  refTotal += `<tr class="border-bottom">
      <td colspan="2">${LocalizedLanguage.refundtotal}</td>
      <td align="right"> ${refundAmount.toFixed(2)}</td>
    </tr>
  </tbody>
</table >`;

  newReceiptRefTotal += `
<div class="refund-amount-container">
  <div class="amount-container">
    <div class="amount-wrapper">
      <div class="amount-label">${
        order_reciept.SubTotal ? order_reciept.SubTotal : `${labelsubTotal}`
      }</div>
      <div class="amount-value">${refundSubTotal.toFixed(2)}</div>
    </div>


    ${
      RefundTotalTax.length > 0
        ? RefundTotalTax.map((_tax) => {
            return `
      <div class="amount-wrapper">
        <div class="amount-label">
          ${_tax.tax} ${labelIncl}
        </div>
        <div class="amount-value">${parseFloat(_tax.value).toFixed(2)}</div>
      </div>
      `;
          }).join('')
        : ''
    }

    ${(newReceiptRefTotal +=
      discountAfterRefund &&
      discountAfterRefund !== 0 &&
      discountAfterRefund !== '0.00'
        ? `
        <div class="amount-wrapper">
          <div class="amount-label">${LocalizedLanguage.discount}</div>
          <div class="amount-value">-${parseFloat(discountAfterRefund).toFixed(
            2
          )}</div>
        </div>
        `
        : '')}

    ${(newReceiptRefTotal +=
      total_cashround && total_cashround !== 0 && total_cashround !== '0.00'
        ? `
        <div class="amount-wrapper">
          <div class="amount-label">${LocalizedLanguage.cashRounding}</div>
          <div class="amount-value">${parseFloat(
            RoundAmount(total_cashround)
          ).toFixed(2)}</div>
      </div>
        `
        : '')}


    ${`<div class="amount-wrapper">
        <div class="amount-label">${LocalizedLanguage.refundtotal}</div>
        <div class="amount-value">${refundAmount.toFixed(2)}</div>
      </div>`}

`;

  return new_print ? newReceiptRefTotal : refTotal;
};
const getRefundPayments = (
  data,
  PrintAndroidData,
  refund_receipt,
  rowNumber,
  timezone,
  new_print = false
) => {
  var displayRefundPayment = '';
  var refundPay = '';
  var newReceiptRefundPay = '';

  var payment_TypeName =
    typeof localStorage.getItem('PAYMENT_TYPE_NAME') !== 'undefined'
      ? JSON.parse(localStorage.getItem('PAYMENT_TYPE_NAME'))
      : null;
  //var isPaymentCash = false;
  var refundpayments =
    data && data.order_Refund_payments
      ? data.order_Refund_payments
      : data && data.order_refund_payments
      ? data.order_refund_payments
      : '';

  refundpayments &&
    refundpayments.map((item, index) => {
      var refDate = moment(item.payment_date, 'DD-MM-YYYY');
      var localDate = FormateDateAndTime.formatDateAndTime(refDate, timezone);
      //var localDate = moment(item.payment_date).format(orderRecieptDateFormate) // CHANGE DATE FORMATE AS PER THE HUB SETTING
      var paytype =
        payment_TypeName &&
        payment_TypeName.filter((itm) => {
          return itm.Code == item.type;
        });
      var paymentName =
        item.type !== 'store-credit'
          ? paytype && paytype.length > 0
            ? paytype[0].Name
            : item.type
            ? item.type
            : ''
          : 'store-credit';

      refundPay =
        refundPay +
        ' <tr><td >' +
        paymentName +
        '(' +
        localDate +
        ')</td>' +
        '<td class="total-value balance" colspan="2"><div class="due" align="right">' +
        parseFloat(item.amount).toFixed(2) +
        '</td></tr> ';

      newReceiptRefundPay += `
      <hr class="divider" style="margin: 3px 0; padding: 0" />
      <hr class="divider" style="margin: 3px 0; padding: 0" />
      <div class="amount-emv">
        <div class="amount-wrapper">
          <div class="amount-label">${paymentName.charAt(0).toUpperCase() + paymentName.slice(1)} (${localDate})</div>
          <div class="amount-value">${parseFloat(item.amount).toFixed(2)}</div>
        </div>
        </div>
    <hr class="divider" style="margin: 3px 0; padding: 0" />
    <hr class="divider" style="margin: 3px 0; padding: 0" />
  </div>
</div>

      `;

      return refundPay;
    });
  if (refundPay !== '') {
    displayRefundPayment +=
      '<table class="item-details-total" style="margin-top:0"><tbody><tfoot>';
    displayRefundPayment +=
      '<tr><td colspan="2">Refund Payments</td></tr>' + refundPay;
    displayRefundPayment +=
      '<tr><td colspan="2"><div class="double-border"></div></td></tr></tfoot></tbody></table>';
  }
  return new_print ? newReceiptRefundPay : displayRefundPayment;
};
const getCustomFeeHtml = (
  data,
  item_detail,
  PrintAndroidData,
  rowNumber,
  refundlog,
  order_reciept
) => {
  var _CustomeFee = '<table class="item-table"><tbody>';
  var _CustomeFeeRow = '';
  var _totalcustomeFee = 0;
  var _onlyCustomFee = [];
  var _tipAmount = 0;
  var _customFee =
    refundlog.Refund_data_collection &&
    refundlog.Refund_data_collection.RefundItems.filter(
      (a) => a.product_id == 0
    ).map((e) => e.item_id);
  var taxInclusiveName = getInclusiveTaxType(data.meta_datas);
  var CustomeFeeArry = data.order_custom_fee ? data.order_custom_fee : [];
  if (CustomeFeeArry && CustomeFeeArry.length == 0) {
    var custFee =
      item_detail &&
      item_detail.find(
        (item) =>
          item.hasOwnProperty('product_id') == false &&
          item.hasOwnProperty('pid') == false
      );
    if (custFee) {
      _onlyCustomFee.push(custFee);
    }
  }
  if (_onlyCustomFee.length == 0) {
    _onlyCustomFee = getCustomFee(data.meta_datas, data.order_custom_fee);
    if (_onlyCustomFee && _onlyCustomFee.length == 0) {
      _onlyCustomFee = data.order_custom_fee ? data.order_custom_fee : [];
    }
  }
  var custFeeTax;
  _onlyCustomFee = _onlyCustomFee.filter((a) => _customFee.includes(a.fee_id));
  _onlyCustomFee &&
    _onlyCustomFee.map((item_fee, index) => {
      if (
        item_fee.hasOwnProperty('pid') == false ||
        (item_fee.hasOwnProperty('pid') &&
          (typeof item_fee.pid == 'undefined' || item_fee.pid == 0))
      ) {
        if (item_fee.note && item_fee.note.includes('Tip')) {
          //ekip tip
          _tipAmount += item_fee.amount ? parseFloat(item_fee.amount) : 0;
          //_tipLable=item_fee.note;
        } else {
          if (item_fee.amount_refunded > 0 && item_fee.amount > 0) {
            var custFee = item_fee.amount
              ? item_fee.amount
              : item_fee.Price
              ? item_fee.Price
              : 0;
            //ADDED THIS CONDITION WHEN amount AND amount_refunded ARE SAME
            //custFee = (custFee - item_fee.amount_refunded)
            if (
              (item_fee.hasOwnProperty('amount_refunded')
                ? item_fee.amount_refunded
                : 0) == custFee
            ) {
              custFee = item_fee.hasOwnProperty('amount_refunded')
                ? item_fee.amount_refunded
                : 0;
            } else {
              custFee =
                custFee -
                (item_fee.hasOwnProperty('amount_refunded')
                  ? item_fee.amount_refunded
                  : 0);
            }

            var custFee_NoTax = custFee;
            custFeeTax = item_fee.total_tax ? item_fee.total_tax : 0;
            custFee += taxInclusiveName == '' ? custFeeTax : 0;
            _CustomeFeeRow += `<tr>
                    <td>${
                      item_fee.note
                        ? item_fee.note
                        : item_fee.Title
                        ? item_fee.Title
                        : ''
                    }</td>
                    <td align="right">${parseFloat(custFee_NoTax).toFixed(
                      2
                    )}</td>
                  </tr>`;
            if (
              parseFloat(custFeeTax) > 0 &&
              (order_reciept.IndividualizedTaxAmountPerItem == true ||
                order_reciept.PercentageTaxPerItem == true)
            ) {
              _CustomeFeeRow += `<tr>
              <td>${'Tax ' + taxInclusiveName + ''}</td>
              <td align="right">${parseFloat(custFeeTax).toFixed(2)}</td></tr>`;
            }
            _totalcustomeFee += parseFloat(parseFloat(custFee));
            //refundLineItemDetail.push(item_fee);
          }
        }
      }
    });
  //Order_subTotal += _totalcustomeFee;
  var customFeefooter = `<tr><td colspan="2"><div class="itemdiv"></div> </td></tr>
                          <tfoot><tr><td>${
                            LocalizedLanguage.producttotal
                          }</td><td align="right">${parseFloat(
    _totalcustomeFee
  ).toFixed(2)}</td></tr></tfoot>`;
  if (parseFloat(custFeeTax) > 0) {
    return _CustomeFeeRow !== ''
      ? _CustomeFee + _CustomeFeeRow + customFeefooter + `</tbody></table>`
      : '';
  } else {
    return _CustomeFeeRow !== ''
      ? _CustomeFee + _CustomeFeeRow + `</tbody></table>`
      : '';
  }
};
const getProductNoteHtml = (data, item_detail, type = '') => {
  if (type == 'activity') {
    return getProductNotes(data.meta_datas, item_detail);
  } else {
    var _productnotes = data.order_notes
      ? data.order_notes.filter(
          (a) =>
            a.hasOwnProperty('pid') &&
            a.pid == item_detail.product_id &&
            !a.note.toLowerCase().match(/payment done with:/) &&
            (a.hasOwnProperty('modifiers_key') &&
            item_detail.hasOwnProperty('modifiers_key')
              ? a.modifiers_key == item_detail.modifiers_key
              : true)
        )
      : [];
    return _productnotes;
  }

  // var _NotesByPid = getProductNotes(data.meta_datas, item_detail);
  // return _NotesByPid;
};

export const PrintPage = {
  PrintElem,
};
var RoundAmount = (val) => {
  return Math.round(val * 100) / 100;
  //var decimals = 2;
  //return Number(Math.round(val + 'e' + decimals) + 'e-' + decimals);
};
//
var currentWidth = window.width; //screen.width;
const Android = window.Android;
const Tizen = window.Tizen;
const Wrapper = window.Wrapper;

function PrintElem(
  data,
  getPdfdateTime,
  isTotalRefund,
  cash_rounding_amount,
  print_bar_code,
  orderList,
  type,
  productxList,
  AllProductList,
  TotalTaxByName,
  redeemPointsToPrint,
  appResponse,
  doPrint = true,
  receiptAppData = null,
  isGift = false
) {
  //console.log("------------------data------------", data)
  // console.log( pdfFormate(),recieptDateFormate(),recieptTimeFormate(),companyLogo());
  var orderRecieptDateFormate = recieptDateFormate();
  const pageSize = pdfFormate();

  // const isImgPrint = true; //for testing printing image instead of sending data
  const isImgPrint = localStorage.getItem('isImgPrint')?.toLowerCase() === 'true';

  //console.log("pageSize", pageSize, "orderRecieptDateFormate", orderRecieptDateFormate)
  var displayExtensionAppData;
  if (appResponse) {
    // var appdata= JSON.parse(appResponse);
    if (
      appResponse &&
      appResponse.command &&
      appResponse.command == 'DataToReceipt'
    )
      displayExtensionAppData = appResponse;

    // console.log("prinData", displayExtensionAppData)
  }
  //console.log("Data", data);

  // var groupSaleName =  data.meta_datas ?  data.meta_datas.find(data => data.ItemName == '_order_oliverpos_group_name') : null;
  // var groupSaleSlug =  data.meta_datas ?  data.meta_datas.find(data => data.ItemName == '_order_oliverpos_group_slug') : null;
  var groupSaleLabel = '';
  if (type == 'activity') {
    var item =
      data.meta_datas && type == 'activity'
        ? data.meta_datas.find(
            (data) => data.ItemName == '_order_oliverpos_group_label'
          )
        : null;
    if (item) groupSaleLabel = item.ItemValue;
  } else {
    groupSaleLabel =
      data.meta_datas && data.meta_datas[0]._order_oliverpos_group_label
        ? data.meta_datas[0]._order_oliverpos_group_label
        : '';
  }

  var realWidth = 250;
  if (currentWidth < 250) {
    realWidth = '90%';
  } else {
    realWidth = '250px';
  }
  var isDemoUser = localStorage.getItem('demoUser');
  var demoUserName = Config.key.DEMO_USER_NAME;
  var redeemPointsForActivity = redeemPointsToPrint
    ? +redeemPointsToPrint.match(/\d+/)
    : 0;
  var taxrate_name = '';
  var site_name = '';
  var address;
  // var decodedString = localStorage.getItem('sitelist');
  // var decod = decodedString ? window.atob(decodedString) : '';
  // var siteName = decod && decod !== '' ? JSON.parse(decod) : '';
  var _tipAmount = 0;
  var _tipLable = '';
  var taxInclusiveName = getInclusiveTaxType(data.meta_datas);
  var labelIncl = taxInclusiveName !== '' ? 'Incl.' : '';
  var siteName =
    localStorage.getItem('clientDetail') &&
    JSON.parse(localStorage.getItem('clientDetail'));
  var udid = get_UDid('UDID');
  //var register_id = localStorage.getItem('register')
  var location_name = localStorage.getItem('UserLocations')
    ? JSON.parse(localStorage.getItem('UserLocations'))
    : '';
  var currentUserLocation = localStorage.getItem('Location')
    ? localStorage.getItem('Location')
    : '';
  var manager =
    localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));
  var order_reciept =
    localStorage.getItem('orderreciept') &&
    localStorage.getItem('orderreciept') !== 'undefined'
      ? JSON.parse(localStorage.getItem('orderreciept'))
      : '';
  var selectedRegister =
    localStorage.getItem('selectedRegister') &&
    localStorage.getItem('selectedRegister') !== 'undefined'
      ? JSON.parse(localStorage.getItem('selectedRegister'))
      : '';
  var LocalLocationName = localStorage.getItem('LocationName');
  var payment_TypeName =
    typeof localStorage.getItem('PAYMENT_TYPE_NAME') !== 'undefined'
      ? JSON.parse(localStorage.getItem('PAYMENT_TYPE_NAME'))
      : null;
  var tempOrderId = localStorage.getItem('tempOrder_Id')
    ? JSON.parse(localStorage.getItem('tempOrder_Id'))
    : '';

  if (data.orderCustomerInfo) var labelCustomerName = '';
  if (
    order_reciept &&
    order_reciept.CustomerNameLabel &&
    order_reciept.CustomerNameLabel !== ''
  ) {
    labelCustomerName = order_reciept.CustomerNameLabel;
  } else {
    labelCustomerName = LocalizedLanguage.customerName;
  }

  var labelCustomerAddress = '';
  if (
    order_reciept &&
    order_reciept.CustomerAddressDisplayLabel &&
    order_reciept.CustomerAddressDisplayLabel !== ''
  ) {
    labelCustomerAddress = order_reciept.CustomerAddressDisplayLabel;
  } else {
    labelCustomerAddress = LocalizedLanguage.CustomerAddress;
  }

  var labelTotalDiscount = '';
  if (
    order_reciept &&
    order_reciept.DiscountDisplayLabel &&
    order_reciept.DiscountDisplayLabel !== ''
  ) {
    labelTotalDiscount = order_reciept.DiscountDisplayLabel;
  } else {
    labelTotalDiscount = LocalizedLanguage.totalDiscount;
  }

  var labelsubTotal = '';
  if (
    order_reciept &&
    order_reciept.SubTotalDisplayLabel &&
    order_reciept.SubTotalDisplayLabel !== ''
  ) {
    labelsubTotal = order_reciept.SubTotalDisplayLabel;
  } else {
    labelsubTotal = LocalizedLanguage.subTotal;
  }

  var labelTableNumber = '';
  if (
    order_reciept &&
    order_reciept.TableNumberDisplayLabel &&
    order_reciept.TableNumberDisplayLabel !== ''
  ) {
    labelTableNumber = order_reciept.TableNumberDisplayLabel;
  } else {
    labelTableNumber = LocalizedLanguage.TableNumber;
  }

  var labelDateDisplay = '';
  if (
    order_reciept &&
    order_reciept.DateDisplayLabel &&
    order_reciept.DateDisplayLabel !== ''
  ) {
    labelDateDisplay = order_reciept.DateDisplayLabel;
  } else {
    labelDateDisplay = LocalizedLanguage.date;
  }

  var labelSale = '';
  if (
    order_reciept &&
    order_reciept.SaleDisplayLabel &&
    order_reciept.SaleDisplayLabel !== ''
  ) {
    labelSale = order_reciept.SaleDisplayLabel;
  } else {
    labelSale = LocalizedLanguage.sale;
  }

  var labelServed = '';
  if (
    order_reciept &&
    order_reciept.ServedByDisplayLabel &&
    order_reciept.ServedByDisplayLabel !== ''
  ) {
    labelServed = order_reciept.ServedByDisplayLabel;
  } else {
    labelServed = LocalizedLanguage.servedBy;
  }
  var labelTaxId = '';
  if (
    order_reciept &&
    order_reciept.TaxIdText &&
    order_reciept.TaxIdText !== ''
  ) {
    labelTaxId = order_reciept.TaxIdText;
  } else {
    labelTaxId = LocalizedLanguage.taxId;
  }

  // var labelTax = '';
  // if (order_reciept && order_reciept.TaxDisplayLabel && order_reciept.TaxDisplayLabel !== '') {
  //   labelTax = order_reciept.TaxDisplayLabel
  // } else {
  //   labelTax = "Tax"
  // }
  // var _rateRate=0.0;
  //   TotalTaxByName && TotalTaxByName.map(name => {_rateRate+= parseFloat(name.TaxRate)})
  //   labelTax += order_reciept.PercentageTaxOfEntireOrder ==true? "("+_rateRate.toFixed(0)+"%)":""

  var labelTime = '';
  if (
    order_reciept &&
    order_reciept.TimeDisplayLabel &&
    order_reciept.TimeDisplayLabel !== ''
  ) {
    labelTime = order_reciept.TimeDisplayLabel;
  } else {
    labelTime = LocalizedLanguage.time;
  }

  var labelTotalOrder = '';
  if (
    order_reciept &&
    order_reciept.TotalDisplayLabel &&
    order_reciept.TotalDisplayLabel !== ''
  ) {
    labelTotalOrder = order_reciept.TotalDisplayLabel;
  } else {
    labelTotalOrder = LocalizedLanguage.total;
  }

  if (
    order_reciept &&
    order_reciept.TipsDisplayLabel &&
    order_reciept.TipsDisplayLabel !== ''
  ) {
    _tipLable = order_reciept.TipsDisplayLabel;
  }

  var _returnPolicyText = '';
  if (
    order_reciept &&
    order_reciept.ReturnpolicyText &&
    order_reciept.ReturnpolicyText !== ''
  ) {
    _returnPolicyText = order_reciept.ReturnpolicyText;
  } else {
    _returnPolicyText = '';
  }
  //NEWLY ADDED LOCALLIZATION AS PER THE CLIENT REQUIREMENTS
  var _refundExchange = LocalizedLanguage.refundExchange
    ? LocalizedLanguage.refundExchange
    : 'Refund Exchange';
  if (
    order_reciept &&
    order_reciept.refundExchange &&
    order_reciept.refundExchange !== ''
  ) {
    _refundExchange = order_reciept.refundExchange;
  }

  var _paidWith = LocalizedLanguage.paidWith;
  if (
    order_reciept &&
    order_reciept.paidWith &&
    order_reciept.paidWith !== ''
  ) {
    _paidWith = order_reciept.paidWith;
  }

  var _register = LocalizedLanguage.register;
  if (
    order_reciept &&
    order_reciept.register &&
    order_reciept.register !== ''
  ) {
    _register = order_reciept.register;
  }

  var _payCash = LocalizedLanguage.cash;
  if (order_reciept && order_reciept.cash && order_reciept.cash !== '') {
    _payCash = order_reciept.cash;
  }
  var _payCard = LocalizedLanguage.card ? LocalizedLanguage.card : 'Card';
  if (order_reciept && order_reciept.card && order_reciept.card !== '') {
    _payCard = order_reciept.card;
  }
  //END
  //for Android Print-----
  var PrintAndroidReceiptData = {};
  var PrintAndroidData = [];
  var WrapperAppData = {};

  var label = {};
  // WrapperAppData["tableNumber"] = groupSaleLabel;
  label['date'] = labelDateDisplay;
  label['time'] = labelTime;
  label['saleNo'] = labelSale;
  label['servedBy'] = labelServed;
  label['customerName'] = labelCustomerName;
  label['taxId'] = labelTaxId;
  // label["paidWith"] = LocalizedLanguage.paidWith;
  // label["register"] = LocalizedLanguage.register;
  label['tableNumber'] = LocalizedLanguage.groupSaleLabel;
  label['productTotal'] = LocalizedLanguage.producttotal;
  label['subtotal'] = labelsubTotal;
  label['discount'] = LocalizedLanguage.discount;
  label['tax'] = labelTaxId;
  label['total'] = labelTotalOrder;
  label['orderNotes'] =
    LocalizedLanguage.orderNotes && LocalizedLanguage.orderNotes.toUpperCase();
  label['returnPolicy'] =
    LocalizedLanguage.returnPolicy &&
    LocalizedLanguage.returnPolicy.toUpperCase();

  label['tip'] = _tipLable;
  label['customerAddress'] = labelCustomerAddress;
  label['totalDiscount'] = labelTotalDiscount;

  label['refundExchange'] = _refundExchange;
  label['paidWith'] = _paidWith;
  label['register'] = _register;
  label['payCash'] = _payCash;
  label['payCard'] = _payCard;
  label['location'] = order_reciept.LocationLabel
    ? order_reciept.LocationLabel
    : LocalizedLanguage.location;
  WrapperAppData['label'] = label;

  var rowNumber = 0;
  //----------------

  var baseurl = '';
  var base64ImgLogo = '';
  var shopName = '';
  var gmtDateTime = '';
  var time = '';
  var receiptId = '';
  var servedBy = '';
  var customershow_name = '';
  var CustomerAddress = '';
  var taxId = '';
  var registerName = '';
  var locationName = '';
  var customeText = '';
  var _new_receipt_emvData = '';
  var _new_receipt_splitTaxDetail = '';

  if (order_reciept.ShowLogo == true) {
    if (order_reciept.CompanyLogo && order_reciept.CompanyLogo !== null) {
      baseurl = Config.key.RECIEPT_IMAGE_DOMAIN + order_reciept.CompanyLogo;
      baseurl = baseurl !== '' ? encodeURI(baseurl) : '';
      console.log('baseurl', baseurl);
    }
  }

  shopName =
    data && data.ShopName ? data.ShopName : manager && manager.shop_name;

  var pdf_format = localStorage.getItem('pdf_format')
    ? JSON.parse(localStorage.getItem('pdf_format'))
    : null;
  var printerIds = [];
  var printersList = localStorage.getItem('cloudPrinters')
    ? JSON.parse(localStorage.getItem('cloudPrinters'))
    : [];
  printersList &&
    printersList.content &&
    printersList.content.map((item) => {
      printerIds.push(item.Id);
    });

  var oprationalDate = null;
  if (
    data !== null &&
    data.date_time !== undefined &&
    Reflect.has(data, data.date_time)
  ) {
    oprationalDate = data.date_time;
  } else if (data.create_date) {
    oprationalDate = data.create_date;
  } else {
    oprationalDate = isSafari
      ? data._currentTime.replace(/-/g, '/')
      : data._currentTime;
  }

  if (order_reciept.ShowDate == true) {
    if (data.time_zone)
      gmtDateTime = FormateDateAndTime.formatDateAndTime(
        oprationalDate,
        data.time_zone
      );
    else gmtDateTime = FormateDateAndTime.formatDateAndTime(oprationalDate);

    gmtDateTime = moment(gmtDateTime).format(orderRecieptDateFormate);

    var ckdateisValid = false;
    if (gmtDateTime !== null && gmtDateTime !== undefined) {
      ckdateisValid = moment(gmtDateTime).isValid();
      if (ckdateisValid == false) {
        var currentdate = new Date();
        if (data.time_zone)
          gmtDateTime = FormateDateAndTime.formatDateAndTime(
            currentdate,
            data.time_zone
          );
        else gmtDateTime = FormateDateAndTime.formatDateAndTime(currentdate);
        gmtDateTime = moment(gmtDateTime).format(orderRecieptDateFormate);
      }
    }

    if (
      gmtDateTime !== null &&
      gmtDateTime !== undefined &&
      gmtDateTime == 'Invalid date'
    ) {
      gmtDateTime = new Date().toLocaleDateString(
        navigator.language || navigator.userLanguage,
        new Date().toLocaleDateString(
          navigator.language || navigator.userLanguage,
          { day: 'numeric', month: 'short', year: 'numeric' }
        )
      );
    }
  }
  WrapperAppData['storeLogo'] = '';
  WrapperAppData['CompanyLogoUrl'] = '';
  if (order_reciept.ShowLogo == true) {
    if (order_reciept.CompanyLogo && order_reciept.CompanyLogo !== null) {
      WrapperAppData['storeLogo'] = localStorage.getItem('companylogo_base64')
        ? localStorage.getItem('companylogo_base64')
        : order_reciept.CompanyLogo;
    }
    //ADDED COMPANY LOGO URL IN "CompanyLogoUrl PARAMETER FOR WRAPPER 25/08/23
    var _logoURl = '';
    if (order_reciept.CompanyLogo && order_reciept.CompanyLogo !== null) {
      _logoURl = Config.key.OP_IMAGE_URL + order_reciept.CompanyLogo;
      WrapperAppData['CompanyLogoUrl'] = _logoURl;
    }
    //END
  }

  WrapperAppData['logo_text'] = shopName;
  WrapperAppData['date'] = '';
  if (order_reciept.ShowDate == true) {
    WrapperAppData['date'] = gmtDateTime;
  }
  if (order_reciept.ShowTime == true) {
    // if(data.time_zone)
    //  time=FormateDateAndTime.formatDateWithTime(data.date_time, data.time_zone);
    //  else
    //  time=FormateDateAndTime.formatDateWithTime(data.order_date);//date_time

    if (data.time_zone)
      time = FormateDateAndTime.formatDateWithTime(
        data.quantity_refunded < 0 ? data.create_date : data.date_time,
        data.time_zone,
        order_reciept.TimeFormat.replace('tt', 'A')
      );
    //tt not suported in moment
    else if (data.order_date && data.order_date !== 'Invalid date')
      time = FormateDateAndTime.formatDateWithTime(
        data.quantity_refunded < 0 ? data.create_date : data.order_date,
        null,
        order_reciept.TimeFormat.replace('tt', 'A')
      );
    //date_time
    else
      time = FormateDateAndTime.formatDateWithTime(
        isSafari ? data._currentTime.replace(/-/g, '/') : data._currentTime,
        null,
        order_reciept.TimeFormat.replace('tt', 'A')
      );

    if (time == 'Invalid date') {
      time = new Date().toLocaleString([], {
        hour: 'numeric',
        minute: 'numeric',
      });
    }
  }
  WrapperAppData['time'] = '';
  if (order_reciept.ShowTime == true) {
    WrapperAppData['time'] = time;
  }
  if (order_reciept.ShowSale == true) {
    receiptId = data.OliverReciptId
      ? data.OliverReciptId
      : data.order_id
      ? data.order_id
      : tempOrderId
      ? tempOrderId
      : '';
  }
  WrapperAppData['staffName'] = '';
  if (order_reciept.ShowServedBy == true) {
    servedBy = isDemoUser
      ? demoUserName
      : manager && manager.display_name
      ? manager.display_name
      : '';
    WrapperAppData['staffName'] = isDemoUser
      ? demoUserName
      : manager && manager.display_name
      ? manager.display_name
      : '';
  }
  WrapperAppData['customerName'] = '';
  if (order_reciept.ShowCustomer == true) {
    if (
      order_reciept.CustomerNameLabel !== null &&
      order_reciept.CustomerNameLabel !== undefined &&
      order_reciept.ShowCustomer == true
    ) {
      if (
        data.orderCustomerInfo !== null &&
        data.orderCustomerInfo !== undefined
      ) {
        // case of activity view
        customershow_name =
          data.orderCustomerInfo.customer_name &&
          data.orderCustomerInfo.customer_name !== undefined &&
          data.orderCustomerInfo.customer_name.trim() !== ''
            ? data.orderCustomerInfo.customer_name
            : data.orderCustomerInfo.customer_email &&
              data.orderCustomerInfo.customer_email.trim() !== ''
            ? data.orderCustomerInfo.customer_email
            : '';
      } else if (
        data.customerDetail !== null &&
        data.customerDetail !== undefined &&
        data.customerDetail.content &&
        data.customerDetail.content !== undefined
      ) {
        // case of order create
        customershow_name =
          data.customerDetail.content.FirstName &&
          data.customerDetail.content.FirstName !== undefined
            ? data.customerDetail.content.FirstName +
              ' ' +
              data.customerDetail.content.LastName
            : data.customerDetail.content.Email !== null &&
              data.customerDetail.content.Email !== undefined
            ? data.customerDetail.content.Email
            : '';
      }
    }
    WrapperAppData['customerName'] = customershow_name;
  }
  WrapperAppData['customerAddress'] = '';
  if (order_reciept.ShowCustomerAddress == true) {
    if (
      order_reciept.CustomerNameLabel !== null &&
      order_reciept.CustomerNameLabel !== undefined &&
      order_reciept.ShowCustomer == true
    ) {
      if (
        data.orderCustomerInfo !== null &&
        data.orderCustomerInfo !== undefined
      ) {
        // case of activity view
        CustomerAddress =
          data.orderCustomerInfo.customer_address +
          ' ' +
          data.orderCustomerInfo.customer_city +
          ' ' +
          data.orderCustomerInfo.customer_State +
          ' ' +
          data.orderCustomerInfo.customer_Country +
          ' ' +
          data.orderCustomerInfo.customer_post_code;
      } else if (
        data.customerDetail !== null &&
        data.customerDetail !== undefined &&
        data.customerDetail.content &&
        data.customerDetail.content !== undefined
      ) {
        // case of order create
        CustomerAddress =
          data.customerDetail.content.StreetAddress +
          ' ' +
          data.customerDetail.content.City +
          ' ' +
          (data.customerDetail.content.State
            ? data.customerDetail.content.State + ' '
            : '') +
          data.customerDetail.content.Country +
          ' ' +
          data.customerDetail.content.Pincode;
      }
      CustomerAddress = CustomerAddress && CustomerAddress.trim();
    }
    WrapperAppData['customerAddress'] = CustomerAddress;
  }
  // WrapperAppData['customerAddress']=CustomerAddress;
  WrapperAppData['taxId'] = '';
  if (order_reciept.ShowTaxId == true && order_reciept.TaxId) {
    taxId = order_reciept.TaxId ? order_reciept.TaxId : '';
    WrapperAppData['taxId'] = taxId;
  }
  WrapperAppData['register'] = '';
  if (order_reciept.ShowRegisterDetails == true) {
    registerName =
      window.location.pathname !== '/salecomplete'
        ? data.RegisterName
        : selectedRegister.name;
    WrapperAppData['register'] = registerName;
  }
  WrapperAppData['location'] = '';
  if (order_reciept.ShowLocationDetails == true) {
    locationName =
      window.location.pathname !== '/salecomplete'
        ? data.LocationName
        : LocalLocationName;
    WrapperAppData['location'] = locationName;
  }
  WrapperAppData['saleId'] = '';
  if (order_reciept.ShowSale == true) {
    WrapperAppData['saleId'] = receiptId;
  }
  topLogo = `
  ${
    baseurl && baseurl !== '' && baseurl !== '' && encodeURI(baseurl)
      ? `  <img style= 'max-width: ${
          pageSize.width == '52mm' || pageSize.width == '58mm'
            ? '190px'
            : pageSize.width == '80mm'
            ? '280px'
            : '300px'
        }' src='${baseurl}' alt='${shopName}'/>`
      : `<b>${shopName}</b>`
  }
`;

  var GetHeaderDetail = `<div>
      <table class="logo" >
        <tr><td> ${
          order_reciept.ShowLogo == true ? topLogo : shopName
        }</td></tr>
      </table>
      <table class="table-common" style="width: 100%;text-align: left;border-collapse: collapse;">
        <tbody>
          ${
            order_reciept.ShowDate == true
              ? `<tr>
                  <td colspan="2">${
                    order_reciept.DateText
                      ? order_reciept.DateText
                      : labelDateDisplay
                  } : ${gmtDateTime ? gmtDateTime : ''}</td>
                  </tr>`
              : ''
          }

          ${
            order_reciept.ShowTime == true
              ? `<tr>
                  <td colspan="2">${labelTime} : ${time ? time : ''} </td>
                  </tr>`
              : ''
          }

          ${
            order_reciept.ShowSale == true
              ? `<tr>
                  <td colspan="2">${labelSale} : #${receiptId}</td>
                  </tr>`
              : ''
          }

          ${
            order_reciept.ShowServedBy == true
              ? `<tr>
                    <td colspan="2">${labelServed} : ${servedBy} </td>
                  </tr>`
              : ''
          }

          ${
            customershow_name !== null && customershow_name !== ''
              ? `<tr>
                      <td colspan="2"> ${labelCustomerName} : ${customershow_name} </td>
                      </tr>`
              : ''
          }
          ${
            order_reciept.ShowCustomerAddress !== false &&
            CustomerAddress !== ''
              ? `<tr><td colspan="2">${labelCustomerAddress} : ${CustomerAddress}   </td>
                      </tr>`
              : ''
          }

          ${
            order_reciept.ShowTaxId && order_reciept.TaxId
              ? `<tr>
                    <td colspan="2"> ${labelTaxId} : ${taxId}</td>
                    </tr>`
              : ''
          }

          ${
            order_reciept.ShowRegisterDetails !== false
              ? `<tr>
                  <td colspan="2">${LocalizedLanguage.register} :${registerName}
                </td></tr>`
              : ''
          }
          ${
            order_reciept.ShowLocationDetails !== false
              ? `<tr>
                    <td colspan="2"> ${
                      order_reciept.LocationLabel
                        ? order_reciept.LocationLabel
                        : LocalizedLanguage.location
                    } :${locationName}
                  </td></tr>`
              : ''
          }
          ${
            order_reciept.ShowTableNumber !== false && groupSaleLabel !== ''
              ? `<tr>
                    <td colspan="2">
                    ${labelTableNumber} :${
                  groupSaleLabel && groupSaleLabel ? groupSaleLabel : ''
                }</td>
                  </tr>`
              : ''
          }
        </tbody>
      </table>
    </div>`;
  /// **********  Refund Header Detail Start ***************************************************************
  const refunHeaderDetailRender = (_refundLog, new_receipt = false) => {
    var refundLog = _refundLog;
    var refundTime = '';
    var refundDate = '';
    if (_refundLog == null) {
      refundTime = time ? time : '';
      refundDate = gmtDateTime ? gmtDateTime : '';
    } else {
      if (refundLog.LocalTimeZoneType)
        refundDate = FormateDateAndTime.formatDateAndTime(
          refundLog.Date,
          refundLog.LocalTimeZoneType
        );
      else
        refundDate = FormateDateAndTime.formatDateAndTime(
          refundLog.LocalTimeZoneType
        );

      refundDate = moment(refundDate).format(orderRecieptDateFormate);

      if (refundLog.LocalTimeZoneType)
        refundTime = FormateDateAndTime.formatDateWithTime(
          refundLog.Time,
          refundLog.LocalTimeZoneType,
          order_reciept.TimeFormat.replace('tt', 'A')
        );
      //tt not suported in moment
      else if (refundLog.Date && refundLog.Date !== 'Invalid date')
        refundTime = FormateDateAndTime.formatDateWithTime(
          refundLog.Time,
          null,
          order_reciept.TimeFormat.replace('tt', 'A')
        );
      //date_time
      else
        refundTime = FormateDateAndTime.formatDateWithTime(
          isSafari
            ? refundLog._currentTime.replace(/-/g, '/')
            : refundLog._currentTime,
          null,
          order_reciept.TimeFormat.replace('tt', 'A')
        );

      if (refundTime == 'Invalid date') {
        refundTime = new Date().toLocaleString([], {
          hour: 'numeric',
          minute: 'numeric',
        });
      }
    }

    if (refundLog == null) {
      refundLog = {
        Register: registerName,
        Location: locationName,
        RefundBy: servedBy,
        GroupName: groupSaleLabel && groupSaleLabel ? groupSaleLabel : '',
      };
    }
    //----Android receipt header START---

    if (order_reciept.ShowLogo == true) {
      if (topLogo && topLogo !== null) {
        baseurl = Config.key.RECIEPT_IMAGE_DOMAIN + topLogo;
        baseurl = baseurl !== '' ? encodeURI(baseurl) : '';
      }
    } else {
      shopName = shopName;
    }

    return new_receipt
      ? `
      <div class="refund-header y-pad-big bold">*** REFUND ***</div>
      <div class="receipt-header full-width">
      <div class="receipt-container">
      <div class="row">
        ${
          order_reciept.ShowDate
            ? `
          <div class="wrapper">
              <div class="label bold">${
                order_reciept.DateText
                  ? order_reciept.DateText
                  : labelDateDisplay
              }:</div>
               <div class="value normal">${refundDate ? refundDate : ''}</div>
          </div>
        `
            : ''
        }

        ${
          order_reciept.ShowTime
            ? `
          <div class="wrapper">
              <div class="label bold">${labelTime}:</div>
              <div class="value normal">${refundTime ? refundTime : ''}</div>
          </div>
          `
            : ''
        }

        ${
          order_reciept.ShowSale
            ? `
          <div class="wrapper">
              <div class="label bold">${labelSale}:</div>
              <div class="value normal">#${receiptId ? receiptId : ''}</div>
          </div>
          `
            : ''
        }
        
        ${
          order_reciept.ShowServedBy
            ? `
              <div class="wrapper">
                  <div class="label bold">${labelServed}:</div>
                  <div class="value normal">${refundLog.RefundBy}</div>
              </div>
        
          `
            : ''
        }

      </div>
      <div class="row">
        ${
          customershow_name !== null && customershow_name !== ''
            ? `
          <div class="wrapper">
            <div class="label bold">${labelCustomerName}:</div>
            <div class="value normal">${customershow_name}</div>
          </div>
          `
            : ''
        }
        
        ${
          order_reciept.ShowTaxId && order_reciept.TaxId
            ? `
          <div class="wrapper">
              <div class="label bold">${labelTaxId}:</div>
              <div class="value normal">#${taxId}</div>
          </div>
          `
            : ''
        }

        ${
          order_reciept.ShowRegisterDetails
            ? `
          <div class="wrapper">
              <div class="label bold">${LocalizedLanguage.register}:</div>
              <div class="value normal">${refundLog.Register}</div>
          </div>
          `
            : ''
        }

        ${
          order_reciept.ShowLocationDetails
            ? `
          <div class="wrapper">
              <div class="label bold">${
                order_reciept.LocationLabel
                  ? order_reciept.LocationLabel
                  : LocalizedLanguage.location
              }:</div>
              <div class="value normal">${refundLog.Loation}</div>
          </div>
          `
            : ''
        }


        ${
          order_reciept.ShowTableNumber && groupSaleLabel !== ''
            ? `
          <div class="wrapper">
              <div class="label bold">${labelTableNumber}:</div>
              <div class="value normal">${
                refundLog.GroupName ? refundLog.GroupName : ''
              }</div>
          </div>
          `
            : ''
        }
        
      </div>
      <div class="row">
        ${
          // order_reciept.ShowCustomText
          //   ? `
          // <div class="wrapper-info">
          //     <div class="label bold">Order Info: </div>
          //     <div class="value no-break normal">${
          //       order_reciept.CustomText ? order_reciept.CustomText : ''
          //     }</div>
          // </div>
          // `
          //   :
          ''
        }
      </div>
      <div class="row">

        ${
          // order_reciept.ShowBarcode && print_bar_code
          //   ? `
          // <div class="wrapper-barcode">
          //     <div class="barcode">
          //         <img
          //         src='${print_bar_code}'
          //         style='padding:0px ; width: 100%; ;height:85px;'/>
          //     </div>
          //     <p>ORDER ID</p>
          // </div>
          // `
          //   : ''
          ''
        }
        
      </div>
    </div>
      </div>
    `
      : `<table><tr><td><tfoot><h2 style="text-align: center; margin-top:20px">*** Refund ***</h2></tfoot></td></tr></table>
    <div>
          <table class="logo" >
          <tr><td> ${
            order_reciept.ShowLogo == true ? topLogo : shopName
          }</td></tr>
          </table>
       <table class="table-common" style="width: 100%;text-align: left;border-collapse: collapse;">
      <tbody>
        ${
          order_reciept.ShowDate == true
            ? `<tr>
                <td colspan="2">${
                  order_reciept.DateText
                    ? order_reciept.DateText
                    : labelDateDisplay
                } : ${refundDate ? refundDate : ''}</td>
                </tr>`
            : ''
        }

        ${
          order_reciept.ShowTime == true
            ? `<tr>
                <td colspan="2">${labelTime} : ${
                refundTime ? refundTime : ''
              } </td>
                </tr>`
            : ''
        }

        ${
          order_reciept.ShowSale == true
            ? `<tr>
                <td colspan="2">${labelSale} : #${receiptId}</td>
                </tr>`
            : ''
        }

        ${
          order_reciept.ShowServedBy == true
            ? `<tr>
                  <td colspan="2">${labelServed} : ${refundLog.RefundBy} </td>
                </tr>`
            : ''
        }



        ${
          order_reciept.ShowTaxId && order_reciept.TaxId
            ? `<tr>
                  <td colspan="2"> ${labelTaxId} : ${taxId}</td>
                  </tr>`
            : ''
        }

        ${
          order_reciept.ShowRegisterDetails !== false
            ? `<tr>
                <td colspan="2">${LocalizedLanguage.register} :${refundLog.Register}
              </td></tr>`
            : ''
        }
        ${
          order_reciept.ShowLocationDetails !== false
            ? `<tr>
                  <td colspan="2"> ${
                    order_reciept.LocationLabel
                      ? order_reciept.LocationLabel
                      : LocalizedLanguage.location
                  } :${refundLog.Loation}
                </td></tr>`
            : ''
        }
        ${
          order_reciept.ShowTableNumber !== false &&
          refundLog.GroupName &&
          refundLog.GroupName !== ''
            ? `<tr>
                  <td colspan="2">
                  ${labelTableNumber} :${
                refundLog.GroupName ? refundLog.GroupName : ''
              }</td>
                </tr>`
            : ''
        }
      </tbody>
    </table>
  </div>`;
  };
  /// ***********  Refund Header Detail End ****************************************************
  //======getting log data============================
  var logLineItemData = [];
  var getorderlist = '';
  var _data;
  if (type !== 'activity') {
    _data = data.meta_datas && data.meta_datas[0];
    getorderlist = _data && _data._order_oliverpos_product_discount_amount;
  } else {
    _data =
      data.meta_datas &&
      data.meta_datas.find(
        (data) => data.ItemName == '_order_oliverpos_product_discount_amount'
      );
    getorderlist = _data && _data.ItemValue && JSON.parse(_data.ItemValue);
  }
  if (getorderlist) {
    getorderlist.map((prd) => {
      if (prd.product_id || prd.variation_id) {
        logLineItemData.push(prd);
      }
    });
  }
  // console.log("logLineItemData", logLineItemData)
  //=========================================

  if (order_reciept.ShowCustomText) {
    customeText =
      order_reciept.CustomText == '*CUSTOM TEXT HERE*'
        ? ''
        : order_reciept.CustomText;
  }

  // if (order_reciept.ShowTotalTax == true) {
  TotalTaxByName &&
    TotalTaxByName.map((name) => {
      taxrate_name +=
        name.tax_amount > 0
          ? `<tr>
      <td class="total-line" style="text-align:left;">Tax ${
        order_reciept.PercentageTaxOfEntireOrder == true
          ? '(' + name.TaxRate + ')'
          : ''
      }</td>
      <td class="total-line" style="text-align:left;">${name.tax_name}</td>
    <td class="total-value" style="text-align:right;"> ${name.tax_amount.toFixed(
      2
    )}</td></<tr>`
          : ``;
    });
  // }
  // ${taxrate_name !== "" ? taxrate_name : ''}
  if (
    siteName &&
    siteName.subscription_detail &&
    siteName.subscription_detail !== ''
  ) {
    if (siteName.subscription_detail.udid == udid) {
      site_name =
        siteName.subscription_detail.host_name &&
        siteName.subscription_detail.host_name !== undefined &&
        siteName.subscription_detail.host_name.trim() !== 'undefined'
          ? siteName.subscription_detail.host_name
          : '';
    }
  }

  location_name &&
    location_name.map((item) => {
      if (item.id == currentUserLocation) {
        address = item;
      }
    });

  var subtotal = 0;
  // if (data && order_reciept.ShowSubTotal == true) {
  if (type == 'activity') {
    subtotal = parseFloat(
      parseFloat(data.total_amount) -
        parseFloat(taxInclusiveName == '' ? data.total_tax : 0)
    ).toFixed(2);
  } else {
    subtotal =
      data && data.subTotal
        ? parseFloat(
            RoundAmount(
              parseFloat(data.subTotal) +
                (taxInclusiveName !== '' ? data.tax : 0)
            )
          ).toFixed(2)
        : '0.00';
  }
  // }
  var _payments = [];
  var _new_receipt_payments = [];
  var _new_receipt_cash_change = [];
  var displayPayments = '';
  var isPaymentCash = false;
  var order_payments =
    data && data.order_payments
      ? data.order_payments
      : orderList
      ? orderList
      : [];
  order_payments &&
    order_payments.map((item, index) => {
      if (item.type !== null && item.amount !== 0) {
        // var gmtDateTime = moment.utc(item.payment_date)
        // var localDate = gmtDateTime.local().format(Config.key.DATE_FORMAT);
        var _emvData = '';
        var _emvDataWrapper = {};
        if (item.description && item.description !== '') {
          try {
            var _desc = JSON.parse(item.description);
            for (var key in _desc) {
              _emvDataWrapper[key] = _desc[key];
              //console.log(key);
              //console.log(_desc[key]);
              _emvData += `<tr><td>${key}</td><td align="right">${_desc[key]}</td></tr>`;
              _new_receipt_emvData += `<div class="emv-data-wrapper">
              <div>${key}</div>
              <div>${_desc[key]}</div>
            </div>`;
            }
            if (_emvData !== '') {
              _emvData =
                '<table class="item-details-total" style="margin-top:0"><tbody>' +
                _emvData +
                '</tbody></table>';
            }
          } catch (e) {
            //console.log(e)
          }
        }

        var localDate = FormateDateAndTime.formatDateAndTime(
          item.payment_date,
          data.time_zone
        );
        //var localDate = moment(item.payment_date).format(orderRecieptDateFormate) // CHANGE DATE FORMATE AS PER THE HUB SETTING
        var paytype =
          payment_TypeName &&
          payment_TypeName.filter((itm) => {
            return type == 'activity'
              ? itm.Code == item.type
                ? itm.Name
                : ''
              : item.payment_type && itm.Code == item.payment_type
              ? itm.Name
              : '';
          });
        var paymentName =
          type == 'activity'
            ? item.type !== 'store-credit'
              ? paytype && paytype.length > 0
                ? paytype[0].Name
                : item.type
                ? item.type
                : ''
              : 'store-credit'
            : item.payment_type && item.payment_type !== 'store-credit'
            ? paytype && paytype.length > 0
              ? paytype[0].Name
              : item.payment_type
              ? item.payment_type
              : ''
            : 'store-credit';
        //  var paymentName= paytype && paytype.length > 0 ? paytype[0].Name :''
        var amount =
          type == 'activity'
            ? item.amount
              ? item.amount.toFixed(2)
              : '0.00'
            : item.payment_amount
            ? parseFloat(RoundAmount(item.payment_amount)).toFixed(2)
            : '0.00';
        displayPayments +=
          ' <tr><td>' +
          paymentName +
          '(' +
          localDate +
          ')</td><td align="right">' +
          amount.toString() +
          '</td></tr> ';
        displayPayments += ' <tr><td colspan="2">' + _emvData + '</td></tr> ';
        _payments.push({
          method: paymentName,
          amount: amount,
          emv: _emvDataWrapper,
        });
        _new_receipt_payments.push({
          method: paymentName,
          amount: amount,
          date: localDate,
        });
      }
    });
  WrapperAppData['payments'] = _payments;

  WrapperAppData['registerId'] = '';
  if (order_reciept.ShowRegisterDetails !== false) {
    WrapperAppData['registerId'] = localStorage.getItem('register')
      ? localStorage.getItem('register').toString()
      : '';
  }
  //tableNum
  WrapperAppData['tableNum'] = '';
  if (order_reciept.ShowTableNumber !== false && groupSaleLabel !== '') {
    WrapperAppData['tableNum'] = groupSaleLabel;
  }
  // cash change payment
  var GTM_ORDER = localStorage.getItem('GTM_ORDER')
    ? JSON.parse(localStorage.getItem('GTM_ORDER'))
    : null;
  var cashChange = '';
  var cashPayment = '';
  var cashOrderData = '';
  if (type == 'activity') {
    var _metaData = data && data.meta_datas ? data.meta_datas : [];
    _metaData &&
      _metaData.map((metaData, index) => {
        if (metaData.ItemName == '_order_oliverpos_cash_change') {
          cashOrderData =
            metaData.ItemValue && metaData.ItemValue !== ''
              ? metaData.ItemValue
              : '';
        }
        return '';
      });
    if (cashOrderData && cashOrderData !== '') {
      cashOrderData = JSON.parse(cashOrderData);
      cashChange = cashOrderData.change;
      cashPayment = cashOrderData.cashPayment;
    }
  }

  if (type !== 'activity' && GTM_ORDER) {
    GTM_ORDER.order_meta &&
      GTM_ORDER.order_meta.map((meta) => {
        if (meta._order_oliverpos_cash_change) {
          cashChange = meta._order_oliverpos_cash_change.change;
          cashPayment = meta._order_oliverpos_cash_change.cashPayment;
        }
      });
  }
  if (cashChange && cashChange !== '' && cashPayment !== '') {
    displayPayments +=
      '<tr><td> Change  </td><td align="right">' +
      parseFloat(RoundAmount(cashChange)).toFixed(2) +
      '</td></tr>' +
      '<tr><td> Cash Payment </td><td align="right">' +
      parseFloat(RoundAmount(cashPayment)).toFixed(2) +
      '</td></tr>';
    _new_receipt_cash_change.push({
      method: 'Change',
      change: parseFloat(RoundAmount(cashChange)).toFixed(2),
      amount: parseFloat(RoundAmount(cashPayment)).toFixed(2),
    });
  }
  if (displayPayments !== '') {
    displayPayments =
      '<table class="item-table" style="margin-top:0"><tbody><tr><td colspan="2"><div class="double-border"></div></td></tr><tfoot>' +
      displayPayments;
    displayPayments +=
      '<tr><td colspan="2"><div class="double-border"></div></td></tr></tfoot></tbody></table>';
  }

  var Order_subTotal = 0.0;
  var Total_IndividualProductDiscount = 0.0;
  var Order_cartDiscount;
  var order_totalTaxPercent = '';
  // var _rateRate=0.0;
  //   TotalTaxByName && TotalTaxByName.map(name => {_rateRate+= parseFloat(name.TaxRate)})
  //   labelTax += order_reciept.PercentageTaxOfEntireOrder ==true? "("+_rateRate.toFixed(0)+"%)":""

  var item_detail = data.line_items ? data.line_items : data.ListItem;
  function getCompositItemDetail(item) {
    var compositChield = [];
    item_detail.map((compositItem) => {
      if (
        compositItem.composite_parent_key &&
        compositItem.composite_parent_key == item.composite_product_key
      ) {
        compositChield.push(compositItem.name);
      }
      if (
        compositItem.bundled_parent_key &&
        compositItem.bundled_parent_key == item.bundle_product_key
      ) {
        compositChield.push(compositItem.name);
      }
    });
    return compositChield.join(', ');
  }

  var lineItem = '';
  var _cartDiscount = 0.0;
  var _itemData = {};
  var DisplayTotalSplitTax = [];
  var refundLineItemDetail = [];
  WrapperAppData['items'] = [];

  if (data.refunded_amount) {
    WrapperAppData['refundItems'] = [];
  }
  //var refundItemDetail = '';
  var refundSubTotal = 0.0;
  var all_modifier_total = 0;
  var all_modifier_tax = 0;
  var all_modifier_taxes = '';
  var all_modifier_list = [];
  var all_modifier_discount = 0;
  var _lineitem =
    item_detail &&
    item_detail.filter(
      (u) =>
        !u.extention_custom_id &&
        (!u.composite_parent_key ||
          (u.composite_parent_key && u.composite_parent_key == '')) && //Remove tip item into itemlist,, remove coposit child product
        (!u.bundled_parent_key ||
          (u.bundled_parent_key && u.bundled_parent_key == '')) //Remove bundle child product
    );
  _lineitem = getProdcutAddons(_lineitem, data.meta_datas);
  var all_modifier_list = getModifierFromCustomFee(
    _lineitem,
    data.meta_datas,
    data.order_custom_fee
  );
  all_modifier_list.map((item) => {
    if (
      item.Title &&
      item.Title.includes('Tip') &&
      !item.hasOwnProperty('product_id')
    ) {
      //ekip tip
      _tipAmount += item.Price ? parseFloat(item.Price) : 0;
      // _tipLable=item.Title;
    } else if (item.hasOwnProperty('product_id') === true) {
      //removing the custome fee

      //if(type="activity")
      // {
      //   _itemData = reCalculateDiscount(data, item, type);
      //   if (_itemData && _itemData.cart_discount) {
      //     _cartDiscount += _itemData.cart_discount;
      //   }
      // }

      var itemName = stripHtml(item.name ? item.name : item.Title);
      var skuName =
        item.sku && item.sku !== 'N/A' && item.sku !== undefined
          ? 'SKU <br/>' + item.sku + ' <br/>'
          : item.Sku && item.Sku !== 'N/A' && item.Sku !== undefined
          ? 'SKU <br/>' + item.Sku + ' <br/>'
          : '';
      var skuName_android =
        item.sku && item.sku !== 'N/A' && item.sku !== undefined
          ? 'SKU ' + item.sku + ''
          : item.Sku && item.Sku !== 'N/A' && item.Sku !== undefined
          ? 'SKU ' + item.Sku + ''
          : '';
      var skuName_wrapper =
        item.sku && item.sku !== 'N/A' && item.sku !== undefined
          ? item.sku
          : item.Sku && item.Sku !== 'N/A' && item.Sku !== undefined
          ? item.Sku
          : '';

      var lineitem_taxType = [];
      var itemvalue =
        item.Taxes && item.Taxes !== null && item.Taxes !== ''
          ? type !== 'activity'
            ? item.Taxes
            : item.Taxes && item.Taxes !== 'null'
            ? JSON.parse(item.Taxes).total
            : ''
          : '';
      var taxRate = JSON.parse(localStorage.getItem('SHOP_TAXRATE_LIST'));
      var TotalTax = 0;
      if (type == 'activity') {
        // for activity
        itemvalue &&
          itemvalue !== '' &&
          Object.keys(itemvalue).map((key) => {
            var taxvalue = itemvalue[key];
            data.order_taxes &&
              data.order_taxes.map((tm) => {
                // taxRate && taxRate.map(tm=>{
                if (tm.RateId == key) {
                  var taxname = tm.Title; //tm.TaxName;
                  var TaxRate = calculateTaxRate(
                    parseFloat(item.price * item.quantity),
                    parseFloat(taxvalue)
                  );
                  if (item.price == 0) {
                    TaxRate = 0;
                  }
                  if (order_reciept) {
                    if (order_reciept.PercentageTaxPerItem == true) {
                      taxname += '(' + TaxRate + '%)';
                    }
                    TotalTax += parseFloat(TaxRate);
                  }
                  lineitem_taxType.push({
                    tax: taxname,
                    tax_name: tm.Title,
                    value: taxvalue,
                    wpTaxName: tm.Title,
                    wpTaxRate: TaxRate,
                    id: tm.RateId,
                  });
                }
              });
          });
      } else {
        //for sale complete
        itemvalue !== '' &&
          itemvalue.map((myObj) => {
            var taxvalue = '';
            var key = '';
            for (const x in myObj) {
              key = x;
              taxvalue = myObj[x];
            }
            taxRate &&
              taxRate.map((tm) => {
                if (tm.TaxId == key) {
                  var taxname = tm.TaxName;
                  if (
                    order_reciept &&
                    order_reciept.PercentageTaxPerItem == true
                  ) {
                    taxname += '(' + tm.TaxRate + ')';
                  }
                  TotalTax += parseFloat(tm.TaxRate);

                  lineitem_taxType.push({
                    tax: taxname,
                    value: taxvalue,
                    wpTaxName: tm.TaxName,
                    wpTaxRate: tm.TaxRate,
                    id: tm.TaxId,
                  });
                }
              });
          });
      }
      //..............insert unique tax ...........................................
      var wrapperTaxes = [];
      lineitem_taxType &&
        lineitem_taxType.map((taxItem) => {
          // '>1' to check if split tax inot more then 1 tax type
          if (DisplayTotalSplitTax.length == 0) {
            if (taxItem.value !== 0) DisplayTotalSplitTax.push(taxItem);
            wrapperTaxes.push({
              name: taxItem.hasOwnProperty('tax_name')
                ? taxItem.tax_name
                : taxItem.wpTaxName,
              percent: taxItem.hasOwnProperty('wpTaxRate')
                ? taxItem.wpTaxRate
                : '',
              amount: taxItem.value,
            });
            //, rate: taxItem.hasOwnProperty("wpTaxRate") ? taxItem.wpTaxRate : ""
          } else {
            var checkTaxExist = false;
            DisplayTotalSplitTax.map((item) => {
              if (item.tax == taxItem.tax && item.id == taxItem.id) {
                checkTaxExist = true;
                item.value = parseFloat(item.value) + parseFloat(taxItem.value);
              }
            });
            if (checkTaxExist == false) {
              if (taxItem.value !== 0) DisplayTotalSplitTax.push(taxItem);
            }
            wrapperTaxes.push({
              name: taxItem.hasOwnProperty('tax_name')
                ? taxItem.tax_name
                : taxItem.wpTaxName,
              percent: taxItem.hasOwnProperty('wpTaxRate')
                ? taxItem.wpTaxRate
                : '',
              amount: taxItem.value,
            });
          }
        });

      //........................................................................
      var lineItemRefundQuntity = 0.0;
      var lineItemRefundAmount = 0.0;
      order_totalTaxPercent =
        TotalTax !== 0 ? '(' + TotalTax.toFixed(0) + '%)' : '';
      // var lineItem_DiscountDetail = getDiscountPerItem(data, type, item.product_id)
      var lineItem_DiscountDetail = getDiscountPerItem(
        data,
        type,
        item.product_id,
        item
      );
      // var lineitem_AcutalPrice = parseFloat(item.subtotal ? item.subtotal : item.subtotalPrice ? item.subtotalPrice : item.Price ? item.Price : 0) +
      //   (taxInclusiveName !== '' ? type == 'activity' ? item.total_tax : (item.subtotaltax ? item.subtotaltax : 0) : 0)

      var logitem =
        logLineItemData &&
        logLineItemData.find((litem) => {
          return (
            (litem.variation_id ? litem.variation_id : litem.product_id) ==
            item.product_id
          );
        });
      //var lineitem_AcutalPrice = logitem ? logitem.old_price * item.quantity : (item.total + ((taxInclusiveName == "incl") ? item.total_tax : 0));
      var lineitem_AcutalPrice = logitem
        ? taxInclusiveName == 'incl'
          ? logitem.old_price * item.quantity
          : logitem.Price
        : item.total + (taxInclusiveName == 'incl' ? item.total_tax : 0);

      // if (item && item.hasOwnProperty("old_price") == false && data.OliverReciptId == "") {
      //   lineitem_AcutalPrice = item.total + ((taxInclusiveName == "incl") ? item.total_tax : 0);
      // }
      if (logitem == null) {
        lineitem_AcutalPrice =
          parseFloat(
            item.subtotal
              ? item.subtotal
              : item.subtotalPrice
              ? item.subtotalPrice
              : item.Price
              ? item.Price
              : 0
          ) +
          (taxInclusiveName !== ''
            ? type == 'activity'
              ? item.total_tax
              : item.subtotaltax
              ? item.subtotaltax
              : 0
            : 0);
      }
      var lineitem_Title =
        type !== 'activity' ? itemName : showTitle(item) !== '' ? itemName : '';
      var lineitem_TotalQty = item.quantity;

      var lineitem_sku = order_reciept.ShowSKU == true ? skuName : null;
      var lineitem_subTitle =
        type == 'activity' && item.meta
          ? item.meta && item.meta.length > 0
            ? CommonJs.showAddons(type, JSON.parse(item.meta))
            : ''
          : item && item.addons_meta_data
          ? CommonJs.showAddons(type, JSON.parse(item.addons_meta_data))
          : productxList &&
            productxList.length > 0 &&
            showProductxSubTitle(item.product_id, AllProductList)
          ? showProductxSubTitle(item.product_id, AllProductList)
          : showSubTitle(item) !== ''
          ? itemName
          : '';
      //-----For composit/bundle Prodcut ---------------                                                                                                                                                                 : showSubTitle(item) !== "" ? itemName : ''
      if (
        type == 'activity' &&
        ((item.composite_product_key && item.composite_parent_key == '') ||
          (item.bundle_product_key && item.bundled_parent_key == ''))
      ) {
        lineitem_subTitle = getCompositItemDetail(item);
      }
      //----------------------------------
      var lineitem_cartDiscount = 0;
      var lineItem_cartDiscountType = '';
      var lineitem_Discount =
        lineItem_DiscountDetail && lineItem_DiscountDetail.discountAmount
          ? lineItem_DiscountDetail.discountAmount
          : 0;
      Total_IndividualProductDiscount += lineitem_Discount;
      if (lineItem_DiscountDetail && lineItem_DiscountDetail.discountCart) {
        lineitem_cartDiscount = parseFloat(
          lineItem_DiscountDetail.cartItemDiscount
        );
        _cartDiscount += lineitem_cartDiscount;
        Order_cartDiscount = lineItem_DiscountDetail.discountCart;

        var _discountType = Order_cartDiscount
          ? Order_cartDiscount.discountType == 'Number'
            ? ''
            : '%'
          : '%';
        lineItem_cartDiscountType =
          lineItem_DiscountDetail.discountCart && _discountType == '%'
            ? '(' +
              parseFloat(lineItem_DiscountDetail.discountCart.discount_amount) +
              _discountType +
              ')'
            : '';
      }
      //item.discount_amount && item.discount_amount !==0? (item.discount_amount).toFixed(2):undefined

      var lineitem_Dis_Percent =
        lineItem_DiscountDetail &&
        lineItem_DiscountDetail.discountApply &&
        lineItem_DiscountDetail.discounttype == 'Number'
          ? ''
          : lineItem_DiscountDetail &&
            lineItem_DiscountDetail.discountApply &&
            '(' + lineItem_DiscountDetail.discountApply + '%)';

      //order_reciept.PercentageDiscountPerItem==true ? "(" +Math.round(((item.discount_amount *100)/lineitem_AcutalPrice)).toFixed(0)+"%)":"";
      var lineItemTax = '';
      var modifiers_html = '';
      var _lineitem = data.line_items ? data.line_items : data.ListItem;
      var _meta_datas = data.meta_datas;

      var _modifier_total = 0;
      var _modifier_total_tax = 0;
      //all_modifier_list = getModifierFromCustomFee(_lineitem, _meta_datas, data.order_custom_fee);
      var product_modifiers = all_modifier_list;

      // if(type=="completecheckout")
      // {
      //   _meta_datas=  _meta_datas && _meta_datas[0] && _meta_datas[0]._order_oliverpos_product_discount_amount;
      //   product_modifiers=getModifierFromCustomFee(_lineitem, _meta_datas, data.order_custom_fee);
      // }
      // var product_modifiers = data.order_custom_fee ? data.order_custom_fee : [];
      product_modifiers =
        product_modifiers &&
        product_modifiers.filter(
          (a) =>
            (a.pid == item.product_id || a.pid == item.variation_id) &&
            (a.hasOwnProperty('modifiers_Id') &&
            item.hasOwnProperty('modifiers_Id')
              ? JSON.stringify(a.modifiers_Id) ==
                JSON.stringify(item.modifiers_Id)
              : true) &&
            (a.hasOwnProperty('modifiers_key') &&
            item.hasOwnProperty('modifiers_key')
              ? a.modifiers_key == item.modifiers_key
              : true)
        );
      if (product_modifiers && product_modifiers.length > 0) {
        var _taxArray = [];
        modifiers_html = '<table class="item-table"><tbody>';
        product_modifiers &&
          product_modifiers.map((item_fee, index) => {
            if (item_fee.hasOwnProperty('pid') == true) {
              _modifier_total += item_fee.amount;
              _modifier_total_tax += item_fee.total_tax;
              //_taxArray.push(item_fee.total_taxes);
              if (_taxArray.length > 0) {
                _taxArray = [..._taxArray, ...item_fee.total_taxes];
              } else {
                _taxArray = item_fee.total_taxes;
              }
              var mod_name = (
                item_fee.note
                  ? item_fee.note
                  : item_fee.Title
                  ? item_fee.Title
                  : ''
              ).replace(/_/g, ' ');
              modifiers_html += `<tr>
          <td>${mod_name}</td>
          <td align="right">${parseFloat(item_fee.amount).toFixed(2)}</td>
        </tr>`;
              //       if ((order_reciept.IndividualizedTaxAmountPerItem == true || order_reciept.PercentageTaxPerItem == true)) {
              //         modifiers_html += `<tr>
              //   <td>${taxInclusiveName !== '' ? 'Tax (Incl.)' : 'Tax'}</td>
              //   <td align="right">${parseFloat(item_fee.total_tax).toFixed(2)}</td>
              // </tr>`;
              //       }
            }
          });
        modifiers_html += `</tbody></table>`;
        //ADDING MODIFER TAX TO THE PRODUCT TAX ACCORDING TO THEIR CLASS --START 14022023
        // var _taxvalue = 0;
        // all_modifier_taxes && all_modifier_taxes !== "" && all_modifier_taxes.map(item1 => {
        //   Object.keys(item1).map(key => {
        //     if (tm.RateId == key) {
        //       _taxvalue += item1[key];
        //       console.log("--taxvalue--" + _taxvalue);
        //     }
        //   })
        // })
        //ADDING MODIFER TAX TO THE PRODUCT TAX ACCORDING TO THEIR CLASS --END 14022023
      }

      // var _result = addModiferTaxToLineItemTax(lineitem_taxType, product_modifiers)
      // var templineItemTax = '';
      // {
      //   if (DisplayTotalSplitTax && DisplayTotalSplitTax.length) {
      //     _result && _result.map(tx => {
      //       templineItemTax += (`<tr><td>${tx.taxname}</td><td align="right">${currecyFunction(tx.taxamount)}</td></tr>`);
      //     })
      //   }
      // }
      // console.log("---LINE NO. 1235-----" + JSON.stringify(_result));
      //HERETOCHECK
      //var _result = addModiferTaxToLineItemTax(lineitem_taxType, product_modifiers)
      var templineItemTax = '';
      //console.log("---LINE NO. 1235-----" + JSON.stringify(_result));
      lineitem_taxType &&
        lineitem_taxType.length > 0 &&
        lineitem_taxType.map((txtitem) => {
          var _taxvalue = 0;
          var _mods = product_modifiers.filter(
            (a) => a.modifiers_key == item.modifiers_key
          );
          if (_mods && _mods.length > 0) {
            _mods.map((a) => {
              a.total_taxes.map((key) => {
                Object.keys(key).map((id) => {
                  if (txtitem.id == id) {
                    _taxvalue += key[id];
                  }
                });
              });
            });
          }
          //HERETOCHECK CALCULATION WILL THERE BUT HIDE SHOW THE DIV
          templineItemTax += `<tr><td>${
            txtitem.tax
          } ${labelIncl}</td><td align="right">${(
            parseFloat(txtitem.value) + parseFloat(_taxvalue)
          ).toFixed(2)}</td></tr>`;
        });
      if (
        order_reciept.IndividualizedTaxAmountPerItem == true ||
        order_reciept.PercentageTaxPerItem == true
      ) {
        lineItemTax = templineItemTax;
        // lineitem_taxType.map(txtitem => {
        //   var _taxvalue = 0;
        //   all_modifier_taxes && all_modifier_taxes !== "" && all_modifier_taxes.map(item1 => {
        //     Object.keys(item1).map(key => {
        //       if (txtitem.RateId == key) {
        //         _taxvalue += item1[key];
        //         console.log("--taxvalue--" + _taxvalue);
        //       }
        //     })
        //   })
        //   //HERETOCHECK CALCULATION WILL THERE BUT HIDE SHOW THE DIV
        //   lineItemTax += `<tr><td>${txtitem.tax}</td><td align="right">${(parseFloat(txtitem.value) + parseFloat(_taxvalue)).toFixed(2)}</td></tr>`;
        // })
      }
      var _taxvalueAll = 0;
      product_modifiers &&
        product_modifiers !== '' &&
        product_modifiers.map((item1) => {
          if (
            item1.hasOwnProperty('cart_discount_amount') &&
            item1.cart_discount_amount != 0
          ) {
            lineitem_cartDiscount += item1.cart_discount_amount;
            all_modifier_discount += item1.cart_discount_amount;
          }
          if (
            item1.hasOwnProperty('product_discount_amount') &&
            item1.product_discount_amount != 0
          ) {
            lineitem_Discount += item1.product_discount_amount;
            all_modifier_discount += item1.product_discount_amount;
          }
          return (_taxvalueAll += parseFloat(item1.total_tax));
        });
      var _lineitemTax =
        taxInclusiveName !== '' ||
        order_reciept.IndividualizedTaxAmountPerItem == true ||
        order_reciept.PercentageTaxPerItem == true
          ? item.total_tax
            ? item.total_tax
            : item.subtotaltax
            ? item.subtotaltax
            : 0
          : 0;
      // console.log("--_taxvalueAll---" + _taxvalueAll)
      //var _activitylineItemTotal = parseFloat(item.subtotal + (taxInclusiveName !== '' ? item.total_tax : 0) - lineitem_Discount - lineitem_cartDiscount).toFixed(2)

      var lineitem_Total =
        lineitem_AcutalPrice -
        parseFloat(lineitem_Discount) -
        lineitem_cartDiscount;
      // var lineitem_Total = type == 'activity' ? _activitylineItemTotal
      //   :
      // (item.product_id ? parseFloat(parseFloat(item.subtotalPrice ? item.subtotalPrice : 0) - parseFloat(lineitem_Discount) - lineitem_cartDiscount + (taxInclusiveName !== '' ? (item.totaltax ? parseFloat((_lineitemTax)) : 0) : 0))//(taxInclusiveName !==''? (item.totaltax?item.totaltax:0):0) ))
      //   : item.Price ? parseFloat(parseFloat(item.Price) - parseFloat(lineitem_Discount) - lineitem_cartDiscount + (taxInclusiveName !== '' ? (item.totaltax ? parseFloat((_lineitemTax)) : 0) : 0))//(taxInclusiveName !==''? (item.totaltax?item.totaltax:0):0) ))
      //     : 0
      // )

      // Order_subTotal += (parseFloat(lineitem_Total).toFixed(2) == "0.01" ? 0.00 : parseFloat(lineitem_Total)); // to remove diffrence of .01
      Order_subTotal += lineitem_AcutalPrice;

      // only for exclusive tax------------
      lineitem_Total =
        parseFloat(lineitem_Total) +
        (taxInclusiveName == ''
          ? order_reciept.IndividualizedTaxAmountPerItem == true ||
            order_reciept.PercentageTaxPerItem == true // added line item tax into product total only when setting is enable
            ? (item.totaltax || item.total_tax ? parseFloat(_lineitemTax) : 0) +
              _taxvalueAll
            : 0
          : 0);

      lineitem_Total =
        parseFloat(lineitem_Total).toFixed(2) == '0.01' ? 0.0 : lineitem_Total; // to remove diffrence of .01

      var lineitem_shortDesc = getProductShortDesc(data, type, item.product_id);

      if (item.quantity_refunded < 0) {
        var taxPerQty = item.subtotal_tax / item.quantity;
        var taxRefunded = 0;
        lineItemRefundQuntity = Math.abs(item.quantity_refunded);
        if (taxPerQty && taxInclusiveName !== '') {
          //for incusive tax
          taxRefunded = taxPerQty * lineItemRefundQuntity;
        }

        refundSubTotal += item.amount_refunded + taxRefunded;
        lineItemRefundAmount = item.amount_refunded + taxRefunded;
      }

      if (item.quantity_refunded < 0) {
        var _refundItemDetail = {};
        _refundItemDetail['productId'] = item.product_id;
        _refundItemDetail['line_item_id'] = item.line_item_id
          ? item.line_item_id
          : null;
        _refundItemDetail['title'] = lineitem_Title;
        _refundItemDetail['totalQuantity'] = lineitem_TotalQty;
        _refundItemDetail['pricePerQuantity'] =
          lineitem_AcutalPrice / lineitem_TotalQty;
        _refundItemDetail['itemDiscountLabel'] =
          lineitem_Discount > 0
            ? LocalizedLanguage.itemDiscount +
              (order_reciept.PercentageDiscountPerItem == true
                ? lineitem_Dis_Percent
                : '')
            : '';
        var lblCartDiscount = LocalizedLanguage.cartdiscount;
        lblCartDiscount +=
          order_reciept.PercentageDiscountPerItem == true
            ? lineItem_cartDiscountType
            : '';
        _refundItemDetail['CartDiscountLabel'] = lblCartDiscount;
        _refundItemDetail['itemDiscount'] = lineitem_Discount;
        _refundItemDetail['CartDiscount'] = lineitem_cartDiscount;
        _refundItemDetail['refundTaxType'] = lineitem_taxType;
        _refundItemDetail['refundedQuantity'] = item.quantity_refunded;

        if (item.hasOwnProperty('modifiers_Id')) {
          _refundItemDetail['modifiers_Id'] = item.modifiers_Id;
        }
        if (item.hasOwnProperty('modifiers_key')) {
          _refundItemDetail['modifiers_key'] = item.modifiers_key;
        }
        _refundItemDetail['taxes'] = itemvalue;
        refundLineItemDetail.push(_refundItemDetail);
      }

      //warapper Items---------
      var wppLineItem = {
        name: lineitem_Title,
        price: lineitem_AcutalPrice,
        quantity: lineitem_TotalQty,
        sku: '',
        descr: '', //lineitem_subTitle,
        addons: [{ name: '', price: '' }], // lineitem_shortDesc,
        taxes: [],
        discount: { name: '', percent: '', amount: 0 },
        //{ "lineItemDiscount": lineitem_Discount, "cartDiscount": lineitem_cartDiscount },
        total: 0.0,
        discountcart: { name: '', percent: '', amount: 0 },
      };
      //if (lineitem_Discount > 0 || lineitem_AcutalPrice !== lineitem_Total ||  order_reciept.IndividualizedTaxAmountPerItem == true || order_reciept.PercentageTaxPerItem == true ) {
      wppLineItem['total'] = lineitem_Total;
      //}
      if (skuName_wrapper && order_reciept.ShowShortDescription == true) {
        wppLineItem['descr'] = lineitem_shortDesc;
      }
      if (skuName_wrapper && order_reciept.ShowSKU == true) {
        wppLineItem['sku'] = skuName_wrapper;
      }
      if (
        order_reciept.PercentageDiscountPerItem == true &&
        lineitem_Discount != 0
      ) {
        wppLineItem['discount'] = {
          name:
            lineitem_Discount && lineitem_Discount.discount_type
              ? lineitem_Discount.discount_type
              : '',
          percent: lineitem_Dis_Percent ? lineitem_Dis_Percent : '',
          amount: lineitem_Discount,
        };
      }
      if (
        order_reciept.PercentageDiscountPerItem == true &&
        lineitem_cartDiscount != 0
      ) {
        wppLineItem['discountcart'] = {
          name: lineItem_cartDiscountType,
          percent: Order_cartDiscount.discountType,
          amount: lineitem_cartDiscount,
        };
      }
      if (
        order_reciept.IndividualizedTaxAmountPerItem == true ||
        order_reciept.PercentageTaxPerItem == true
      ) {
        wppLineItem['taxes'] = wrapperTaxes;
      }
      WrapperAppData.items.push(wppLineItem);
      WrapperAppData['discount'] = {
        name:
          lineitem_Discount && lineitem_Discount.discount_type
            ? lineitem_Discount.discount_type
            : '',
        percent: lineitem_Dis_Percent ? lineitem_Dis_Percent : '',
        amount: lineitem_Discount /*, "cartDiscount": lineitem_cartDiscount */,
      };
      //WrapperAppData["taxes"] = wrapperTaxes;
      //-----------------------

      var addons =
        item.addons && item.addons !== '' && item.addons.length > 0
          ? '<table class="item-table"><tbody>'
          : '';
      item.addons &&
        item.addons !== '' &&
        item.addons.length > 0 &&
        item.addons.map((i) => {
          var _addTitle = i.title ? i.title.replace(/_/g, ' ') : '';
          _addTitle += i.sub_title
            ? '(' + i.sub_title.replace(/_/g, ' ') + ')'
            : '';
          addons += `<tr><td colspan="2"> + ${_addTitle}</td>
         <td align="right">${
           i.amount && i.amount !== ''
             ? ' + ' + parseFloat(i.amount).toFixed(2)
             : ''
         }</td>
       </tr>`;
        });

      addons = addons !== '' ? addons + '</tbody></table' : '';

      lineItem += `<table class="item-table">
                <tbody><tr>
                  <td> ${lineitem_Title}</td>
                  <td   align="right"> ${
                    lineitem_TotalQty && lineitem_TotalQty > 1
                      ? `<div class="item-quantity;padding-right:10px">(${lineitem_TotalQty} * ${(
                          lineitem_AcutalPrice / lineitem_TotalQty
                        ).toFixed(2)})</div>`
                      : ''
                  }</td>
                  <td align="right">${lineitem_AcutalPrice.toFixed(2)}</td>
                </tr> 
                ${
                  item.psummary &&
                  typeof item.psummary !== 'undefined' &&
                  item.psummary !== null &&
                  item.psummary !== ''
                    ? `<tr><td colspan='2' style="text-transform: capitalize;font-size:10px;">${item.psummary}</td> </tr>`
                    : ``
                }
                ${
                  addons !== ''
                    ? `<tr><td colspan="3">${addons}</td></tr>`
                    : item.ProductSummery &&
                      typeof item.ProductSummery !== 'undefined' &&
                      item.ProductSummery !== null &&
                      item.ProductSummery !== '' &&
                      item.ProductSummery.length > 0
                    ? `<tr><td colspan='2' style="text-transform: capitalize;font-size:10px;">${item.ProductSummery.toString()} </td> </tr> `
                    : lineitem_subTitle &&
                      lineitem_subTitle !== '' &&
                      lineitem_subTitle !== null
                    ? `<tr><td colspan='2' style="text-transform: capitalize;font-size:10px;">${lineitem_subTitle.toString()}</td></tr>`
                    : ''
                }`;
      lineItem +=
        (lineitem_sku && order_reciept.ShowSKU == true) ||
        order_reciept.ShowShortDescription == true
          ? `<tr>
                  <td colspan='2'>
                      <div class="item-addon-items">
                      ${
                        lineitem_sku && order_reciept.ShowSKU == true
                          ? `<p>${lineitem_sku && lineitem_sku} </p>`
                          : ``
                      }
                      ${
                        order_reciept.ShowShortDescription == true &&
                        lineitem_shortDesc &&
                        lineitem_shortDesc !== ''
                          ? `<p> ${lineitem_shortDesc} </p>`
                          : ``
                      }
                       </div>
                  </td>
                  <td></td>
                </tr> `
          : '';
      // var modifiers_html = '';
      // var _lineitem = data.line_items ? data.line_items : data.ListItem;
      // var _meta_datas = data.meta_datas;
      // var _modifier_total = 0;
      // var _modifier_total_tax = 0;
      // var product_modifiers = getModifierFromCustomFee(_lineitem, _meta_datas, data.order_custom_fee);
      // // var product_modifiers = data.order_custom_fee ? data.order_custom_fee : [];
      // product_modifiers = product_modifiers && product_modifiers.filter(a => a.pid == item.product_id || a.pid == item.variation_id);
      // if (product_modifiers && product_modifiers.length > 0) {
      //   var _taxArray = [];
      //   modifiers_html = '<table class="item-table"><tbody>';
      //   product_modifiers && product_modifiers.map((item_fee, index) => {
      //     if (item_fee.hasOwnProperty("pid") == true) {
      //       _modifier_total += item_fee.amount;
      //       _modifier_total_tax += item_fee.total_tax;
      //       //_taxArray.push(item_fee.total_taxes);
      //       if (_taxArray.length > 0) {
      //         _taxArray = [..._taxArray, ...item_fee.total_taxes]
      //       }
      //       else { _taxArray = item_fee.total_taxes; }

      //       modifiers_html += `<tr>
      //     <td>${item_fee.note ? item_fee.note : item_fee.Title ? item_fee.Title : ''}</td>
      //     <td align="right">${parseFloat(item_fee.amount).toFixed(2)}</td>
      //   </tr>` }
      //   });
      //   modifiers_html+=`</tbody></table>`;
      lineItem +=
        modifiers_html && modifiers_html !== ''
          ? `<tr><td colspan="3">${modifiers_html}</td></tr>`
          : ``; //+ `</tbody></table>`;
      lineitem_Total += _modifier_total;
      all_modifier_total += _modifier_total;
      all_modifier_tax += _modifier_total_tax;
      all_modifier_taxes = _taxArray;
      _lineitemTax += _modifier_total_tax;

      //IT CAUSES MODIFIFER TAX DOUBLE
      // if (order_reciept.IndividualizedTaxAmountPerItem == true || order_reciept.PercentageTaxPerItem == true)
      // {
      //   lineitem_Total += taxInclusiveName != "" ? 0 : _modifier_total_tax;
      // }

      //}

      var notes_list = getProductNoteHtml(data, item, type);
      if (notes_list && notes_list.length > 0) {
        var parser = new DOMParser();

        notes_list &&
          notes_list.map((a) => {
            var doc = parser.parseFromString(a.note ? a.note : '', 'text/html');
            lineItem += `<tr><td colspan="3"><table class="item-table"><tbody><tr>
                    <td>${LocalizedLanguage.notes}</td>
                    <td align="right">${
                      a.note ? doc.body.innerHTML : ''
                    }</td></tr></tbody></table></td></tr>`;
          });
      }

      //getOrderNotes(data.meta_datas,data.order_notes);
      // data.order_notes && data.order_notes.map((item_note, index) => {

      //   if ((item_note.hasOwnProperty("pid") && item.hasOwnProperty("product_id") && item_note.pid == item.product_id)) {
      //     var parser = new DOMParser();
      //     var doc = parser.parseFromString(item_note.note ? item_note.note : "", "text/html");

      //     lineItem += item_note.note.toLowerCase().match(/payment done with:/) ? ''
      //       : item_note.is_extension_note && item_note.is_extension_note == true ? '' :
      //         `<tr>
      //               <td>${LocalizedLanguage.notes}</td>
      //               <td align="right">${item_note.note ? doc.body.innerHTML : ''}
      //                         </td>
      //                 </tr>`
      //     //for android............
      //     if (!item_note.note.toLowerCase().match(/payment done with:/) && !(item_note.is_extension_note && item_note.is_extension_note == true)) {
      //       receipt += LocalizedLanguage.notes + item_note.note ? item_note.note : '' + "\n";
      //       rowNumber += 1;
      //       PrintAndroidData.push({ "rn": rowNumber, "cms": 1, "c1": "Notes " + LocalizedLanguage.notes + item_note.note ? item_note.note : '', "c2": "", "c3": "", "bold": "0,0,0", "fs": "24", "alg": "0" });
      //     }
      //   }
      //   //---------------
      // })
      lineItem +=
        /* (order_reciept.PercentageDiscountPerItem == true || order_reciept.AmountDiscountPerItem == true) && */ lineitem_Discount ||
        lineitem_cartDiscount
          ? `<tr>
                        <td colspan="3">
                            <div class="itemdiv">
                                <table class="innertable">
                                      <tfoot>
                                     ${
                                       lineitem_Discount > 0
                                         ? `<tr>
                                          <td>${
                                            LocalizedLanguage.itemDiscount
                                          } ${
                                             order_reciept.PercentageDiscountPerItem ==
                                             true
                                               ? lineitem_Dis_Percent
                                               : ''
                                           }</td>
                                          <td align="right">-${lineitem_Discount.toFixed(
                                            2
                                          )}</td>
                                      </tr>`
                                         : ''
                                     }
                                      ${
                                        lineitem_cartDiscount > 0
                                          ? `<tr>
                                          <td>${
                                            LocalizedLanguage.cartdiscount
                                          } ${
                                              order_reciept.PercentageDiscountPerItem ==
                                              true
                                                ? lineItem_cartDiscountType
                                                : ''
                                            }</td>
                                          <td align="right">-${lineitem_cartDiscount.toFixed(
                                            2
                                          )}</td>
                                        </tr>`
                                          : ''
                                      }
                                      </tfoot>

                                </table>
                            </div>
                        </td>
                    </tr>`
          : '';

      lineItem += lineItemTax
        ? `<tr>
                       <td colspan="3">
                           <div class="itemdiv">
                                <table class="innertable">

                                ${lineItemTax}

                                </table>
                           </div>
                       </td>
                   </tr>`
        : '';
      /* `<tr><td colspan="3"><div class="itemdiv"></div> </td></tr> ` */

      // lineItem += ((lineitem_Discount > 0 && (order_reciept.PercentageDiscountPerItem == true || order_reciept.AmountDiscountPerItem == true)) || order_reciept.IndividualizedTaxAmountPerItem == true || order_reciept.PercentageTaxPerItem == true || order_reciept.ShowTotalAmountPerItem == true) && lineitem_TotalQty >= 0 ? `</tbody>
      lineItem +=
        lineItemTax == '' && lineitem_Discount == 0
          ? `<tr><td colspan="3"><div class="itemdiv"></div> </td></tr>`
          : ``;
      lineItem +=
        lineitem_Discount > 0 ||
        lineitem_AcutalPrice !== lineitem_Total ||
        order_reciept.IndividualizedTaxAmountPerItem == true ||
        order_reciept.PercentageTaxPerItem == true
          ? `</tbody>
                <tfoot>
                    <tr>
                        <td colspan='2'>
                           ${LocalizedLanguage.producttotal}
                        </td>
                        <td align="right">${parseFloat(lineitem_Total).toFixed(
                          2
                        )}</td>
                    </tr>
                </tfoot>
            </table>`
          : ``;
      lineItem +=
        lineitem_Discount > 0 ||
        lineitem_AcutalPrice !== lineitem_Total ||
        order_reciept.IndividualizedTaxAmountPerItem == true ||
        order_reciept.PercentageTaxPerItem == true
          ? `<tr><td colspan="3"><div class="itemdiv"></div> </td></tr>`
          : ``;
    }
  });


  DisplayTotalSplitTax = addModiferTaxToLineItemTax(
    DisplayTotalSplitTax,
    all_modifier_list.filter((a) => !a.hasOwnProperty('modifiers_Id'))
  );
  var splitTaxDetail = '';
  // console.log("DisplayTotalSplitTax", DisplayTotalSplitTax)
  // DisplayTotalSplitTax.length > 0 && DisplayTotalSplitTax.map(item => {  //display split tax if tax is more then 1
  //   var taxvalue = 0;
  //   all_modifier_taxes && all_modifier_taxes !== "" && all_modifier_taxes.map(item1 => {
  //     Object.keys(item1).map(key => {
  //       if (item.id == key) {
  //         taxvalue += item1[key];
  //         console.log("--taxvalue--" + taxvalue);
  //       }
  //     });
  //   });
  //   splitTaxDetail += `<tr><td colspan="2">${item.tax} ${labelIncl}</td>
  //   <td align="right"> ${(parseFloat(item.value) + parseFloat(taxvalue)).toFixed(2)}</td>
  //   </tr>`
  //   splitTaxDetail = '';//testing
  // })

  // var _result = addModiferTaxToLineItemTax(DisplayTotalSplitTax, product_modifiers)
  // console.log("--------"+JSON.stringify(_result));
  //console.log("splitTaxDetail", splitTaxDetail)
  var shippingAmount = 0;
  var shippingTax = 0;
  shippingTax = data.shipping_tax;
  shippingAmount =
    (data.total_shipping && data.total_shipping !== ''
      ? parseFloat(data.total_shipping)
      : 0) + (taxInclusiveName !== '' ? shippingTax : 0);

  var _CustomeFee = '<table class="item-table"><tbody>';
  var _newReceiptCustomFee = '';
  var _CustomeFeeRow = '';
  var _totalcustomeFee = 0;
  var _totalcustom = 0;
  var _onlyCustomFee = [];
  var CustomeFeeArry = data.order_custom_fee ? data.order_custom_fee : [];
  if (CustomeFeeArry && CustomeFeeArry.length == 0) {
    var custFee =
      item_detail &&
      item_detail.find(
        (item) =>
          item.hasOwnProperty('product_id') == false &&
          item.hasOwnProperty('pid') == false
      );
    if (custFee) {
      var _isModifier = false;
      if (
        all_modifier_list &&
        typeof all_modifier_list !== 'undefined' &&
        all_modifier_list.length > 0
      ) {
        _isModifier = all_modifier_list.any((a) => a.fee_id == custFee.fee_id);
      }
      if (_isModifier == false) {
        _onlyCustomFee.push(custFee);
      }
    }
  } else {
    if (
      all_modifier_list &&
      typeof all_modifier_list !== 'undefined' &&
      all_modifier_list.length > 0
    ) {
      var tempCustomeFeeArry = [];
      CustomeFeeArry.map((cf) => {
        if (
          all_modifier_list.findIndex(
            (a) => a.hasOwnProperty('fee_id') && a.fee_id == cf.fee_id
          ) == -1
        ) {
          tempCustomeFeeArry.push(cf);
        }
      });
      CustomeFeeArry = tempCustomeFeeArry;

      // CustomeFeeArry = CustomeFeeArry.filter(a => { all_modifier_list.some(b => b.fee_id == a.fee_id) });
      //CustomeFeeArry= CustomeFeeArry.map(d1 => ({return all_modifier_list.some(d2 => d2.name === d1.name)
    }
  }
  var _RefundItemLog = [];
  var metaData = data && data.meta_datas ? data.meta_datas : [];
  var isGetCustomFee = true;
  // metaData && metaData.length > 0 && metaData.map((metaData, index) => {
  //   if (metaData.ItemName == '_order_oliverpos_refund_log') {
  //     _RefundItemLog = metaData.ItemValue && metaData.ItemValue !== '' ? JSON.parse(metaData.ItemValue) : ''
  //   }
  //   if (metaData.ItemName == '_order_oliverpos_source' && metaData.ItemValue && metaData.ItemValue !== '' &&  (metaData.ItemValue=="3.0" || metaData.ItemValue=="2.0" )) {
  //     isGetCustomFee=false;
  //   }
  // })

  // metaData && metaData.length > 0 && metaData.map((metaData, index) => {
  //   if (metaData.ItemName == '_order_oliverpos_refund_log') {
  //     _RefundItemLog = metaData.ItemValue && metaData.ItemValue !== '' ? JSON.parse(metaData.ItemValue) : ''
  //   }
  // })
  //if(typeof _RefundItemLog=="undefined" || !_RefundItemLog || _RefundItemLog.length==0)
  //{
  var isGetCustomFee = true;
  // metaData && metaData.length > 0 && metaData.map((metaData, index) => {
  //   if (metaData.ItemName == '_order_oliverpos_refund_log') {
  //     _RefundItemLog = metaData.ItemValue && metaData.ItemValue !== '' ? JSON.parse(metaData.ItemValue) : ''
  //   }
  //   if (metaData.ItemName == '_order_oliverpos_source' && metaData.ItemValue && metaData.ItemValue !== '' &&  (metaData.ItemValue=="3.0" || metaData.ItemValue=="2.0" )) {
  //     isGetCustomFee=false;
  //   }
  // })
  if (
    (typeof _RefundItemLog == 'undefined' ||
      !_RefundItemLog ||
      _RefundItemLog.length == 0) &&
    isGetCustomFee == true
  ) {
    if (_onlyCustomFee.length == 0) {
      _onlyCustomFee = getCustomFee(data.meta_datas, data.order_custom_fee);
      if (_onlyCustomFee && _onlyCustomFee.length == 0) {
        _onlyCustomFee = data.order_custom_fee ? data.order_custom_fee : [];
      }
    }
  }
  if (
    all_modifier_list &&
    typeof all_modifier_list !== 'undefined' &&
    all_modifier_list.length > 0
  ) {
    var tempCustomeFeeArry = [];
    _onlyCustomFee.map((cf) => {
      if (
        all_modifier_list.findIndex(
          (a) => a.hasOwnProperty('fee_id') && a.fee_id == cf.fee_id
        ) == -1
      ) {
        tempCustomeFeeArry.push(cf);
      }
    });

    var cfee = _onlyCustomFee.filter(
      (a) =>
        a.hasOwnProperty('pid') &&
        a.pid == 0 &&
        (!a.hasOwnProperty('is_modi') ||
          (a.hasOwnProperty('is_modi') && a.is_modi == false))
    );
    // var cfee = _onlyCustomFee.filter(a => (a.hasOwnProperty("pid") && a.pid == 0 &&
    // (!a.hasOwnProperty("is_modi") || (a.hasOwnProperty("is_modi") && a.is_modi == false)))
    // || a.hasOwnProperty("pid") && typeof a.pid=="undefined");
    tempCustomeFeeArry = [...tempCustomeFeeArry, ...cfee];

    _onlyCustomFee = tempCustomeFeeArry;

    // CustomeFeeArry = CustomeFeeArry.filter(a => { all_modifier_list.some(b => b.fee_id == a.fee_id) });
    //CustomeFeeArry= CustomeFeeArry.map(d1 => ({return all_modifier_list.some(d2 => d2.name === d1.name)
  }
  var custFeeTax;
  _onlyCustomFee &&
    _onlyCustomFee.map((item_fee, index) => {
      if (
        item_fee.hasOwnProperty('pid') == false ||
        (item_fee.hasOwnProperty('pid') &&
          (typeof item_fee.pid == 'undefined' || item_fee.pid == 0))
      ) {
        if (item_fee.note && item_fee.note.includes('Tip')) {
          //ekip tip
          _tipAmount += item_fee.amount ? parseFloat(item_fee.amount) : 0;
          //_tipLable=item_fee.note;
        } else {
          var custFee = item_fee.amount
            ? item_fee.amount
            : item_fee.Price
            ? item_fee.Price
            : 0;
          custFee =
            custFee -
            (item_fee.hasOwnProperty('amount_refunded')
              ? item_fee.amount_refunded
              : 0);
          _totalcustom += custFee;
          custFeeTax = item_fee.total_tax ? item_fee.total_tax : 0;
          //custFee += taxInclusiveName == "" ? custFeeTax : 0;
          _CustomeFeeRow += `<tr>
                    <td>${
                      item_fee.note
                        ? item_fee.note
                        : item_fee.Title
                        ? item_fee.Title
                        : ''
                    }</td>
                    <td align="right">${parseFloat(custFee).toFixed(2)}</td>
                  </tr>`;

          _newReceiptCustomFee += `
          <div class="product-tax-wrapper">
            <div>${
              item_fee.note
                ? item_fee.note
                : item_fee.Title
                ? item_fee.Title
                : ''
            }
            </div>
            <div>
              ${parseFloat(custFee).toFixed(2)}
            </div>
          </div>
        `;

          if (
            order_reciept.IndividualizedTaxAmountPerItem == true ||
            order_reciept.PercentageTaxPerItem == true
          ) {
            _CustomeFeeRow += `<tr>
          <td>${'Tax ' + taxInclusiveName + ''}</td>
          <td align="right">${parseFloat(custFeeTax).toFixed(2)}</td>
        </tr>`;

            _newReceiptCustomFee += `
          <div class="product-tax-wrapper">
            <div>${'Tax ' + taxInclusiveName + ''}</div>
            <div>
            ${parseFloat(custFeeTax).toFixed(2)}
            </div>
          </div>
        `;
          }
          // _totalcustomeFee += parseFloat(custFee)+(taxInclusiveName == '' ? 0 : custFeeTax);
          _totalcustomeFee +=
            parseFloat(custFee) + (taxInclusiveName == '' ? custFeeTax : 0);
          refundLineItemDetail.push(item_fee);
        }
      }
    });
  Order_subTotal += _totalcustom;
  var customFeefooter = '';
  var newReceiptCustomFeeFooter = '';
  if (
    order_reciept.IndividualizedTaxAmountPerItem == true ||
    order_reciept.PercentageTaxPerItem == true
  ) {
    customFeefooter = `<tr><td colspan="2"><div class="itemdiv"></div> </td></tr>
  <tfoot><tr><td>${
    LocalizedLanguage.producttotal
  }</td><td align="right">${parseFloat(_totalcustomeFee).toFixed(
      2
    )}</td></tr></tfoot>`;
    //   newReceiptCustomFeeFooter = `
    //   <div class="product-tax-wrapper">
    //     <div>${LocalizedLanguage.producttotal}</div>
    //     <div>${parseFloat(_totalcustomeFee).toFixed(2)}</div>
    //   </div>
    // `;
  } else {
    customFeefooter = `<tr><td colspan="2"><div class="itemdiv"></div> </td></tr>
  <tfoot><tr><td></td></tr></tfoot>`;
  }
  //if (parseFloat(custFeeTax) > 0) {
  _CustomeFee =
    _CustomeFeeRow !== ''
      ? _CustomeFee + _CustomeFeeRow + customFeefooter + `</tbody></table>`
      : '';
  // } else {
  //   _CustomeFee = _CustomeFeeRow !== "" ? (_CustomeFee + _CustomeFeeRow + `</tbody></table>`) : "";
  // }
  _newReceiptCustomFee =
    _newReceiptCustomFee !== ''
      ? _newReceiptCustomFee + newReceiptCustomFeeFooter
      : '';

  DisplayTotalSplitTax.length > 0 &&
    DisplayTotalSplitTax.map((item, index) => {
      //display split tax if tax is more then 1
      var taxvalue = 0;
      // all_modifier_taxes && all_modifier_taxes !== "" && all_modifier_taxes.map(item1 => {
      //   Object.keys(item1).map(key => {
      //     if (item.id == key) {
      //       taxvalue += item1[key];
      //       // // console.log("--taxvalue--" + taxvalue);
      //     }
      //   });
      // });
      _onlyCustomFee &&
        _onlyCustomFee &&
        _onlyCustomFee.map((item1) => {
          item1.total_taxes &&
            item1.total_taxes.map((_item1) => {
              Object.keys(_item1).map((key) => {
                if (item.id == key) {
                  taxvalue += _item1[key];
                  // console.log("-- _onlyCustomFee taxvalue--" + taxvalue);
                }
              });
            });
        });
      //CUSTOM FEE TAX BEING ADDED
      if (taxvalue == 0 && _onlyCustomFee && _onlyCustomFee.length > 0) {
        _onlyCustomFee.map((item1) => {
          taxvalue += item1.total_tax;
        });
      }

      // if (DisplayTotalSplitTax && DisplayTotalSplitTax.length == 1) {
      //   taxvalue += all_modifier_tax;
      // }
      //all_modifier_tax
      splitTaxDetail += `<tr><td colspan="2">${item.tax} ${labelIncl}</td>
      <td align="right"> ${(
        parseFloat(item.value) +
        parseFloat(
          taxvalue + (index == 0 && shippingTax !== 0 ? shippingTax : 0)
        )
      ).toFixed(2)}</td>
      </tr>`;

      _new_receipt_splitTaxDetail += `<div class="amount-wrapper">
      <div class="amount-label">${item.tax} ${labelIncl}</div>
      <div class="amount-value">${(
        parseFloat(item.value) +
        parseFloat(
          taxvalue + (index == 0 && shippingTax !== 0 ? shippingTax : 0)
        )
      ).toFixed(2)}</div>
  </div>`;
    });

  var _Notes = ''; // '<table class="item-table"><tbody>'
  var _notesdisp = '';
  var notes_list = [];
  if (type == 'activity') {
    notes_list = getOrderNotes(data.meta_datas);
  } else {
    notes_list = data.order_notes
      ? data.order_notes.filter(
          (a) =>
            (!a.hasOwnProperty('pid') || a.pid == 0) &&
            !a.note.toLowerCase().match(/payment done with:/)
        )
      : [];
  }

  if (notes_list && notes_list.length > 0) {
    var parser = new DOMParser();

    notes_list &&
      notes_list.map((a) => {
        var doc = parser.parseFromString(a.note ? a.note : '', 'text/html');
        if (_notesdisp != '') {
          _notesdisp += ', ';
        }
        _notesdisp += a.note ? doc.body.innerHTML : '';
        _Notes += `<tr>
                <td>${a.note ? doc.body.innerHTML : ''}
                          </td>
                  </tr>`;
      });
  }
  // data.order_notes && data.order_notes.map((item_note, index) => {
  //   if ((item_note.hasOwnProperty("pid") && item_note.pid == 0) || !item_note.hasOwnProperty("pid")) {
  //     var parser = new DOMParser();
  //     var doc = parser.parseFromString(item_note.note ? item_note.note : "", "text/html");

  //     _notesdisp += item_note.note.toLowerCase().match(/payment done with:/) ? ''
  //       : item_note.is_extension_note && item_note.is_extension_note == true ? '' :
  //         `<tr>
  //               <td>${LocalizedLanguage.notes}</td>
  //               <td align="right">${item_note.note ? doc.body.innerHTML : ''}
  //                         </td>
  //                 </tr>`
  //     //for android............
  //     if (!item_note.note.toLowerCase().match(/payment done with:/) && !(item_note.is_extension_note && item_note.is_extension_note == true)) {
  //       receipt += LocalizedLanguage.notes + item_note.note ? item_note.note : '' + "\n";
  //       rowNumber += 1;
  //       PrintAndroidData.push({ "rn": rowNumber, "cms": 1, "c1": "Notes " + LocalizedLanguage.notes + item_note.note ? item_note.note : '', "c2": "", "c3": "", "bold": "0,0,0", "fs": "24", "alg": "0" });
  //     }
  //   }
  //   //---------------
  // })

  //_Notes = _notesdisp == "" ? "" : _Notes + _notesdisp;//+ "</tbody></table>"

  var getorderlist = data.meta_datas
    ? data.meta_datas.find(
        (data) => data.ItemName == '_order_oliverpos_product_discount_amount'
      )
    : null;
  var orderData = [];
  if (
    data.meta_datas !== null &&
    data.meta_datas !== '' &&
    getorderlist !== null
  ) {
    getorderlist =
      getorderlist &&
      getorderlist !== undefined &&
      getorderlist.ItemValue &&
      getorderlist.ItemValue !== undefined &&
      JSON.parse(getorderlist.ItemValue);
    getorderlist &&
      getorderlist.map((item, index) => {
        if (item.order_notes !== null) {
          item.order_notes &&
            item.order_notes.map((item, index) => {
              if (
                item.is_customer_note !== undefined &&
                item.is_customer_note !== null
              ) {
              } else {
                orderData.push(item);
              }
            });
        }
      });
  }

  var _PayNotes = '';
  orderData &&
    orderData.map((item_note, index) => {
      _PayNotes += item_note.note.match(/Extension/)
        ? ''
        : ` <tr  class="item-row">
                  <td>Notes </td>
                 <td colspan="3" class="description">${
                   item_note.note.match(/Extension/)
                     ? ' '
                     : LocalizedLanguage.notes + item_note.note
                     ? item_note.note
                     : ''
                 }
                 </td>
        </tr>`;
    });

  var payments = 0;
  var topLogo = '';
  var sideLogo = '';
  var total_cashround;
  var total_Tax;
  var total_discount = 0;
  var totalProdDisc = 0;
  var total_RedeemPoint = 0;
  var total_amount = 0;
  var tipPercent = 0;
  var refund_total_amount = 0;
  var refund_cash_rounding_amount = 0;
  var refunded_tax = 0;

  var refunded_amount = 0;
  var tax_refunded = 0;
  orderList &&
    orderList.map((item, index) => {
      //CHECK FOR THE CASH PAYMENT----
      if (item.payment_type && item.payment_amount > 0) {
        if (item.payment_type == 'cash') {
          isPaymentCash = true;
        }
      }

      payments +=
        item && item.payment_amount ? parseFloat(item.payment_amount) : 0;
    });
  var total_cashround =
    data && data.hasOwnProperty('totalPrice')
      ? payments - parseFloat(data.totalPrice).toFixed(2)
      : 0;
  if (total_cashround >= 1 || total_cashround <= -1) {
    total_cashround = 0;
  }
  if (
    total_cashround == 0 &&
    cash_rounding_amount &&
    cash_rounding_amount !== 0 &&
    cash_rounding_amount !== ''
  ) {
    total_cashround = cash_rounding_amount;
  }

  // console.log("Shop Name", data && data.ShopName);

  total_Tax = data
    ? type == 'activity' && data.total_tax
      ? parseFloat(RoundAmount(data.total_tax)).toFixed(2)
      : parseFloat(RoundAmount(data.tax ? data.tax : 0)).toFixed(2)
    : '0.00';
  if (type == 'activity' && data.total_tax) {
    total_Tax = parseFloat(RoundAmount(data.total_tax)).toFixed(2);
  }

  totalProdDisc = Total_IndividualProductDiscount
    ? parseFloat(Total_IndividualProductDiscount).toFixed(2)
    : 0;
  // var total_discount = data ? (data.discount ? parseFloat(data.discount - totalProdDisc) : parseFloat(data.discountCalculated) ? parseFloat(RoundAmount(data.discountCalculated) - totalProdDisc) : 0) : 0
  // total_discount = _itemData ? _cartDiscount : total_discount
  total_discount = _cartDiscount + parseFloat(totalProdDisc);
  total_discount += all_modifier_discount;
  total_RedeemPoint =
    (data.redeemedAmountPoints &&
      data.redeemedAmountPoints !== 0 &&
      data.redeemedAmountPoints !== undefined) ||
    (redeemPointsForActivity !== 0 && redeemPointsForActivity !== '')
      ? type == 'activity'
        ? parseInt(redeemPointsForActivity).toFixed(2)
        : parseFloat(RoundAmount(data.redeemedPoints)).toFixed(2)
      : 0;
  total_amount = parseFloat(
    type == 'activity'
      ? data.total_amount
      : data && data.totalPrice
      ? parseFloat(RoundAmount(parseFloat(data.totalPrice))) //+ parseFloat(total_cashround))).toFixed(2)
      : '0.00'
  );

  var total_cartDiscountPercentLable =
    /* order_reciept.PercentageDiscountOfEntireOrder == true ? */ '(' +
    (Order_cartDiscount && Order_cartDiscount.discountType == 'Percentage'
      ? Order_cartDiscount.discount_amount
      : ((total_discount * 100) / Order_subTotal).toFixed(2)) +
    '%)'; /* : ""*/
  //IF THERE IS WOOCOMMERCE DISCOUNT APPLIED
  if (data && data.isWebShopOrder && data.isWebShopOrder == true) {
    total_discount = getWCDiscount(data, data.discount, taxInclusiveName);
  }
  //END
  tipPercent =
    order_reciept.PercentageTipsOfEntireOrder == true
      ? '(' + ((_tipAmount * 100) / Order_subTotal).toFixed(2) + '%)'
      : '';

  refund_total_amount = data.refunded_amount && data.refunded_amount.toFixed(2);
  refund_cash_rounding_amount =
    data.refund_cash_rounding_amount &&
    data.refund_cash_rounding_amount.toFixed(2);
  refunded_tax = data.tax_refunded && data.tax_refunded.toFixed(2);

  //(pageSize.width == "80mm" || pageSize.width == "52mm") ?

  // commented By: Nagendra
  // Desc: Creating issue for Inclusive Tax Shop.
  // if (type == 'activity' && taxInclusiveName !== "") {//fixing the issue for garmany client
  //   Order_subTotal += parseFloat(total_Tax);
  // }
  //Payments
  if (order_payments && order_payments.length > 0) {
    order_payments.map((item, index) => {
      if (item.type !== null && item.amount !== 0) {
        let _emvData = [];
        if (item.description && item.description !== '') {
          try {
            let _desc = JSON.parse(item.description);
            for (var key in _desc) {
              _emvData.push({
                rn: rowNumber,
                cms: 2,
                c1: key,
                c2: _desc[key],
                c3: '',
                bold: '0,0,0',
                fs: '24',
                alg: '0,2',
              });
            }
          } catch (e) {
            // console.log(e)
          }
        }
      }
    });
  }

  //WHEN CASH_ROUND AMOUNT IS MORE THAN 1 THEN ADJUSTING THE CALCULATION 28/08/23
  if (
    total_cashround &&
    total_cashround > 1 &&
    data.order_status === 'completed' &&
    type == 'activity'
  ) {
    var new_amount = 0;
    if (order_payments && order_payments.length > 0) {
      order_payments.map((b) => (new_amount += b.amount));
    }
    if (
      parseFloat(total_amount - total_cashround).toFixed(2) ==
      parseFloat(new_amount).toFixed(2)
    ) {
      total_amount = total_amount - total_cashround;
      total_cashround = 0;
    }
  }
  //END

  // ...........................................
  Order_subTotal += all_modifier_total;
  //${order_reciept.PercentageTaxOfEntireOrder == true ? order_totalTaxPercent : ''}
  var _total_mm = `<table class="item-total">
              <tbody><tr>
                        <td colspan="2">${
                          order_reciept.SubTotal
                            ? order_reciept.SubTotal
                            : `${labelsubTotal}`
                        }</td>
                        <td align="right"> ${Order_subTotal.toFixed(2)}</td>
                      </tr>
                      ${
                        shippingAmount > 0
                          ? `<tr>
                        <td colspan="2">${LocalizedLanguage.shippingAmount}</td>
                        <td align="right">${parseFloat(
                          RoundAmount(shippingAmount)
                        ).toFixed(2)}
                          </td>
                      </tr>`
                          : ''
                      }
      ${taxrate_name !== '' ? taxrate_name : ''}
      ${
        total_discount > 0
          ? `<tr>
        <td colspan="2">${labelTotalDiscount} </td>
        <td align="right">-${total_discount.toFixed(2)}
           </td></tr>`
          : ''
      }
      ${splitTaxDetail}
      ${
        total_RedeemPoint !== 0
          ? `<tr>
      <td colspan="2">${LocalizedLanguage.redeemedpoint}</td>
      <td align="right">${total_RedeemPoint}</td>
      </tr>`
          : ''
      }


        ${
          total_cashround && total_cashround !== 0 && total_cashround !== '0.00'
            ? `<tr>
              <td colspan="2">${LocalizedLanguage.cashRounding}</td>
              <td align="right">${parseFloat(
                RoundAmount(total_cashround)
              ).toFixed(2)}
              </td>
          </tr>`
            : ''
        }

        ${
          _tipAmount && _tipAmount !== 0
            ? `<tr>
            <td colspan="2">${_tipLable}${tipPercent}</td>
            <td align="right">${parseFloat(RoundAmount(_tipAmount)).toFixed(2)}
              </td>
          </tr>`
            : ''
        }

       ${
         order_reciept.ShowTax == false
           ? `<tr id="hiderow">
        <td colspan="2" style="border-left: 0px; border-right: 0px;">${
          order_reciept.ShowTax == false
            ? order_reciept.TaxIncludedDisclaimer
              ? order_reciept.TaxIncludedDisclaimer
              : ''
            : ''
        }</td>
       </tr> `
           : ''
       }
  <tr class="border-bottom">
        <td colspan="2">${labelTotalOrder}</td>
      <td align="right"> ${total_amount.toFixed(2)}</td>
       </tr>

 </tbody>
         </table>
        
         `;
  //warapper Items---------
  //var _currency = (localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).currency && (JSON.parse(localStorage.getItem('user')).currency).toLowerCase() : 'usd');
  var _currency = 'usd';
  var _clientDetail = localStorage.getItem('clientDetail')
    ? JSON.parse(localStorage.getItem('clientDetail'))
    : null;

  if (
    _clientDetail &&
    _clientDetail.shop_settings &&
    _clientDetail.shop_settings.shop_currency
  ) {
    if (
      _clientDetail.shop_settings.shop_currency !== null &&
      _clientDetail.shop_settings.shop_currency !== ''
    )
      _currency = _clientDetail.shop_settings.shop_currency.toLowerCase();
  }

  var user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : '';
  WrapperAppData['subtotal'] = Order_subTotal;
  WrapperAppData['orderDiscount'] = {
    percent: '',
    amount: total_discount.toFixed(2),
  };
  //WrapperAppData['orderTax'] ={ "percent":'',"amount": total_discount.toFixed(2)};
  WrapperAppData['shippingAmount'] = {
    amount: shippingAmount.toFixed(2),
    currency: _currency,
  };

  WrapperAppData['orderTotal'] = {
    amount: total_amount.toFixed(2),
    currency: _currency,
  };
  WrapperAppData['orderNotes'] = _notesdisp;
  WrapperAppData['returnPolicy'] = '';
  if (
    order_reciept.ShowReturnPolicy &&
    order_reciept.ShowReturnPolicy == true &&
    _returnPolicyText !== ''
  ) {
    WrapperAppData['returnPolicy'] = _returnPolicyText;
  }
  WrapperAppData['fidMarkers'] = '';
  WrapperAppData['markerOption'] =
    print_bar_code != '' &&
    typeof print_bar_code != 'undefined' &&
    print_bar_code != null &&
    print_bar_code.includes('base64') > 0
      ? 0
      : 1;
  if (order_reciept.ShowBarcode == true && print_bar_code) {
    WrapperAppData['fidMarkers'] = print_bar_code;
  }
  WrapperAppData['storeUrl'] = '';
  if (
    siteName &&
    siteName.subscription_detail &&
    siteName.subscription_detail !== ''
  )
    WrapperAppData['storeUrl'] = siteName.subscription_detail.url;

  WrapperAppData['address'] = {
    lineOne: user.shop_address1 ? user.shop_address1 : '',
    lineTwo: user.shop_address2 ? user.shop_address2 : '',
    zip: user.shop_postcode ? user.shop_postcode : '',
    country: user.shop_country_full_Name ? user.shop_country_full_Name : '',
  };

  var WPTaxes = [];
  DisplayTotalSplitTax &&
    DisplayTotalSplitTax.map((taxItem) => {
      WPTaxes.push({
        name: taxItem.hasOwnProperty('tax_name')
          ? taxItem.tax_name
          : taxItem.tax,
        percent: taxItem.wpTaxRate,
        amount: taxItem.value,
      });
    });
  WrapperAppData['orderTax'] = [];
  WrapperAppData['taxes'] = [];
  if (taxInclusiveName.toLowerCase().includes('incl') == false) {
    WrapperAppData['orderTax'] = WPTaxes;
    WrapperAppData['taxes'] = WPTaxes;
  }
  WrapperAppData['tips'] = '';
  if (_tipAmount && _tipAmount !== 0) {
    WrapperAppData['tips'] = { percent: tipPercent, amount: _tipAmount };
  }
  WrapperAppData['total'] = total_amount;

  // if (taxInclusiveName.toLowerCase().includes("incl") == true) {
  //   WrapperAppData['orderTax'] = WPTaxes;
  // }
  //------------------------

  var _footer = '';
  var refundOrderNotes = [];
  var barcodewidth =
    pageSize.width !== '80mm' && pageSize.width !== '52mm'
      ? "'padding:0px ; width:" +
        realWidth +
        " ;height:60px;image-rendering: pixelated;'"
      : "'padding:0px ; width:80% ; height:60px;image-rendering: pixelated; image-rendering: auto;image-rendering:-moz-crisp-edges;image-rendering: crisp-edges;image-rendering: pixelated;'";
  _footer = ` <div>
            <table class="footer-informations">
            ${
              order_reciept.ShowOrderNotes &&
              order_reciept.ShowOrderNotes == true
                ? _Notes != ''
                  ? `<tr><td>*` +
                    LocalizedLanguage.orderNotes.toUpperCase() +
                    `*</td></tr>` +
                    _Notes
                  : ''
                : ``
            }

              ${
                order_reciept.ShowReturnPolicy &&
                order_reciept.ShowReturnPolicy == true &&
                _returnPolicyText !== ''
                  ? ` <tr><td> ${_returnPolicyText}</td></tr>`
                  : ``
              }
              ${
                order_reciept.ShowBarcode == true && print_bar_code
                  ? `<tr><td><img src=${
                      order_reciept.ShowBarcode == true ? print_bar_code : ''
                    } style=${
                      order_reciept.ShowBarcode == true ? barcodewidth : ''
                    }/></td></tr> `
                  : ``
              }
                <tr><td>
                ${
                  order_reciept && order_reciept.ShowWebsiteLink == true
                    ? site_name
                    : ''
                }
                </td></tr>
                ${
                  address &&
                  (address.address_1 !== '' ||
                    address.address_2 !== '' ||
                    address.zip !== '' ||
                    address.city !== '' ||
                    address.country_name !== '')
                    ? `<tr><td>
                              ${
                                address && address.address_1
                                  ? address.address_1
                                  : ''
                              } ${
                        address && address.address_2 ? address.address_2 : ''
                      }
                              ${address && address.zip ? address.zip : ''} ${
                        address && address.city ? ', ' + address.city : ''
                      } ${
                        address && address.country_name
                          ? ', ' + address.country_name
                          : ''
                      }
                              </td></tr>`
                    : ``
                }


    </table>
 </div>`;

  var _externalApp =
    displayExtensionAppData && displayExtensionAppData !== null
      ? ` <div >
  <table class="additional-informations">
      <tr>
          <td>
          ${
            displayExtensionAppData &&
            displayExtensionAppData !== null &&
            displayExtensionAppData.url
              ? `<img src=${displayExtensionAppData.url} style= 'padding:${
                  pageSize.width == '80mm'
                    ? '2px'
                    : pageSize.width == '52mm' || pageSize.width == '58mm'
                    ? '2px'
                    : '5px'
                };'   class='pagesize'>`
              : `App to add <br> information here.`
          }  </td>
          </tr>
      </table>
  </div>`
      : '';
  const refundItemDetailRender = (
    refund_lineitem_Title,
    lineitem_pricePerQuantity,
    itemDiscount,
    CartDiscount,
    itemDiscountLabel,
    CartDiscountLabel,
    refundItemTax,
    refund_lineitem_Total,
    RefundTotalTax,
    item,
    lineitem_TotalQty,
    refundItemPrice,
    labelIncl = '',
    modifers,
    addons,
    new_print = false
  ) => {
    var local_refundItemDetail = '';
    var local_newRefundItemDetail = '';

    local_refundItemDetail += `<table class="item-table">
    <tbody><tr>
      <td> ${refund_lineitem_Title}</td>
      <td   align="right"> ${
        item.Quantity && lineitem_TotalQty > 1
          ? `<div class="item-quantity;padding-right:10px">(${
              item.Quantity
            } * ${lineitem_pricePerQuantity.toFixed(2)})</div>`
          : ''
      }</td>
      <td align="right">${refundItemPrice.toFixed(2)}</td>
    </tr>`;

    local_newRefundItemDetail += `
      <div class="product full-width">
        <div class="product-wrapper y-pad-small normal">
          <div class="bold">${refund_lineitem_Title}</div>
          <div class="product-price-wrapper">
          ${
            item.Quantity && lineitem_TotalQty > 1
              ? `<div class="multiple-product">(${
                  item.Quantity
                } x ${lineitem_pricePerQuantity.toFixed(2)})</div>`
              : ''
          }
            <div class="normal">${refundItemPrice.toFixed(2)}</div>
          </div>
        </div>
    
    `;

    var _modifier_total = 0;

    var addons_html = '<table class="item-table"><tbody>';

    addons &&
      addons.map((i, index) => {
        var _addTitle = i.title ? i.title.replace(/_/g, ' ') : '';
        _addTitle += i.sub_title
          ? '(' + i.sub_title.replace(/_/g, ' ') + ')'
          : '';

        addons_html += `<tr>
      <td> + ${_addTitle}</td>
      <td align="right">${
        i.amount && i.amount !== ''
          ? ' + ' + parseFloat(i.amount).toFixed(2)
          : ''
      }</td>
    </tr>`;
      });
    addons_html += `</tbody></table>`;

    var modifiers_html = '<table class="item-table"><tbody>';
    var newReceiptModifierHTML = `<div class="modifiers y-pad-small x-pad-small">`;

    modifers &&
      modifers.map((item_fee, index) => {
        if (item_fee.hasOwnProperty('pid') == true) {
          _modifier_total += item_fee.amount_refunded;
          refund_lineitem_Total += item_fee.amount_refunded;
          // var _mod_tax = 0;
          var _tax = 0;
          if (
            taxInclusiveName != '' &&
            taxInclusiveName != 'tax' &&
            taxInclusiveName != 'Tax' &&
            item_detail
          ) {
            var _tax =
              (item_fee.amount / item_fee.quantity) * item.Quantity -
              item_fee.amount_refunded;
            // _mod_tax = item_fee.total_tax / lineitem_TotalQty;
            // _mod_tax = _mod_tax * item.Quantity;
            _modifier_total += _tax;
            refund_lineitem_Total += _tax;
          }
          //refund_lineitem_Total += taxInclusiveName == '' ? 0 : item_fee.total_tax;
          // _modifier_total += item_fee.amount;
          // _modifier_total_tax += item_fee.total_tax;
          //_taxArray.push(item_fee.total_taxes);
          // if (_taxArray.length > 0) {
          //   _taxArray = [..._taxArray, ...item_fee.total_taxes]
          // }
          // else { _taxArray = item_fee.total_taxes; }

          modifiers_html += `<tr>
      <td>${
        item_fee.note ? item_fee.note : item_fee.Title ? item_fee.Title : ''
      }</td>
      <td align="right">${parseFloat(item_fee.amount_refunded + _tax).toFixed(
        2
      )}</td>
    </tr>`;

          newReceiptModifierHTML += `
        <div class="modifiers-wrapper">
          <div>+ ${
            item_fee.note ? item_fee.note : item_fee.Title ? item_fee.Title : ''
          }</div>
          <div>+${parseFloat(item_fee.amount_refunded + _tax).toFixed(2)}</div>
        </div>`;
          //       if (order_reciept.IndividualizedTaxAmountPerItem == true || order_reciept.PercentageTaxPerItem == true) {
          //         modifiers_html += `<tr>
          //   <td>${taxInclusiveName !== '' ? 'Tax (Incl.)' : 'Tax'}</td>
          //   <td align="right">${parseFloat(item_fee.total_tax).toFixed(2)}</td>
          // </tr>`;
          //       }
        }
      });

    modifiers_html += `</tbody></table>`;

    newReceiptModifierHTML += `</div>`;

    local_refundItemDetail +=
      addons_html && addons_html !== ''
        ? `<tr><td colspan="3">${addons_html}</td></tr>`
        : ``;
    local_refundItemDetail +=
      modifiers_html && modifiers_html !== ''
        ? `<tr><td colspan="3">${modifiers_html}</td></tr>`
        : ``;

    local_newRefundItemDetail +=
      newReceiptModifierHTML && newReceiptModifierHTML !== ''
        ? newReceiptModifierHTML
        : '';

    local_refundItemDetail +=
      /* ( order_reciept.PercentageDiscountPerItem == true || order_reciept.AmountDiscountPerItem == true) && */ itemDiscount ||
      CartDiscount
        ? `<tr><td colspan="3"><div class="itemdiv">
                            <table class="innertable">
                                  <tfoot>
                                 ${
                                   itemDiscount > 0
                                     ? `<tr>
                                      <td>${itemDiscountLabel}</td>
                                      <td align="right">-${itemDiscount.toFixed(
                                        2
                                      )}</td>
                                  </tr>`
                                     : ''
                                 }
                                  ${
                                    CartDiscount > 0
                                      ? `<tr>
                                      <td>${CartDiscountLabel}</td>
                                      <td align="right">-${CartDiscount.toFixed(
                                        2
                                      )}</td>
                                    </tr>`
                                      : ''
                                  }
                                  </tfoot>

                            </table>
                        </div>
                    </td>
                </tr>`
        : ``;

    local_newRefundItemDetail +=
      itemDiscount || CartDiscount
        ? `
        <div class="item-discount-container">
         ${
           itemDiscount > 0
             ? `
          <div class="item-discount-wrapper">
            <div>${itemDiscountLabel}</div>
            <div>-${itemDiscount.toFixed(2)}</div>
          </div>
         `
             : ''
         }
  
         ${
           CartDiscount > 0
             ? `
          <div class="item-discount-wrapper">
            <div>${CartDiscountLabel}</div>
            <div>-${CartDiscount.toFixed(2)}</div>
          </div>
          `
             : ''
         }
        </div>
      `
        : '';

    // ***Refund Item Tax*****************************
    if (
      order_reciept.IndividualizedTaxAmountPerItem == true ||
      order_reciept.PercentageTaxPerItem == true
    ) {
      local_refundItemDetail +=
        refundItemTax && refundItemTax.length > 0
          ? `<tr><td colspan="3"><div class="itemdiv">
                    <table class="innertable">`
          : ``;

      local_newRefundItemDetail +=
        refundItemTax && refundItemTax.length > 0
          ? `
          <div class="product-tax">
        `
          : '';

      refundItemTax &&
        refundItemTax.length > 0 &&
        refundItemTax.map((_tax) => {
          var _taxvalue = 0;
          if (modifers && modifers.length > 0) {
            modifers.map((a) => {
              a.total_taxes.map((key) => {
                Object.keys(key).map((id) => {
                  if (_tax.id == id) {
                    _taxvalue += key[id];
                  }
                });
              });
            });
          }
          local_refundItemDetail += `<tr>
                    <td colspan="2">${_tax.tax} ${labelIncl}</td>
                    <td align="right"> ${(
                      parseFloat(_tax.value) + parseFloat(_taxvalue)
                    ).toFixed(2)}</td>
                    </tr>`;

          local_newRefundItemDetail += `
                    <div class="product-tax-wrapper">
                      <div>${_tax.tax} ${labelIncl}</div>
                      <div>${(
                        parseFloat(_tax.value) + parseFloat(_taxvalue)
                      ).toFixed(2)}</div>
                    </div>
                    `;
        });

      local_refundItemDetail +=
        refundItemTax && refundItemTax.length > 0
          ? `</tfoot></table></div> </td></tr>`
          : ``;

      local_newRefundItemDetail +=
        refundItemTax && refundItemTax.length > 0 ? `</div>` : '';
    } else {
      local_refundItemDetail += `<tr><td colspan="3"><div class="itemdiv"></div> </td></tr>`;
    }

    local_newRefundItemDetail += `<hr class="divider" />`;
    // *** Refund Item Tax End *********************
    // local_refundItemDetail += !refundItemTax || refundItemTax.length == 0 ? `<div class="itemdiv"><table class="innertable">`: ``
    local_refundItemDetail += `
    <tr>
                          <td colspan='2'>
                          ${LocalizedLanguage.producttotal}
                          </td>
                          <td align="right">${parseFloat(
                            refund_lineitem_Total
                          ).toFixed(2)}</td>
                          </tr>
                   `;

    local_newRefundItemDetail += `
    <div class="product-total">
      <div class="product-total-wrapper">
        <div>${LocalizedLanguage.producttotal}</div>
        <div class="bold">${parseFloat(refund_lineitem_Total).toFixed(2)}</div>
      </div>
    </div>
    `;

    // local_refundItemDetail += !refundItemTax || refundItemTax.length == 0 ?`</div></table > `:``
    local_refundItemDetail += `</tbody></table > `;

    refundOrderNotes &&
      refundOrderNotes.map((item_note, index) => {
        local_refundItemDetail +=
          item_note.note &&
          item_note.note.toLowerCase().match(/payment done with:/)
            ? ''
            : item_note.is_extension_note &&
              item_note.is_extension_note == false
            ? ''
            : `
                   <table class="item-table"><tbody><tr>
                    <td>${LocalizedLanguage.note}</td>
                    <td align="right">${
                      item_note.note ? item_note?.note : ''
                    }</td>
                  </tr></tbody></table>
                `;
      });

    local_refundItemDetail += `</tbody></table > `;
    local_newRefundItemDetail += `</div>`;

    new_print
      ? (newReceiptRefundDetail += local_newRefundItemDetail)
      : (refundItemDetail += local_refundItemDetail);
  }; // END METHOD refundItemDetailRender

  var refundItemDetail = '';
  var newReceiptRefundDetail = '';

  var metaData = data && data.meta_datas ? data.meta_datas : [];
  var RefundItemLog = [];

  metaData &&
    metaData.length > 0 &&
    metaData.map((metaData, index) => {
      if (metaData.ItemName == '_order_oliverpos_refund_log') {
        RefundItemLog =
          metaData.ItemValue && metaData.ItemValue !== ''
            ? JSON.parse(metaData.ItemValue)
            : '';
      }
    });

  RefundItemLog &&
    RefundItemLog.map((item) => {
      refundOrderNotes = item.Refund_data_collection.order_notes.filter(
        (item) => typeof item.note === 'string'
      );
    });
  // console.log("refundOrderNotes",refundOrderNotes)
  var isAnyRefund =
    item_detail && item_detail.filter((a) => Math.abs(a.quantity_refunded) > 0);
  var isOldRefund = false;
  if (
    (!RefundItemLog || RefundItemLog == '' || RefundItemLog.length == 0) &&
    typeof isAnyRefund !== 'undefined' &&
    isAnyRefund.length > 0
  ) {
    //Old Order Refunds handle
    isOldRefund = true;
    //refundItemDetail += `<table><tr><td><tfoot><h2 style="text-align: center; margin-top:20px">*** Refund ***</h2></tfoot></td></tr></table>`
    //androidRefundHeaderTitle();
    //refundItemDetail += GetHeaderDetail;
    refundItemDetail += refunHeaderDetailRender(null);
    newReceiptRefundDetail += refunHeaderDetailRender(null, true);

    var RefundTotalTax = [];
    refundSubTotal = 0;
    var discountAfterRefund = 0;
    for (var key in refundLineItemDetail) {
      // var item = refundLineItemDetail[key];
      // RefundItems && RefundItems.map(item => {
      var refund_lineitem_Title = '';
      var lineitem_pricePerQuantity = 0;
      var lineitem_TotalQty = 0;
      var itemDiscountLabel = '';
      var CartDiscountLabel = '';
      var itemDiscount = 0;
      var CartDiscount = 0;
      var refundItemTax = null;
      var refund_lineitem_Total = 0;
      var refundedQuantity = 0;
      var item_tax = 0;
      var _refundItem = refundLineItemDetail[key];
      var pricePerQuantity = _refundItem.hasOwnProperty('pricePerQuantity')
        ? _refundItem.pricePerQuantity
        : _refundItem.amount;
      // for (var key in refundLineItemDetail) {
      //console.log("key: " + key + " value: " + JSON.parse(refundLineItemDetail[key]) + "\n");

      //if (_refundItem.productId == item.product_id) {
      refundedQuantity = _refundItem.hasOwnProperty('refundedQuantity')
        ? Math.abs(_refundItem.refundedQuantity)
        : -1;
      refund_lineitem_Title = _refundItem.hasOwnProperty('title')
        ? _refundItem.title
        : _refundItem.note;
      lineitem_pricePerQuantity = pricePerQuantity;
      itemDiscountLabel = _refundItem.hasOwnProperty('itemDiscountLabel')
        ? _refundItem.itemDiscountLabel
        : LocalizedLanguage.itemDiscount;
      CartDiscountLabel = _refundItem.hasOwnProperty('CartDiscountLabel')
        ? _refundItem.CartDiscountLabel
        : LocalizedLanguage.cartdiscount;
      refundSubTotal += refundedQuantity * pricePerQuantity; //_refundItem.pricePerQuantity; _refundItem.pricePerQuantity;
      refundItemTax = []; //= _refundItem.refundTaxType;
      lineitem_TotalQty = _refundItem.hasOwnProperty('totalQuantity')
        ? _refundItem.totalQuantity
        : 1;
      itemDiscount = _refundItem.hasOwnProperty('itemDiscount')
        ? (_refundItem.itemDiscount / lineitem_TotalQty) * refundedQuantity
        : 0;
      CartDiscount = _refundItem.hasOwnProperty('CartDiscount')
        ? (_refundItem.CartDiscount / lineitem_TotalQty) * refundedQuantity
        : 0; //Calculate discount for refunded item

      discountAfterRefund += itemDiscount + CartDiscount;

      refund_lineitem_Total = refundedQuantity * pricePerQuantity;
      refund_lineitem_Total -= itemDiscount;
      refund_lineitem_Total -= CartDiscount;
      var taxes = _refundItem.taxes;
      //taxes && taxes.length > 0 && taxes.map(ltax => {
      _refundItem.taxes &&
        _refundItem.taxes !== '' &&
        Object.keys(_refundItem.taxes).map((tkey) => {
          var taxvalue = _refundItem.taxes[tkey];
          taxvalue =
            (taxvalue / _refundItem.totalQuantity) *
            Math.abs(_refundItem.refundedQuantity);
          data.order_taxes &&
            data.order_taxes.map((tm) => {
              // taxRate && taxRate.map(tm=>{
              if (tm.RateId == tkey) {
                //ADDING MODIFER TAX TO THE PRODUCT TAX ACCORDING TO THEIR CLASS --START 14022023
                var _taxvalue = 0;
                all_modifier_taxes &&
                  all_modifier_taxes !== '' &&
                  all_modifier_taxes.map((item1) => {
                    Object.keys(item1).map((key) => {
                      if (tm.RateId == key) {
                        _taxvalue += item1[key];
                        // console.log("--taxvalue--" + _taxvalue);
                      }
                    });
                  });
                //ADDING MODIFER TAX TO THE PRODUCT TAX ACCORDING TO THEIR CLASS --END 14022023
                var taxname = tm.Title; //tm.TaxName;
                var TaxRate = calculateTaxRate(
                  parseFloat(
                    lineitem_pricePerQuantity -
                      (taxInclusiveName !== '' ? parseFloat(taxvalue) : 0) *
                        refundedQuantity
                  ),
                  parseFloat(taxvalue)
                );
                if (lineitem_pricePerQuantity == 0) {
                  TaxRate = 0;
                }
                if (order_reciept) {
                  if (order_reciept.PercentageTaxPerItem == true) {
                    taxname += '(' + TaxRate + ' %)';
                  }
                  //refundItemTax.push({ "tax": taxname, "value": taxvalue + _taxvalue })
                  refundItemTax.push({
                    tax: taxname,
                    value: taxvalue + _taxvalue,
                    wpTaxName: tm.Title,
                    wpTaxRate: TaxRate,
                    id: tm.RateId,
                  });
                }
                var found = false;
                RefundTotalTax &&
                  RefundTotalTax.length > 0 &&
                  RefundTotalTax.map((tTax) => {
                    if (tTax.tax == taxname) {
                      //ADDING MODIFER TAX TO THE PRODUCT TAX ACCORDING TO THEIR CLASS --START 14022023
                      var _taxvalue = 0;
                      all_modifier_taxes &&
                        all_modifier_taxes !== '' &&
                        all_modifier_taxes.map((item1) => {
                          Object.keys(item1).map((key) => {
                            if (tTax.RateId == key) {
                              _taxvalue += item1[key];
                              // console.log("--REFUND TOTAL taxvalue--" + _taxvalue);
                            }
                          });
                        });
                      //ADDING MODIFER TAX TO THE PRODUCT TAX ACCORDING TO THEIR CLASS --END 14022023

                      tTax.value += parseFloat(taxvalue);
                      found = true;
                    }
                  });
                if (found == false) {
                  RefundTotalTax.push({
                    tax: taxname,
                    value: taxvalue + _taxvalue,
                    RateId: tm.RateId,
                  });
                }
                item_tax += parseFloat(taxvalue);
                //refund_lineitem_Total += parseFloat(taxvalue);
              }
            });
        });
      // })

      // }

      // console.log("--taxvalue--" + JSON.stringify(all_modifier_taxes))

      //     DisplayTotalSplitTax.length > 0 && DisplayTotalSplitTax.map(item => {  //display split tax if tax is more then 1
      // var taxvalue=0;
      // all_modifier_taxes && all_modifier_taxes !== "" && all_modifier_taxes.map(item1 => {
      //   Object.keys(item1).map(key => {
      //       if(item.id==key)
      //       {
      //          taxvalue += item1[key];
      //          // console.log("--taxvalue--" + taxvalue);
      //       }
      //     });
      //   });
      //   splitTaxDetail += `<tr><td colspan="2">${item.tax} ${labelIncl}</td>
      //   <td align="right"> ${(parseFloat(item.value)+parseFloat(taxvalue)).toFixed(2)}</td>
      //   </tr>`
      // })
      refund_lineitem_Total += taxInclusiveName != '' ? 0 : item_tax;
      // console.log('refundItemTax', refundItemTax)
      var refundItemPrice = refundedQuantity * lineitem_pricePerQuantity;

      //-----Android
      // console.log("refund_lineitem_Title", refund_lineitem_Title)
      refundItemDetailRender(
        refund_lineitem_Title,
        lineitem_pricePerQuantity,
        itemDiscount,
        CartDiscount,
        itemDiscountLabel,
        CartDiscountLabel,
        refundItemTax,
        refund_lineitem_Total,
        RefundTotalTax,
        { Quantity: refundedQuantity },
        lineitem_TotalQty,
        refundItemPrice,
        labelIncl
      );

      //-----Android
      //warapper Items---------
      WrapperAppData.refundItems.push({
        name: refund_lineitem_Title,
        quantity: lineitem_pricePerQuantity,
        tax: RefundTotalTax,
        //"discount":{ "lineItemDiscount": itemDiscount ,"cartDiscount": CartDiscount},
        total: refund_lineitem_Total,
      });

      //------------------------
    }

    var displayRefundPayment = '';
    var refundPay = '';
    var isPaymentCash = false;
    var refundpayments = data ? data.order_Refund_payments : '';
    refundpayments &&
      refundpayments.map((item, index) => {
        //CHECK FOR THE CASH PAYMENT----
        if (item.type && item.amount > 0) {
          if (item.type == 'cash') {
            isPaymentCash = true;
          }
        }

        var localDate = FormateDateAndTime.formatDateAndTime(
          item.payment_date,
          data.time_zone
        );
        //var localDate = moment(item.payment_date).format(orderRecieptDateFormate) // CHANGE DATE FORMATE AS PER THE HUB SETTING
        var paytype =
          payment_TypeName &&
          payment_TypeName.filter((itm) => {
            return itm.Code == item.type;
          });
        var paymentName =
          item.type !== 'store-credit'
            ? paytype && paytype.length > 0
              ? paytype[0].Name
              : item.type
              ? item.type
              : ''
            : 'store-credit';
        refundPay =
          refundPay +
          ' <tr><td >' +
          paymentName +
          '(' +
          localDate +
          ')</td>' +
          '<td class="total-value balance" colspan="2"><div class="due" align="right">' +
          item.amount.toFixed(2) +
          '</td></tr> ';
      });

    //-Android
    //End Refund Total------------------
    refundItemDetail += getRefundTotalDetail(
      order_reciept,
      data.refunded_amount,
      refundSubTotal,
      RefundTotalTax,
      data.refund_cash_rounding_amount,
      labelsubTotal,
      discountAfterRefund,
      '',
      PrintAndroidData,
      rowNumber,
      labelIncl
    );
    //End Refund Total-------------------

    if (refundPay !== '') {
      refundItemDetail +=
        '<table class="item-details-total" style="margin-top:0"><tbody><tfoot>';
      refundItemDetail +=
        '<tr><td colspan="2">Refund Payments</td></tr><tr><td colspan="2"><div class="double-border"></div></td></tr>' +
        refundPay;
      refundItemDetail +=
        '<tr><td colspan="2"><div class="double-border"></div></td></tr></tfoot></tbody></table>';
    }
  } else if (
    RefundItemLog &&
    RefundItemLog !== '' &&
    RefundItemLog.length > 0
  ) {
    ///****** Start refund Item ******************************* */

    RefundItemLog &&
      RefundItemLog.map((refundlog) => {
        var discountAfterRefund = 0;
        var RefundTotalTax = [];
        //refundItemDetail += `<table><tr><td><tfoot><h2 style="text-align: center; margin-top:20px">*** Refund ***</h2></tfoot></td></tr></table>`
        //androidRefundHeaderTitle();
        refundItemDetail += refunHeaderDetailRender(refundlog);
        newReceiptRefundDetail += refunHeaderDetailRender(refundlog, true);
        var RefundItems =
          refundlog.Refund_data_collection &&
          refundlog.Refund_data_collection.RefundItems;
        var refundProductTotal = 0;
        var refundSubTotal = 0;

        RefundItems &&
          RefundItems.map((item) => {
            var refund_lineitem_Title = '';
            var lineitem_pricePerQuantity = 0;
            var lineitem_TotalQty = 0;
            var itemDiscountLabel = '';
            var CartDiscountLabel = '';
            var itemDiscount = 0;
            var CartDiscount = 0;
            var refundItemTax = null;
            var refund_lineitem_Total = 0;

            for (var key in refundLineItemDetail) {
              //console.log("key: " + key + " value: " + JSON.parse(refundLineItemDetail[key]) + "\n");
              var _refundItem = refundLineItemDetail[key];

              // if ((_refundItem.hasOwnProperty("fee_id") ? _refundItem.fee_id : _refundItem.line_item_id) == item.item_id) {//for Custom Fee
              if (
                _refundItem.hasOwnProperty('item_id') &&
                _refundItem.item_id == item.item_id &&
                (_refundItem.hasOwnProperty('modifiers_key') &&
                item.hasOwnProperty('modifiers_key')
                  ? _refundItem.modifiers_key == item.modifiers_key
                  : true)
              ) {
                //for Custom Fee
                refund_lineitem_Title = _refundItem.hasOwnProperty('note')
                  ? _refundItem.note
                  : _refundItem.title;
                lineitem_pricePerQuantity = 1;
                // itemDiscount=0,
                // CartDiscount=0,
                // itemDiscountLabel,
                // CartDiscountLabel,
                // refundItemTax = _refundItem.hasOwnProperty("total_tax") ? _refundItem.total_tax : _refundItem.refundTaxType;
                // refund_lineitem_Total = _refundItem.hasOwnProperty("amount") ? _refundItem.amount : _refundItem.pricePerQuantity;
                // RefundTotalTax = _refundItem.hasOwnProperty("total_tax") ? _refundItem.total_tax : _refundItem.refundTaxType;
                // lineitem_TotalQty = 1;
                // refundSubTotal += _refundItem.hasOwnProperty("amount") ? _refundItem.amount : _refundItem.pricePerQuantity;

                refund_lineitem_Title = _refundItem.note;
                refundItemTax = _refundItem.total_tax;
                refund_lineitem_Total = _refundItem.amount;
                if (_refundItem.hasOwnProperty('refundTaxType')) {
                  RefundTotalTax = _refundItem.hasOwnProperty('refundTaxType')
                    ? _refundItem.refundTaxType
                    : _refundItem.total_tax;
                }
                lineitem_TotalQty = 1;
                refundSubTotal += _refundItem.amount;
              } else if (
                (_refundItem.productId == item.product_id ||
                  _refundItem.line_item_id == item.item_id) &&
                (_refundItem.hasOwnProperty('modifiers_key') &&
                item.hasOwnProperty('modifiers_key')
                  ? _refundItem.modifiers_key == item.modifiers_key
                  : true)
              ) {
                refund_lineitem_Title = _refundItem.title;
                lineitem_pricePerQuantity = _refundItem.pricePerQuantity;
                itemDiscountLabel = _refundItem.itemDiscountLabel;
                CartDiscountLabel = _refundItem.CartDiscountLabel;
                if (item.item_type == 'product') {
                  refundSubTotal +=
                    item.Quantity * _refundItem.pricePerQuantity;

                  //ADDING MODIFIERS AMOUNT TO SUBTOTAL
                  var _foundTaxInLog = RefundItems.filter(
                    (a) =>
                      a.modifiers_key == item.modifiers_key &&
                      a.item_type == 'customFee'
                  );
                  if (_foundTaxInLog && _foundTaxInLog != null) {
                    _foundTaxInLog.map((reItem) => {
                      refundSubTotal += reItem.amount;
                      if (
                        taxInclusiveName != '' &&
                        taxInclusiveName != 'tax' &&
                        taxInclusiveName != 'Tax' &&
                        item_detail
                      ) {
                        refundSubTotal += reItem.tax;
                      }
                    });
                  }
                }
                //refundSubTotal += item.Quantity * _refundItem.pricePerQuantity;
                refundItemTax = []; //= _refundItem.refundTaxType;
                lineitem_TotalQty = _refundItem.totalQuantity;
                itemDiscount =
                  (_refundItem.itemDiscount / lineitem_TotalQty) *
                  item.Quantity;
                CartDiscount =
                  (_refundItem.CartDiscount / lineitem_TotalQty) *
                  item.Quantity; //Calculate discount for refunded item
                if (item.item_type == 'product') {
                  discountAfterRefund += itemDiscount + CartDiscount;
                }

                refund_lineitem_Total =
                  item.Quantity * _refundItem.pricePerQuantity;
                refund_lineitem_Total -= itemDiscount;
                refund_lineitem_Total -= CartDiscount;
                item.taxes &&
                  item.taxes.length > 0 &&
                  item.taxes.map((ltax) => {
                    //console.log("-----modifiers_key->" + item.product_id, item.modifiers_key);
                    ltax &&
                      ltax !== '' &&
                      Object.keys(ltax).map((tkey) => {
                        var taxvalue = ltax[tkey];
                        data.order_taxes &&
                          data.order_taxes.map((tm) => {
                            // taxRate && taxRate.map(tm=>{
                            if (tm.RateId == tkey) {
                              var taxname = tm.Title; //tm.TaxName;
                              var TaxRate = calculateTaxRate(
                                parseFloat(
                                  lineitem_pricePerQuantity * item.Quantity
                                ),
                                parseFloat(taxvalue)
                              );
                              if (lineitem_pricePerQuantity == 0) {
                                TaxRate = 0;
                              }
                              if (order_reciept) {
                                if (
                                  order_reciept.PercentageTaxPerItem == true
                                ) {
                                  taxname += '(' + TaxRate + ' %)';
                                }
                                //refundItemTax.push({ "tax": taxname, "value": taxvalue })
                                //taxes_refund.push({ "id": tm.RateId, "value": taxvalue });
                                refundItemTax.push({
                                  tax: taxname,
                                  value: taxvalue,
                                  wpTaxName: tm.Title,
                                  wpTaxRate: TaxRate,
                                  id: tm.RateId,
                                });
                              }
                              // var found = false;
                              // RefundTotalTax && RefundTotalTax.length > 0 && RefundTotalTax.map(tTax => {

                              //   if (tTax.wpTaxName == tm.Code && tTax.id == tm.RateId) {
                              //     tTax.value += parseFloat(taxvalue);
                              //     found = true;
                              //   }
                              // })
                              // if (found == false) {
                              //   var result = RefundTotalTax.filter(a => a.id == tm.RateId);
                              //   if (result && result.length==0) {
                              //     RefundTotalTax.push({ "tax": taxname, "value": taxvalue, "wpTaxName": tm.Title, "wpTaxRate": TaxRate, "id": tm.RateId })

                              //   }
                              //   //RefundTotalTax.push({ "tax": taxname, "value": taxvalue })
                              // }
                              if (
                                taxInclusiveName !== 'incl' &&
                                taxInclusiveName !== '(Incl)'
                              ) {
                                // refund_lineitem_Total += taxvalue;
                              }
                            }
                          });
                      });
                  });
                //RefundTotalTax=[];
                refundItemTax &&
                  refundItemTax.map((tm) => {
                    var found = false;
                    RefundTotalTax &&
                      RefundTotalTax.length > 0 &&
                      RefundTotalTax.map((tTax) => {
                        if (tTax.id == tm.id) {
                          tTax.value += parseFloat(tm.value);
                          found = true;
                        }
                      });
                    if (found == false) {
                      var result = RefundTotalTax.filter((a) => a.id == tm.id);
                      if (result && result.length == 0) {
                        RefundTotalTax.push({
                          tax: tm.tax,
                          value: tm.value,
                          wpTaxName: tm.wpTaxName,
                          wpTaxRate: tm.wpTaxRate,
                          id: tm.id,
                        });
                      }
                    }
                  });
              }
            }
            //CHECKHERE
            //var _result = addModiferTaxToLineItemTax(refundItemTax, product_modifiers)
            //addModiferTaxToLineItemTax(RefundTotalTax,)
            // console.log('refundItemTax', refundItemTax)
            var refundItemPrice = 0;
            if (item.item_type == 'product') {
              refundItemPrice = item.Quantity * lineitem_pricePerQuantity;
            } else if (item.item_type == 'customFee') {
              refundItemPrice = item.amount * item.Quantity;
            }
            //var refundItemPrice = item.hasOwnProperty("amount") ? (item.item_type == "customFee" ? item.amount : item.amount * item.Quantity) : (item.item_type == "customFee" ? item.amount : item.Quantity * lineitem_pricePerQuantity);
            //refundItemPrice = refundItemPrice + (taxInclusiveName != "" ? 0 : item.tax);
            var product_modifiers = getModifierFromCustomFee(
              data.line_items,
              data.meta_datas,
              data.order_custom_fee
            );
            var line_items = getProdcutAddons(
              data.line_items,
              data.meta_datas,
              data.order_custom_fee
            );
            // var modifers = product_modifiers.filter(a =>
            //   ((!a.hasOwnProperty("item_type") || a.item_type !== "customFee") && ((a.hasOwnProperty("product_id") && a.product_id == item.product_id)))
            // );
            var addonItem =
              line_items &&
              line_items.find((a) => a.product_id == item.product_id);
            var _addons = '';
            if (addonItem) {
              _addons = addonItem.addons ? addonItem.addons : '';
            }

            var modifers =
              product_modifiers &&
              product_modifiers.filter(
                (a) =>
                  a.hasOwnProperty('pid') &&
                  (a.pid == item.product_id || a.pid == item.product_id) &&
                  a.hasOwnProperty('is_modi') &&
                  a.is_modi == true &&
                  ((a.hasOwnProperty('modifiers_key') &&
                  item.hasOwnProperty('modifiers_key')
                    ? a.modifiers_key == item.modifiers_key
                    : true) ||
                    (!a.hasOwnProperty('modifiers_Id') &&
                      !item.hasOwnProperty('modifiers_Id')))
              );
            // console.log("-----modifers---" + JSON.stringify(modifers));
            //console.log("-----modifers1---" + JSON.stringify(modifers1));
            if (item.item_type !== 'customFee') {
              //-----Android
              refundItemDetailRender(
                refund_lineitem_Title,
                lineitem_pricePerQuantity,
                itemDiscount,
                CartDiscount,
                itemDiscountLabel,
                CartDiscountLabel,
                refundItemTax,
                refund_lineitem_Total,
                RefundTotalTax,
                item,
                lineitem_TotalQty,
                refundItemPrice,
                labelIncl,
                modifers,
                _addons
              );

              refundItemDetailRender(
                refund_lineitem_Title,
                lineitem_pricePerQuantity,
                itemDiscount,
                CartDiscount,
                itemDiscountLabel,
                CartDiscountLabel,
                refundItemTax,
                refund_lineitem_Total,
                RefundTotalTax,
                item,
                lineitem_TotalQty,
                refundItemPrice,
                labelIncl,
                modifers,
                _addons,
                true
              );
            }

            //warapper Items---------
            WrapperAppData.refundItems.push({
              name: refund_lineitem_Title,
              quantity: lineitem_pricePerQuantity,
              tax: RefundTotalTax,
              //"discount":{ "lineItemDiscount": itemDiscount ,"cartDiscount": CartDiscount},
              total: refund_lineitem_Total,
            });
            //------------------------
          });
        //// End refund Item **********************************

        refundItemDetail += getCustomFeeHtml(
          data,
          item_detail,
          PrintAndroidData,
          rowNumber,
          refundlog,
          order_reciept
        );
        //// ****************Refund Total Start **********************************

        var _RefundTotalTax = [];
        RefundTotalTax &&
          RefundTotalTax.map((tax) => {
            var _item = RefundTotalTax.filter((a) => a.id == tax.id);
            if (_item && _item.length == 1) {
              var found = _RefundTotalTax.find((a) => a.id == tax.id);
              if (typeof found == 'undefined' || found == null) {
                _RefundTotalTax.push(_item[0]);
              }
            } else if (_item && _item.length > 1) {
              var found = _RefundTotalTax.find((a) => a.id == tax.id);
              if (typeof found == 'undefined' || found == null) {
                var amount = 0;
                _item.map((a) => {
                  amount += a.value;
                });
                var _tax = tax;
                _tax['value'] = amount;
                _RefundTotalTax.push(_tax);
              }
            }
          });
        RefundTotalTax = _RefundTotalTax;
        //End Refund Total------------------
        refundItemDetail += getRefundTotalDetail(
          order_reciept,
          refundlog.Refund_data_collection.refund_amount,
          refundSubTotal,
          RefundTotalTax,
          total_cashround,
          labelsubTotal,
          discountAfterRefund,
          '',
          PrintAndroidData,
          rowNumber,
          labelIncl
        );

        newReceiptRefundDetail += getRefundTotalDetail(
          order_reciept,
          refundlog.Refund_data_collection.refund_amount,
          refundSubTotal,
          RefundTotalTax,
          total_cashround,
          labelsubTotal,
          discountAfterRefund,
          '',
          PrintAndroidData,
          rowNumber,
          labelIncl,
          true
        );
        //End Refund Total-------------------

        var refundPayments = getRefundPayments(
          refundlog.Refund_data_collection,
          PrintAndroidData,
          '',
          rowNumber,
          data.time_zone
        );

        var newReceiptPaymentInfo = getRefundPayments(
          refundlog.Refund_data_collection,
          PrintAndroidData,
          '',
          rowNumber,
          data.time_zone,
          true
        );

        if (refundPayments !== '') {
          refundItemDetail +=
            '<table class="item-table" style="margin-top:0"><tbody><tr><td colspan="2"><div class="double-border"></div></td></tr><tfoot>' +
            refundPayments;
          refundItemDetail += '</tfoot></tbody></table>';
        }

        if (newReceiptPaymentInfo !== '') {
          newReceiptRefundDetail += newReceiptPaymentInfo
        } else {
          newReceiptRefundDetail += `</div></div>`
        }
      });
  }
  //console.log("refund receipt string ", receipt + refund_receipt);
  //console.log("refundItemDetail", refundItemDetail)
  //// ****************Refund Total End **********************************

  var htmlbody = `<html> <head><meta charset="utf-8">
            <meta http-equiv="X-UA-Compatible" content="IE=edge">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title align="left"></title>

                <meta name="msapplication-TileColor" content="#da532c">
                  <meta name="theme-color" content="#ffffff">
                    <head>
                      <style>
                      body, h1,h2,h3,h4,h5,h6 {
                        margin: 0;
                        padding: 0;
                    }
                    .table-common {
                        margin-bottom: 10px;
                    }
                    .item-report td {
                        padding: 3px;
                        padding-left: 0px;
                    }
                    table.item-table {
                        width: 100%;
                        padding-bottom:10px;
                    }
                    .item-details-total {
                        margin-top: 30px;
                    }
                    table.item-table .item-quantity {
                        width: auto;
                        display: inline-block;
                    }
                    table.item-total {
                      font-weight:bold;
                    }
                    table.item-table p {
                        margin: 0;
                        text-align: left;
                    }
                    .item-subaddon table{
                        width: 100%;
                    }
                    table {
                        width: 100%;
                        padding-bottom:10px;
                    }
                    .item-total-tax {
                        width: 100%;
                        border: 1px solid #979797;
                        border-left: 0;
                        border-right: 0;
                    }

                    .item-subaddon {
                        padding-top: 5px;
                    }
                    .item-subaddon tbody td {
                        padding-left: 15px;
                    }

                    .double-border {
                        width: 100%;
                        border: 1px solid #979797;
                        border-left: 0;
                        border-right: 0;
                        height: 7px;
                    }
                    .table-common {
                        margin-bottom: 10px;
                    }
                    .table-common td {
                        padding-top: 5px;
                        padding-bottom: 5px;
                    }
                    .table-common tr:first-child td {
                        padding-top: 6px;
                    }
                    .table-common thead th{
                        border-bottom: 1px solid #050505;
                        padding-bottom: 10px;
                    }
                    .table-common tr:last-child td {
                        padding-bottom: 8px;
                        border-bottom: 1px solid #050505;
                    }
                    .font-bold {
                        font-weight: 600;
                    }

                    .section-heading{
                        font-weight: 500;
                        border-bottom: 1px solid #050505;
                        padding-bottom: 10px;
                        font-size: 14px;
                    }
                    .additional-informations {
                        background-color: #F0F2F4;
                        margin-top: 30px;
                        text-align: center;
                        height: 171px;
                    }
                    .footer-informations {
                        text-align: center;
                        margin-top: 5px;
                    }
                    .footer-informations td {
                      word-break: break-word;
                     }
                    .logo {

                        text-align: center;
                        margin-bottom: 10px;
                    }
                    .innertable{
                      padding-bottom: 2px;
                     }
                     .itemdiv{
                      border-bottom: 1px solid #979797;
                    }
                    .pagesize{
                      width:${pageSize.width}; overflow:hidden;
                        margin: 0 auto;
                        border: 1px solid #979797;
                    }
                    </style>
                    </head>
                    <body >
                      <div style='padding:${
                        pageSize.width == '80mm'
                          ? '2px'
                          : pageSize.width == '52mm' || pageSize.width == '58mm'
                          ? '2px'
                          : '5px'
                      };'   class='pagesize'>
                      ${GetHeaderDetail}
                      <div class="item-report">
                        <h3 style="text-align: center;">${
                          order_reciept.ShowCustomText &&
                          order_reciept.ShowCustomText == true &&
                          customeText !== null
                            ? customeText
                            : ''
                        } </h3>

                        ${lineItem}
                        ${_CustomeFee}
                        
                        ${_total_mm}
                        ${displayPayments}

                        ${_externalApp}
                        ${_footer}

                        ${refundItemDetail}



                        `;
  htmlbody += ' </div></div></body ></html>';
  //console.log("------Printreceipt---" + htmlbody);

  const getHeaderDetails = () => {
    const logo = `<img id="receipt-logo-image" class="logo-image y-pad-small" src="${
      baseurl ? baseurl : ''
    }"/>`;

    return `
      <div class="title">
          <div class="title-wrapper">
              <div class="logo">${
                order_reciept.ShowLogo && baseurl !== '' ? logo : shopName
              }</div>
              <p class="receipt-title-logo">${
                isGift ? `Gift Receipt` : `Receipt`
              }</p>
              
          </div>
      </div>
  
      <div class="receipt-header">
        <div class="receipt-container">
        ${isGift ? `<p id="gift-header-small" class="bold" style="margin: 10px 0; font-size: '16px';">Gift Receipt</p>` : ''}
        <div class="header-wrapper-container y-pad-big">
          <div class="header-info-content">
          <div class="row">
          ${
            order_reciept.ShowDate
              ? `
            <div class="wrapper">
                <div class="label bold">${
                  order_reciept.DateText
                    ? order_reciept.DateText
                    : labelDateDisplay
                }:</div>
                 <div class="value normal">${
                   gmtDateTime ? gmtDateTime : ''
                 }</div>
            </div>
          `
              : ''
          }

          ${
            order_reciept.ShowTime
              ? `
            <div class="wrapper">
                <div class="label bold">${labelTime}:</div>
                <div class="value normal">${time ? time : ''}</div>
            </div>
            `
              : ''
          }

          ${
            order_reciept.ShowSale
              ? `
            <div class="wrapper">
                <div class="label bold">${labelSale}:</div>
                <div class="value normal">#${receiptId ? receiptId : ''}</div>
            </div>
            `
              : ''
          }
          
          ${
            order_reciept.ShowServedBy
              ? `
                <div class="wrapper">
                    <div class="label bold">${labelServed}:</div>
                    <div class="value normal">${servedBy}</div>
                </div>
          
            `
              : ''
          }

        </div>
        <div class="row">
          ${
            customershow_name !== null && customershow_name !== ''
              ? `
            <div class="wrapper">
              <div class="label bold">${labelCustomerName}:</div>
              <div class="value normal">${customershow_name}</div>
            </div>
            `
              : ''
          }

          ${
            order_reciept.ShowCustomerAddress !== false &&
            CustomerAddress !== ''
              ? `
              <div class="wrapper">
                <div class="label bold">${labelCustomerAddress}:</div>
                <div class="value normal">${CustomerAddress}</div>
              </div>`
              : ''
          }
          
          ${
            order_reciept.ShowTaxId && order_reciept.TaxId
              ? `
            <div class="wrapper">
                <div class="label bold">${labelTaxId}:</div>
                <div class="value normal">#${taxId}</div>
            </div>
            `
              : ''
          }

          ${
            order_reciept.ShowRegisterDetails
              ? `
            <div class="wrapper">
                <div class="label bold">${LocalizedLanguage.register}:</div>
                <div class="value normal">${registerName}</div>
            </div>
            `
              : ''
          }

          ${
            order_reciept.ShowLocationDetails
              ? `
            <div class="wrapper">
                <div class="label bold">${
                  order_reciept.LocationLabel
                    ? order_reciept.LocationLabel
                    : LocalizedLanguage.location
                }:</div>
                <div class="value normal">${locationName}</div>
            </div>
            `
              : ''
          }


          ${
            order_reciept.ShowTableNumber && groupSaleLabel !== ''
              ? `
            <div class="wrapper">
                <div class="label bold">${labelTableNumber}:</div>
                <div class="value normal">${
                  groupSaleLabel && groupSaleLabel ? groupSaleLabel : ''
                }</div>
            </div>
            `
              : ''
          }
          
        </div>
          </div>
          <div id="order-info-header">
            ${
              order_reciept.ShowCustomText
                ? `
              <div class="y-pad-big">
                  <div class="label bold">Order Info: </div>
                  <div class="value no-break normal">${
                    order_reciept.CustomText ? order_reciept.CustomText : ''
                  }</div>
              </div>
              `
                : ''
            }
          </div>
        </div>
          
          <div class="row">
  
            ${
              order_reciept.ShowBarcode && print_bar_code
                ? `
              <div class="wrapper-barcode">
                  <div class="barcode">
                      <img
                      src='${print_bar_code}'
                      style='padding:0px ; width: 100%; ;height:85px;'/>
                  </div>
              </div>
              `
                : ''
            }
            
          </div>
        </div>
        <hr class="divider" />
      </div>
    `;
  };

  const getProductContainer = () => {
    let productDiv = '';

    if (!isImgPrint && (typeof Wrapper !== "undefined")) return '';

    all_modifier_list.map((item) => {
      if (
        item.Title &&
        item.Title.includes('Tip') &&
        !item.hasOwnProperty('product_id')
      ) {
        _tipAmount += item.Price ? parseFloat(item.Price) : 0;
      } else if (item.hasOwnProperty('product_id') === true) {
        //removing the custome fee

        var itemName = stripHtml(item.name ? item.name : item.Title);
        var skuName =
          item.sku && item.sku !== 'N/A' && item.sku !== undefined
            ? 'SKU: ' + item.sku
            : item.Sku && item.Sku !== 'N/A' && item.Sku !== undefined
            ? 'SKU: ' + item.Sku
            : '';
        var skuName_android =
          item.sku && item.sku !== 'N/A' && item.sku !== undefined
            ? 'SKU ' + item.sku + ''
            : item.Sku && item.Sku !== 'N/A' && item.Sku !== undefined
            ? 'SKU ' + item.Sku + ''
            : '';
        var skuName_wrapper =
          item.sku && item.sku !== 'N/A' && item.sku !== undefined
            ? item.sku
            : item.Sku && item.Sku !== 'N/A' && item.Sku !== undefined
            ? item.Sku
            : '';

        var lineitem_taxType = [];
        var itemvalue =
          item.Taxes && item.Taxes !== null && item.Taxes !== ''
            ? type !== 'activity'
              ? item.Taxes
              : item.Taxes && item.Taxes !== 'null'
              ? JSON.parse(item.Taxes).total
              : ''
            : '';
        var taxRate = JSON.parse(localStorage.getItem('SHOP_TAXRATE_LIST'));
        var TotalTax = 0;
        if (type == 'activity') {
          // for activity
          itemvalue &&
            itemvalue !== '' &&
            Object.keys(itemvalue).map((key) => {
              var taxvalue = itemvalue[key];
              data.order_taxes &&
                data.order_taxes.map((tm) => {
                  // taxRate && taxRate.map(tm=>{
                  if (tm.RateId == key) {
                    var taxname = tm.Title; //tm.TaxName;
                    var TaxRate = calculateTaxRate(
                      parseFloat(item.price * item.quantity),
                      parseFloat(taxvalue)
                    );
                    if (item.price == 0) {
                      TaxRate = 0;
                    }
                    if (order_reciept) {
                      if (order_reciept.PercentageTaxPerItem == true) {
                        taxname += '(' + TaxRate + '%)';
                      }
                      TotalTax += parseFloat(TaxRate);
                    }
                    lineitem_taxType.push({
                      tax: taxname,
                      tax_name: tm.Title,
                      value: taxvalue,
                      wpTaxName: tm.Title,
                      wpTaxRate: TaxRate,
                      id: tm.RateId,
                    });
                  }
                });
            });
        } else {
          //for sale complete
          itemvalue !== '' &&
            itemvalue.map((myObj) => {
              var taxvalue = '';
              var key = '';
              for (const x in myObj) {
                key = x;
                taxvalue = myObj[x];
              }
              taxRate &&
                taxRate.map((tm) => {
                  if (tm.TaxId == key) {
                    var taxname = tm.TaxName;
                    if (
                      order_reciept &&
                      order_reciept.PercentageTaxPerItem == true
                    ) {
                      taxname += '(' + tm.TaxRate + ')';
                    }
                    TotalTax += parseFloat(tm.TaxRate);

                    lineitem_taxType.push({
                      tax: taxname,
                      value: taxvalue,
                      wpTaxName: tm.TaxName,
                      wpTaxRate: tm.TaxRate,
                      id: tm.TaxId,
                    });
                  }
                });
            });
        }
        //..............insert unique tax ...........................................
        var wrapperTaxes = [];
        lineitem_taxType &&
          lineitem_taxType.map((taxItem) => {
            // '>1' to check if split tax inot more then 1 tax type
            if (DisplayTotalSplitTax.length == 0) {
              if (taxItem.value !== 0) DisplayTotalSplitTax.push(taxItem);
              wrapperTaxes.push({
                name: taxItem.hasOwnProperty('tax_name')
                  ? taxItem.tax_name
                  : taxItem.wpTaxName,
                percent: taxItem.hasOwnProperty('wpTaxRate')
                  ? taxItem.wpTaxRate
                  : '',
                amount: taxItem.value,
              });
            } else {
              var checkTaxExist = false;
              DisplayTotalSplitTax.map((item) => {
                if (item.tax == taxItem.tax && item.id == taxItem.id) {
                  checkTaxExist = true;
                  item.value =
                    parseFloat(item.value) + parseFloat(taxItem.value);
                }
              });
              if (checkTaxExist == false) {
                if (taxItem.value !== 0) DisplayTotalSplitTax.push(taxItem);
              }
              wrapperTaxes.push({
                name: taxItem.hasOwnProperty('tax_name')
                  ? taxItem.tax_name
                  : taxItem.wpTaxName,
                percent: taxItem.hasOwnProperty('wpTaxRate')
                  ? taxItem.wpTaxRate
                  : '',
                amount: taxItem.value,
              });
            }
          });

        //........................................................................
        var lineItemRefundQuntity = 0.0;
        var lineItemRefundAmount = 0.0;
        order_totalTaxPercent =
          TotalTax !== 0 ? '(' + TotalTax.toFixed(0) + '%)' : '';
        var lineItem_DiscountDetail = getDiscountPerItem(
          data,
          type,
          item.product_id,
          item
        );

        var logitem =
          logLineItemData &&
          logLineItemData.find((litem) => {
            return (
              (litem.variation_id ? litem.variation_id : litem.product_id) ==
              item.product_id
            );
          });
        var lineitem_AcutalPrice = logitem
          ? taxInclusiveName == 'incl'
            ? logitem.old_price * item.quantity
            : logitem.Price
          : item.total + (taxInclusiveName == 'incl' ? item.total_tax : 0);

        if (logitem == null) {
          lineitem_AcutalPrice =
            parseFloat(
              item.subtotal
                ? item.subtotal
                : item.subtotalPrice
                ? item.subtotalPrice
                : item.Price
                ? item.Price
                : 0
            ) +
            (taxInclusiveName !== ''
              ? type == 'activity'
                ? item.total_tax
                : item.subtotaltax
                ? item.subtotaltax
                : 0
              : 0);
        }
        var lineitem_Title =
          type !== 'activity'
            ? itemName
            : showTitle(item) !== ''
            ? itemName
            : '';
        var lineitem_TotalQty = item.quantity;

        var lineitem_sku = order_reciept.ShowSKU == true ? skuName : null;
        var lineitem_subTitle =
          type == 'activity'
            ? item.meta && item.meta !== '[]'
              ? CommonJs.showAddons(type, JSON.parse(item.meta))
              : ''
            : item && item.addons_meta_data
            ? CommonJs.showAddons(type, JSON.parse(item.addons_meta_data))
            : productxList &&
              productxList.length > 0 &&
              showProductxSubTitle(item.product_id, AllProductList)
            ? showProductxSubTitle(item.product_id, AllProductList)
            : showSubTitle(item) !== ''
            ? itemName
            : '';
        //-----For composit/bundle Prodcut ---------------                                                                                                                                                                 : showSubTitle(item) !== "" ? itemName : ''
        if (
          type == 'activity' &&
          ((item.composite_product_key && item.composite_parent_key == '') ||
            (item.bundle_product_key && item.bundled_parent_key == ''))
        ) {
          lineitem_subTitle = getCompositItemDetail(item);
        }
        //----------------------------------
        var lineitem_cartDiscount = 0;
        var lineItem_cartDiscountType = '';
        var lineitem_Discount =
          lineItem_DiscountDetail && lineItem_DiscountDetail.discountAmount
            ? lineItem_DiscountDetail.discountAmount
            : 0;
        Total_IndividualProductDiscount += lineitem_Discount;
        if (lineItem_DiscountDetail && lineItem_DiscountDetail.discountCart) {
          lineitem_cartDiscount = parseFloat(
            lineItem_DiscountDetail.cartItemDiscount
          );
          _cartDiscount += lineitem_cartDiscount;
          Order_cartDiscount = lineItem_DiscountDetail.discountCart;

          var _discountType = Order_cartDiscount
            ? Order_cartDiscount.discountType == 'Number'
              ? ''
              : '%'
            : '%';
          lineItem_cartDiscountType =
            lineItem_DiscountDetail.discountCart && _discountType == '%'
              ? '(' +
                parseFloat(
                  lineItem_DiscountDetail.discountCart.discount_amount
                ) +
                _discountType +
                ')'
              : '';
        }

        var lineitem_Dis_Percent =
          lineItem_DiscountDetail &&
          lineItem_DiscountDetail.discountApply &&
          lineItem_DiscountDetail.discounttype == 'Number'
            ? ''
            : lineItem_DiscountDetail &&
              lineItem_DiscountDetail.discountApply &&
              '(' + lineItem_DiscountDetail.discountApply + '%)';

        var lineItemTax = '';
        var modifiers_html = '';
        var _lineitem = data.line_items ? data.line_items : data.ListItem;
        var _meta_datas = data.meta_datas;

        var _modifier_total = 0;
        var _modifier_total_tax = 0;
        var product_modifiers = all_modifier_list;

        product_modifiers =
          product_modifiers &&
          product_modifiers.filter(
            (a) =>
              (a.pid == item.product_id || a.pid == item.variation_id) &&
              (a.hasOwnProperty('modifiers_Id') &&
              item.hasOwnProperty('modifiers_Id')
                ? JSON.stringify(a.modifiers_Id) ==
                  JSON.stringify(item.modifiers_Id)
                : true) &&
              (a.hasOwnProperty('modifiers_key') &&
              item.hasOwnProperty('modifiers_key')
                ? a.modifiers_key == item.modifiers_key
                : true)
          );
        if (product_modifiers && product_modifiers.length > 0) {
          var _taxArray = [];
          modifiers_html = '<div class="modifiers">';
          product_modifiers &&
            product_modifiers.map((item_fee, index) => {
              if (item_fee.hasOwnProperty('pid') == true) {
                _modifier_total += item_fee.amount;
                _modifier_total_tax += item_fee.total_tax;

                if (_taxArray.length > 0) {
                  _taxArray = [..._taxArray, ...item_fee.total_taxes];
                } else {
                  _taxArray = item_fee.total_taxes;
                }
                var mod_name = (
                  item_fee.note
                    ? item_fee.note
                    : item_fee.Title
                    ? item_fee.Title
                    : ''
                ).replace(/_/g, ' ');
                modifiers_html += `<div class="modifiers-wrapper">
                  <div>+ ${mod_name}</div>
                  ${
                    isGift
                      ? `
                  `
                      : `
                  <div>+${parseFloat(item_fee.amount).toFixed(2)}</div>
                  `
                  }
                  
                </div>`;
              }
            });
          modifiers_html += `</div>`;
        }

        //HERETOCHECK
        var templineItemTax = '';
        lineitem_taxType &&
          lineitem_taxType.length > 0 &&
          lineitem_taxType.map((txtitem) => {
            var _taxvalue = 0;
            var _mods = product_modifiers.filter(
              (a) => a.modifiers_key == item.modifiers_key
            );
            if (_mods && _mods.length > 0) {
              _mods.map((a) => {
                a.total_taxes.map((key) => {
                  Object.keys(key).map((id) => {
                    if (txtitem.id == id) {
                      _taxvalue += key[id];
                    }
                  });
                });
              });
            }
            //HERETOCHECK CALCULATION WILL THERE BUT HIDE SHOW THE DIV

            templineItemTax += `
              <div class="product-wrapper">
                <div>${txtitem.tax} ${labelIncl}</div>
                <div>${(
                  parseFloat(txtitem.value) + parseFloat(_taxvalue)
                ).toFixed(2)}</div>
              </div>
            `;
          });
        if (
          order_reciept.IndividualizedTaxAmountPerItem == true ||
          order_reciept.PercentageTaxPerItem == true
        ) {
          lineItemTax = templineItemTax;
        }
        var _taxvalueAll = 0;
        product_modifiers &&
          product_modifiers !== '' &&
          product_modifiers.map((item1) => {
            if (
              item1.hasOwnProperty('cart_discount_amount') &&
              item1.cart_discount_amount != 0
            ) {
              lineitem_cartDiscount += item1.cart_discount_amount;
              all_modifier_discount += item1.cart_discount_amount;
            }
            if (
              item1.hasOwnProperty('product_discount_amount') &&
              item1.product_discount_amount != 0
            ) {
              lineitem_Discount += item1.product_discount_amount;
              all_modifier_discount += item1.product_discount_amount;
            }
            return (_taxvalueAll += parseFloat(item1.total_tax));
          });


        var _lineitemTax =
          taxInclusiveName !== '' ||
          order_reciept.IndividualizedTaxAmountPerItem == true ||
          order_reciept.PercentageTaxPerItem == true
            ? item.total_tax
              ? item.total_tax
              : item.subtotaltax
              ? item.subtotaltax
              : 0
            : 0;

        var lineitem_Total =
          lineitem_AcutalPrice -
          parseFloat(lineitem_Discount) -
          lineitem_cartDiscount;

        // Order_subTotal += lineitem_AcutalPrice;

        // only for exclusive tax------------
        lineitem_Total =
          parseFloat(lineitem_Total) +
          (taxInclusiveName == ''
            ? order_reciept.IndividualizedTaxAmountPerItem == true ||
              order_reciept.PercentageTaxPerItem == true // added line item tax into product total only when setting is enable
              ? (item.totaltax || item.total_tax
                  ? parseFloat(_lineitemTax)
                  : 0) + _taxvalueAll
              : 0
            : 0);

        lineitem_Total =
          parseFloat(lineitem_Total).toFixed(2) == '0.01'
            ? 0.0
            : lineitem_Total; // to remove diffrence of .01

        var lineitem_shortDesc = getProductShortDesc(
          data,
          type,
          item.product_id
        );

        if (item.quantity_refunded < 0) {
          var taxPerQty = item.subtotal_tax / item.quantity;
          var taxRefunded = 0;
          lineItemRefundQuntity = Math.abs(item.quantity_refunded);
          if (taxPerQty && taxInclusiveName !== '') {
            //for incusive tax
            taxRefunded = taxPerQty * lineItemRefundQuntity;
          }

          refundSubTotal += item.amount_refunded + taxRefunded;
          lineItemRefundAmount = item.amount_refunded + taxRefunded;
        }

        if (item.quantity_refunded < 0) {
          var _refundItemDetail = {};
          _refundItemDetail['productId'] = item.product_id;
          _refundItemDetail['line_item_id'] = item.line_item_id
            ? item.line_item_id
            : null;
          _refundItemDetail['title'] = lineitem_Title;
          _refundItemDetail['totalQuantity'] = lineitem_TotalQty;
          _refundItemDetail['pricePerQuantity'] =
            lineitem_AcutalPrice / lineitem_TotalQty;
          _refundItemDetail['itemDiscountLabel'] =
            lineitem_Discount > 0
              ? LocalizedLanguage.itemDiscount +
                (order_reciept.PercentageDiscountPerItem == true
                  ? lineitem_Dis_Percent
                  : '')
              : '';
          var lblCartDiscount = LocalizedLanguage.cartdiscount;
          lblCartDiscount +=
            order_reciept.PercentageDiscountPerItem == true
              ? lineItem_cartDiscountType
              : '';
          _refundItemDetail['CartDiscountLabel'] = lblCartDiscount;
          _refundItemDetail['itemDiscount'] = lineitem_Discount;
          _refundItemDetail['CartDiscount'] = lineitem_cartDiscount;
          _refundItemDetail['refundTaxType'] = lineitem_taxType;
          _refundItemDetail['refundedQuantity'] = item.quantity_refunded;

          if (item.hasOwnProperty('modifiers_Id')) {
            _refundItemDetail['modifiers_Id'] = item.modifiers_Id;
          }
          if (item.hasOwnProperty('modifiers_key')) {
            _refundItemDetail['modifiers_key'] = item.modifiers_key;
          }
          _refundItemDetail['taxes'] = itemvalue;
          refundLineItemDetail.push(_refundItemDetail);
        }

        //warapper Items---------
        var wppLineItem = {
          name: lineitem_Title,
          price: lineitem_AcutalPrice,
          quantity: lineitem_TotalQty,
          sku: '',
          descr: '', //lineitem_subTitle,
          addons: [{ name: '', price: '' }], // lineitem_shortDesc,
          taxes: [],
          discount: { name: '', percent: '', amount: 0 },
          total: 0.0,
          discountcart: { name: '', percent: '', amount: 0 },
        };
        if (
          lineitem_Discount > 0 ||
          lineitem_AcutalPrice !== lineitem_Total ||
          order_reciept.IndividualizedTaxAmountPerItem == true ||
          order_reciept.PercentageTaxPerItem == true
        ) {
          wppLineItem['total'] = lineitem_Total;
        }
        if (skuName_wrapper && order_reciept.ShowShortDescription == true) {
          wppLineItem['descr'] = lineitem_shortDesc;
        }
        if (skuName_wrapper && order_reciept.ShowSKU == true) {
          wppLineItem['sku'] = skuName_wrapper;
        }
        if (
          order_reciept.PercentageDiscountPerItem == true &&
          lineitem_Discount != 0
        ) {
          wppLineItem['discount'] = {
            name:
              lineitem_Discount && lineitem_Discount.discount_type
                ? lineitem_Discount.discount_type
                : '',
            percent: lineitem_Dis_Percent ? lineitem_Dis_Percent : '',
            amount: lineitem_Discount,
          };
        }
        if (
          order_reciept.PercentageDiscountPerItem == true &&
          lineitem_cartDiscount != 0
        ) {
          wppLineItem['discountcart'] = {
            name: lineItem_cartDiscountType,
            percent: Order_cartDiscount.discountType,
            amount: lineitem_cartDiscount,
          };
        }
        if (
          order_reciept.IndividualizedTaxAmountPerItem == true ||
          order_reciept.PercentageTaxPerItem == true
        ) {
          wppLineItem['taxes'] = wrapperTaxes;
        }
        WrapperAppData.items.push(wppLineItem);
        WrapperAppData['discount'] = {
          name:
            lineitem_Discount && lineitem_Discount.discount_type
              ? lineitem_Discount.discount_type
              : '',
          percent: lineitem_Dis_Percent ? lineitem_Dis_Percent : '',
          amount: lineitem_Discount,
        };
        //WrapperAppData["taxes"] = wrapperTaxes;
        //-----------------------
        //Product starts
        productDiv += `<div class="product">
                      <div class="product-wrapper y-pad-small normal">
                        <div class="bold">${`${isGift ? `${lineitem_TotalQty} x ` : ''} `}${lineitem_Title}</div>
                        <div class="product-price-wrapper">
                        ${
                          isGift
                            ? ``
                            : `${
                                lineitem_TotalQty && lineitem_TotalQty > 1
                                  ? `<div class="multiple-product">(${lineitem_TotalQty} x ${(
                                      lineitem_AcutalPrice / lineitem_TotalQty
                                    ).toFixed(2)})</div>`
                                  : ''
                              }
                        <div style="font-weight: 300;">${lineitem_AcutalPrice.toFixed(
                          2
                        )}</div>`
                        }
                        </div>
                      </div>
                      `;
        // ${
        //   item.psummary &&
        //   typeof item.psummary !== 'undefined' &&
        //   item.psummary !== null &&
        //   item.psummary !== ''
        //     ? `<tr><td colspan='2' style="text-transform: capitalize;font-size:10px;">${item.psummary}</td> </tr>`
        //     : ``
        // }
        // ${
        //   item.ProductSummery &&
        //   typeof item.ProductSummery !== 'undefined' &&
        //   item.ProductSummery !== null &&
        //   item.ProductSummery !== '' &&
        //   item.ProductSummery.length > 0
        //     ? `<tr><td colspan='2' style="text-transform: capitalize;font-size:10px;">${item.ProductSummery.toString()} </td> </tr> `
        //     : lineitem_subTitle &&
        //       lineitem_subTitle !== '' &&
        //       lineitem_subTitle !== null
        //     ? `<tr><td colspan='2' style="text-transform: capitalize;font-size:10px;">${lineitem_subTitle.toString()}</td></tr>`
        //     : ''
        // }`;
        productDiv +=
          (lineitem_sku && order_reciept.ShowSKU == true) ||
          order_reciept.ShowShortDescription == true
            ? `
                ${
                  lineitem_sku && order_reciept.ShowSKU == true
                    ? `<div class="sku">${lineitem_sku && lineitem_sku}</div>`
                    : ``
                }
                
                ${
                  order_reciept.ShowShortDescription == true &&
                  lineitem_shortDesc &&
                  lineitem_shortDesc !== ''
                    ? `<div class="item-info y-pad-small"> ${lineitem_shortDesc} </div>`
                    : ``
                }
                `
            : '';

        productDiv +=
          modifiers_html && modifiers_html !== '' ? `${modifiers_html}` : ``;
        lineitem_Total += _modifier_total;
        all_modifier_total += _modifier_total;
        all_modifier_tax += _modifier_total_tax;
        all_modifier_taxes = _taxArray;
        _lineitemTax += _modifier_total_tax;

        var notes_list = getProductNoteHtml(data, item, type);
        if (notes_list && notes_list.length > 0) {
          var parser = new DOMParser();

          notes_list &&
            notes_list.map((a) => {
              var doc = parser.parseFromString(
                a.note ? a.note : '',
                'text/html'
              );
              productDiv += `<div class="y-pad-small">
                ${a.note ? doc.body.innerHTML : ''}
                </div>`;
            });
        }

        productDiv +=
        (lineitem_Discount || lineitem_cartDiscount) && !isGift
            ? `<div class="item-discount-container">
              ${
                lineitem_Discount > 0
                  ? `<div class="item-discount-wrapper">
                  <div>${LocalizedLanguage.itemDiscount} ${
                      order_reciept.PercentageDiscountPerItem == true
                        ? lineitem_Dis_Percent
                        : ''
                    }
                  </div>
                  <div>-${lineitem_Discount.toFixed(2)}</div>
                  </div>
                  `
                  : ''
              }
    
              ${
                lineitem_cartDiscount > 0
                  ? `<div class="item-discount-wrapper">
                  <div>${LocalizedLanguage.cartdiscount} ${
                      order_reciept.PercentageDiscountPerItem == true
                        ? lineItem_cartDiscountType
                        : ''
                    }
                  </div>
                  <div>-${lineitem_cartDiscount.toFixed(2)}</div>
                  </div>`
                  : ''
              }
              </div>
             `
            : '';

        // console.log('LINE ITEM TAX ??: ', lineItemTax, lineitem_Discount == 0, lineItemTax === '')

        productDiv +=
          lineItemTax === '' && lineitem_Discount == 0 && !isGift
            ? `${lineItemTax}
            `
            : ``;

        productDiv += !isGift ? lineItemTax : '';

        productDiv += `</div>`;

        productDiv +=

          lineitem_Discount > 0 ||
          lineitem_AcutalPrice !== lineitem_Total ||
          order_reciept.IndividualizedTaxAmountPerItem == true ||
          order_reciept.PercentageTaxPerItem == true
            ? `<hr class="divider" />
              ${
                isGift
                  ? ``
                  : `
              <div class="product-total">
                  <div class="product-total-wrapper">
                  <div style="font-weight: 300;">${
                    LocalizedLanguage.producttotal
                  }</div>
                  <div class="bold">${parseFloat(lineitem_Total).toFixed(
                    2
                  )}</div>
                </div>
              </div>  
              `
              }`
            : ``;
      }
    });

    productDiv += _newReceiptCustomFee;

    return productDiv;
  };

  const getBottomContainer = () => {
    return `
    <div class="side-info-container">
      <div class="bottom-container-info">
        ${
          order_reciept.ShowOrderNotes
            ? `
          <div class="wrapper-info">
              <div class="label bold">*${LocalizedLanguage.orderNotes.toUpperCase()}*</div>
              <div class="value no-break normal y-pad-small" style="text-align: center;">${
                _Notes !== '' ? _Notes : ''
              }</div>
          </div>
          `
            : ''
        }

        ${
          order_reciept.ShowReturnPolicy
            ? `
          <div class="wrapper-info">
            <div class="label bold">*${LocalizedLanguage.returnPolicy.toUpperCase()}*</div>
            <div class="value no-break normal y-pad-small" style="text-align: center;">${
              _returnPolicyText !== '' ? order_reciept.ReturnpolicyText : ''
            }</div>
          </div>
          `
            : ''
        }
      </div>
      
      ${
        displayExtensionAppData &&
        displayExtensionAppData !== null &&
        displayExtensionAppData.url
          ? `
          <div class="custom-image">
            <img src=${displayExtensionAppData.url}>
          </div>
          `
          : ``
      }
    </div>


    <div class="amount-container">
      <div class="amount-wrapper">
      ${
        isGift
          ? ``
          : `
      <div class="amount-label">${
        order_reciept.SubTotal ? order_reciept.SubTotal : `${labelsubTotal}`
      }</div>
      <div class="amount-value">${Order_subTotal.toFixed(2)}</div>
      `
      }

      
        
      </div>

      <div class="amount-container">
        <div class="amount-wrapper">
        ${
          shippingAmount > 0 && !isGift
            ? `
          <div class="amount-label">${LocalizedLanguage.shippingAmount}</div>
          <div class="amount-value">${parseFloat(
            RoundAmount(shippingAmount)
          ).toFixed(2)}</div>
          
          `
            : ``
        }
        </div>
      </div>

      ${
        order_reciept.ShowTotalTax && TotalTaxByName && !isGift
          ? TotalTaxByName.map((name) => {
              taxrate_name +=
                name.tax_amount > 0
                  ? `
              <div class="amount-wrapper">
                <div class="amount-label">${name.tax_name}(${
                      order_reciept.PercentageTaxOfEntireOrder == true
                        ? '(' + name.TaxRate + ')'
                        : ''
                    }) Incl.</div>
                <div class="amount-value">${name.tax_amount.toFixed(2)}</div>
              </div>
              `
                  : '';
            }).join('')
          : ''
      }

      ${
        total_discount > 0 && !isGift
          ? `
        <div class="amount-wrapper">
          <div class="amount-label">${labelTotalDiscount}</div>
          <div class="amount-value">-${total_discount.toFixed(2)}</div>
        </div>
        `
          : ''
      }

      ${!isGift ? _new_receipt_splitTaxDetail : ''}

      ${
        total_RedeemPoint > 0 && !isGift
          ? `
        <div class="amount-wrapper">
          <div class="amount-label">${LocalizedLanguage.redeemedpoint}</div>
          <div class="amount-value">${total_RedeemPoint}</div>
        </div>
        `
          : ''
      }

      ${
        total_cashround &&
        total_cashround !== 0 &&
        total_cashround !== '0.00' &&
        !isGift
          ? `
        <div class="amount-wrapper">
          <div class="amount-label">${LocalizedLanguage.cashRounding}</div>
          <div class="amount-value">${parseFloat(
            RoundAmount(total_cashround)
          ).toFixed(2)}</div>
        </div>
        `
          : ''
      }

      ${
        _tipAmount && _tipAmount !== 0 && !isGift
          ? `
        <div class="amount-wrapper">
          <div class="amount-label">${_tipLable} ${tipPercent}</div>
          <div class="amount-value">${parseFloat(
            RoundAmount(_tipAmount)
          ).toFixed(2)}</div>
        </div>
        `
          : ''
      }

      <div class="amount-wrapper y-pad-small">
      ${
        isGift
          ? ``
          : `
        <div class="amount-label">${labelTotalOrder} ${localStorage.getItem("currecySymbole") ? `(${localStorage.getItem("currecySymbole")})` : ''}</div>
        <div class="amount-value">${total_amount.toFixed(2)}</div>
        `
      }
        
      </div>

      ${
        isGift || _new_receipt_payments.length < 1
          ? ``
          : `<hr class="divider" style="margin: 3px 0; padding: 0" />
      <hr class="divider" style="margin: 3px 0; padding: 0" />`
      }

      <div class="amount-emv">

      ${
        isGift
          ? ``
          : `
      
      ${_new_receipt_payments
        .map((payment) =>
          payment.method.toLowerCase() === 'card'
            ? `<div class="amount-wrapper">
        <div class="amount-label">${payment.method.charAt(0).toUpperCase() + payment.method.slice(1)} (${payment.date})</div>
        <div class="amount-value">${payment.amount}</div>
      </div>`
            : ''
        )
        .join('')}

      ${
        _new_receipt_emvData && _new_receipt_emvData !== ''
          ? `
        <div class="emv-data-container">
          <div class="emv-data">
            <p>EMV Data</p>
          </div>
          ${_new_receipt_emvData}
        </div>
        `
          : ''
      }

      ${
        _new_receipt_payments.length > 0 
          ? _new_receipt_payments
              .filter(payment => payment.method.toLowerCase() !== 'card')
              .map(payment => 
                `
              <div class="amount-wrapper">
                <div class="amount-label">${payment.method.charAt(0).toUpperCase() + payment.method.slice(1)} (${payment.date})</div>
                <div class="amount-value">${payment.amount}</div>
              </div>
              `).join('') 
          : ''
      }
      
      ${
        _new_receipt_cash_change.length > 0 ? `
        <div class="amount-wrapper">
          <div class="amount-label">Cash Payment</div>
          <div class="amount-value">${_new_receipt_cash_change[0].amount}</div>
        </div>

        <div class="amount-wrapper">
          <div class="amount-label">Change</div>
          <div class="amount-value">${_new_receipt_cash_change[0].change}</div>
        </div>
        ` : ''
      }
      `
    }
      
      </div>


      
      ${
        isGift || _new_receipt_payments.length < 1
          ? ``
          : `<hr class="divider" style="margin: 3px 0; padding: 0" />
      <hr class="divider" style="margin: 3px 0; padding: 0" />`
      }

      

      <div class="info-container">

        ${
          displayExtensionAppData &&
          displayExtensionAppData !== null &&
          displayExtensionAppData.url
            ? `
            <div class="custom-image">
              <img src=${displayExtensionAppData.url}>
            </div>
            `
            : ``
        }

        ${
          order_reciept.ShowOrderNotes
            ? `
          <div class="notes-wrapper y-pad-small">
              <div class="label bold y-pad-small">*${LocalizedLanguage.orderNotes.toUpperCase()}*</div>
              <div id="order-notes" class="value no-break normal" style="text-align: center;">${
                _Notes !== '' ? _Notes : ''
              }</div>
          </div>
          `
            : ''
        }

        ${
          order_reciept.ShowReturnPolicy
            ? `
          <div class="notes-wrapper y-pad-small">
            <div class="label bold y-pad-small">*${LocalizedLanguage.returnPolicy.toUpperCase()}*</div>
            <div id="return-policy-notes" class="value no-break normal" style="text-align: center;">${
              _returnPolicyText !== '' ? order_reciept.ReturnpolicyText : ''
            }</div>
          </div>
          `
            : ''
        }
      </div>
    </div>
    `;
  };

  const getFooterDetails = () => {
    return `
    <div class="footer-wrapper">

      ${
        order_reciept.ShowBarcode && print_bar_code
          ? `
          <div class="barcode">
            <img
              src='${print_bar_code}'
              style='padding:0px ; width: 100%; ;height:85px;'/>
          </div>
        `
          : ''
      }
      <p class="yourshop">${
        order_reciept && order_reciept.ShowWebsiteLink == true ? site_name : ''
      }</p>
      <div class="footer-address">

      ${
        address &&
        (address.address_1 !== '' ||
          address.address_2 !== '' ||
          address.zip !== '' ||
          address.city !== '' ||
          address.country_name !== '')
          ? `
          <p style="margin-right: 5px" class="no-break">${
            address && address.address_1 ? address.address_1 : ''
          } ${address && address.address_2 ? address.address_2 : ''}</p>
          <p class="no-break">${address && address.zip ? address.zip : ''} ${
              address && address.city ? ', ' + address.city : ''
            } ${
              address && address.country_name ? ', ' + address.country_name : ''
            }</p>
            `
          : ``
      }
      </div>
    </div>
    `;
  };

  const getProductDetails = () => {
    return `
    <div class="product-container">
      ${getProductContainer()}
    </div>
    <div class="bottom-container">
      ${getBottomContainer()}
    </div>
    `;
  };

  const getRefundContainer = () => {
    return `
      ${newReceiptRefundDetail}
    `;
  };

  // if not wrapper show new receipt
  // if wrapper and img print do new receipt (Ok)
  // if wrapper and not img print sends data (OK)

  const myResReceipt = `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;600;700&display=swap"
        rel="stylesheet"
      />
      <title>Receipt</title>
      <style>
        * {
          margin: 0;
          padding: 0;
          box-sizing: border-box; 
          image-rendering: crisp-edges;
        }

        .receipt {
          flex-shrink: initial;
          min-height: 100dvh;
          margin: 0 30px;
          padding: 10px 0;
          display: flex;
          flex-direction: column;
          font-family: 'Poppins', sans-serif;
          font-size: 12px;
          overflow-x: hidden;
        }

        .receipt-title-logo {
          text-transform: uppercase;
          font-weight: 700;
          font-size: 36px;
        }

        .y-pad-small {
          padding: 5px 0;
        }

        .y-pad-big {
          padding: 10px 0;
        }

        .x-pad-small {
          padding-top: 10px;
        }

        .logo {
          font-size: 28px;
          font-weight: 600;
          color: #3d4c66;
        }

        .receipt-header {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #f0f2f4;
        }

        .receipt-container {
          display: flex;
          flex-direction: row;
          align-items: stretch;
          /* padding: 20px; */
          width: 100%;
          font-weight: 100;
          background-color: white;
          font-family: 'Roboto Mono', monospace;
        }

        .row {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          width: 100%;
          padding: 5px 0;
          margin: 0 15px;
        }

        .row:first-child {
          margin-left: 0;
        }

        .row:nth-child(4),
        .row:nth-child(3) {
          margin: 0;
        }

        .wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        .wrapper-info {
          width: 90%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .wrapper-info-inner {
          display: flex;
          justify-content: center;
          display: none;
          margin: 0 0 10px 0;
        }

        .label {
          margin-right: 5px;
        }

        .value,
        .label {
          font-size: 12px;
        }

        .label {
          font-weight: 600;
        }

        hr.divider {
          display: block;
          height: 1px;
          border: none;
          background-color: black;
          margin: 4px 0;
        }

        .content {
          flex: 1 0 auto;
          font-family: 'Roboto Mono', monospace;
        }

        .bottom-container {
          display: flex;
          justify-content: space-between;
        }

        .amount-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }

        .amount-container-emv {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          align-items: center;
          margin: 10px 20px;
          width: 100%;
        }

        .amount-wrapper-emv {
          width: 100%;
        }

        .emv-data-container {
          margin: 5px 0;
          font-size: 12px;
        }

        .wrapper-barcode {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
          font-weight: 400;
        }

        .barcode {
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 250px;
          min-width: 250px;
          object-fit: contain;
          transform: scale(0.9);
        }

        .amount-label {
          min-width: 300px;
        }

        .amount-label,
        .amount-value {
          font-weight: bold;
        }

        .title {
          margin-top: 10px;
          font-size: 24px;
          width: 100%;
        }

        .title-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 20px;
        }

        .title-wrapper-note {
          display: flex;
          justify-content: center;
        }

        .footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 20px;
          flex-shrink: 0;
          font-size: 12px;
        }

        .footer-wrapper {
          display: flex;
          width: 100%;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 10px 20px;
          word-break: break-all;
        }

        .footer-wrapper > .barcode {
          display: none;
        }

        .yourshop {
          font-weight: 600;
          width: 100%;
          word-wrap: break-word;
          word-break: break-all;
          margin-bottom: 10px;
        }

        .footer-address {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
        }

        .side-info-container {
          width: 100%;
          margin-right: 20px;
        }

        .info-container {
          display: none;
          justify-content: center;
          align-items: center;
        }

        .custom-image {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 50px;
          width: 85%;
          height: 250px;
          background-color: #f0f2f4;
        }

        .header-wrapper-container {
          width: 100%;
        }
  
        .header-info-content {
          display: flex;
          width: 100%;
        }

        .bold {
          font-weight: 600;
        }

        .normal {
          font-weight: 500;
        }

        .small-font {
          font-size: 10px;
        }

        .product-container {
          width: 100%;
          font-weight: 300;
        }

        .bottom-container-info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        .bottom-container-info > .wrapper-info:not(:first-child) {
          margin-top: 10px;
        }

        .bottom-container {
          justify-content: center;
          margin-top: 10px;
        }

        .modifiers {
          margin: 5px 15px;
        }

        .sku {
          margin-bottom: 5px;
        }

        .no-break {
          word-break: keep-all;
        }

        .break-word {
          word-wrap: break-word;
          word-break: break-all;
        }

        .full-width {
          width: 100%;
        }

        .product-wrapper {
          display: flex;
          justify-content: space-between;
        }

        .product-price-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: end;
        }

        .modifiers-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        .product-tax-wrapper {
          display: flex;
          justify-content: space-between;
        }

        .product-total-wrapper {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
        }

        .multiple-product {
          margin-right: 10px;
        }

        .refund-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          font-family: 'Roboto Mono', monospace;
          font-weight: 300;
        }

        .refund-header {
          font-size: 12px;
        }

        .refund-amount-container {
          display: flex;
          justify-content: end;
          width: 100%;
        }

        .logo-image {
          width: 175px;
          object-fit: fill;
        }

        .item-discount-container {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        .item-discount-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .notes-wrapper {
          display: flex;
          align-items: center;
          flex-direction: column;
        }

        .emv-data-wrapper {
          display: flex;
          justify-content: space-between;
          margin-top: 2px;
        }

        #gift-header-small {
          display: none;
        }

        @media (max-width: 680px) {
          .receipt {
            margin: 5px;
            padding: 5px;
            transform: scale(0.9);
          }

          #gift-header-small {
            display: block;
          }

          .row {
            flex-direction: column;
            align-items: flex-start;
            margin: 0;
            padding: 0;
          }
          .label,
          .value {
            margin-right: 5px;
          }

          .amount-container {
            justify-content: center;
            width: 100%;
          }

          .footer {
            justify-content: center;
            flex-direction: column;
          }

          .footer-wrapper {
            flex-direction: column;
          }

          .title-wrapper {
            justify-content: center;
          }

          .title-wrapper > img {
            display: none;
          }

          .footer-wrapper > .barcode {
            display: flex;
            align-items: center;
            width: 300px;
          }

          .wrapper-barcode {
            display: none;
          }

          .wrapper-info {
            display: none;
          }

          .mar {
            margin: 0 40px;
          }

          .receipt-container {
            flex-direction: column;
          }

          .receipt-title-logo {
            display: none;
          }

          .wrapper-info-inner {
            display: flex;
            text-align: center;
            margin-top: 15px;
          }

          .header-info-content {
            display: flex;
            flex-direction: column;
            width: 100%;
            }
    
            #order-info-header {
              display: none;
            }

          hr.divider {
            display: block;
            border-bottom: 1px solid black;
          }

          .side-info-container {
            display: none;
          }

          .info-container {
            display: flex;
            flex-direction: column;
          }

          .info-container > .custom-image {
            margin-top: 15px;
            height: 200px;

            object-fit: cover;
          }

          .yourshop {
            text-align: center;
          }

          .item-info {
            max-width: 250px;
          }

          .multiple-product {
            font-size: 10px;
          }

          .amount-label {
            min-width: 0;
          }

          .barcode > img {
            transform: scale(1);
          }

          .modifiers {
            margin: 0 20px 0 0;
          }

          .product-price-wrapper {
            flex-direction: column;
          }

          .multiple-product {
            margin-right: 0px;
          }

          .small-font {
            font-size: 12px;
          }
        }

        @media (max-width: 220px) {
          .item-info {
            font-size: 10px;
          }

          .sku {
            font-size: 10px;
          }

          .yourshop {
            font-size: 9px;
          }

          .footer-address {
            font-size: 10px;
          }

          .wrapper-info-inner > .value {
            padding: 10px;
          }

          .barcode {
            transform: scale(0.95);
          }

          .modifiers,c
          .modifiers > div {
            font-size: 11px;
          }
        }
      </style>
    </head>
      <div class="receipt" id="print">
        ${getHeaderDetails()}
        <div class="content">
          ${
            order_reciept.ShowCustomText
              ? `
            <div class="wrapper-info-inner">
              <div class="value y-pad-small">${
                order_reciept.CustomText ? order_reciept.CustomText : ''
              }</div>
            </div>
          `
              : ''
          }
          ${getProductDetails()}
        </div>
        <div class="footer">
          ${getFooterDetails()}
        </div>

        <div class="refund-container">
          ${getRefundContainer()}
        </div>
      </div>
      </html>
  `;

  //// wrapper -----------------------
  WrapperAppData['openDrawer'] = isPaymentCash == true ? 1 : 0;
  WrapperAppData['siteName'] = '';
  if (order_reciept && order_reciept.ShowWebsiteLink == true) {
    WrapperAppData['siteName'] = site_name;
  }
  WrapperAppData['shopAddress1'] =
    address && address.address_1 ? address.address_1 : '';
  WrapperAppData['shopAddress2'] =
    address && address.address_2 ? address.address_2 : '';
  WrapperAppData['shopZip'] = address && address.zip ? address.zip : '';
  WrapperAppData['shopCity'] =
    address && address.city ? ', ' + address.city : '';
  WrapperAppData['shopCountry'] =
    address && address.country_name ? ', ' + address.country_name : '';
  WrapperAppData['barcode'] = '';
  if (order_reciept.ShowBarcode == true && print_bar_code) {
    WrapperAppData['barcode'] = print_bar_code;
  }
  //WrapperAppData['isTaxIncl'] = taxInclusiveName.toLowerCase().includes("incl") == true ? 1 : 0
  //CASH DRAWER OPENING AS PER THE SETTING

  // var isTizenWrapper = localStorage.getItem("isTizenWrapper");
  // if (isTizenWrapper && isTizenWrapper !== null && typeof isTizenWrapper !== "undefined" && isTizenWrapper == "true") {
  //   if (Tizen && Tizen !== null && typeof Tizen !== "undefined") {
  //     var whenToOpenDrawer = localStorage.getItem('selected_drawer');
  //     if ((isPaymentCash == true && (typeof whenToOpenDrawer !== "undefined" && whenToOpenDrawer !== "" && whenToOpenDrawer == "cash-only")) || (typeof whenToOpenDrawer !== "undefined" && whenToOpenDrawer !== "" && whenToOpenDrawer == "every-sale")) {
  //       Tizen.openCashBox();
  //     }
  //   }
  // }

  

  if (
    type !== 'activity' &&
    typeof Android !== 'undefined' &&
    Android !== null &&
    Android.getDatafromDevice('isWrapper') == true
  ) {
    var whenToOpenDrawer = localStorage.getItem('selected_drawer');
    // if ((isPaymentCash == true && (typeof whenToOpenDrawer !== "undefined" && whenToOpenDrawer !== "" && whenToOpenDrawer == "cash-only")) || (typeof whenToOpenDrawer !== "undefined" && whenToOpenDrawer !== "" && whenToOpenDrawer == "every-sale")) {
    //   Android.openCahsDrawer();
    //   //console.log("---------drawer opening-------");
    // }
  }
  //if (isPaymentCash == true) { sendDataToWrapper("open_cashdrawer", "", { "version": "3.0" }) }
  //var env = localStorage.getItem("env_type");
  // var isTizenWrapper = localStorage.getItem("isTizenWrapper");

  // send data into wrapper app command-------+
  //wrapperReceiptData(WrapperAppData)
  var data = WrapperAppData;
  if(localStorage.getItem("currecySymbole")){
    data['currency'] =localStorage.getItem("currecySymbole");
  }
  var _receiptdata = { version: '3.0', data };
  if (isImgPrint) {
    console.log("PRINTING");
    printImageHandler(myResReceipt, 'receipt_detail');
  } else {
    if (receiptAppData != null) {
      var _receiptAppData = { ...receiptAppData };
      _receiptAppData['data'] = WrapperAppData;

      console.log(_receiptAppData);
      sendDataToWrapper('receipt_detail', '', _receiptAppData);
      postmessage(_receiptAppData);
    } else {
      sendDataToWrapper('receipt_detail', '', _receiptdata);
    }
  }

  // console.log("WrapperAppData", data)
  // if (receiptAppData != null) {
  //   var _receiptAppData = { ...receiptAppData };
  //   _receiptAppData['data'] = WrapperAppData;
  //   sendDataToWrapper('receipt_detail', '', _receiptAppData);
  //   postmessage(_receiptAppData);
  // } else {
  //   sendDataToWrapper('receipt_detail', '', _receiptdata);
  // }

  //-------------------------------------------

  if (doPrint == true) {
    // if (isTizenWrapper && isTizenWrapper !== null && typeof isTizenWrapper !== "undefined" && isTizenWrapper == "true") {

    //   if (Tizen && Tizen !== null && typeof Tizen !== "undefined") {
    //     Tizen.generateReceipt("", JSON.stringify(PrintAndroidReceiptData))
    //   }
    // }
    // else if ((env && env !== '' && env !== 'ios')) { //typeof Android !== "undefined" || Android !== null ||

    //   showAndroidReceipt("", PrintAndroidReceiptData)

    // }
    // else
    {
      
      if (typeof Wrapper !== "undefined" && Wrapper !== null) return true;

      var mywindow = window.open('#', 'my div', "width='400', 'A2'");

      mywindow && mywindow.document && mywindow.document.write(myResReceipt);
      //console.log(myResReceipt);
      if (mywindow) {
        setTimeout(() => {
          mywindow.print();
          if (!isSafari) {
            mywindow.close();
          }
        }, 300);
      }
    }
    return true;
  } else {
    // if doPrint variable value is false, then we are retruing PrintAndroidReceiptData object to ReceiptData extension app
    //return PrintAndroidReceiptData;
    return _receiptdata;
  }
}
