import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Login from '../login/Login';
import Pin from '../pinPage/Pin';
import {compare } from 'compare-versions';
import { NavigateComponent } from '../../settings';
export const ValidateComponent = () => {   
  const navigate = useNavigate()        
      let clientDetail = localStorage.getItem('clientDetail');
       let authKey= localStorage.getItem("AUTH_KEY");
      if (clientDetail && clientDetail !== null && authKey) {
        let isAuth = localStorage.getItem('user');
        if (!isAuth || isAuth == null) {
           // return <Pin />
           if(window.location.pathname !=="/pin")
           NavigateComponent(navigate,'pin');
      }
    }
      else {
        if(window.location.pathname !=="/login")
        navigate("/login")
        //return <Login />
      }

}

export const checkBridgeVirson=()=> {
  var loginRes = localStorage.getItem('clientDetail') && JSON.parse(localStorage.getItem('clientDetail'));

  var versionCompare =true;
  if(loginRes && loginRes.subscription_detail){
      var userSubscription = loginRes.subscription_detail;
      var BridgeVersion = userSubscription && userSubscription.bridge_version;

  if (BridgeVersion ) {
      BridgeVersion = BridgeVersion.replace(/['"]+/g, '')
    
      try { versionCompare= compare(`${BridgeVersion}`, `2.4.0.8`, '>') == true                
      } catch (error) {
          versionCompare =true;
      }
     
  }
}
return versionCompare;
}