import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import LocalizedLanguage from "../../../settings/LocalizedLanguage";
import X_Icon_DarkBlue from '../../../assets/images/svg/X-Icon-DarkBlue.svg';
import STATUSES from "../../../constants/apiStatus";
import { get_UDid } from "../localSettings";
import { sendMail } from "../commonAPIs/sendMailSlice";
import { LoadingModal } from "./LoadingModal";
import { saveCustomerToTempOrder } from "../../customer/CustomerSlice";
const ViewReceipt = (props) => {
    const dispatch = useDispatch();
    const [hasCustomer, setHasCustomer] = useState(null);
    const [orderid, setOrderid] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isSendEmail, setisSendEmail] = useState(false);
    const [customerEmail, setcustomerEmail] = useState("")
    const [isEmailvalid, setisEmailvalid] = useState(false)
    const [oliverRecieptId, setoliverRecieptId] = useState("")
    const [msg, setMsg] = useState('');
    const outerClick = (e) => {
        if (e && e.target && e.target.className && e.target.className === "subwindow-wrapper") {
            props.toggleViewReceipt() && props.toggleViewReceipt();
        }
    }
    const [activitygetdetails] = useSelector((state) => [state.activityGetDetail])
    useEffect(() => {
        if (activitygetdetails && activitygetdetails.status == STATUSES.IDLE && activitygetdetails.is_success && activitygetdetails.data && activitygetdetails.data.content) {
            if (activitygetdetails.data.content && activitygetdetails.data.content.orderCustomerInfo) {
                setHasCustomer(activitygetdetails.data.content.orderCustomerInfo);
                setcustomerEmail(activitygetdetails?.data?.content?.orderCustomerInfo?.customer_email)
                setoliverRecieptId(activitygetdetails?.data?.content?.OliverReciptId)
                setOrderid(activitygetdetails?.data?.content?.order_id);
                setisEmailvalid(true)
            }
        }
    }, [activitygetdetails]);

    const [respSendMail] = useSelector((state) => [state.sendMail])
    useEffect(() => {
        if (respSendMail && respSendMail.status == STATUSES.IDLE && respSendMail.is_success && loading == true) {
            console.log('respSendMail---' + JSON.stringify(respSendMail));
            setMsg("Mail sent");
            setLoading(false);
            props.toggleViewReceipt()
        }
        else if (respSendMail && respSendMail.status == STATUSES.IDLE && respSendMail.is_success == false && loading == true) {
            setMsg("Something went wrong");
            setLoading(false);
        }

    }, [respSendMail]);

    const hundleEmail = (e) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        var value = e.target.value
        setcustomerEmail(value)
        /// Email
        if (!value && typeof value !== 'undefined') {
            setMsg(" Email is required!")
            setisEmailvalid(false)
        } else if (!regex.test(value) && typeof value !== 'undefined') {
            setMsg("This is not a valid email format!")
            setisEmailvalid(false)
        } else {
            setMsg("")
            setisEmailvalid(true)
        }
    }

    const sendReceiptByMail = () => {
        if (customerEmail !== "" && isEmailvalid==true ) {
            var email = customerEmail
            var UID = get_UDid('UDID');
            var requestData = {
                "OrderNo": orderid,
                "EmailTo": email,
                "Udid": UID,
            }
            setLoading(true);
            dispatch(sendMail(requestData));
            dispatch(saveCustomerToTempOrder({ order_id: oliverRecieptId, email_id: customerEmail }))
        }

    }
    return (
        <React.Fragment>
            {loading === true ? <LoadingModal></LoadingModal> : null}
            <div className={props.isShow === true ? "subwindow-wrapper" : "subwindow-wrapper hidden"} onClick={(e) => outerClick(e)}>
                <div className={props.isShow === true ? "subwindow receipt current" : "subwindow receipt"}>
                    <div className="subwindow-header">
                        <p>{LocalizedLanguage.Receipt}</p>
                        <button className="close-subwindow" onClick={() => props.toggleViewReceipt()}>
                            <img src={X_Icon_DarkBlue} alt="" />
                        </button>
                    </div>
                    {/* <div className="subwindow-body">
                        <div className="auto-margin-top"></div>
                        <p>Select how you would like to view receipt:</p>
                        <button onClick={() => props.PrintClick()}>Print</button>
                        <button disabled={hasCustomer == null ? true : false} style={{ opacity: hasCustomer == null ? 0.5 : 1 }} onClick={() => sendReceiptByMail()}>Email</button>
                        <div className="auto-margin-bottom"></div>
                        <p>{msg}</p>
                    </div> */}
                    <div className="subwindow-body">
                        <div className="auto-margin-top"></div>
                        <div className={isSendEmail == false ? "receipt-options" : "receipt-options hidden"}>
                            <p>{LocalizedLanguage.youwouldliktoviewreceipt}:</p>
                            <button onClick={() => props.PrintClick()}>{LocalizedLanguage.print}</button>
                            
                            <button disabled={hasCustomer == null ? true : false} style={{ opacity: hasCustomer == null ? 0.5 : 1 }} onClick={() => setisSendEmail(true)} id="receiptChooseEmail">{LocalizedLanguage.Email}</button>
                        </div>
                        <div className={isSendEmail == true ? "receipt-email" : "receipt-email hidden"}>
                            <label htmlFor="receiptEmail">{LocalizedLanguage.EmailAddress} </label>
                            
                            <input type="email" id="receiptEmail" placeholder={LocalizedLanguage.enterEmailAddress} value={customerEmail} onChange={hundleEmail} />

                            <button disabled={hasCustomer == null ? true : false} style={{ opacity: hasCustomer == null ? 0.5 : 1 }} onClick={() => sendReceiptByMail()}>{LocalizedLanguage.sendReq}</button>
                            <p>{msg}</p>
                        </div>
                        <div className="auto-margin-bottom"></div>
                        <p></p>
                    </div>
                </div></div></React.Fragment>
    )
}

export default ViewReceipt 