import React, { useState, useEffect } from "react";
import EmptyCart from '../../assets/images/svg/EmptyCart.svg';
import Minus_Blue from '../../assets/images/svg/Minus-Blue.svg';
import Plus_Blue from '../../assets/images/svg/Plus-Blue.svg';
import { RoundAmount } from "../common/TaxSetting";
import STATUSES from "../../constants/apiStatus";
import { useSelector } from 'react-redux';
import { NumericFormat } from 'react-number-format';
import { getInclusiveTaxType } from "../../settings/CommonModuleJS";
import { currecyFunction } from "../common/commonFunctions/currencyFunction";
import LocalizedLanguage from "../../settings/LocalizedLanguage";
import { currencyCode, getCustomFeeWC_Register, getProdcutAddons, getWCCustomFeeDetails } from "../../settings/CommonWork";
import { v4 as uniqueKey } from 'uuid';
const RefundCartListBody = (props) => {
    //  props.refreshRefundNote==true
    // const [taxRate, setTaxRate] = useState(0.00);
    const [listItem, setListItem] = useState([]);
    const [getorder, setGetorder] = useState(localStorage.getItem("getorder") ? JSON.parse(localStorage.getItem("getorder")) : {});

    // useEffect(() => {
    //     if (localStorage.getItem("getorder")) {
    //         var _getorder = JSON.parse(localStorage.getItem("getorder"));
    //         setGetorder(_getorder)
    //         var _listItem = _getorder.line_items;
    //         if (typeof _getorder.order_custom_fee != "undefined" && _getorder.order_custom_fee != null && _getorder.order_custom_fee.length > 0) {
    //             _getorder.order_custom_fee.map(f => {
    //                 if (f.hasOwnProperty("pid") && f.pid === 0)
    //                     _listItem.push(f);
    //             })
    //         }
    //         setListItem(_listItem);
    //     }
    // }, [props.refreshRefundNote == true]);

    useEffect(() => {
        calculateCart();
    }, [listItem]);
    useEffect(() => {
        if (localStorage.getItem("getorder")) {
            var _getorder = JSON.parse(localStorage.getItem("getorder"));
            const _taxInclusiveName = getorder ? getInclusiveTaxType(getorder.meta_datas) : "";
            const isIncl = _taxInclusiveName !== "" ? _taxInclusiveName.toLocaleLowerCase().includes("incl") : false;
            setGetorder(_getorder)
            var _listItem = _getorder.line_items;
            //----sending custom fee as lineitem from activity footer section

            // if (typeof _getorder.order_custom_fee != "undefined" && _getorder.order_custom_fee != null && _getorder.order_custom_fee.length > 0) {
            //     _getorder.order_custom_fee.map(f => {
            //         _listItem.push(f);
            //     })
            // }
            var custFeeItemList = getCustomFeeWC_Register(getorder, isIncl);
            _listItem = [...new Set([..._listItem, ...custFeeItemList])];
           
            _listItem = getProdcutAddons(_listItem, _getorder.meta_datas);

            setListItem(_listItem);
        }
    }, [props.refreshRefundNote == true]);
    const updateQuantity = (item, type, modifiers_key) => {
        var _listItem = listItem;
        var id = item.line_item_id;
        var _item = _listItem && _listItem.find(a => a.line_item_id === id);

        var _modifiers = _listItem.filter(a => (a.pid == item.product_id || a.pid == item.variation_id)
            && (a.hasOwnProperty("pid") && a.pid == 0 ? a.note == item.note : true)
            && ((a.hasOwnProperty("fee_id") && item.hasOwnProperty("fee_id")) ? a.fee_id == item.fee_id : true)
            && ((a.hasOwnProperty("modifiers_key") && item.hasOwnProperty("modifiers_key")) ? a.modifiers_key == item.modifiers_key : true)
        )
        if (item.hasOwnProperty("fee_id")) {
            _modifiers = [];
        }
        if (_item && typeof _item != "undefined") {
            if (_item.hasOwnProperty("quantity_to_refund")) {
                if (type === "inc") {
                    if ((_item.quantity - Math.abs(_item.quantity_refunded)) > _item.quantity_to_refund) {
                        _item.quantity_to_refund = _item.quantity_to_refund + 1;
                        _modifiers.map(a => {
                            a.quantity_to_refund = a.quantity_to_refund + 1;
                        });
                    }
                }
                else if (type === "dec") {
                    if (_item.quantity_to_refund > 0) {
                        _item.quantity_to_refund = _item.quantity_to_refund - 1;
                        _modifiers.map(a => {
                            a.quantity_to_refund = a.quantity_to_refund - 1;
                        });
                    }
                }
            }
            else {
                if (type === "inc") {
                    if (_item.quantity + _item.quantity_refunded > 0) {
                        _item["quantity_to_refund"] = 1;
                        _modifiers.map(a => {
                            a["quantity_to_refund"] = 1;
                        });
                    }
                }
            }
        }
        setListItem(_listItem);
        calculateCart();
        props.setRefresh(!props.refresh);

    }
    const calculateCart = () => {
        var refund_subtotal = 0.0;
        var refund_total = 0.0;
        var refund_tax = 0.0;
        // var _totalDiscountedAmount = 0.0;
        // var _customFee = 0.0;
        // var _exclTax = 0;
        // var _inclTax = 0;
        // var _taxId = [];
        // var _taxRate = [];
        // var TaxIs = [];
        // var _subtotalPrice = 0.00;
        // var _subtotalDiscount = 0.00;
        var _cartDiscountAmount = 0.00;
        // var _productDiscountAmount = 0.00;
        // var _seprateDiscountAmount = 0.00;

        var total_refund_amount = getorder && getorder.total_amount;
        var cash_rounding_amount = getorder && getorder.cash_rounding_amount;
        var taxInclusiveName = getorder ? getInclusiveTaxType(getorder.meta_datas) : "";
        var total_tax = 0;
        listItem && listItem.map((item, index) => {
            if (item.hasOwnProperty("quantity_to_refund") && item.quantity_to_refund > 0) {
                
                refund_tax = (parseFloat(item.total_tax / item.quantity) * item.quantity_to_refund);

                if (item.hasOwnProperty("pid") && item.pid != 0) {
                    refund_subtotal += (item.price / item.quantity) * item.quantity_to_refund;
                    refund_tax=parseFloat(refund_tax.toFixed(2));
                    total_tax += refund_tax;
                }
                else {
                    refund_subtotal += item.price * item.quantity_to_refund;
                    total_tax += refund_tax;
                }
                //MODIFIER CART DISCOUNT AMOUNT MINUS
                if (item.hasOwnProperty("cart_discount_amount") && item.cart_discount_amount != 0) {
                    refund_subtotal -= item.cart_discount_amount;
                }
                //if(item.isTaxable===true)
                {
                    // refund_tax = (parseFloat(item.total_tax / item.quantity) * item.quantity_to_refund);
                    // total_tax += refund_tax;
                }
                if (taxInclusiveName && taxInclusiveName !== "") { //in inclusive tax need to add tax intosub total
                    refund_subtotal += refund_tax;
                }
                refund_total = (parseFloat(refund_subtotal) + parseFloat(taxInclusiveName === "" ? total_tax /*refund_tax*/ : 0)); //added tax for exclusive tax
                if (refund_total + (cash_rounding_amount) == total_refund_amount) {
                    refund_total = refund_total + (cash_rounding_amount)
                }
            }
        })


        var _dis = _cartDiscountAmount > 0 ? RoundAmount(_cartDiscountAmount) : 0;
        var refundlistItem = listItem && listItem.filter(a => a.hasOwnProperty("quantity_to_refund") && a.quantity_to_refund > 0);


        props.setValues && props.setValues(refund_subtotal, RoundAmount(total_tax), _dis, refund_total, refundlistItem);
    }
    var _taxInclusiveName = getorder ? getInclusiveTaxType(getorder.meta_datas) : "";
    //var isIncl = _taxInclusiveName !== "" ? _taxInclusiveName.toLocaleLowerCase().includes("incl") : false;
    var isQuantityUpdaterDisble = localStorage.getItem("oliver_refund_order_payments") && JSON.parse(localStorage.getItem("oliver_refund_order_payments")).length > 0 ? true : false;


    return (
        <div className="body">
            <img src={EmptyCart} alt="" />
            {props.hasCustomer == null ?
                null :
                <div className="cart-item"><div className="main-row aligned"><div className="tag customer">{LocalizedLanguage.customer}</div><div className="content-style">{props.hasCustomer.customer_name && props.hasCustomer.customer_name !== '' ? props.hasCustomer.customer_name : props.hasCustomer.customer_email}</div></div></div>
            }

            {(props.refresh == false || props.refresh == true) && listItem && listItem.length > 0 && listItem.map(item => {
                var modifiers = [];
                var item_type = "";
                if (item.refundnote == true) {
                    item_type = "note"
                } else if (item.hasOwnProperty('product_id')) {
                    item_type = "product"
                }
                if (item.hasOwnProperty("fee_id") && item.hasOwnProperty("pid") && item.pid != 0) {
                    item_type = "modifier";
                }
                modifiers = listItem.filter(a => (a.pid == item.product_id || a.pid == item.variation_id) && a.pid != 0
                    && ((a.hasOwnProperty("modifiers_key") && item.hasOwnProperty("modifiers_key")) ? a.modifiers_key == item.modifiers_key : true)
                )
                // var _single_amount='';
                // if (_productCartDiscountAmount == 0 && _indivisualDiscount > 0) {
                //     _single_amount = ((item.subtotal+item.subtotal_tax)/qty);
                // }
                var _price = item.total;
                var _tax = item.total_tax;
                _price = (_price / item.quantity);
                _price = _price * (item.quantity - Math.abs(item.quantity_refunded ? item.quantity_refunded : 0));
                _tax = (_tax / item.quantity);
                _tax = _tax * (item.quantity - Math.abs(item.quantity_refunded ? item.quantity_refunded : 0));
                if (_taxInclusiveName && _taxInclusiveName !== "") { //in inclusive tax need to add tax intosub total
                    _price += _tax;
                }
                switch (item_type) {
                    case "product":
                        return <div className="cart-item" key={uniqueKey()}>
                            <div className="main-row">
                                <p className="quantity">{item.quantity - Math.abs(item.quantity_refunded)}</p>
                                <p className="content-style">{item.name}</p>
                                {/* <p className="price">{(item.quantity + item.quantity_refunded) > 0 ? (currecyFunction(parseFloat((item.price * (item.quantity - Math.abs(item.quantity_refunded))) + (isIncl == true ? (parseFloat(item.total_tax)) : 0)).toFixed(2))) : "0.00"}</p> */}
                                {/* <p className="price">{(item.quantity + item.quantity_refunded) > 0 ? (currecyFunction(parseFloat((item.price * (item.quantity - Math.abs(item.quantity_refunded))) + (isIncl == true ? (parseFloat((item.total_tax/item.quantity) * (item.quantity - Math.abs(item.quantity_refunded)))) : 0)).toFixed(2))) : "0.00"}</p> */}
                                {/* + (isIncl == true ? (parseFloat(item.total_tax / item.quantity)) * (item.quantity - Math.abs(item.quantity_refunded)) : 0)).toFixed(2)}</p> */}
                                <p className="price">{currecyFunction(parseFloat(_price))}</p>
                            </div>                           
                                {item.addons && item.addons.map(i => {
                                     var _addTitle= i.title ? i.title.replace(/_/g, " ") :"";
                                     _addTitle += i.sub_title ? '('+ i.sub_title.replace(/_/g, " ") +')':'';
                                   
                                return <div className="main-row txt-modifier-fields">
                                    <p className="quantity"></p>
                                    {/* {item.quantity - Math.abs(item.quantity_refunded)} */}
                                    <p className="content-style txt-base-grey">{_addTitle}</p>
                                    <p className="price txt-base-grey">{i.amount && i.amount !==""?currecyFunction(parseFloat(i.amount)):""}</p>
                                </div>
                            })}
                            {modifiers && modifiers.map(item => {
                                _price = item.total;
                                _tax = item.total_tax;
                                _price = (_price / item.quantity);
                                _price = _price * (item.quantity - Math.abs(item.quantity_refunded ? item.quantity_refunded : 0));
                                _tax = (_tax / item.quantity);
                                _tax = _tax * (item.quantity - Math.abs(item.quantity_refunded ? item.quantity_refunded : 0));
                                if (_taxInclusiveName && _taxInclusiveName !== "") { //in inclusive tax need to add tax intosub total
                                    _price += _tax;
                                }
                                if (item.hasOwnProperty("product_discount_amount") && item.product_discount_amount != 0) {
                                    _price -= item.product_discount_amount * (item.discount_type === "Number" ? 1 : item.quantity);
                                }
                                if (item.hasOwnProperty("cart_discount_amount") && item.cart_discount_amount != 0) {
                                    _price -= item.cart_discount_amount;// * (item.discount_type === "Number" ? 1 : item.quantity);
                                }
                                return <div className="main-row txt-modifier-fields">
                                    <p className="quantity">{item.quantity - Math.abs(item.quantity_refunded)}</p>
                                    {/* <p className="content-style txt-base-grey">{item.name}</p> */}
                                    <p className="content-style txt-base-grey">{item.name ? item.name.replace(/_/g, " ") : ""}</p>
                                    <p className="price txt-base-grey">{currecyFunction(parseFloat(_price))}</p>
                                </div>
                            })}
                            <div className={isQuantityUpdaterDisble == true ? "increment-input is-disabled" : "increment-input"}>
                                <button onClick={() => updateQuantity(item, 'dec')}>
                                    <img src={Minus_Blue} alt="" />
                                </button>
                                <input type="text" readOnly value={(item.hasOwnProperty("quantity_to_refund") && (item.quantity - Math.abs(item.quantity_refunded)) > 0 ? item.quantity_to_refund : "0") + "/" + (item.quantity - Math.abs(item.quantity_refunded))} />
                                {/* <input type="text" readOnly defaultValue={item.quantity_to_refund} /> */}
                                <button onClick={() => updateQuantity(item, 'inc')}>
                                    <img src={Plus_Blue} alt="" />
                                </button>
                            </div>

                        </div>
                    case "note":
                        return <div className="cart-item" key={uniqueKey()}>
                            <div className="main-row aligned">
                                <div className="tag cart-note">{LocalizedLanguage.note}</div>
                                <p className="content-style line-capped">
                                    {item.Title && item.Title}
                                </p>
                            </div>
                        </div>

                }
            })}


        </div>)
}
export default RefundCartListBody