import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from 'react-redux';
import { get_locName, get_regName, getShopName } from '../common/localSettings'
import imgOpenReg from '../../assets/images/svg/OpenSign.svg';
import LogOut_Icon_White from '../../assets/images/svg/LogOut-Icon-White.svg';

import LocalizedLanguage from '../../settings/LocalizedLanguage';
import { useDispatch, useSelector } from 'react-redux';
// import imgBackSpace from '../../assets/images/svg/Backspace-BaseBlue.svg'
import CashmanagementSlice, { GetOpenRegister, closeRegister, getDetails, cashRecords } from '../cashmanagement/CashmanagementSlice'
// import {createPin, validatePin} from "./pinSlice"
// import { useNavigate } from "react-router-dom";
// import { get_UDid } from "../common/localSettings"; 
import STATUSES from "../../constants/apiStatus";
// import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import PinPad from "./PinPad";
import { get_UDid } from "../common/localSettings";
import LogoutConfirm from "../common/commonComponents/LogoutConfirm";
import { getPostMeta } from "../common/commonAPIs/postMetaSlice";
import { productCount } from "../loadProduct/productCountSlice";
// import { RememberData } from "../../settings";
import { getRates, getTaxRateList } from "../common/commonAPIs/taxSlice";
import { isMultipleTaxSupport } from "../common/commonAPIs/taxSlice";
import { useIndexedDB } from "react-indexed-db";
import { validatePin } from "./pinSlice"
import { tile } from "../dashboard/tiles/tileSlice";
import { ValidateComponent } from "../common/validate";
import { isAutoFocus, sendDataToWrapper } from "../../settings/AndroidIOSConnect";
import { convertImageToBase64, notificationStatus } from "../../settings/CommonWork";
import { getAdvertiseImages } from "../location/locationSlice";
import Config from "../../Config";
import { popupMessage } from "../common/commonAPIs/messageSlice";
import MsgPopup from "../common/commonComponents/MsgPopup";
import { getPaymentTypeName } from "../checkout/checkoutSlice";
import { receiptSetting } from '../serverSetting/receiptSettingSlice';
import { discount } from "../common/commonAPIs/discountSlice";
import { IsIndexDbExist } from "../../settings/CommonJS";
//import $ from "jquery";
const Pin = () => {
    const dispatch = useDispatch();
    const UID = get_UDid('UDID');
    const navigate = useNavigate();
    const [onClick, setOnClick] = useState(false);
    const [isShowLogoutConfirm, setisShowLogoutConfirm] = useState(false)
    const register_Id = localStorage.getItem('register');
    var client = localStorage.getItem("clientDetail") ? JSON.parse(localStorage.getItem("clientDetail")) : '';
    var selectedRegister = localStorage.getItem('selectedRegister') ? JSON.parse(localStorage.getItem("selectedRegister")) : '';
    
    const [isShowMsg, setisShowMsg] = useState(false);
    const [msgTitle, setmsgTitle] = useState('');
    const [msgBody, setmsgBody] = useState('');
    localStorage.removeItem("intercom");
    const toggleMsgPopup = () => {
        setisShowMsg(!isShowMsg)
    }

    const toggleLogoutConfirm = () => {
        setisShowLogoutConfirm(!isShowLogoutConfirm)
        dispatch(validatePin(null));
    }
    if(!register_Id || register_Id==null || register_Id ==undefined ){
        navigate('/register');
    }
   
    if(!localStorage.getItem("AUTH_KEY")){
        navigate('/login');
    }
    // //Clear index db-----------------------------
    // var { clear: customers_clear } = useIndexedDB('customers');
    // var { clear: modifiers_clear } = useIndexedDB('modifiers');
    // var { clear: products_clear } = useIndexedDB('products');
    // if (!sessionStorage.getItem("lastUrl")) {
    //     //Trigger this listener when the view has changed
    //     // sendDataToWrapper("current_view", "pin", "");
    //     customers_clear && customers_clear().then(() => {
    //         //console.log('All Clear customers!');
    //     });
    //     modifiers_clear && modifiers_clear().then(() => {
    //         // console.log('All Clear modifiers!');
    //     });
    //     products_clear && products_clear().then(() => {
    //         //console.log('All Clear products!');
    //     });
    // }
    // //-------------------------------------------
    useEffect(() => {      
        if(notificationStatus()==false|| localStorage.getItem("hasNotifyPermission")=='false')
        {         
           // setTimeout(() => {
                 navigate('/enablenotification?page=pin');
            return;
            //}, 100);           
        }
        setTimeout(() => { //check index db exist or not
            var isdbExist=IsIndexDbExist();                     
            if(isdbExist===false){
                    navigate('/productloader');
            };
        }, 100); 
    },[]);

    let isCallable = true;
    useEffect(() => {     
        if (isCallable === true) {
            //Intercom initialization  
            if (window.location.hostname === Config.key.DEMO_USER_URL) {
                window.Intercom &&  window.Intercom('startTour', Config.key.TOUR_PIN_KEY );                
            } 
            isCallable=false;
        }
        if (!localStorage.getItem("clientDetail") && !localStorage.getItem("rememberme")) {
            navigate('/login')
        }
        if (localStorage.getItem("rememberme") && localStorage.getItem("rememberme") == "true") {
            if (!localStorage.getItem("clientDetail")) {
                navigate('/login')
            }
            //RememberData("set");

        }
        //if (useCancelled == false) {
        if (!sessionStorage.getItem("lastUrl")) { //CHECKED, IF THIS IS NOT TIMEOUT REDIRECT
            var loc_id = localStorage.getItem('Location');
            if( !loc_id || loc_id ==null || loc_id ==""){
                navigate('/location')
            }
            if (loc_id && !localStorage.getItem('customer_advertise_images')) 
            { 
                dispatch(getAdvertiseImages({ "id": loc_id }));
             }

            dispatch(getRates());
            dispatch(isMultipleTaxSupport());
            dispatch(getTaxRateList())
            dispatch(getPaymentTypeName());
            dispatch(receiptSetting());
            dispatch(discount());
            fetchData();
           // dispatch(productCount(get_UDid));
            dispatch(getPostMeta("recent_apps"));
            dispatch(tile("clear"));
            localStorage.removeItem(get_UDid() + "_FavList");
            localStorage.removeItem(get_UDid() + "_NumOfTiles");
            localStorage.removeItem("companylogo_base64");
            localStorage.removeItem("user_shoplogo_base64");

            //REMOVING THESE LOCAL STORAGE, SO WE CAN GET FRESH FROM API'S ON HOME PAGE
            localStorage.removeItem("attribute");
            localStorage.removeItem("category");
            localStorage.removeItem("user_List");
            localStorage.removeItem("discountlst");
            localStorage.removeItem("GET_EXTENTION_FIELD");
           // localStorage.removeItem("PAYMENT_TYPE_NAME");
            localStorage.removeItem("CASH_ROUNDING");
            localStorage.removeItem("paybyproduct");
            localStorage.removeItem("paybyproduct_unpaid");
           
        }
        // }
        // return () => {
        //     useCancelled = true;
        // }
        sendDataToWrapper("current_view", "pin", "");
    }, []);

    const [ respupdateTaxRateList] = useSelector((state) => [state.updateTaxRateList])
    useEffect(() => {
        if (respupdateTaxRateList && respupdateTaxRateList.status === STATUSES.IDLE && respupdateTaxRateList.is_success) {
            getTax();
        }
    }, [respupdateTaxRateList]);
    const [resGetRates, respIsMultipleTaxSupport] = useSelector((state) => [state.getRates, state.isMultipleTaxSupport])
    useEffect(() => {
        if ((resGetRates && resGetRates.status === STATUSES.IDLE && resGetRates.is_success) && (respIsMultipleTaxSupport && respIsMultipleTaxSupport.status === STATUSES.IDLE && respIsMultipleTaxSupport.is_success)) {
            getTax(respIsMultipleTaxSupport.data.content, resGetRates.data.content);
        }
    }, [resGetRates, respIsMultipleTaxSupport]);
    const getTax = (multiple_tax_support, get_tax_rates) => {
        if (multiple_tax_support && multiple_tax_support === true) {
            var taxList = localStorage.getItem('TAXT_RATE_LIST') ? JSON.parse(localStorage.getItem('TAXT_RATE_LIST')) : [];
            if ((typeof taxList !== 'undefined') && taxList !== null && taxList && taxList.length > 0) {
                var taxData = [];
                taxList && taxList.length > 0 && taxList.map(rate => {
                    taxData.push({
                        check_is: rate.check_is,
                        TaxRate: rate.TaxRate ? rate.TaxRate : '0%',
                        TaxName: rate.TaxName ? rate.TaxName : '',
                        TaxId: rate.TaxId ? rate.TaxId : '',
                        Country: rate.Country ? rate.Country : '',
                        State: rate.State ? rate.State : '',
                        TaxClass: rate.TaxClass ? rate.TaxClass : '',
                        Priority: rate.Priority ? rate.Priority : ''
                    })
                })
                localStorage.setItem('TAXT_RATE_LIST', JSON.stringify(taxData));
                if (!localStorage.getItem('DEFAULT_TAX_STATUS')) {
                    localStorage.setItem('DEFAULT_TAX_STATUS', 'true');
                }
            } else {
                localStorage.setItem('DEFAULT_TAX_STATUS', 'true')
                var taxData = [];
                var inactiveTaxData = [];
                if (get_tax_rates && get_tax_rates.length > 0) {
                    get_tax_rates && get_tax_rates.length > 0 && get_tax_rates.map(rate => {
                        taxData.push({
                            check_is: true,
                            TaxRate: rate.TaxRate ? rate.TaxRate : '0%',
                            TaxName: rate.TaxName ? rate.TaxName : '',
                            TaxId: rate.TaxId ? rate.TaxId : '',
                            Country: rate.Country ? rate.Country : '',
                            State: rate.State ? rate.State : '',
                            TaxClass: rate.TaxClass ? rate.TaxClass : '',
                            Priority: rate.Priority ? rate.Priority : ''
                        })
                        inactiveTaxData.push({
                            check_is: false,
                            TaxRate: rate.TaxRate ? rate.TaxRate : '0%',
                            TaxName: rate.TaxName ? rate.TaxName : '',
                            TaxId: rate.TaxId ? rate.TaxId : '',
                            Country: rate.Country ? rate.Country : '',
                            State: rate.State ? rate.State : '',
                            TaxClass: rate.TaxClass ? rate.TaxClass : '',
                            Priority: rate.Priority ? rate.Priority : ''
                        })
                    })
                    localStorage.setItem('TAXT_RATE_LIST', JSON.stringify(inactiveTaxData))
                    if (!localStorage.getItem("SELECTED_TAX")) {
                        localStorage.setItem("SELECTED_TAX", JSON.stringify(inactiveTaxData));
                    }
                    //Update by Nagendra: Remove the tax which has same priority and lower rate, only for default tax..................................
                    taxData && taxData.length > 0 && taxData.map(rate => {
                        var duplicateArr = taxData.filter((ele, index) => ele.TaxClass === rate.TaxClass && ele.Priority === rate.Priority && ele.TaxClass === "");
                        if (duplicateArr && duplicateArr.length > 0) {
                            duplicateArr.map(dup => {
                                if (rate.TaxId < dup.TaxId) {
                                    taxData.splice(taxData.indexOf(dup), 1);
                                }
                            });

                            if (taxData && taxData.length > 0) {
                                var taxfilterData = taxData.filter((ele, index) => ele.TaxClass === "");
                                if (taxfilterData) {
                                    taxData = taxfilterData;
                                }
                            }

                            //..............................................................................
                        }
                    })
                    localStorage.setItem('APPLY_DEFAULT_TAX', JSON.stringify(taxData));
                }
            }

        } else if (!multiple_tax_support || multiple_tax_support === false) {
            var taxList = localStorage.getItem('TAXT_RATE_LIST') ? JSON.parse(localStorage.getItem('TAXT_RATE_LIST')) : [];
            if (taxList && taxList.length === 0) {
                localStorage.setItem('DEFAULT_TAX_STATUS', 'true');
                var taxData = [];
                if (get_tax_rates && get_tax_rates.length > 0) {
                    get_tax_rates && get_tax_rates.length > 0 && get_tax_rates.map(rate => {
                        taxData.push({
                            check_is: true,
                            TaxRate: rate.TaxRate ? rate.TaxRate : '0%',
                            TaxName: rate.TaxName ? rate.TaxName : '',
                            TaxId: rate.TaxId ? rate.TaxId : '',
                            Country: rate.Country ? rate.Country : '',
                            State: rate.State ? rate.State : '',
                            TaxClass: rate.TaxClass ? rate.TaxClass : '',
                            Priority: rate.Priority ? rate.Priority : ''
                        })
                    })
                    var taxRateListIs = []
                    taxRateListIs.push(taxData[0]);
                    localStorage.setItem('APPLY_DEFAULT_TAX', JSON.stringify(taxData))
                    if (typeof multiple_tax_support != "undefined") {
                        localStorage.setItem('TAXT_RATE_LIST', JSON.stringify(taxRateListIs))
                    }
                }
            }
        }
        if (!localStorage.getItem('APPLY_DEFAULT_TAX') && localStorage.getItem('DEFAULT_TAX_STATUS') && localStorage.getItem('DEFAULT_TAX_STATUS') === 'true') {
            var taxData = [];
            var inactiveTaxData = [];
            if (get_tax_rates && get_tax_rates.length > 0) {
                get_tax_rates && get_tax_rates.length > 0 && get_tax_rates.map(rate => {
                    taxData.push({
                        check_is: true,
                        TaxRate: rate.TaxRate ? rate.TaxRate : '0%',
                        TaxName: rate.TaxName ? rate.TaxName : '',
                        TaxId: rate.TaxId ? rate.TaxId : '',
                        Country: rate.Country ? rate.Country : '',
                        State: rate.State ? rate.State : '',
                        TaxClass: rate.TaxClass ? rate.TaxClass : '',
                        Priority: rate.Priority ? rate.Priority : ''
                    })
                    inactiveTaxData.push({
                        check_is: false,
                        TaxRate: rate.TaxRate ? rate.TaxRate : '0%',
                        TaxName: rate.TaxName ? rate.TaxName : '',
                        TaxId: rate.TaxId ? rate.TaxId : '',
                        Country: rate.Country ? rate.Country : '',
                        State: rate.State ? rate.State : '',
                        TaxClass: rate.TaxClass ? rate.TaxClass : '',
                        Priority: rate.Priority ? rate.Priority : ''
                    })
                })
                localStorage.setItem('TAXT_RATE_LIST', JSON.stringify(inactiveTaxData))
                if (!localStorage.getItem("SELECTED_TAX")) {
                    localStorage.setItem("SELECTED_TAX", JSON.stringify(inactiveTaxData));
                }
                //Update by Nagendra: Remove the tax which has same priority and lower rate, only for default tax..................................
                taxData && taxData.length > 0 && taxData.map(rate => {
                    var duplicateArr = taxData.filter((ele, index) => ele.TaxClass === rate.TaxClass && ele.Priority === rate.Priority && ele.TaxClass === "");
                    if (duplicateArr && duplicateArr.length > 0) {
                        duplicateArr.map(dup => {
                            if (rate.TaxId < dup.TaxId) {
                                taxData.splice(taxData.indexOf(dup), 1);
                            }
                        });

                        if (taxData && taxData.length > 0) {
                            var taxfilterData = taxData.filter((ele, index) => ele.TaxClass === "");
                            if (taxfilterData) {
                                taxData = taxfilterData;
                            }
                        }

                        //..............................................................................
                    }
                })
                localStorage.setItem('APPLY_DEFAULT_TAX', JSON.stringify(taxData));
            }
        }

   //Update by Nagendra: Remove the tax which has same priority and lower rate, for TAXT_RATE_LIST..................................
   var taxRateList = localStorage.getItem('TAXT_RATE_LIST') ? JSON.parse(localStorage.getItem('TAXT_RATE_LIST')) : [];
         taxRateList && taxRateList.length > 0 && taxRateList.map(rate => {
            var duplicateArr = taxRateList.filter((ele, index) => ele.TaxClass === rate.TaxClass && ele.Priority === rate.Priority );
            if (duplicateArr && duplicateArr.length > 0) {
                duplicateArr.map(dup => {
                    if (rate.TaxId < dup.TaxId) {
                        taxRateList.splice(taxRateList.indexOf(dup), 1);
                    }
                });
                //..............................................................................
            }
        })
        localStorage.setItem('TAXT_RATE_LIST', JSON.stringify(taxRateList));
    }


    const resGetPostMeta = useSelector((state) => state && state.getPostMeta)
    if (resGetPostMeta && resGetPostMeta.is_success == true) {
        if (resGetPostMeta.data && resGetPostMeta.data.content && resGetPostMeta.data.content.Slug == "recent_apps") {
            localStorage.setItem("recent_apps", resGetPostMeta.data.content.Value)
        }
    }
    // if ($('#whichkey')) {
    //     //inputElement && inputElement !== null && inputElement.current.focus();
    //     $('#whichkey').focus();
    // }


    const [respReceiptSetting, respTaxSetting, resProlductCount] = useSelector((state) => [state.receiptsetting, state.taxsetting, state.productcount])

    if (respReceiptSetting && respReceiptSetting.status == STATUSES.IDLE && respReceiptSetting.is_success) {
        localStorage.setItem('orderreciept', JSON.stringify(respReceiptSetting.data && respReceiptSetting.data.content))
    }
    if (respTaxSetting && respTaxSetting.status == STATUSES.IDLE && respTaxSetting.is_success) {
        localStorage.setItem('TAX_SETTING', JSON.stringify(respTaxSetting.data && respTaxSetting.data.content))
    }
    const fetchData = () => {
        if (UID && register_Id) {
            // this.props.dispatch(favouriteListActions.getAll(UID, register_Id));

            if (client && client.subscription_permission && client.subscription_permission.AllowCashManagement == true && selectedRegister && selectedRegister.EnableCashManagement == true) {
                dispatch(GetOpenRegister(register_Id));
            }
            else {
                localStorage.setItem("IsCashDrawerOpen", "false");
            }
        }
    }
    //CUSTOMER FRONT FACING ADVERTISE IMAGE IF NOT LOADING ON REGISTER PAGE
    const [respGetAdvertiseImages] = useSelector((state) => [state.getAdvertiseImages])
    useEffect(() => {
        if ((respGetAdvertiseImages && respGetAdvertiseImages.status === STATUSES.IDLE && respGetAdvertiseImages.is_success)) {
            var imgArray = [];
            respGetAdvertiseImages.data.content && respGetAdvertiseImages.data.content.map(d => {
                imgArray.push({ "data": "", "type": (d.hasOwnProperty("Type") ? d.Type : ""), "imageUrl": (Config.key.OP_IMAGE_URL + "" + (d.hasOwnProperty("ImagePath") ? d.ImagePath : d)) });
            })
            localStorage.setItem("AdvImages", JSON.stringify(imgArray));
        }
    }, [respGetAdvertiseImages]);
    //----------


    const { status, dataone, error, is_success } = useSelector((state) => state.GetOpenRegister)
    // console.log("status", status, "dataone", dataone, "error", error, "is_success", is_success)

    if (status === STATUSES.IDLE && is_success) {
        if (dataone && dataone.content && dataone.content !== undefined) {
            if (dataone.content && dataone.content !== '' && dataone.content !== 0) {
                localStorage.setItem("IsCashDrawerOpen", "true");
                localStorage.setItem("Cash_Management_ID", dataone.content.Id);
            } else {
                localStorage.setItem("IsCashDrawerOpen", "false");
                localStorage.removeItem("Cash_Management_ID");
            }

        }else{          
            localStorage.removeItem("Cash_Management_ID");
        }
    }


    const hundleTrue = () =>   { 
        setOnClick(true)
    }
    const goToCloseRegister = () => {
        if (window.location.hostname === Config.key.DEMO_USER_URL) {
            setmsgBody(LocalizedLanguage.demoModeCloseRegisterMsg)
            toggleMsgPopup();
            return;
        }
        // dispatch(closeRegister(null));
        dispatch(closeRegister("clear"));
        dispatch(cashRecords("clear"))
        setTimeout(() => {
            navigate("/closeregister")
        }, 100);

    }

    const reSync=()=>{
        navigate("/productloader")
    }
   //console.log(" this.props.product_tour_id", this.props.match.params)
    return <React.Fragment>
        <div className="idle-register-wrapper" onClick={hundleTrue}>
            {window.location.hostname === Config.key.DEMO_USER_URL ? "" :
                <button id="logoutRegisterButton" onClick={() => toggleLogoutConfirm()}>
                    <img src={LogOut_Icon_White} alt="" />
                    {LocalizedLanguage.logout}
                </button>}
                <button id="resync" onClick={() => reSync()}>                    
                    {"Re-sync"}
                </button>
            <header>
                <img src={imgOpenReg} alt="" />
                <div className="col text-center">
                    <p className="style1">{getShopName()}</p>
                    <div className="divider divider-pin devider-width"></div>
                    <p className="style2">{get_regName()}</p>
                    <p className="style3">{get_locName()}</p>

                    <button id="closeRegister1" disabled={client && client.subscription_permission && client.subscription_permission.AllowCashManagement == true && selectedRegister && selectedRegister.EnableCashManagement !== true ? true : false} style={{ opacity: client && client.subscription_permission && client.subscription_permission.AllowCashManagement == true && selectedRegister && selectedRegister.EnableCashManagement !== true ? 0.5 : 1 }} onClick={goToCloseRegister}  >{LocalizedLanguage.closeRegister}</button>
                </div>
            </header>
            <main>{<PinPad autoFocus={isAutoFocus == true ? true : false} onClick={onClick}></PinPad>} <button id="closeRegister2" onClick={() => navigate("/closeregister")}>{LocalizedLanguage.closeRegister}</button></main>
        </div>
       
        <MsgPopup isShow={isShowMsg} toggleMsgPopup={toggleMsgPopup} msgTitle={msgTitle} msgBody={msgBody}></MsgPopup>
        {isShowLogoutConfirm === true ? <LogoutConfirm isShow={isShowLogoutConfirm} toggleLogoutConfirm={toggleLogoutConfirm}></LogoutConfirm> : null}
    </React.Fragment>
}

export default Pin