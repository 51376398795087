import  React,{ useEffect, useState } from "react";
import { useIndexedDB } from "react-indexed-db";
import LocalizedLanguage from "../../../settings/LocalizedLanguage";
import down_angled_bracket from '../../../assets/images/svg/down-angled-bracket.svg';
import { currecyFunction } from "../../common/commonFunctions/currencyFunction";
const ProductAddons=(props)=>{
    const [productAddons, setAddOns] = useState([]);
    const [selectedAddOns,setselectedAddOns]=useState([]);
    const [toggleDrowpdown, settoggleDrowpdown] = useState(false);
    const [addOnSelectValue, setAddOnSelectValue] = useState('');
    const [requiredAddons, setRequiredAddons] = useState([]);
    const [isDisable, setIsDisable] = useState(false);
  
    const { getByID: getProductByID, getAll: getAllProducts,update } = useIndexedDB("products");
    useEffect(()=>{
      
        if(props.isClear && props.isClear==true){    
            setAddOns([]);    
            setAddOnSelectValue('');
            setselectedAddOns([]); 
            setTimeout(() => {
                getAddons(props.product);  
            }, 1000);
                     
        }else{
            setAddOnSelectValue('');
            setAddOns([]);
            setselectedAddOns([]); 
              getAddons(props.product);
        }
        
    },[props.product,props.isClear]  )
   
    const getAddons = async (_product = null, isClear = false) => {
       // console.log("_product",_product) 
       // console.log("props.variationProduct",props.variationProduct )     
       // var _modifierList = rows;
        var product = props.variationProduct ? props.variationProduct : props.selProduct;
        if (_product != null) {
            product = _product;
        }
        //console.log("_product",product)
        if (product == null || typeof product == "undefined")
            return;

        if(product.ParentId ==0 && (product.Type=="variation" || product.Type=="variable")){ //if variation not selected then addons is disable
            setIsDisable(true)
        }else{
            setIsDisable(false)
        }
        if(product.ParentId !==0){
            var aa = await getProductByID(product.ParentId).then((row) => {
                return row;
            });
            if (aa &&  product.ProductMetaJson =="[]") {
                product=aa;
            }
        }     
        
        var all_addons=[];
        if(product.ProductMetaJson !=="")
        {
           var jsonMeta= product.ProductMetaJson;
           if(jsonMeta && jsonMeta !==""){
            var _jsonMeta=JSON.parse(jsonMeta)

            if(_jsonMeta.length>0){
                var _addon=  _jsonMeta.filter(item=>(item.slug=="oliver_addons_data"))
                _addon && _addon.map(i=>{
                    i.value && i.value.map(i=>{
                        var gpid = props.product.WPID+"_" + (i.name).replace(/ /g, "_"); 
                        i["id"]= gpid; 
                        all_addons.push(i);
                    })
                    
                })
              
            }
           
           // console.log("all_Addons",all_addons)
            if(all_addons && all_addons.length>0){
                var reqAddon= all_addons.filter(ra=> ra.required==1 && (ra.type=='radiobutton' || ra.type=='custom_text' || ra.type=='checkbox' || ra.type=='inputNumber' || ra.type=='select'))
                if(reqAddon && reqAddon.length>0){
                    setRequiredAddons(reqAddon)
                   // console.log("reqAddon",reqAddon)
                }
            }
            props.setAddonsValue([],reqAddon)   
            setAddOns(all_addons);

           }
        }
    }
    const onChangeAddon = (event,selectedData) => {
        if(isDisable==true){
            return;
        }
        // if(selectedAddOns.length==0)
        // {
        //     getModifiers();
        // }
        var add_sub = "";
        var amount_type = "";
        // if (event.target.getAttribute("id").includes("quantityUpdater")) {
        //     if (event.target.value == "")
        //         event.target.value = 1;
        //     var amount = event.target.getAttribute("data-amount") && event.target.getAttribute("data-amount") * event.target.value;
        //     amount_type = event.target.getAttribute("data-amount-type") && event.target.getAttribute("data-amount-type");
        //     var newid = event.target.getAttribute("id").replace("quantityUpdater", "amount");
        //     document.getElementById(newid).value = amount_type + " " + parseFloat(amount).toFixed(2);
        // }
        var gpid = "";
        var gpname_Name = "";
        
        if (event){
        amount_type = event.target.getAttribute("data-amount-type") && event.target.getAttribute("data-amount-type");
        var gpid = event.target.getAttribute("data-gpid") ? event.target.getAttribute("data-gpid") : "";
        var gpname_Name = event.target.getAttribute("data-gparent-name") ? event.target.getAttribute("data-gparent-name") : "";
        
        if (event.target.value == "Select"){
            var newAddons= selectedAddOns.filter(obj => obj.id !== gpid) ;
            setselectedAddOns(newAddons);    
            return ;
        }
        add_sub = event.target.getAttribute("data-add-sub") && event.target.getAttribute("data-add-sub");
    }
        var data = {};
        var action = "add_update";
        var _qty = 1;       
        if (selectedData || event.target.type) {
            
            if( !event && selectedData){          
                data = { id:selectedData.gpid,title:selectedData.gparent, sub_title: selectedData.name, qty: _qty, amount: selectedData.amount, add_sub: selectedData.addsub, amount_type: selectedData.amounttype, field_type: "2" }; 
                gpid = selectedData.gpid;
                gpname_Name = selectedData.name;
            }else{
              
            switch (event.target.type) {
                case "number":
                    data = { id: event.target.getAttribute("id").replace("-quantityUpdater", ""),title:gpname_Name, sub_title: event.target.getAttribute("name"), qty: event.target.value, amount: event.target.getAttribute("data-amount"), add_sub: add_sub, amount_type: amount_type, field_type: "1" };
                    break;
                case "radio":
                    action = "update";
                    data = { id: gpid,title:gpname_Name, sub_title: event.target.value, qty: _qty, amount: event.target.getAttribute("data-amount"), add_sub: add_sub, amount_type: amount_type, field_type: "2" };
                    break;
                case "checkbox":
                    if (event.target.checked == false) { action = "remove"; }
                    data = { id: event.target.getAttribute("id"), title:gpname_Name,sub_title: event.target.value, qty: _qty, amount: event.target.getAttribute("data-amount"), add_sub: add_sub, amount_type: amount_type, field_type: "3" };
                    break;
                case "text":
                    if (event.target.value == "")
                        return
                    data = { id: event.target.getAttribute("id"),title:gpname_Name, sub_title: event.target.value, qty: _qty, amount: event.target.getAttribute("data-amount"), add_sub: add_sub, amount_type: amount_type, field_type: "0" };
                    break;
                case "select-one":
                    action = "update";
                        if (event.target.value == "")
                        return;
                        var selectData= event.target.selectedOptions[0].dataset;
                        var selectedText=event.target.selectedOptions[0].label;                        
                        data = { id: event.target.getAttribute("id"),title:gpname_Name, sub_title: selectedText, qty: _qty, amount: selectData.amount, add_sub: add_sub, amount_type: selectData.amountType, field_type: "2" };                        
                    
                   break;
                default:
                    break;
            }
              
        }   
           // console.log("data",data);
          data["product_id"]=props.product.WPID;
             productAddons.map(obj => {
                    if (obj.id === gpid) {
                        obj.is_active = true;
                        //var index =  obj.data?obj.data.findIndex(object => object.id == data.id || (object.sub_title.replace(/ /g, "_") + object.id) == data.id) :-1;

                      var index=null;
                      var _selectedAddOns=selectedAddOns;
                       if(_selectedAddOns && _selectedAddOns.length>0){
                        index =  _selectedAddOns.findIndex(object => object.id == data.id)
                       } 
                        if (index==null || index === -1) { 
                                 _selectedAddOns.push(data);
                         }
                        else {
                            if(action=="remove"){
                                _selectedAddOns.splice(index,1);
                            }else{
                             _selectedAddOns[index] = data;
                             }
                            }
                        setselectedAddOns(_selectedAddOns);    
                        var reqAddon=requiredAddons;
                        if(reqAddon.length>0 && action !=="remove"){
                            var rIndex=reqAddon.findIndex(object => object.id == data.id);
                            if(rIndex !==-1){
                                 reqAddon.splice(rIndex ,1)
                            }
                           
                        } 
                        setRequiredAddons(reqAddon);                        
                        props.setAddonsValue(_selectedAddOns,reqAddon)                  
                    }
                   
                });
                
               // this.setState({ selectedAddOns: update_data });
            
           // if (action == "add_update") {
           // var addons= selectedAddOns && selectedAddOns.length>0? selectedAddOns:
                // var update_data = productAddons.map(obj => {
                //     if (obj.modifier_id === gpid) {
                //         obj.is_active = true;
                //         var index = obj.data.findIndex(object => object.id == data.id || (object.sub_title.replace(/ /g, "_") + object.id) == data.id);

                //         if (index === -1) { obj.data.push(data); }
                //         else { obj.data[index] = data; }
                //         return obj;
                //     }
                //     return obj;
                // });
               // setselectedAddOns(data);
                // this.setState({ selectedAddOns: update_data });
            // }
            // else if (action == "remove") {
            //     var update_data = selectedAddOns.map(md => {
            //         if (md.modifier_id === gpid) {
            //             md.is_active = true;
            //             md.data = md.data.filter(d => d.id !== data.id);
            //         }
            //         return md;
            //     });
            //     // this.setState({ selectedAddOns: update_data });
            //     setselectedAddOns(update_data)
            // }
            // else if (action == "update") {
            //     var update_data = selectedAddOns.map(md => {
            //         if (md.modifier_id === gpid || md.id == gpid) {
            //             md.is_active = true;
            //             md.data = [data];
            //         }
            //         return md;
            //     });
            //     setselectedAddOns(update_data)
            // }
        }
       // console.log("seleced addons ",selectedAddOns )
    }
    
    const hundledropdown = () => {
        settoggleDrowpdown(!toggleDrowpdown)
    }
    const billingHandleSearch = (event) => {
        let value = event.target.value;
        setAddOnSelectValue(value)
       // console.log("value",value)
        // if (props.editcustomerparam == "editcustomer") {
        //     setValues({
        //         ...values,           
        //     });
        //     // setValues({
        //     //     billingCountry: ''
        //     // })
        // }
    }
     // Billing DropDown Click  
     const onChangeSelectAddon = (code, name) => {      
        if(isDisable==true){
            return;
        } 
        setAddOnSelectValue({key: name ,value:code});
      
        // setbillingCountryCode(code)      
        // setBillingSerachString(name)
      
    }
    //console.log("productAddons",productAddons)
    return (
        <React.Fragment>
    {
        productAddons && productAddons.length>0 && <div className="row">
         <p>{LocalizedLanguage.selectaddOnd}</p>
         </div>
   }
   {
       productAddons && productAddons.map(mod => {
          var gpid = mod.id;        
           var gpname = mod.name;
           var type= mod.type;// mod.type=="custom_text"? "textbox":mod.display;
           if(mod.type=='multiple_choice' && mod.display=='radiobutton'){
            type= mod.display;
           }
            if( mod.type ==undefined ){
            type= mod.display
           }
            if(mod.type ==false && mod && mod.options && mod.options.length>0){
            type='checkbox'
           }
           if(mod.price_type=="quantity_based"){
            type='inputNumber'
           }     
        //console.log("requiredAddons",requiredAddons)
        if(requiredAddons && requiredAddons.length>0){
            var isFound=requiredAddons.find(f=>f.id==mod.id)
            if(!isFound){
                mod.required=0;
            }
        }else{
            mod.required=0
        }

           switch (type) {
            case "inputNumber" :
                   return (//** HIDE THIS BECAUSE WE HAVE NOT DESIGN FOR IT 23-DEC-2022 */
                       <React.Fragment>                                                  
                           {
                              // mod.modifierFields && mod.modifierFields.map(mf => {
                                 //  return (
                                      // mf.ExtendFormData && mf.ExtendFormData.map(efm => {
                                      // var id = (mod.name).replace(/ /g, "_");
                                       //return (
                                       <React.Fragment>

                                           <p className={mod.required==1?"error-addon":""}>{mod.name}({currecyFunction(mod.price !=="" ?mod.price:0)})</p>
                                           <input  disabled={isDisable}  id={gpid} type="number" min="1" max="5" name={(mod.name).replace(/ /g, "_") + "-txt"} defaultValue="" data-amount={mod.price} data-amount-type={mod.price_type} data-gparent-name={gpname} data-gpid={gpid} data-add-sub={mod.AddnSubtract} onChange={onChangeAddon}  />
                                           
                                       </React.Fragment>
                                       //)
                                  // })
                                 //  )
                              // })
                           }

                       </React.Fragment>
                   )
                   break;
              case  'multiple_choice':
               case  'checkbox':
                   return (
                       <React.Fragment>
                           <p className={mod.required==1?"error-addon":""}>{mod.name}</p>
                           <div className={ isDisable ==true?"radio-group":"radio-group"}  onChange={onChangeAddon}>{
                               mod.options && mod.options.map(efm => {                               
                                var addonPrice=efm.price !=="" ?efm.price:0;
                                var amtPercent=efm.price_type=="percentage_based"?amtPercent=addonPrice+ "%": currecyFunction(addonPrice);                               
                                   //return (mf.ExtendFormData && mf.ExtendFormData.map(efm => {
                                       var id = (efm.label != null && typeof efm.label != "undefined") && (efm.label).replace(/ /g, "_");
                                       return (
                                           <label>
                                               <input disabled={isDisable} type="checkbox" id={id} name={efm.label} value={id} data-checked-value={efm.price} data-gparent-name={gpname} data-gpid={gpid} data-amount={efm.price} data-amount-type={efm.price_type} onChange={null} /*checked={efm.Default=="yes"?true:false}*/ /> 
                                               {/* checked={preselectedAddOns(gpname, efm.Name)}  */}
                                               <div className={ isDisable ==true?"custom-radio btn-disable":"custom-radio"}>
                                                   <p>{efm.label}({amtPercent})</p>
                                               </div>
                                           </label>)
                                   //}))
                               })
                           }</div></React.Fragment>
                   )
                   break;            
               case "radiobutton":
                   return (
                       <React.Fragment>
                           <p className={mod.required==1?"error-addon":""}>{mod.name}</p>
                           <div className="radio-group" onChange={onChangeAddon}>{
                               mod.options && mod.options.map(efm => {
                                var addonPrice=efm.price !=="" ?efm.price:0;
                                var amtPercent=efm.price_type=="percentage_based"?amtPercent="%"+addonPrice: currecyFunction(addonPrice);
                                   //return (mf.ExtendFormData && mf.ExtendFormData.map(efm => {
                                       var id = (efm.label != null && typeof efm.label != "undefined") && (efm.label).replace(/ /g, "_");
                                       return (
                                           <label htmlFor={id}>
                                               <input disabled={isDisable} type="radio" id={id} name={mod.name} value={efm.label} data-checked-value={efm.label} data-gparent-name={gpname} data-gpid={gpid} data-amount={efm.price} data-add-sub={efm.AddnSubtract} data-amount-type={efm.price_type} onChange={null} /*checked={efm.Default=="yes"?true:false}*/ /> { /*checked={preselectedAddOns(gpname, efm.Name)} */}
                                               <div className={ isDisable ==true?"custom-radio btn-disable":"custom-radio"}>
                                                   <p>{efm.label}({amtPercent})</p>
                                               </div>
                                           </label>)
                                   //})
                                   //)
                               })
                           }</div></React.Fragment>
                   )
                   break;
               case "custom_textarea" :
               case "custom_text" :
                   return (//** HIDE THIS BECAUSE WE HAVE NOT DESIGN FOR IT 23-DEC-2022 */
                       <React.Fragment>                                                  
                           {                           
                              // mod.modifierFields && mod.modifierFields.map(mf => {
                                 //  return (
                                      // mf.ExtendFormData && mf.ExtendFormData.map(efm => {    
                                      // var id = (mod.name).replace(/ /g, "_");
                                       //return (
                                       <React.Fragment>

                                           <p className={mod.required==1?"error-addon":""} >{mod.name}{mod.price !=="" && "("} {mod.price !=="" ? currecyFunction(mod.price !==""? mod.price :"0")  :""} {mod.price !=="" && ")"}</p>
                                           <input  disabled={isDisable}  id={gpid} type="text" name={(mod.name).replace(/ /g, "_") + "-txt"} defaultValue="" data-amount={mod.price} data-amount-type={mod.price_type} data-gparent-name={gpname} data-gpid={gpid} data-add-sub={mod.AddnSubtract} onChange={onChangeAddon} placeholder={LocalizedLanguage.enterYourtext} />
                                       </React.Fragment>
                                       //)
                                  // })
                                 //  )
                              // })
                           }

                       </React.Fragment>
                   )
                   break;
               case "select":
                   return (
                   <React.Fragment>
                           <p className={mod.required==1?"error-addon":""}>{mod.name}</p>                         
                           <div className={ isDisable==false && toggleDrowpdown === true ? "dropdown-wrapper open " : "dropdown-wrapper"}  onClick={()=>hundledropdown()}>
                            <img src={down_angled_bracket} alt="" />
                            <input  disabled={isDisable}  type="text" id="employee" placeholder="Select option" readonly="true" value={addOnSelectValue ? addOnSelectValue.key +"("+localStorage.getItem("currecySymbole") +parseFloat(addOnSelectValue.value).toFixed(2)+")" : ""} onChange={e => { isDisable==false && billingHandleSearch(e);isDisable==false && settoggleDrowpdown(true) }} />
                            <div className="option-list">
                            {  
                                mod.options && mod.options.map(efm => {
                                    var data={amount:efm.price, addsub:efm.AddnSubtract, gpid:gpid,amounttype:efm.price_type,value:efm.label,name:efm.label, gparent:gpname};
                                    var addonPrice=efm.price !=="" ?efm.price:0;
                                    var amtPercent=efm.price_type=="percentage_based"?amtPercent="%"+addonPrice: currecyFunction(addonPrice);
                                return <div className="option" onClick={() => {onChangeAddon(this,data) ; onChangeSelectAddon(efm.price, efm.label)}} >
                                    {/*  */}
                                <p data-amount={efm.price} data-add-sub={efm.AddnSubtract} data-gpid={gpid} data-amount-type={efm.price_type} value={efm.price}> {efm.label}({amtPercent}) </p>
                                </div>                               
                                })
                            }
                            </div>
                            </div>
                         
                          
                           </React.Fragment>
                   )
                   break;
                   //    case 'textfield':
            //        return (
            //            //** HIDE THIS BECAUSE WE HAVE NOT DESIGN FOR IT 23-DEC-2022 */
            //            <React.Fragment>
            //                <p className="labelTitle">{mod.Title}</p>
            //                {
            //                    mod.modifierFields && mod.modifierFields.map(mf => {
            //                        return (mf.ExtendFormData && mf.ExtendFormData.map(efm => {
            //                            var id = ((efm.Name != null && typeof efm.Name != "undefined") ? efm.Name : String(efm.ModifierId)).replace(/ /g, "_");
            //                            return (<React.Fragment>
            //                                <p>{efm.Name}</p>
            //                                <div className="increment-input" onChange={onChangeAddon}>
            //                                    <button onClick={qunatityChange} data-parent-id={id} data-btn-type="minus" data-gparent-name={gpname} data-gpid={gpid} data-add-sub={efm.AddnSubtract}>
            //                                        <img src={Checkout_Minus} alt="" />
            //                                        <input id={id + "-amount"} type="text" defaultValue={efm.Type + " " + efm.Amount} data-amount-type={efm.Type} readOnly className='hide' />
            //                                    </button>
            //                                    <input onKeyDown={(e) => validateEnteredValue(e)} id={id + "-quantityUpdater"} type="text" readOnly={true} name={id} data-max-number={efm.Maxnumber} data-min-number={efm.Minnumber} defaultValue={efm.Startingnumber} data-amount={efm.Amount} data-amount-type={efm.Type} data-gparent-name={gpname} data-gpid={gpid} data-add-sub={efm.AddnSubtract} />
            //                                    <button id="btn_dv_plus_popup" onClick={qunatityChange} data-parent-id={id} data-btn-type="plus" data-gparent-name={gpname} data-gpid={gpid} data-add-sub={efm.AddnSubtract}>
            //                                        <img src={Checkout_Plus} alt="" />
            //                                    </button>
            //                                </div>
            //                            </React.Fragment>)
            //                        }))
            //                    })
            //                }
            //            </React.Fragment>
            //            //**END */
            //        )
            //        break;
               default:
                   break;
           }
       })
       
   }
   </React.Fragment>
    )
} 
export default ProductAddons;