
import {React} from 'react'
const SkeletonBox=()=>{
    return(
<div className="activity-skeleton-container">
                                <div className="activity-skeleton-card">
                                    <div className="input"></div>
                                    <div className="button"></div>
                                    <div className="button"></div>
                                    <div className="button"></div>
                                </div>
                                <div className="activity-skeleton-card">
                                    <div className="input"></div>
                                    <div className="button"></div>
                                    <div className="button"></div>
                                    <div className="button"></div>
                                </div>
                                <div className="activity-skeleton-card">
                                    <div className="input"></div>
                                    <div className="button"></div>
                                    <div className="button"></div>
                                    <div className="button"></div>
                                </div>
                                <div className="activity-skeleton-card">
                                    <div className="input"></div>
                                    <div className="button"></div>
                                    <div className="button"></div>
                                    <div className="button"></div>
                                </div>
                                <div className="activity-skeleton-card">
                                    <div className="input"></div>
                                    <div className="button"></div>
                                    <div className="button"></div>
                                    <div className="button"></div>
                                </div>
                                <div className="activity-skeleton-card">
                                    <div className="input"></div>
                                    <div className="button"></div>
                                    <div className="button"></div>
                                    <div className="button"></div>
                                </div>
                                <div className="activity-skeleton-card">
                                    <div className="input"></div>
                                    <div className="button"></div>
                                    <div className="button"></div>
                                    <div className="button"></div>
                                </div>
                              </div>);
}
export default SkeletonBox;